import {
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  provideEnvironmentInitializer,
  Provider
} from '@angular/core';
import { Store, StoreModule } from '@softline/core';
import { TranslationServiceStoreAdapter } from '../i18n/translation-service-store.adapter';
import * as LocalizationStore from './localization.store';
import {
  SOFTLINE_CONFIG_DEFAULT_LOCALE,
  SOFTLINE_DATA_LOCALIZATIONS,
  SOFTLINE_FEATURE_LOCALIZATION
} from './l10n.shared';
import { MomentCalendarService } from '../services/moment-calendar.service';
import { LocalizationFile } from './data/localization-file';

export const provideLocalization: (module: LocalizationFile) => Array<Provider | EnvironmentProviders> = (module: LocalizationFile) => {
  return [
    { provide: SOFTLINE_DATA_LOCALIZATIONS, useValue: module, multi: true }
  ];
}

export const provideL10N: () => Array<Provider | EnvironmentProviders> = () => {
  return [
    //TODO: remove if TranslationStore is removed
    provideEnvironmentInitializer(() => {
      inject(TranslationServiceStoreAdapter);
      const defaultLocale = inject(SOFTLINE_CONFIG_DEFAULT_LOCALE);
      const calendarService = inject(MomentCalendarService);
      calendarService.setLocale(defaultLocale);
      const store = inject(Store);
      store.dispatch(
        SOFTLINE_FEATURE_LOCALIZATION,
        LocalizationStore.actions.changeLocale,
        { locale: defaultLocale }
      );
    })
  ]
}

