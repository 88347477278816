import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { inject, ModuleWithProviders, NgModule, provideEnvironmentInitializer } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ApiResourceLocation,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SOFTLINE_STORE_FEATURES,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import { SOFTLINE_CONFIG_PREVIEW_HANDLER, TranslationStore2, UiCoreModule } from '@softline/ui-core';
import {
  SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME,
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_FEATURE_APP_INFO,
  SOFTLINE_FEATURE_APPLICATIONS,
  SOFTLINE_FEATURE_ARCHIVE,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_MODULE,
  SOFTLINE_FEATURE_NOTIFICATION,
  SOFTLINE_FEATURE_SETTINGS,
  SOFTLINE_FEATURE_TITLE
} from './application.shared';
import { ArchiveService } from './archive/services/archive.service';
import { IsFileDownloadingPipe } from './pipes/is-file-downloading.pipe';
import { SettingsComponent } from './settings/components/settings.component';
import { SettingsService } from './settings/services/settings.service';
import {
  UploadFileDialogComponent
} from './archive/components/dialogs/upload-file-dialog/upload-file-dialog.component';
import { ArchiveListComponent } from './archive/components/archive-list/archive-list.component';
import { DownloadUrlPipe } from './archive/pipes/download-url.pipe';
import { IsDownloadingPipe } from './archive/pipes/is-downloading.pipe';
import { CommonAppInfoService } from './app/services/common-app-info.service';
import { AppInfoService } from './app/services/app-info.service';
import * as AppInfoStore from './app/app-info.store';
import * as TitleStore from './program/title.store';
import * as CommandStore from './program/command.store';
import * as ModuleStore from './app/module.store';
import * as SettingsStore from './settings/settings.store';
import * as ArchiveStore from './archive/archive.store';
import { DashboardComponent } from './dashboard/components/dashboard.component';
import { ModuleInterceptor } from './app/interceptors/module.interceptor';
import { AppInfoInterceptor } from './app/interceptors/app-info.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackNavigationService } from './program/back-navigation/back-navigation.service';
import { RefreshService } from './program/services/refresh.service';
import { ApplicationStore } from './app/application.store';
import { default as de } from '../i18n/de.json';
import { default as en } from '../i18n/en.json';
import { ModuleService } from './app/services/module.service';
import { AddOnService } from './program/services/add-on.service';
import { ArchiveImagePreviewHandler } from './archive/services/archive-image-preview.handler';
import { MengenEingabeComponent } from './components/mengen-eingabe/mengen-eingabe.component';
import { StepHeaderComponent } from './components/step-header/step-header.component';
import {
  BlockingLoadingSpinnerComponent
} from './components/blocking-loading-spinner/blocking-loading-spinner.component';
import { NotificationStore } from './notifications/notifications.store';
import { SOFTLINE_API_NOTIFICATION } from './application.api';
import { filerAndSortProviders } from './filter-and-sort/filer-and-sort.providers';
import { AudioService } from './services/audio.service';
import { AppInfoSettingsComponent } from './app/settings/app-info/app-info-settings.component';
import { ApplicationSettingsComponent } from './app/settings/application/application-settings.component';
import { JwtAuthenticationStore, SOFTLINE_FEATURE_JWT_AUTHENTICATION } from '@softline/auth';
import { StartupRouteService } from './app/services/startup-route.service';
import { SortHeaderDirective } from './filter-and-sort/directive/sort-header.directive';
import { FilterInputComponent } from './filter-and-sort/components/filter-input/filter-input.component';
import { BottomOutletService } from './program/services/bottom-outlet.service';
import { BottomTabGroupService } from './program/services/bottom-tab-group.service';
import { TitleStore2 } from './program/title.store2';
import { MenuItemStore2 } from './menu/menu-item.store';
import { requestLogProviders } from './request-log/request-log.providers';
import { languageProviders } from './language/language.providers';

@NgModule({
  declarations: [
    IsFileDownloadingPipe,
    UploadFileDialogComponent,
    ArchiveListComponent,
    DownloadUrlPipe,
    IsDownloadingPipe,
    AppInfoSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiCoreModule,
    PortalModule,
    SettingsComponent,
    MengenEingabeComponent,
    StepHeaderComponent,
    BlockingLoadingSpinnerComponent,
    DashboardComponent,
    SortHeaderDirective,
    FilterInputComponent,
  ],
  providers: [
    languageProviders,
  ],
  exports: [
    IsFileDownloadingPipe,
    ArchiveListComponent,
    SettingsComponent,
    DashboardComponent,
    MengenEingabeComponent,
    StepHeaderComponent,
    BlockingLoadingSpinnerComponent,
    SortHeaderDirective,
    FilterInputComponent
  ],
})
export class ApplicationModule {
  static forRoot(): ModuleWithProviders<ApplicationRootModule> {
    return {
      ngModule: ApplicationRootModule,
      providers: [],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    ApplicationModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SETTINGS,
      feature: SettingsStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_APP_INFO,
      feature: AppInfoStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARCHIVE,
      feature: ArchiveStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TITLE,
      feature: TitleStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MODULE,
      feature: ModuleStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_APPLICATIONS,
      feature: ApplicationStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_COMMANDS,
      feature: CommandStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_NOTIFICATION,
      feature: NotificationStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: (service: ResourceService<ApiResourceLocation>) => new SoftlineEntityService(service, SOFTLINE_API_NOTIFICATION),
        deps: [SOFTLINE_SERVICE_HTTP]
      }]
    }),
  ],
  exports: [ApplicationModule],
  providers: [
    ArchiveService,
    SettingsService,
    DownloadUrlPipe,
    BackNavigationService,
    RefreshService,
    AddOnService,
    ModuleService,
    BottomOutletService,
    BottomTabGroupService,
    AudioService,
    StartupRouteService,
    ...filerAndSortProviders,
    { provide: AppInfoService, useClass: CommonAppInfoService },
    TitleStore2,
    { provide: SOFTLINE_STORE_FEATURES, useExisting: MenuItemStore2, multi: true},
    { provide: SOFTLINE_STORE_FEATURES, useExisting: TitleStore2, multi: true},
    {
      provide: SOFTLINE_CONFIG_PREVIEW_HANDLER,
      useClass: ArchiveImagePreviewHandler,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ModuleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppInfoInterceptor, multi: true },
    ...requestLogProviders,
    {
      provide: SOFTLINE_CONFIG_SETTINGS,
      useValue: {
        key: 'appInfo',
        component: AppInfoSettingsComponent,
        title: '#APPLICATION.APP_INFO.SETTINGS',
        icon: 'fa-regular fa-circle-info',
        priority: -1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_SETTINGS,
      useFactory: ( store) => ({
        key: 'application',
        component: ApplicationSettingsComponent,
        title: '#APPLICATION.APPLICATION.SETTINGS',
        icon: 'fa-regular fa-house-laptop',
        visible: store.observe(SOFTLINE_FEATURE_JWT_AUTHENTICATION, JwtAuthenticationStore.getters.authenticated)
      }),
      deps: [Store],
      multi: true,
    },
    {provide: SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME, useValue: 200},
    provideEnvironmentInitializer(async () => {
        const store = inject(Store)
        const moduleService = inject(ModuleService)
        const startupRouteService = inject(StartupRouteService);
        const translationStore = inject(TranslationStore2)
        translationStore.add({
          module: 'application',
          language: 'de',
          translations: de,
        });
        translationStore.add({
          module: 'application',
          language: 'en',
          translations: en,
        })
        await store.dispatch(SOFTLINE_FEATURE_APP_INFO, AppInfoStore.actions.load);
        await store.dispatch(SOFTLINE_FEATURE_SETTINGS, SettingsStore.actions.init);
        await store.dispatch(SOFTLINE_FEATURE_SETTINGS, SettingsStore.actions.load);
        await startupRouteService.navigateToStartupRoute()
      }),
  ],
})
export class ApplicationRootModule {
  constructor() {
  }
}
