import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  inject,
  input, model,
  signal,
  TemplateRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { I18nModule } from '../../i18n/i18n.module';
import { PanelViewBase } from './views/panel-view.base';
import { SettingsComponent } from '../abstraction';
import { Dictionary, SOFTLINE_SERVICE_UUID } from '@softline/core';

@Component({
    selector: 'soft-panel',
    imports: [CommonModule, IconComponent, I18nModule],
    templateUrl: './panel.component.html',
    styleUrl: './panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelComponent implements SettingsComponent {
  private _onSettingsChange: (settings: Dictionary<unknown>) => void = () => {};

  uuid = inject(SOFTLINE_SERVICE_UUID)();
  selectedPanelName = signal<string | null>(null);
  collapsed = model<boolean>(false);

  title = input<string | null>(null);
  titleTemplate = input<TemplateRef<any> | null>();
  headerTemplate = input<TemplateRef<any> | null>();
  footerTemplate = input<TemplateRef<any> | null>();
  collapsable = input<boolean>(true);

  panels = contentChildren(PanelViewBase);

  firstPanelName = computed(() => {
    const firstPanel = this.panels()[0];
    if (firstPanel) return firstPanel.name();
    return null;
  });

  selectedPanel = computed(() => {
    const name = this.selectedPanelName() ?? this.firstPanelName();
    return this.panels().find((panel) => panel.name() === name);
  });

  constructor() {}

  selectPanel(name: string, event: Event): void {
    this.collapsed.set(false);
    this.selectedPanelName.set(name);
    this._onSettingsChange({ selectedPanelName: name, collapsed: false });
    event.preventDefault();
    event.stopPropagation();
  }

  toggleCollapse(event: Event): void {
    if (this.collapsable()) {
      const collapsed = !this.collapsed();
      this.collapsed.set(collapsed);
      this._onSettingsChange({ collapsed });
    }
    event.preventDefault();
    event.stopPropagation();
  }

  registerOnSettingsChange(
    callback: (settings: Dictionary<unknown>) => void
  ): void {
    this._onSettingsChange = callback;
  }

  applySettings(settings: Dictionary<unknown>): void {
    if (settings['selectedPanelName'])
      this.selectedPanelName.set(settings['selectedPanelName'] as string);
    if (settings['collapsed'])
      this.collapsed.set(settings['collapsed'] as boolean);
  }
}
