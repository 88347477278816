import { ApplicationRef, EnvironmentProviders, inject, provideEnvironmentInitializer, Provider } from '@angular/core';
import { ModalContainerComponent } from './components/modal-container.component';
import { DOCUMENT } from '@angular/common';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';

export function provideModals(): Array<EnvironmentProviders | Provider> {
  return [
    provideEnvironmentInitializer(() => {
      const document = inject(DOCUMENT);
      const appRef = inject(ApplicationRef);
      const outlet = new DomPortalOutlet(document.body, undefined, appRef)

      const portal = new ComponentPortal(ModalContainerComponent);
      outlet.attach(portal);
    })
  ]
}
