import { inject, Injectable } from '@angular/core';
import {
  ConnectionHttpService,
} from '@softline/core';
import { firstValueFrom } from 'rxjs';
import { Reklamation, ReklamationGrund } from '../types/reklamation';
import {
  SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA, SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA_CREATE,
  SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA_GUENDE
} from '../warenuebernahme.api';

@Injectable()
export class ReklamationService {
  private readonly httpService = inject(ConnectionHttpService);

  async loadGruende(): Promise<ReklamationGrund[]> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA_GUENDE,
    };

    return await firstValueFrom(
      this.httpService.get<ReklamationGrund[]>(location)
    ).then((result) => {
      return result['result']
    });
  }

  async loadReklamation(beweId: number): Promise<Reklamation> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA,
      pathParams: {
        belegtyp: 'bewewu',
        id: beweId
      }
    };

    return await firstValueFrom(
      this.httpService.get<Reklamation>(location)
    ).then((result) => {
      return result['result']
    });
  }

  async createReklamation(body: Reklamation): Promise<Reklamation> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA_CREATE,
    };

    return await firstValueFrom(
      this.httpService.create<Reklamation, Reklamation>(location, body)
    );
  }
}
