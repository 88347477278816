@if (artikelMenge(); as artikelMenge) {
  <div class="flex flex-col">
    <span class="text-sm color-light">{{artikelMenge.artikel.nummer}}</span>
    <span class="text-lg font-semibold">{{artikelMenge.artikel.artbez1}}</span>
    <span class="color-light">{{artikelMenge.artikel.artbez2}}</span>
    <span class="color-light">{{artikelMenge.artikel.artbez3}}</span>
    <span class="color-light">{{artikelMenge.artikel.artbez4}}</span>
    <span class="color-light">{{artikelMenge.artikel.artbez5}}</span>
  </div>
  <div class="flex flex-row flex-nowrap color-light items-center text-xl">
    <span>{{artikelMenge.menge}}</span>
    <span class="ml-1">{{artikelMenge.einheit.arteh}}</span>
  </div>
}
