import { inject, Injectable } from '@angular/core';
import { EMPTY, lastValueFrom, Observable, of, throwError } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { DownloadRequestOptions } from '../data/abstraction';
import { HttpResourceLocation } from './types/http.location';
import {
  ConnectionError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  RequestError,
  ServerError,
  UnauthorizedError,
  UnknownError,
  UnknownResponseError,
} from '../types/errors';
import { RequestEvent } from './types/request';
import { convertHttpRequestEvent } from '../data/specialized/http/http-response.converter';

const ERROR_CODE_BAD_REQUEST = 400;
const ERROR_CODE_UNAUTHORIZED = 401;
const ERROR_CODE_FORBIDDEN = 403;
const ERROR_CODE_NOT_FOUND = 404;

const ERROR_CODE_SERVER_INTERNAL = 500;
const ERROR_CODE_SERVER_NOT_IMPLEMENTED = 501;
const ERROR_CODE_SERVER_BAD_GATEWAY = 502;
const ERROR_CODE_SERVER_UNAVAILABLE = 503;
const ERROR_CODE_SERVER_GATEWAY_TIMEOUT = 504;

@Injectable({providedIn: 'root'})
export class HttpDataAccess {

  httpClient = inject(HttpClient);

  async get<T>(location: HttpResourceLocation, payload?: unknown): Promise<T> {
    try {
      if(payload === undefined)
        return await lastValueFrom(
          this.httpClient.get<T>(location.url, { params: location.queryParams })
        );
      else {
        const headers = new HttpHeaders().append('Content-Type', 'application/json');
        return await lastValueFrom(
          this.httpClient.post<T>(location.url, payload, { headers })
        );
      }
    }
    catch (e) {
      throw this.mapError(e);
    }
  }

  async create<T, TResponse>(location: HttpResourceLocation, resource: T): Promise<TResponse> {
    try {
      const headers = new HttpHeaders().append('Content-Type', 'application/json');
      return await lastValueFrom(
        this.httpClient.post<TResponse>(location.url, resource, { headers, params: location.queryParams, })
      );
    }
    catch (e) {
      throw this.mapError(e);
    }
  }

  async update<T, TResponse>(location: HttpResourceLocation, resource: T): Promise<TResponse> {
    try {
      const headers = new HttpHeaders().append('Content-Type', 'application/json');
      return await lastValueFrom(
        this.httpClient.put<TResponse>(location.url, resource, { headers, params: location.queryParams, })
      );
    }
    catch (e) {
      throw this.mapError(e);
    }
  }

  async patch<T, TResponse>(location: HttpResourceLocation, changes: Partial<T>): Promise<TResponse> {
    try {
      const headers = new HttpHeaders().append('Content-Type', 'application/json');
      return await lastValueFrom(
        this.httpClient.patch<TResponse>(location.url, changes, { headers, params: location.queryParams, })
      );
    }
    catch (e) {
      throw this.mapError(e);
    }
  }

  async delete<T>(location: HttpResourceLocation): Promise<T> {
    try {
      return lastValueFrom(
        this.httpClient.delete<T>(location.url, { params: location.queryParams })
      );
    }
    catch (e) {
      throw this.mapError(e);
    }
  }

  download<T>(location: HttpResourceLocation, payload?: unknown, options?: DownloadRequestOptions): Observable<RequestEvent<Blob  | ArrayBuffer>> {
    let func: Observable<HttpEvent<Blob | ArrayBuffer>>;

    const responseType = options?.responseType ? options.responseType : 'blob' as any;

    if (payload === undefined)
      func = this.httpClient.get(location.url, {
        params: location.queryParams,
        responseType: responseType,
        observe: 'events',
        reportProgress: true,
      });
    else {
      const headers = new HttpHeaders().append('Content-Type', 'application/json');
      func = this.httpClient.post(location.url, payload, {
        headers,
        responseType,
        observe: 'events',
        reportProgress: true,
      });
    }

    return func.pipe(
      mergeMap((event) => {
        const returnValue = convertHttpRequestEvent<T, Blob | ArrayBuffer>(event);
        if (returnValue) return of(returnValue);
        return of(undefined) as unknown as Observable<RequestEvent<any>>;
      }),
      catchError((error) => throwError(() => this.mapError(error)))
    );
  }

  upload<T, TResponse>(location: HttpResourceLocation, resource: T): Observable<RequestEvent<TResponse>> {
    return this.httpClient
      .post<TResponse>(location.url, this.toFormData(resource), {
        params: location.queryParams,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        mergeMap((event) => {
          const returnValue = convertHttpRequestEvent(event);
          if (returnValue) return of(returnValue);
          return EMPTY;
        }),
        catchError((error) => throwError(() => this.mapError(error)))
      );
  }

  private mapError(error: any): Error {
    if (error.error instanceof ErrorEvent) {
      return new NetworkError('A client-side network error occurred', error);
    } else if (error instanceof HttpErrorResponse) {
      let message: string | undefined = undefined;
      let code: string | undefined = undefined;
      if(error.error?.errors && error.error?.errors[0]?.message) {
        message = error.error?.errors[0]?.message;
        code = error.error?.errors[0]?.code;
      }
      switch (error.status) {
        case 0:
          return  new ConnectionError('Cannot establish a connection to the server', error);
        case ERROR_CODE_BAD_REQUEST:
          return new RequestError(
            `HTTP ${error.status} - ${error.statusText}: ${message} (${code})`,
            message,
            code ?? 'No code',
            error
          );
        case ERROR_CODE_UNAUTHORIZED:
          return new UnauthorizedError(
            `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            error
          );
        case ERROR_CODE_FORBIDDEN:
          return new ForbiddenError(
            `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            message ?? `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            code ?? 'No code',
            error,
          );
        case ERROR_CODE_NOT_FOUND:
          return new NotFoundError(
            `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            error
          );
        case ERROR_CODE_SERVER_INTERNAL:
        case ERROR_CODE_SERVER_NOT_IMPLEMENTED:
        case ERROR_CODE_SERVER_BAD_GATEWAY:
        case ERROR_CODE_SERVER_UNAVAILABLE:
        case ERROR_CODE_SERVER_GATEWAY_TIMEOUT:
          return new ServerError(
            `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            message ?? `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            code ?? 'No code',
            error
          );
        default:
          return new UnknownResponseError(
            `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            message ?? `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
            code ?? 'No code',
            error
          );
      }
    }
    else if(error instanceof Error) {
      return new UnknownError('An unexpected error occurred: ' + error.message, error);
    }
    else {
      return new UnknownError('An unexpected error occurred: ' + error, error);
    }
  }

  private toFormData(data: any): FormData {
    const formData = new FormData();
    const stack = Object.keys(data);

    while (stack.length > 0) {
      const key = stack.pop();
      if (!key) continue;
      const value = data[key];
      if (Array.isArray(value))
        for (const arrayValue of value) formData.append(`${key}[]`, arrayValue);
      else if (typeof value === 'object' && !(value instanceof File))
        formData.append(key, JSON.stringify(value));
      else formData.append(key, value);
    }
    return formData;
  }
}
