import { computed, inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { firstValueFrom, Subscription } from 'rxjs';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithActivatedRoute = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class ActivatedRouteMixin extends Base implements OnInit, OnDestroy {
    #routeParamsSubscription: Subscription | undefined;
    #routeQueryParamsSubscription: Subscription | undefined;
    #routeFragmentSubscription: Subscription | undefined;
    #routeDataSubscription: Subscription | undefined;

    route = inject(ActivatedRoute);

    routeParams = toSignal(this.route.params, {initialValue: {}});
    routeQueryParams = toSignal(this.route.queryParams, {initialValue: {}});
    routeFragment = toSignal(this.route.fragment);
    routeData = toSignal(this.route.data);

    routeParam = (key: string) => computed(() => this.routeParams()[key]);
    routeQueryParam = <T>(key: string) => computed(() => this.routeQueryParams()[key] as T);

    constructor(...args: any[]) {
      super(...args);
      const router = inject(Router);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      this.#routeParamsSubscription = this.route.params.subscribe(o => this.onRouteParamsChanged(o));
      this.#routeQueryParamsSubscription = this.route.queryParams.subscribe(o => this.onRouteQueryParamsChanged(o));
      this.#routeFragmentSubscription = this.route.fragment.subscribe(o => this.onRouteFragmentChanged(o));
      this.#routeDataSubscription = this.route.data.subscribe(o => this.onRouteDataChanged(o));
    }

    ngOnDestroy(): void {
      if(this.#routeParamsSubscription !== undefined && !this.#routeParamsSubscription.closed)  {
        this.#routeParamsSubscription.unsubscribe();
        this.#routeParamsSubscription = undefined;
      }
      if(this.#routeQueryParamsSubscription !== undefined && !this.#routeQueryParamsSubscription.closed)  {
        this.#routeQueryParamsSubscription.unsubscribe();
        this.#routeQueryParamsSubscription = undefined;
      }
      if(this.#routeFragmentSubscription !== undefined && !this.#routeFragmentSubscription.closed)  {
        this.#routeFragmentSubscription.unsubscribe();
        this.#routeFragmentSubscription = undefined;
      }
      if(this.#routeDataSubscription !== undefined && !this.#routeDataSubscription.closed)  {
        this.#routeDataSubscription.unsubscribe();
        this.#routeDataSubscription = undefined;
      }
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async onRouteParamsChanged(params: Params): Promise<void> { }
    async onRouteQueryParamsChanged(params: Params): Promise<void> { }
    async onRouteFragmentChanged(fragment: string | null): Promise<void> { }
    async onRouteDataChanged(data: Data): Promise<void> { }
  }
  return ActivatedRouteMixin;
}
