import { Component } from '@angular/core';
import { ResetDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@softline/ui-core';

@Component({
  selector: 'soft-reset-input',
  templateUrl: './reset-input.component.html',
  styleUrls: ['./reset-input.component.scss'],
  imports: [NgIf, ReactiveFormsModule, TranslatePipe],
})
export class ResetInputComponent extends DynamicInputAtom<ResetDefinition> {
  constructor() {
    super();
  }
}
