import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Emballage } from '../../types/emballage';
import {EmballageBewe, ReturnNote, StornoBewe} from '../../types/return-note';
import {Store} from '@softline/core';
import {ReturnNoteBeweModalService} from '../../services/return-note-bewe-modal.service';
import {SOFTLINE_FEATURE_RETURN_NOTE} from '../../delivery-note.shared';
import {ReturnNoteStore} from '../../stores/return-note.store';
import {Arteh} from '../../types/arteh';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-return-note-emballage-bewe-item',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './return-note-emballage-bewe-item.component.html',
    styleUrls: ['./return-note-emballage-bewe-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReturnNoteEmballageBeweItemComponent {

  @Input() returnNoteId!: string;
  @Input() value!: EmballageBewe;

  constructor(private store: Store,
              private modalService: ReturnNoteBeweModalService) {
  }

  async onEditBeweClick(bewe: EmballageBewe): Promise<void> {
    await this.modalService.editBewe(this.returnNoteId, bewe);
  }

  async onDeleteBeweClick(bewe: EmballageBewe): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.deleteBewe, {
      id: this.returnNoteId,
      bewe
    });
  }

  async onChange(bewe: EmballageBewe, menge: number | null | undefined): Promise<void> {
    bewe = {...bewe, menge };
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id: this.returnNoteId, bewe});
  }

}
