import { Component, OnInit } from '@angular/core';
import { DurationDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import {
  DurationInputComponent as UICoreDIC,
  FieldValidationDirective,
} from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';

import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import { NgIf } from '@angular/common';
import { FormatRulePipe } from '../../../../pipes/format-rule.pipe';

@Component({
  selector: 'soft-dynamic-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  imports: [
    UICoreDIC,
    ReactiveFormsModule,
    FieldValidationDirective,
    BooleanRulePipe,
    FormControlPipe,
    FormatRulePipe,
    NgIf,
  ],
})
export class DurationInputComponent
  extends DynamicInputAtom<DurationDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
