import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";
import {UiCoreModule} from '@softline/ui-core';

@Component({
    selector: 'soft-field-ok-data-view',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './artstamm.detail-view.html',
    styleUrls: ['./artstamm.detail-view.scss']
})
export class ArtstammDetailView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
