import {
  ApiResourceLocation,
  Dictionary,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SoftlineEntityService,
} from '@softline/core';
import { WorkflowData } from '../types/workflow-data';
import { Inject, Injectable } from '@angular/core';
import {
  SOFTLINE_API_WORKFLOW,
  SOFTLINE_API_WORKFLOW_DATA,
  SOFTLINE_API_WORKFLOW_NEXT,
} from '../freigabe.api';
import { Observable } from 'rxjs';
import { Workflow } from '../types/workflow';

@Injectable()
export class WorkflowService extends SoftlineEntityService<WorkflowData> {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    protected override service: ResourceService<ApiResourceLocation>
  ) {
    super(service, SOFTLINE_API_WORKFLOW);
  }

  next(id: string, task: string): Observable<Workflow> {
    const location = {
      path: SOFTLINE_API_WORKFLOW_NEXT,
      pathParams: { id, task },
    };
    return this.service.create(location, undefined);
  }
}
