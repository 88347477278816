import { Component, OnInit } from '@angular/core';
import { Modal } from '@softline/ui-core';
import { Definition } from '../../../../../data/definitions';
import { DynamicFormComponent } from '@softline/dynamic';

@Component({
  selector: 'soft-edit-item-dialog',
  templateUrl: './edit-item-dialog.component.html',
  styleUrls: ['./edit-item-dialog.component.scss'],
  imports: [DynamicFormComponent],
})
export class EditItemDialogComponent implements OnInit, Modal<unknown> {
  close: (result: unknown) => void = () => {};

  title = '';
  definition!: Definition;
  value: unknown;

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: unknown) => void): void {
    this.close = handler;
  }
}
