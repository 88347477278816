import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtikelMenge } from '../../types/artikel-menge';

@Component({
  selector: 'soft-artikel-menge',
  imports: [CommonModule],
  templateUrl: './artikel-menge.component.html',
  styleUrl: './artikel-menge.component.scss',
})
export class ArtikelMengeComponent {
  artikelMenge = input.required<ArtikelMenge>()
}
