import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@softline/core';
import { SOFTLINE_PERMISSION_TIMESTAMP_SELECT_EMPLOYEE } from '../../stempelerfassung.permissions';
import moment from 'moment';
import {
  BackNavigable,
  BackNavigationService,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { of } from 'rxjs';
import { TimestampQuery } from '../../data/timestamp.query';
import {
  SOFTLINE_FEATURE_TIMESTAMP,
  SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
  TimestampEmployeeStore,
  TimestampStore,
} from '../../stempelerfassung.shared';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-timestamp-query',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule]
})
export class QueryComponent implements OnInit, OnDestroy, BackNavigable {
  title$ = of('#TIMESTAMP.TITLE_QUERY');

  form = new UntypedFormGroup({
    employee: new UntypedFormControl(),
    from: new UntypedFormControl(),
    to: new UntypedFormControl(),
  });

  canModifyEmployee$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIMESTAMP_SELECT_EMPLOYEE
  );

  constructor(
    private store: Store,
    protected router: Router,
    private backNavigationService: BackNavigationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      '#TIMESTAMP.TITLE_QUERY'
    );
    this.backNavigationService.set(this);
    const query = this.store.get(
      SOFTLINE_FEATURE_TIMESTAMP,
      TimestampStore.getters.query.query
    );
    if (query) this.form.patchValue({ from: query?.from, to: query?.to });

    const employees = await this.store.dispatch(
      SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
      TimestampEmployeeStore.actions.loadMany
    );
    this.form.patchValue({ employee: employees[0] });
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/stempelerfassung']);
  }

  async onSubmit(value: any): Promise<void> {
    const params: TimestampQuery = {
      employeeid: value.employee.id,
      from: moment(value.from).format('YYYY-MM-DD'),
      to: moment(value.to).format('YYYY-MM-DD'),
    };
    await this.router.navigate(['stempelerfassung', 'query', 'result'], {
      queryParams: params,
    });
  }
}
