<div class="flex flex-col gap-2 w-full">
  <div class="p-4 sm:pl-0 sm:pt-0 text-2xl font-semibold">
    Handelt es sich um eine Teillieferung?
  </div>
<!--  <div class="question pr-4 pl-4 pb-4 sm:pl-0">-->
<!--    Question-->
<!--  </div>-->
  <div class="flex no-wrap w-full gap-4 px-4 sm:px-0 pb-4 pt-2">
    <button
      class="soft-button secondary h-16 w-full"
      (click)="close('reklemation')"
    >
      <soft-icon [name]="'fa-regular fa-triangle-exclamation'"></soft-icon>
    </button>
    <button
      class="soft-button accent sm:ml-3 h-16 w-full flex items-center justify-center"
      (click)="close('confirmation')"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </button>
  </div>
</div>
