import { ChangeDetectionStrategy, Component, inject, input, linkedSignal } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { debounceTime, Subject } from 'rxjs';
import { SOFTLINE_CONFIG_RICH_TEXT_EDITOR } from './rich-text-editor.tokens';
import { RichTextEditorCoreComponent } from './rich-text-editor-core/rich-text-editor-core.component';

const DEFAULT_DEBOUNCE_TIME = 3_000;

@Component({
  selector: 'soft-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RichTextEditorCoreComponent],
})
export class RichTextEditorComponent {
  private rtfConfig = inject(SOFTLINE_CONFIG_RICH_TEXT_EDITOR, {
    optional: true,
  });

  readonly content = input<string | null>(null);

  protected _content = linkedSignal({
    source: () => this.content(),
    computation: () => this.content() ?? null,
  });

  private readonly valueChanges$ = new Subject<string | null>();

  private readonly debouncedContent$ = this.valueChanges$.pipe(debounceTime(this.rtfConfig?.debounceTime ?? DEFAULT_DEBOUNCE_TIME));

  contentChange = outputFromObservable(this.debouncedContent$);

  contentChanged(content: string | null): void {
    this._content.set(content);
    this.valueChanges$.next(content);
  }

  getContent(): string | null {
    return this._content();
  }
}
