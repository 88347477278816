import { Component } from '@angular/core';
import { DateService, Store } from '@softline/core';
import * as DynamicActionStore from '../../../../dynamic-action.store';
import { ActionDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import {
  DynamicValueComponent,
  SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
} from '@softline/dynamic';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'soft-dynamic-action-input',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  imports: [
    NgIf,
    ReactiveFormsModule,
    NgSwitchDefault,
    NgSwitchCase,
    NgSwitch,
    DynamicValueComponent,
  ],
})
export class ActionInputComponent extends DynamicInputAtom<ActionDefinition> {
  constructor(private store: Store, private dateService: DateService) {
    super();
  }

  onClick(): void {
    if (typeof this.definition.action === 'function') {
      this.definition.action();
    } else {
      this.store.commit(
        SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
        DynamicActionStore.mutations.add,
        {
          action: this.definition.action,
          timestamp: this.dateService.now(),
          params: {
            value: undefined,
            context: this.form.value,
            definition: this.definition.params,
          },
        }
      );
    }
  }
}
