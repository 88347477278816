import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultComponent} from '@softapps/core';
import {I18nModule} from '@softline/ui-core';
import {KtostammLieferantListView} from "../list-view/ktostamm-lieferant.list-view";

@Component({
    selector: 'soft-search-result-view',
    imports: [CommonModule, I18nModule, KtostammLieferantListView],
    templateUrl: './search-result-view.component.html',
    styleUrl: './search-result-view.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KtostammLieferantFieldOkSearchResultViewComponent implements SearchResultComponent<any> {
  readonly result = input.required<any>();
}
