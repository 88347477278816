import { Component, inject, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent, ModalService } from '@softline/ui-core';
import { Palette } from '../../types/palette';
import { Lagerplatz } from '../../types/lagerplatz';
import { ArtikelMenge } from '../../types/artikel-menge';

@Component({
  selector: 'soft-mixed-pallet',
  imports: [CommonModule, IconComponent],
  templateUrl: './mixed-pallet.component.html',
  styleUrl: './mixed-pallet.component.scss',
})
export class MixedPalletComponent {
  private modalService = inject(ModalService);
  pallet = input<Palette>();
  items = input<ArtikelMenge[]>();
  stockyard = input<Lagerplatz>();

  infoModalTemplate = viewChild<TemplateRef<any>>('infoModalTemplate');

  async openInfoModal(): Promise<void> {
    const template = this.infoModalTemplate();
    if(!template)
      return;
    await this.modalService.open({
      title: 'Mischpaletten Info',
      template
    });
  }
}
