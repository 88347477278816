import { AfterViewInit, Component, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { DateService, Store } from "@softline/core";
import {
  LabelType,
  Scan,
  ScannerStore,
  SettingsStore,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_SETTINGS
} from "@softline/application";
import { ClickOutsideDirective, UiCoreModule } from "@softline/ui-core";
import { Html5Qrcode } from "html5-qrcode";
import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import { Html5ScannerSettings } from "../types/html5-scanner.settings";
import { SOFTLINE_SETTINGS_HTML5_SCANNER } from "../html5-scanner.shared";

@Component({
    selector: 'soft-html5-scanner',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './html5-scanner.component.html',
    styleUrls: ['./html5-scanner.component.scss']
})
export class Html5ScannerComponent implements OnInit, AfterViewInit {

  isTorchAvailable: boolean = false;

  scanner!: Html5Qrcode;

  constructor(private store: Store, private dateService: DateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.scanner = new Html5Qrcode(/* element id */ "html5Scanner");
    // Maybe reason of: ERROR Scanning is not in running state
    // let settings = this.scanner.getRunningTrackSettings();
    //this.isTorchAvailable = "torch" in settings;
  }

  async startCameraScan(options?: Html5ScannerSettings): Promise<Scan> {
    return new Promise( async (resolve, reject) => {
      let device: string | { facingMode: "environment" } = { facingMode: "environment" };
      if(options?.cameraId)
        device = options.cameraId;
      await this.scanner.start(
        device,
        {
          advanced: [{ zoom: 2.0 }], // kein effekt
          focusMode: "continuous", // kein effekt
          fps: options?.fps ?? 10,    // Optional, frame per seconds for qr code scanning
          qrbox: options?.qrBox ?? { width: 250, height: 250 },  // Optional, if you want bounded box UI
          aspectRatio: options?.aspectRatio ?? 1,
        } as any,
        async (decodedText, decodedResult) => {
          console.log('SCAN SUCCESS: ', decodedResult)
          const decodedLabelType = this.getFrameworkLabelType(decodedResult.result.format?.format);

          if(!decodedLabelType) {
            await this.scanner.stop();
            this.scanner.clear();
            reject('[Html5ScannerComponent] scan: Unknown LabelType');
            return;
          }

          await this.beep();
          await this.scanner.stop();
          this.scanner.clear();
          resolve({
            data: decodedText,
            labelType: decodedLabelType,
            timestamp: this.dateService.now()
          })
        },
        (error) => {
          // console.log('SCAN FAILURE: ', error)
        });
    })
  }

  async stopScan(): Promise<void> {
    if(!this.scanner)
      throw new Error('[Html5ScannerComponent] cancel: Scanner not started');
    if(!this.scanner.isScanning)
      return;
    await this.scanner.stop();
    await this.scanner.clear();
  }

  async startFileScan(file: File): Promise<Scan> {
    const { decodedText, result } = await this.scanner.scanFileV2(file, false);
    await this.beep();
    const decodedLabelType = this.getFrameworkLabelType(result.format?.format);
    return {
      data: decodedText,
      labelType: decodedLabelType,
      timestamp: this.dateService.now()
    }
  }

  async cancel(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.cancel);
  }

  private getFrameworkLabelType(
    labelType?: Html5QrcodeSupportedFormats
  ): LabelType | undefined {
    switch (labelType) {
      case Html5QrcodeSupportedFormats.AZTEC:
        return 'aztec';
      case Html5QrcodeSupportedFormats.CODABAR:
        return 'codabar';
      case Html5QrcodeSupportedFormats.CODE_39:
        return 'code39';
      case Html5QrcodeSupportedFormats.CODE_93:
        return 'code93';
      case Html5QrcodeSupportedFormats.CODE_128:
        return 'code128';
      case Html5QrcodeSupportedFormats.DATA_MATRIX:
        return 'datamatrix';
      case Html5QrcodeSupportedFormats.EAN_8:
        return 'ean8';
      case Html5QrcodeSupportedFormats.EAN_13:
        return 'ean13';

      case Html5QrcodeSupportedFormats.ITF:
        return 'itf';
      case Html5QrcodeSupportedFormats.PDF_417:
        return 'pdf417';
      case Html5QrcodeSupportedFormats.QR_CODE:
        return 'qrcode';

      case Html5QrcodeSupportedFormats.UPC_A:
        return 'upc_a';
      case Html5QrcodeSupportedFormats.UPC_E:
        return 'upc_e';
      case Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION:
        return 'upc_e0';

      case Html5QrcodeSupportedFormats.MAXICODE:
        return 'maxicode';
      case Html5QrcodeSupportedFormats.RSS_14:
        return 'rss14';
      case Html5QrcodeSupportedFormats.RSS_EXPANDED:
        return 'rss_expanded';
      default:
        return undefined;
    }
  }

  private async beep(): Promise<void> {
    try {
      const audio = new Audio('/assets/audio/beep.mp3');
      await audio.play();
    }
    catch (e) {
      console.warn('[Html5ScannerComponent] cannot find audiofile ')
    }
  }

}
