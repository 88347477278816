import { Component, ElementRef, inject, input, model, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';

@Component({
    selector: 'soft-data-field',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './data-field.component.html',
    styleUrl: './data-field.component.scss'
})
export class DataFieldComponent {

  label = input<string | null | undefined>();
  overline = input<string | null| undefined>();

  borderBottomMobileVisible = model<boolean>(true);

  constructor() {}

  setBorderBottomVisible(value: boolean) {
    this.borderBottomMobileVisible.set(value);
  }
}
