import { provideStoreFeature, SOFTLINE_STORE_FEATURES, Store } from '@softline/core';
import { EnvironmentProviders, inject, provideEnvironmentInitializer, Provider } from '@angular/core';

import * as TranslationStore from './translation.store';
import { TranslationServiceStoreAdapter } from './translation-service-store.adapter';
import { SOFTLINE_DATA_TRANSLATIONS, SOFTLINE_FEATURE_TRANSLATION, Translations } from './i18n.shared';
import { TranslationStore2 } from './translation.store2';

export const provideTranslation: (module: Translations) => Array<Provider | EnvironmentProviders> = (module: Translations) => {
  return [
    {provide: SOFTLINE_DATA_TRANSLATIONS, useValue: module, multi: true}
  ]
}


export const provideI18N: () => Array<Provider | EnvironmentProviders> = () => {
  return [
    provideStoreFeature({
      name: SOFTLINE_FEATURE_TRANSLATION,
      feature: TranslationStore.feature,
    }),
    //TODO: remove if TranslationStore is removed
    { provide: SOFTLINE_STORE_FEATURES, useExisting: TranslationStore2, multi: true },
    { provide: TranslationStore2, useClass: TranslationStore2 },
    provideEnvironmentInitializer(() => {
      inject(TranslationServiceStoreAdapter);
    })
  ]
}
