import { inject, Injectable } from '@angular/core';
import {
  ConnectionHttpService,
} from '@softline/core';
import { firstValueFrom } from 'rxjs';
import {
  SOFLTINE_API_WWS_WARENUEBERNAHME_ETIKETTEN_PRINT,
  SOFTLINE_API_WWS_WARENUEBERNAHME_EINLAGERN,
  SOFTLINE_API_WWS_WARENUEBERNAHME_EINLAGERN_BEAN,
} from '../warenuebernahme.api';
import { EinlagerungBean, EinlagerungBody } from '../types/direkte-einlagerung';

@Injectable()
export class EinlagerungService {
  private readonly httpService = inject(ConnectionHttpService);

  async loadEinlagerungBean(idwu: number): Promise<EinlagerungBean> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_EINLAGERN_BEAN,
      pathParams: {
        idwu,
      },
    };

    return await firstValueFrom(
      this.httpService.get<EinlagerungBean>(location)
    );
  }

  async createEinlagerung(body: EinlagerungBody): Promise<EinlagerungBean> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_EINLAGERN,
    };

    return await firstValueFrom(
      this.httpService.create<EinlagerungBody, EinlagerungBean>(location, body)
    );
  }

  async printEtiketten(idwu: number): Promise<any> {
    const location = {
      path: SOFLTINE_API_WWS_WARENUEBERNAHME_ETIKETTEN_PRINT,
      pathParams: {
        idwu,
      },
    };

    return await firstValueFrom(
      this.httpService.create<any, any>(location, {})
    );
  }
}
