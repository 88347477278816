import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { QueryStore, Store } from '@softline/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import {
  SOFTLINE_FEATURE_TIMESTAMP,
  SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
  SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
  TimestampEmployeeStore,
  TimestampStore,
} from '../stempelerfassung.shared';
import {
  AuthenticationContextStore,
  JwtAuthenticationStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from '@softline/auth';
import { TimestampCurrentEmployeeStore } from '../store/current-employee.store';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Trace } from '@softline/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-timestamp-app',
    templateUrl: './timestamp-app.component.html',
    styleUrls: ['./timestamp-app.component.scss'],
    imports: [RouterModule]
})
export class TimestampAppComponent implements OnInit, OnDestroy {
  isLoading$ = this.store
    .observe(SOFTLINE_FEATURE_TIMESTAMP, TimestampStore.getters.loading)
    .pipe(debounceTime(400));

  constructor(
    private store: Store,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#TIMESTAMP.TITLE'
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: TimestampAppComponent,
        commands: this.createCommands(),
      }
    );

    const context = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    );
    const userId = (context as any).userId;
    await this.store.dispatch(
      SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
      TimestampCurrentEmployeeStore.actions.loadEmployee,
      { userId }
    );
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      TimestampAppComponent
    );
  }

  protected createCommands(): Command[] {
    const commands: Command[] = [
      {
        icon: 'fa-regular fa-stopwatch',
        name: '#TIMESTAMP.TITLE',
        class: 'menu main-menu main-menu-top',
        routerLink: ['stempelerfassung'],
      },
      {
        icon: 'fa-regular fa-magnifying-glass',
        name: '#TIMESTAMP.QUERY',
        class: 'menu main-menu main-menu-top',
        routerLink: ['stempelerfassung', 'query'],
      },
    ];
    return commands;
  }
}
