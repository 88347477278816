import { Lagerplatz } from "./lagerplatz";
import { ArtikelMenge } from "./artikel-menge";
import { MischPalette } from './misch-palette';
import { Palette } from './palette';

export interface LagerplatzInhalt {
  lagerplatz: Lagerplatz;
  inhalt: ArtikelMenge[];
  mischpaletten?: MischPalette[];
  palette?: Palette;
}
