import {
  DefaultSearchResultViewComponent,
  SearchStrategy,
} from './../search.strategy';
import {
  FieldOkParameters,
  FieldOkService,
  FieldOkServiceOptions,
} from '@softline/dynamic';
import { inject, InjectionToken, signal, Type } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  filter,
  firstValueFrom,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { ConnectionHttpService, Sort, SortService } from '@softline/core';
import { SearchResultComponent } from '../abstraction/search-result.component';
import { FavoriteStore2 } from '../../../user/favorites/favorite.store2';
import { LastUsedStore2 } from '../../../user/last-used/last-used.store2';
import { Favorite } from '../../../user/favorites/favorite';
import { map } from 'rxjs/operators';
import { ResolvableModal } from '@softline/ui-core';

export interface FieldOkSearchStrategyConfig<T extends object> {
  queryFormatter?: (query: string | null) => FieldOkParameters<T>;
  sort?: Sort<T, any>;
}

export interface FieldOkSearchConfig<T extends object, TModal extends object = object> {
  name: string;
  view: Type<SearchResultComponent<any>>;
  queryModalView?: Type<ResolvableModal<TModal>>;
  priority?: number;
  options?: {
    autoLoad?: boolean;
    serviceOptions?: FieldOkServiceOptions<T>;
  }
}

export const SOFTLINE_CONFIG_FIELD_OK_SEARCH_PAGE = new InjectionToken<FieldOkSearchConfig<any,any>[]>('FIELD_OK_SEARCH_VIEW');

export class FieldOkSearchStrategy<T extends object> extends SearchStrategy<T> {

  private favoriteStore = inject(FavoriteStore2);
  private lastUsedStore = inject(LastUsedStore2);

  protected readonly searchStream$ = new Subject<string | null>();
  protected readonly manualQueryResults$ = new BehaviorSubject<T[] | null>(null);

  protected fokService: FieldOkService<T>;
  protected fokSearchConfig?: FieldOkSearchConfig<any, any>;

  isManualSearchActive = signal(false);

  override get resultViewType() {
    return this.fokSearchConfig?.view ?? DefaultSearchResultViewComponent;
  }

  override get queryModalViewType() {
    return this.fokSearchConfig?.queryModalView ?? undefined;
  }

  override results = toSignal(
      this.searchStream$.pipe(
        startWith(null),
        combineLatestWith(this.manualQueryResults$),
        filter(([o, manualResults]) =>
            (this.fokSearchConfig?.options?.autoLoad === true
            ? true
            : (!!o && o?.length > 2)) || (manualResults?.length || 0) > 0
        ),
        switchMap(([filter, results]) => {
          if (results && results.length > 0) {
            this.loadingState.set('loaded');
            return of(results).pipe(
              map(results => {
                if (this.config?.sort) {
                  return this.sortService.sort(results, this.config?.sort as any)
                } else {
                  return results;
                }
              })
            );
          } else {
            return this.query(filter).pipe(
              tap({
                subscribe: () => this.loadingState.set('loading'),
                unsubscribe: () => this.loadingState.set('idle'),
                complete: () => this.loadingState.set('loaded'),
                error: () => this.loadingState.set('error'),
              }),
              catchError(() => of([])),
              map(results => {
                if (this.config?.sort) {
                  return this.sortService.sort(results, this.config?.sort as any)
                } else {
                  return results;
                }
              })
            )
          }
        }
      ),
    ), { initialValue: [] }
  );

  override favorites = this.favoriteStore.favorites(this.fieldOkName);
  override lastUsed = this.lastUsedStore.lastUsed(this.fieldOkName);

  override loadingState = signal<'loaded' | 'loading' | 'idle' | 'error'>('idle');

  sortService = inject(SortService);

  constructor(protected fieldOkName: string, protected config?: FieldOkSearchStrategyConfig<T>) {
    const connectionHttpService = inject(ConnectionHttpService);
    const searchConfigs = inject(SOFTLINE_CONFIG_FIELD_OK_SEARCH_PAGE)

    super();

    const configs = searchConfigs.filter(o => o.name === this.fieldOkName);

    configs.sort(( a, b) => (b?.priority || 0) > (a?.priority || 0) ? 1 : -1);

    if (configs.length > 0) {
      this.fokSearchConfig = configs[0];
    }

    if (!this.fokSearchConfig) {
      console.warn(`[FieldOkSearchStrategy] - No configuration with name "${fieldOkName}" for token FIELD_OK_SEARCH_VIEW!`)
    }

    this.fokService = new FieldOkService<T>(connectionHttpService, fieldOkName, this.fokSearchConfig?.options?.serviceOptions);
  }

  searchInputChange(value: string | null) {
    if ((!value || value?.length <= 2) && this.loadingState() !== 'idle' && this.fokSearchConfig?.options?.autoLoad !== true) {
      this.loadingState.set('idle');
    }

    this.manualQueryResults$.next(null);
    this.isManualSearchActive.set(false);
    this.searchStream$.next(value);
  }

  async loadFavorites(): Promise<Favorite<T>[]> {
    return await this.favoriteStore.load(this.fieldOkName);
  }

  async loadLastUsed(): Promise<Favorite<T>[]> {
    return await this.lastUsedStore.load(this.fieldOkName);
  }

  async search(value: string | null): Promise<T[]> {
    return await firstValueFrom(this.query(value));
  }

  favoriteChange(value: T) {
    const favorites = this.favorites();
    if(favorites.find(o => o.id === value['id']))
      this.favoriteStore.remove(this.fieldOkName, value['id']);
    else
      this.favoriteStore.add(this.fieldOkName, value['id'], value);
  }

  addToLastUsed(value: T) {
    this.lastUsedStore.add(this.fieldOkName, value['id'], value);
  }

  async modalResolved(result: object): Promise<void> {
    try {
      this.loadingState.set('loading');

      this.searchStream$.next(null);

      const results = await firstValueFrom(
        this.fokService.query({
          filter: '',
          multiValued: false,
          maxAbfrageResults: 1000,
          parameters: result
        }) as Observable<T[]>
      );

      this.loadingState.set('loaded');

      if (results.length > 0) {
        this.isManualSearchActive.set(true);
        this.manualQueryResults$.next(results);
      }
    } catch (e: unknown) {
      this.loadingState.set('error');
      this.isManualSearchActive.set(false);
      console.log(e);
    }
  }

  private query(filter: string | null): Observable<T[]> {
    return this.fokService.query(
      this.config?.queryFormatter
        ? this.config.queryFormatter(filter)
        : {
          filter: filter ?? '',
          multiValued: false,
          maxAbfrageResults: 1000,
          parameters: {}
        }
    ) as Observable<T[]>;
  }
}

export function provideFieldOkSearchStrategy<T extends object = object>(params: { name: string; config?: FieldOkSearchStrategyConfig<T> }) {
  return {
    provide: SearchStrategy,
    useFactory: () => new FieldOkSearchStrategy(params.name, params?.config)
  }
}
