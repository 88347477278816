import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResolvableModal } from '../../abstraction/modal';
import { ModalOption } from '../../types/modal-config';

@Component({
  selector: 'soft-option-modal',
  imports: [CommonModule],
  templateUrl: './option-modal.component.html',
  styleUrl: './option-modal.component.scss',
})
export class OptionModalComponent<T> extends ResolvableModal<T> {

  options= input<ModalOption<T>[]>([]);

  onClick(result: ModalOption<T>): void {
    if(result.disabled)
      return;
    this.resolve(result.value);
  }
}
