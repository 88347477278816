import { InjectionToken } from '@angular/core';

export interface Translations {
  module: string,
  language: string;
  translations: object;
}

export const SOFTLINE_CONFIG_TRANSLATION_BASE_PATH = new InjectionToken<string>(
  'TRANSLATION_FILE_BASE_PATH'
);
/***@deprecated: use provideTranslations for commit Translation file or TranslationService for get translations instead */
export const SOFTLINE_FEATURE_TRANSLATION = 'i18n';

export const SOFTLINE_DATA_TRANSLATIONS = new InjectionToken<Translations[]>('SOFTLINE_DATA_TRANSLATIONS');
export const SOFTLINE_CONFIG_DEFAULT_LANGUAGE = new InjectionToken<string>(
  'SOFTLINE_CONFIG_DEFAULT_LANGUAGE',
  {
    providedIn: 'root',
    factory: () => 'de'
  }
);
