import { Lagerplatz } from '@softapps/lager/core';

export interface Kunde {
  kundennummer: number,
  kurzbez: string,
  strasse: string,
  strassenr: string,
  plz: number,
  ort: string,
  id: number
}
export interface Auftrag {
  auftragnr: string,
  kunde: Kunde
}
export interface Lieferant {
  lieferantennummer: number,
  kurzbez: string,
  id: number
}
export interface Anwender {
  anwender: string,
  id: number
}

export interface Emballage {
  id: number,
  emballage: string,
  bezeichnung: string
}

export interface Warenuebernahme {
  id: number,
  lieferant: Lieferant,
  belegnummer: string, //(lieferscheinnummer)
  bestellnummer: string,
  anwender: Anwender | null
  anwenderExzess: Anwender | null,
  barcodeEk?: string | null;
  lagerplatzAnlieferung: Lagerplatz | null;
  archivekey: string
}
