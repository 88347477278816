import { TreeNode } from './tree-node';

/**
 * This type represents the event when a node's expansion status changes
 * Emitted by the `soft-tree-view` component via the `TreeViewService`
 */
export interface TreeNodeExpandedChangeEvent<T> {
  node: TreeNode<T>;
  state: {
    current: boolean;
    previous: boolean;
  }
}
