<div class="flex flex-col gap-2 p-2 w-full max-h-[50vh]">
  <div class="sm:pl-0 sm:pt-0 text-2xl font-semibold">
    Scannen Sie den Lagerplatz
  </div>

  <div>
    <soft-lagerplatz-suche (lagerplatzChange)="setLagerplatz($event)"></soft-lagerplatz-suche>

    @if(!lagerplatzScan()){
      <label class="text-light text-sm text-red-500">Es wird ein Lagerplatz benötigt!</label>
    }
  </div>

  @if(_isPalette()) {
    <label class="text-light text-sm">Palette auswählen</label>
    <soft-select
      [value]="selectedPalette()()"
      (valueChange)="selectedPalette().set($event)"
      class="h-full border border-primary-400 p-2 w-48 lg:w-1/2">
      @for(palette of paletten(); track palette.id) {
        <soft-select-option [value]="palette">
          {{palette.sscc }}
        </soft-select-option>
      }
    </soft-select>

  } @else {
    <label class="text-light text-sm">Menge für Lagerplatz</label>
    <soft-mengen-eingabe
      [disabled]="false"
      [value]="menge()"
      (valueChange)="menge.set($event)"
      [maxValue]="maxMenge()"
      [float]="true"
    ></soft-mengen-eingabe>
  }

  <button
    [disabled]="!lagerplatzScan() || menge() === 0"
    class="soft-button primary h-16 w-full flex items-center justify-center"
    (click)="submit()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
  </button>
</div>
