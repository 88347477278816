import {
  CreateResourceService,
  DeleteResourceService, DownloadRequestOptions,
  DownloadResourceService,
  GetResourceService,
  PatchResourceService,
  UpdateResourceService,
  UploadResourceService
} from '../../abstraction';
import { HttpResourceLocation } from '../../../data-access/types/http.location';
import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { EMPTY, from, Observable, ObservableInput, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { RequestEvent } from '../../../data-access/types/request';
import { convertHttpRequestEvent } from './http-response.converter';
import { Logger } from '../../../log/logger';
import { LogEntry, LogPriority } from '../../../log/log';
import {
  ConnectionError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  RequestError,
  ServerError,
  UnauthorizedError,
  UnknownError,
  UnknownResponseError,
} from '../../../types/errors';

const ERROR_CODE_BAD_REQUEST = 400;
const ERROR_CODE_UNAUTHORIZED = 401;
const ERROR_CODE_FORBIDDEN = 403;
const ERROR_CODE_NOT_FOUND = 404;

const ERROR_CODE_SERVER_INTERNAL = 500;
const ERROR_CODE_SERVER_NOT_IMPLEMENTED = 501;
const ERROR_CODE_SERVER_BAD_GATEWAY = 502;
const ERROR_CODE_SERVER_UNAVAILABLE = 503;
const ERROR_CODE_SERVER_GATEWAY_TIMEOUT = 504;

@Injectable()
export class HttpService
  implements
    GetResourceService<HttpResourceLocation>,
    DownloadResourceService<HttpResourceLocation>,
    CreateResourceService<HttpResourceLocation>,
    UpdateResourceService<HttpResourceLocation>,
    DeleteResourceService<HttpResourceLocation>,
    PatchResourceService<HttpResourceLocation>,
    UploadResourceService<HttpResourceLocation>
{
  constructor(
    protected httpClient: HttpClient,
    @Inject(Logger) protected logger: Logger
  ) {}

  get<T>(location: HttpResourceLocation, payload?: unknown): Observable<T> {
    if (payload === undefined)
      return this.httpClient
        .get<T>(location.url, { params: location.queryParams })
        .pipe(catchError((error) => this.handleError(error)));
    else {
      const headers = new HttpHeaders().append(
        'Content-Type',
        'application/json'
      );
      return this.httpClient
        .post<T>(location.url, payload, { headers })
        .pipe(catchError((error) => this.handleError(error)));
    }
  }

  download<T>(
    location: HttpResourceLocation,
    payload?: unknown,
    options?: DownloadRequestOptions
  ): Observable<RequestEvent<Blob  | ArrayBuffer>> {
    let func: Observable<HttpEvent<Blob | ArrayBuffer>>;

    const responseType = options?.responseType ? options.responseType : 'blob' as any;

    if (payload === undefined)
      func = this.httpClient.get(location.url, {
        params: location.queryParams,
        responseType: responseType,
        observe: 'events',
        reportProgress: true,
      });
    else {
      const headers = new HttpHeaders().append(
        'Content-Type',
        'application/json'
      );
      func = this.httpClient.post(location.url, payload, {
        headers,
        responseType,
        observe: 'events',
        reportProgress: true,
      });
    }

    return func.pipe(
      mergeMap((event) => {
        const returnValue = convertHttpRequestEvent<T, Blob | ArrayBuffer>(event);
        if (returnValue) return of(returnValue);
        return of(undefined) as unknown as Observable<RequestEvent<any>>;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  create<T, TResponse>(
    location: HttpResourceLocation,
    resource: T
  ): Observable<TResponse> {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    return this.httpClient
      .post<TResponse>(location.url, resource, {
        headers,
        params: location.queryParams,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  update<T, TResponse>(
    location: HttpResourceLocation,
    resource: T
  ): Observable<TResponse> {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    return this.httpClient
      .put<TResponse>(location.url, resource, {
        headers,
        params: location.queryParams,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  patch<T, TResponse>(
    location: HttpResourceLocation,
    changes: Partial<T>
  ): Observable<TResponse> {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    return this.httpClient
      .patch<TResponse>(location.url, changes, {
        headers,
        params: location.queryParams,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  delete<T>(location: HttpResourceLocation): Observable<T> {
    return this.httpClient
      .delete<T>(location.url, { params: location.queryParams })
      .pipe(catchError((error) => this.handleError(error)));
  }

  upload<T, TResponse>(
    location: HttpResourceLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>> {
    return this.httpClient
      .post<TResponse>(location.url, this.toFormData(resource), {
        params: location.queryParams,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        mergeMap((event) => {
          const returnValue = convertHttpRequestEvent(event);
          if (returnValue) return of(returnValue);
          return EMPTY;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  protected handleError(error: any): ObservableInput<never> {
    if (error.error instanceof ErrorEvent) {
      this.log(error, 'A client-side network error occurred');
      return throwError(
        () => new NetworkError('A client-side network error occurred', error)
      );
    } else if (error instanceof HttpErrorResponse) {
      let message: string | undefined = undefined;
      let code: string | undefined = undefined;
      if(error.error?.errors && error.error?.errors[0]?.message) {
        message = error.error?.errors[0]?.message;
        code = error.error?.errors[0]?.code;
      }
      this.log(error, `${error.status} - ${error.statusText}`);
      switch (error.status) {
        case 0:
          return throwError(
            () => new ConnectionError(
              'Cannot establish a connection to the server',
              error
            )
          );
        case ERROR_CODE_BAD_REQUEST:
          return throwError(
            () => new RequestError(
              `HTTP ${error.status} - ${error.statusText}: ${message} (${code})`,
              message,
              code ?? 'No code',
              error
            )
          );
        case ERROR_CODE_UNAUTHORIZED:
          return throwError(
            () => new UnauthorizedError(
              `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              error
            )
          );
        case ERROR_CODE_FORBIDDEN:
          return throwError(
            () => new ForbiddenError(
              `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              message ?? `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              code ?? 'No code',
              error,
            )
          );
        case ERROR_CODE_NOT_FOUND:
          return throwError(
            () => new NotFoundError(
              `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              error
            )
          );
        case ERROR_CODE_SERVER_INTERNAL:
        case ERROR_CODE_SERVER_NOT_IMPLEMENTED:
        case ERROR_CODE_SERVER_BAD_GATEWAY:
        case ERROR_CODE_SERVER_UNAVAILABLE:
        case ERROR_CODE_SERVER_GATEWAY_TIMEOUT:
          return throwError(
            () => new ServerError(
              `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              message ?? `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              code ?? 'No code',
              error
            )
          );
        default:
          return throwError(
            () => new UnknownResponseError(
              `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              message ?? `HTTP ${error.status} - ${error.statusText}: ${error.message}`,
              code ?? 'No code',
              error
            )
          );
      }
    }
    else if(error instanceof Error) {
      return throwError(() => new UnknownError('An unexpected error occurred: ' + error.message, error));
    }
    else {
      return throwError(() => new UnknownError('An unexpected error occurred: ' + error, error));
    }
  }

  private log(e: Error, errorSubject: string): void {
    const log: LogEntry = {
      type: 'error',
      priority: LogPriority.Normal,
      source: HttpService,
      category: 'HTTP ERROR',
      subject: errorSubject,
      message: e.message,
    };
    this.logger.log(log);
  }

  private toFormData(data: any): FormData {
    const formData = new FormData();
    const stack = Object.keys(data);

    while (stack.length > 0) {
      const key = stack.pop();
      if (!key) continue;
      const value = data[key];
      if (Array.isArray(value))
        for (const arrayValue of value) formData.append(`${key}[]`, arrayValue);
      else if (typeof value === 'object' && !(value instanceof File))
        formData.append(key, JSON.stringify(value));
      else formData.append(key, value);
    }
    return formData;
  }
}
