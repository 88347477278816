import { EnvironmentProviders, importProvidersFrom, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import {ConnectionHttpService, isNullOrEmpty, SOFTLINE_SERVICE_HTTP, Store, StoreModule} from "@softline/core";
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {PasswordStore} from './store/password.store';
import {PasswordService} from './services/password.service';
import {SOFTLINE_CONFIG_LOGIN_LINKS} from '@softapps/allgemein/login';
import {SOFTLINE_CONFIG_SETTINGS} from '@softline/application';
import {PASSWORD_SETTINGS_KEY} from './password.tokens';
import {SettingsComponent} from './settings/components/settings.component';
import {SOFTLINE_FEATURE_PASSWORD} from './password.shared';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {map} from "rxjs/operators";

export const passwordProviders: (Provider | EnvironmentProviders)[] = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'password',
          language: 'de',
          translations: de,
        }
      );
    }),
  {
    provide: SOFTLINE_CONFIG_LOGIN_LINKS,
    useValue: { name: '#PASSWORD.TITLE', route: ['/forgot-password'] },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useFactory: (store: Store) => {
      return {
        key: PASSWORD_SETTINGS_KEY,
        component: SettingsComponent,
        title: '#PASSWORD.SETTINGS.TITLE',
        icon: 'fa-regular fa-key',
        group: 'internal',
        visible: store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
          .pipe(map(o => !isNullOrEmpty(o)))
      }
    },
    deps: [Store]
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_PASSWORD,
      feature: PasswordStore.feature,
      providers: [
        {
          provide: PasswordService,
          useFactory: (service: ConnectionHttpService) =>
            new PasswordService(service),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    })
  ),
];
