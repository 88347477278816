import { Component, input, signal, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from "../../../../pipes/ui-core-pipes.module";
import { I18nModule } from "../../../../i18n/i18n.module";

@Component({
    selector: 'soft-template-modal',
    imports: [CommonModule, UiCorePipesModule, I18nModule],
    templateUrl: './template-modal.component.html',
    styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent<T>  {
  /*close!: (result: T) => void;

  title = input<string | undefined>();
  subtitle = input<string | undefined>();
  template = input<TemplateRef<any>>();

  registerCloseHandler(handler: (result: T) => void): void {
    this.close = handler;
  }*/
}
