import { Component, computed, inject, model, signal } from '@angular/core';
import { SOFTLINE_DATA_CONSOLE } from '../console.shared';
import { Observable } from 'rxjs';
import { ConsoleEntry } from '../console.entry';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { ModalBase, UiCoreModule } from '@softline/ui-core';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'soft-log-settings',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './console-settings.component.html',
    styleUrls: ['./console-settings.component.scss']
})
export class ConsoleSettingsComponent extends ModalBase<any> {
  console$ = inject<Observable<ConsoleEntry[]>>(SOFTLINE_DATA_CONSOLE)
  logs$ = this.console$.pipe(map((o) => o.slice(-50)));

  debug = model(false);
  log = model(true);
  info = model(true);
  warning = model(true);
  error = model(true);

  allLogs = toSignal(this.console$, {initialValue: []});
  logs = computed(() => {
    const allLogs = this.allLogs();

    const log = this.log();
    const debug = this.debug();
    const info = this.info();
    const warning = this.warning();
    const error = this.error();

    return allLogs.filter((o) => {
      if (o.type === 'log' && log) return true;
      if (o.type === 'debug' && debug) return true;
      if (o.type === 'info' && info) return true;
      if (o.type === 'warn' && warning) return true;
      if (o.type === 'error' && error) return true;
      return false;
    }).slice(-50);
  });

  constructor() {
    super();
  }

  isError(value: unknown): value is Error {
    return value instanceof Error;
  }
}
