@if (result(); as item) {
  <div class="flex flex-col gap-0.5">
    <div class="flex flex-row text-sm color-primary">
      <span>{{item['objekt']}}</span>
    </div>
    <div class="flex flex-row">
      <span class="font-bold">{{item['kurzbez'] ?? item['bezeichnung'] }}</span>
    </div>
  </div>
}

