import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'first',
    standalone: false
})
export class FirstPipe<T, U> implements PipeTransform {
  transform(
    array: readonly T[] | null | undefined,
    func?: (value: T, parameter?: U) => boolean,
    parameter?: U
  ): T | undefined {
    if (!array) return undefined;
    let index = 0;
    if (func) index = array.findIndex((o) => func(o, parameter));
    if (index < 0) return undefined;

    return array[index];
  }
}
