@let mode = (settings$ | async)?.display;

<div class="w-100 flex flex-col flex-grow">
  <div class="app-header c mb-1 mt-1">
    <soft-input
      class=""
      placeholder="{{ '#APP_OVERVIEW.SEARCH' | translate }}"
      (valueChange)="search = $event ?? ''"
    ></soft-input>
    <button
      type="button"
      class="soft-button soft-button-outline flex items-center justify-center text-center !border-secondary !border-l-0 button-height pointer"
      [ngClass]="mode === 'grid' ? 'bg-white text-primary' : 'bg-white !text-color'"
      title="{{ '#APP_OVERVIEW.GRID' | translate }}"
      (click)="setMode('grid')"
    >
      <i class="fa-regular fa-table-cells-large" [ngClass]="mode === 'grid' ? 'fa-solid' : 'fa-regular opacity-60'"></i>
    </button>
    <button
      type="button"
      class="soft-button soft-button-outline flex items-center justify-center text-center  !border-secondary !border-l-0 button-height pointer"
      [ngClass]="mode === 'list' ? 'bg-white text-primary' : 'bg-white !text-color'"
      title="{{ '#APP_OVERVIEW.LIST' | translate }}"
      (click)="setMode('list')"
    >
      <i class="fa-regular fa-list" [ngClass]="mode === 'list' ? 'fa-solid' : 'fa-regular  opacity-60'"></i>
    </button>
  </div>
  <div class="pb-4 mb-2"
    [ngClass]="
      (settings$ | async)?.display === 'list'
        ? 'grid-container-list'
        : 'grid-container'
    "
    *ngIf="permissions$ | async as permissions"
  >
    <ng-container
      *ngFor="
        let application of applications()
          | filter: filterPermissions:permissions
          | filter: filterInput:{ store: store, input: search }
      "
    >
      <soft-app-grid
        [id]="'softapps-allgemein-modules-griditem-' + application.name"
        *ngIf="(settings$ | async)?.display !== 'list'"
        class="transition-none"
        [application]="application"
        (navigate)="navigate($event)"
      >
      </soft-app-grid>
      <soft-app-list
        class="mt-2"
        [id]="'softapps-allgemein-modules-listitem-' + application.name"
        *ngIf="(settings$ | async)?.display === 'list'"
        [application]="application"
        (navigate)="navigate($event)"
      >
      </soft-app-list>
    </ng-container>
  </div>
</div>
