import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-default-page-header-content',
    imports: [CommonModule],
    templateUrl: './default-page-header-content.component.html',
    styleUrl: './default-page-header-content.component.scss'
})
export class DefaultPageHeaderContentComponent {
  overline = input<string | null | undefined>(undefined);
  title= input<string | null | undefined>(undefined);
  subtitle= input<string | null | undefined>(undefined);
}
