import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ResetPassword } from '../../../data/reset-password';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
  Validators,
} from '@softline/ui-core';
import { Store } from '@softline/core';
import { Subscription } from 'rxjs';
import { passwordValidator } from '../../../validation/password.validator';
import { passwordConfirmValidator } from '../../../validation/password-confirm.validator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SOFTLINE_FEATURE_PASSWORD,
  SOFTLINE_PAGE_LOGIN,
} from '../../../password.shared';
import { PasswordStore } from '../../../store/password.store';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'lib-reset-form',
    templateUrl: './reset-form.component.html',
    styleUrls: ['./reset-form.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule]
})
export class ResetFormComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;
  isUsernameReadOnly = false;
  isTokenReadOnly = false;

  form = new UntypedFormGroup({
    username: new UntypedFormControl('', {
      validators: [Validators.required()],
    }),
    token: new UntypedFormControl('', { validators: [Validators.required()] }),
    password: new UntypedFormControl('', {
      validators: [passwordValidator({ minLength: 8, strengthLevel: 4 })],
    }),
    passwordRepeat: new UntypedFormControl('', {
      validators: [Validators.required(), passwordConfirmValidator()],
    }),
  });

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscription = this.activatedRoute.queryParams.subscribe((o) => {
      this.form.patchValue(o);
      this.isUsernameReadOnly = !!o['username'];
      this.isTokenReadOnly = !!o['token'];
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = undefined;
  }

  async onSubmit(resetPassword: ResetPassword): Promise<void> {
    try {
      const action = PasswordStore.actions.resetPassword;
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_PASSWORD,
        action,
        resetPassword
      );

      if (result === true) {
        const message = '#PASSWORD.MESSAGES.RESET_SUCCESS';
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          message
        );
        await this.router.navigate([SOFTLINE_PAGE_LOGIN]);
      } else {
        const message = '#PASSWORD.MESSAGES.RESET_FAILURE';
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          message
        );
      }
    } catch (e) {
      const message = '#PASSWORD.MESSAGES.RESET_FAILURE';
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        message
      );
    }
  }
}
