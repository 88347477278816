import { Dictionary } from '../types/dictionary';
import { Observable } from 'rxjs';
import { ResourceService } from '../data/abstraction';
import { ApiResourceLocation } from '../data-access/types/api.location';
import { SOFTLINE_SERVICE_HTTP } from '../core.shared';
import { Inject } from '@angular/core';
import { Entity } from '../store/specialized/entity/types/entity';
import { QueryParameters } from '../store/specialized/entity/types/query';

export class SoftlineQueryService<T extends Entity, TCreate = T> {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    protected service: ResourceService<ApiResourceLocation>,
    protected queryPath: string
  ) {}

  query(
    query: QueryParameters<T>,
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined
  ): Observable<T[]> {
    const location = {
      path: this.queryPath,
      pathParams,
      queryParams: {
        ...queryParams,
        sort: query.sort,
        limit: query.limit,
        offset: query.offset,
      },
    };
    return this.service.get<T[], unknown>(location, query.query);
  }
}
