import { computed, Signal, signal } from '@angular/core';
import {
  LoadCollectionParameters,
  LoadListParameters,
  LoadObjectParameters,
  ReadonlyListRepository, ReadonlyRepository
} from '../../repositories/abstraction';
import { cancelableRxResource } from '../../signals/cancelable-resource';
import { of } from 'rxjs';

export interface RepositoryObjectServiceOptions<T> {
  defaultValue?: T;
  skipInitialLoad?: boolean;
}

export class ReadonlyRepositoryObjectService<T> {

  protected params = signal<Signal<LoadObjectParameters> | undefined>(undefined);
  protected resolvedParams: Signal<LoadObjectParameters | 'NO_QUERY'> = computed(() => {
    const params = this.params();
    if(!params)
      return 'NO_QUERY';
    return params();
  })


  private _resource = cancelableRxResource({
    request: () => ({
      params: this.resolvedParams(),
    }),
    loader: (param) => {
      if(param.request.params === 'NO_QUERY')
        return of(null);
      return this.repository.load(param.request.params);
    }
  });

  resource = this._resource.asReadonly();
  loading = this._resource.isLoading;
  error = this._resource.error;

  value = computed<T | null | undefined>(() => this._resource.value());

  constructor(protected repository: ReadonlyRepository<T>, private options: RepositoryObjectServiceOptions<T> = {}) {
    if(options.defaultValue)
      this._resource.set(options.defaultValue);
  }

  cancel(): void {
    this._resource.cancel()
  }

  reload(): void {
    this._resource.reload();
  }

  registerParams(params: Signal<LoadObjectParameters>): void {
    this.params.set(params)
  }

  unregisterParams() {
    this.params.set(undefined)
  }
}
