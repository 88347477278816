import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ObjectDefinition } from '../../data/definitions';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ClassRulePipe } from '../../pipes/class-rule.pipe';
import { BooleanRulePipe } from '../../pipes/boolean-rule.pipe';
import { GroupComponent } from '../atoms/values/group/group.component';
import { DynamicValueComponent } from '../dynamic-value/dynamic-value.component';

@Component({
  selector: 'soft-dynamic-object',
  templateUrl: './dynamic-object.component.html',
  styleUrls: ['./dynamic-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    ClassRulePipe,
    BooleanRulePipe,
    GroupComponent,
    DynamicValueComponent,
    NgForOf,
  ],
})
export class DynamicObjectComponent implements OnInit {
  @Input() value: any = {};
  @Input() definition?: ObjectDefinition;

  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {}
}
