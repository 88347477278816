import {
  Component,
  computed,
  inject,
  input,
  linkedSignal,
  resource,
  Signal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IconComponent,
  L10nModule,
  ResolvableModal,
  UiCorePipesModule,
} from '@softline/ui-core';
import {
  FilterAndSortComponent,
  FilterConfig,
  SortConfig,
} from '@softline/application';
import {
  Filter,
  FilterService,
  isDefinedNotEmpty,
  Sort,
  SortService,
} from '@softline/core';
import {
  DocumentInformation,
  UserInformation,
} from '../../types/document-information';

@Component({
  selector: 'soft-document-information-dialog',
  imports: [
    CommonModule,
    L10nModule,
    UiCorePipesModule,
    IconComponent,
    FilterAndSortComponent,
  ],
  templateUrl: './document-information-dialog.component.html',
  styleUrl: './document-information-dialog.component.scss',
})
export class DocumentInformationDialogComponent extends ResolvableModal<{
  changedAt: string;
  detail: DocumentInformation;
  downloadFn: () => void;
  downloadReadListFn: () => void;
}> {
  changedAt = input<string | null>(null);
  detail = input<DocumentInformation | null>(null);
  detailLoader = input<() => Promise<DocumentInformation>>();
  downloadFn = input<() => void>();
  downloadReadListFn = input<(documentInfo: DocumentInformation) => void>();

  filter = signal<Filter | null>(null);
  sort = signal<Sort | null>(null);

  readonly documentInfo = linkedSignal<
    DocumentInformation | null,
    DocumentInformation | null
  >({
    source: () => this.detail() ?? this.details.value() ?? null,
    computation: (source) => source,
  });

  filteredItems: Signal<UserInformation[]> = computed(() => {
    return this.sortService.sort(
      this.filterService.filter(
        this.documentInfo()?.userInformationResponseList ?? [],
        this.filter()
      ),
      this.sort()
    );
  });

  private sortService = inject(SortService);
  private filterService = inject(FilterService);

  sortConfig: SortConfig = {
    properties: [
      {
        property: 'anwender.anwender',
        title: 'Benutzername',
        comparer: (a, b) => (a as string).localeCompare(b as string),
      },
      {
        property: 'anwender.anwenderName',
        title: 'Name',
        comparer: (a, b) => (a as string).localeCompare(b as string),
      },
      {
        property: 'webDocFavorit.favourite',
        title: 'Favorit',
        comparer: (a, b) => Number(a) - Number(b),
      },
      {
        property: 'downloadHistory.downloadedAt',
        title: 'Gelesen',
        comparer: (_, b) => (isDefinedNotEmpty(b) ? 1 : -1),
      },
    ],
  };

  filterConfig: FilterConfig = {
    properties: [
      {
        property: 'anwender.anwender',
        title: 'Benutzername',
      },
      {
        property: 'anwender.anwenderName',
        title: 'Name',
      },
      {
        property: 'downloadHistory.downloadedAt',
        title: 'Gelesen',
        operators: ['isDefinedNotEmpty'],
        params: {
          formatFn: (value) =>
            !!value && isDefinedNotEmpty(value) ? 'Ja' : 'Nein',
        },
      },
      {
        property: 'webDocFavorit.favourite',
        title: 'Favorit',
        operators: ['isDefinedNotEmpty'],
        params: {
          formatFn: (value) =>
            !!value && isDefinedNotEmpty(value) ? 'Ja' : 'Nein',
        },
      },
    ],
  };

  details = resource({
    loader: async () => {
      const loader = this.detailLoader();

      if (loader) return loader();

      return this.detail();
    },
  });

  constructor() {
    super();
  }

  async download(): Promise<void> {
    this.downloadFn()?.();
  }

  async downloadReadList(
    documentInfo?: DocumentInformation | null
  ): Promise<void> {
    if (!documentInfo) return;

    this.downloadReadListFn()?.(documentInfo);
  }
}
