import { FilterService } from '../../filter/services/filter.service';
import { Filter } from '../../filter/types/filter';
import { SortService } from '../../sort/services/sort.service';
import { Sort } from '../../sort/types/sort';
import { computed, inject, signal, Signal } from '@angular/core';

export interface FilterAndSortServiceOptions {
  defaultFilter?: Filter;
  defaultSort?: Sort
}

export class FilterAndSortService<T> {

  protected filterService = inject(FilterService);
  protected sortService = inject(SortService);

  protected _filters = signal<Filter | null>(null);
  protected _sort = signal<Sort | null>(null);

  filters = this._filters.asReadonly();
  sort = this._sort.asReadonly();

  items = computed(() => {
    const items = this._items();
    const filter = this._filters();
    const sort = this._sort();

    return this.sortService.sort(this.filterService.filter(items(), filter), sort);
  });

  count = computed(() => this.items().length);

  constructor(protected _items: () => Signal<T[]>, protected options: FilterAndSortServiceOptions) {
    if(options.defaultFilter)
      this._filters.set(options.defaultFilter);
    if(options.defaultSort)
      this._sort.set(options.defaultSort);
  }

  setFilter(filter: Filter | null): void {
    this._filters.set(filter);
  }

  setSort(sort: Sort | null): void {
    this._sort.set(sort);
  }

  reset(): void {
    this._filters.set(this.options?.defaultFilter ?? null);
    this._sort.set(this.options?.defaultSort ?? null);
  }

  clear(): void {
    this._filters.set(null);
    this._sort.set(null);
  }
}
