import { Component } from '@angular/core';
import { ModalBase } from '../../data/modal';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../../../i18n/i18n.module';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { CalculatorComponent } from '../../../../components/calculator/calculator.component';

@Component({
    selector: 'soft-calculator-modal',
    imports: [CommonModule, UiCorePipesModule, I18nModule, CalculatorComponent],
    templateUrl: './calculator-modal.component.html',
    styleUrls: ['./calculator-modal.component.scss'],
})
export class CalculatorModalComponent extends ModalBase<number> {

  title: string | undefined;
  showCancel = false;
  params?: object;
  value: string = '';

  constructor() {
    super();
  }

  onSubmit(result: number): void {
    this.close(result);
  }
}
