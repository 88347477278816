import {Injectable} from '@angular/core';
import {ConnectionHttpService, ApiResourceLocation, ResponseResult} from '@softline/core';
import {Item} from '../types/item';
import {FavoriteList} from '../types/favorite-list';
import {Observable} from 'rxjs';
import {Favorite} from '../types/favorite';
import {map} from 'rxjs/operators';
import {SOFTLINE_API_ITEM_FAVORITE_LISTS} from '../artikel-info.api';

@Injectable()
export class FavoriteResourceService {

  constructor(private http: ConnectionHttpService) {}

  addItemToList(item: { id: number | string }, list: FavoriteList): Observable<Favorite> {
    return this.http.create<unknown, ResponseResult<Favorite>>(this.detailLocation(list.id), { item }).pipe(
      map(o => o.result)
    );
  }

  exportViaMail(payload: { id: number, mail: string }): Observable<any> {
    return this.http.create<unknown, ResponseResult<any>>(this.exportLocation(), payload).pipe(
      map(o => o?.result)
    );
  }

  private detailLocation(listId: number): ApiResourceLocation {
    return {
      path: `${SOFTLINE_API_ITEM_FAVORITE_LISTS}/${listId}`,
    }
  }

  private exportLocation(): ApiResourceLocation {
    return {
      path: `${SOFTLINE_API_ITEM_FAVORITE_LISTS}/export`,
    }
  }
}
