import { Pipe, PipeTransform, QueryList } from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: false
})
export class FilterPipe implements PipeTransform {
  transform<T, U>(
    array: readonly T[] | QueryList<T> | null | undefined,
    callbackFn: (o: T, p?: U) => boolean,
    parameter?: U
  ): T[] {
    if (array instanceof QueryList) array = array.toArray();

    if (!array || !Array.isArray(array)) return [];

    const returnValue = array.filter(
      (o) => callbackFn(o, parameter),
      parameter
    );
    return returnValue;
  }
}
