import { inject, OnDestroy, Pipe, PipeTransform, signal } from '@angular/core';
import { isDefined } from '@softline/core';
import { FormatOptions, LocalizationService } from '../localization.service';

@Pipe({
    name: 'format',
    pure: false
})
export class FormatPipe implements PipeTransform {

  private prevValue: string | number | object | undefined | null | Date = null;
  private service = inject(LocalizationService);
  private state = signal<string>('').asReadonly();

  transform(
    value: string | number | object | undefined | null | Date,
    format: string | undefined,
    options?: FormatOptions): string {
    if (!isDefined(value))
      return '';

    if (this.prevValue !== value) {
      // This must be untracked because we want to create signals inside of template execution.
      this.state = this.service.format(value, format, options);
      this.prevValue = value;
    }
    return this.state();
  }
}
