import { signal, ValueEqualityFn } from '@angular/core';

export interface ListServiceOptions<T> {
  defaultItems?: T[];
  allowDuplicates?: boolean;
  equals?: ValueEqualityFn<T>;
}

export class ListService<T> {
  _items = signal<T[]>([]);
  items = this._items.asReadonly();

  constructor(protected options: ListServiceOptions<T> = {}) {
    if(options.defaultItems)
      this._items.set(options.defaultItems);
  }

  add(value: T): void {
    const items = this.items();
    if(this.options.allowDuplicates == false && items.findIndex(o => this.options.equals ? this.options.equals(o, value) : o === value))
      throw new Error(`[ListService] add: item already exists in the list.`);
    this._items.set([...items, value]);
  }

  insert(index: number, value: T): void {
    const items = this.items();
    if(this.options.allowDuplicates == false && items.findIndex(o => this.options.equals ? this.options.equals(o, value) : o === value))
      throw new Error(`[ListService] insert: item already exists in the list.`);
    this._items.set([...items.slice(0, index), value, ...items.slice(index)]);
  }

  removeAt(index: number): void {
    const items = [...this.items()];
    if(index < 0 || index >= items.length)
      throw new Error(`[ListService] removeAt: index out of range.`);
    items.splice(index, 1);
    this._items.set(items);
  }

  remove(value: T): void {
    const items = [...this.items()];
    const index = items.findIndex(o => this.options.equals ? this.options.equals(o, value) : o === value);
    if(index === -1)
      throw new Error(`[ListService] remove: item not found in the list.`);
    items.splice(index, 1);
    this._items.set(items);
  }

  clear(): void {
    this._items.set([]);
  }

  reset(): void {
    this._items.set(this.options?.defaultItems ?? []);
  }
}
