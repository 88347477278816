import { Component, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { dataUriToBlob } from '@softline/core';
import { SignaturePadComponent } from '../../../components/signature-pad/signature-pad.component';
import { TranslatePipe } from '../../../i18n/pipes/translate.pipe';
import { ResolvableModal } from '../../abstraction/modal';

@Component({
  selector: 'soft-signature-modal',
  imports: [CommonModule, SignaturePadComponent, TranslatePipe],
  templateUrl: './signature-modal.component.html',
  styleUrl: './signature-modal.component.scss',
})
export class SignatureModalComponent extends ResolvableModal<Blob | null>{
  showCancel = input<boolean>(false);

  onSubmit(value: string | null): void {
    if (typeof value === 'string') {
      const blob = dataUriToBlob(value);
      this.resolve(blob);
    } else
      this.resolve(value);
  }
}
