import { EnvironmentProviders, Provider, Type } from '@angular/core';
import { SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT } from "./filter-and-sort.shared";
import { FilterConfigOperator } from "@softline/application";
import {
  NumberFilterValueComponent
} from "./components/filter-list/filter/values/number-filter-value/number-filter-value.component";
import {
  TextFilterValueComponent
} from "./components/filter-list/filter/values/text-filter-value/text-filter-value.component";
import {
  containsTextFilterRegistration,
  endsWithFilterRegistration,
  equalFilterRegistration,
  falseFilterRegistration,
  greaterThanFilterRegistration,
  greaterThanOrEqualFilterRegistration,
  isDefinedFilterRegistration,
  isDefinedNotEmptyFilterRegistration,
  isNotDefinedFilterRegistration,
  isNotDefinedOrEmptyFilterRegistration,
  lessThanFilterRegistration,
  lessThanOrEqualFilterRegistration,
  likeFilterRegistration,
  notEqualFilterRegistration,
  notLikeFilterRegistration,
  startsWithFilterRegistration,
  trueFilterRegistration,
  inFilterRegistration,
  notInFilterRegistration
} from "@softline/core";
import { getFilterValueTypes } from "./functions/get-filter-value-types";
import {
  DateFilterValueComponent
} from "./components/filter-list/filter/values/date-filter-value/date-filter-value.component";
import {
  SelectFilterValueComponent
} from "./components/filter-list/filter/values/select-filter-value/select-filter-value.component";
import {
  MultiFilterValueComponent
} from "./components/filter-list/filter/values/multi-filter-value/multi-filter-value.component";

const textFilterFactory = ({vcRef, values, filter, filterChange, config }) => {
  const component = vcRef.createComponent(TextFilterValueComponent);
  component.instance.values = values;
  component.instance.filter = filter;
  component.instance.config = config;
  component.instance.registerOnChange(filterChange);
  return component
};

const relationalFilterFactory = ({vcRef, values, filter, filterChange, config }) => {
  const types = getFilterValueTypes(values);
  let componentType: Type<any> = TextFilterValueComponent;
  if(types.indexOf('number') > -1)
    componentType = NumberFilterValueComponent
  else if(types.indexOf('date') > -1)
    componentType = DateFilterValueComponent
  else if(types.indexOf('object') > -1)
    componentType = SelectFilterValueComponent

  const component = vcRef.createComponent(componentType);
  component.instance.values = values;
  component.instance.filter = filter;
  component.instance.config = config;
  component.instance.registerOnChange(filterChange);
  return component
};

const multiFilterFactory = ({vcRef, values, filter, filterChange, config }) => {
  const component = vcRef.createComponent(MultiFilterValueComponent);
  component.instance.values = values;
  component.instance.filter = filter;
  component.instance.config = config;
  component.instance.registerOnChange(filterChange);
  return component
};

const trueOperatorComponent: FilterConfigOperator = {
  operator: trueFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.TRUE',
}
const falseOperatorComponent: FilterConfigOperator = {
  operator: falseFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.FALSE',
}
const equalOperatorComponent: FilterConfigOperator = {
  operator: equalFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.EQUAL',
  componentFactory: relationalFilterFactory
}
const notEqualOperatorComponent: FilterConfigOperator = {
  operator: notEqualFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.NOT_EQUAL',
  componentFactory: relationalFilterFactory
}
const greaterThanOperatorComponent: FilterConfigOperator = {
  operator: greaterThanFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.GREATER_THAN',
  componentFactory: relationalFilterFactory
}
const greaterThanOrEqualOperatorComponent: FilterConfigOperator = {
  operator: greaterThanOrEqualFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.GREATER_THAN_OR_EQUAL',
  componentFactory: relationalFilterFactory
}
const lessThanOperatorComponent: FilterConfigOperator = {
  operator: lessThanFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.LESS_THAN',
  componentFactory: relationalFilterFactory
}
const lessThanOrEqualOperatorComponent: FilterConfigOperator = {
  operator: lessThanOrEqualFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.LESS_THAN_OR_EQUAL',
  componentFactory: relationalFilterFactory
}

const isDefinedOperatorComponent: FilterConfigOperator = {
  operator: isDefinedFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.DEFINED',
}
const isNotDefinedOperatorComponent: FilterConfigOperator = {
  operator: isNotDefinedFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.NOT_DEFINED',
}
const isDefinedNotEmptyOperatorComponent: FilterConfigOperator = {
  operator: isDefinedNotEmptyFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.DEFINED',
}
const isNotDefinedOrEmptyOperatorComponent: FilterConfigOperator = {
  operator: isNotDefinedOrEmptyFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.NOT_DEFINED',
}

const likeOperatorComponent: FilterConfigOperator = {
  operator: likeFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.LIKE',
  componentFactory: textFilterFactory
}
const notLikeOperatorComponent: FilterConfigOperator = {
  operator: notLikeFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.NOT_LIKE',
  componentFactory: textFilterFactory
}
const startsWithOperatorComponent: FilterConfigOperator = {
  operator: startsWithFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.STARTS_WITH',
  componentFactory: textFilterFactory
}
const endsWithOperatorComponent: FilterConfigOperator = {
  operator: endsWithFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.ENDS_WITH',
  componentFactory: textFilterFactory
}

const containsTextOperatorComponent: FilterConfigOperator = {
  operator: containsTextFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.CONTAINS_TEXT',
  componentFactory: textFilterFactory
}

const inOperatorComponent: FilterConfigOperator = {
  operator: inFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.IN',
  componentFactory: multiFilterFactory
}
const notInOperatorComponent: FilterConfigOperator = {
  operator: notInFilterRegistration.operator,
  title: '#APPLICATION.FILTER_AND_SORT.FILTER.REGISTRATIONS.NOT_IN',
  componentFactory: multiFilterFactory
}

export const filerAndSortProviders: Array<Provider | EnvironmentProviders> = [
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: trueOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: falseOperatorComponent, multi: true},

  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: equalOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: notEqualOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: lessThanOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: lessThanOrEqualOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: greaterThanOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: greaterThanOrEqualOperatorComponent, multi: true},

  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: isDefinedOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: isNotDefinedOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: isDefinedNotEmptyOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: isNotDefinedOrEmptyOperatorComponent, multi: true},

  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: likeOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: notLikeOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: startsWithOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: endsWithOperatorComponent, multi: true},

  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: containsTextOperatorComponent, multi: true},

  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: inOperatorComponent, multi: true},
  {provide: SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT, useValue: notInOperatorComponent, multi: true},
]
