<div class="flex flex-col p-2">

  <!-- {{ documentInfo() | json }}-->

  <div class="grid grid-cols-1 md:grid-cols-2 items-center">
    <span class="font-semibold text-xl mb-4">Dokumenten-Informationen</span>
    @if (!!downloadReadListFn) {
      <button class="soft-button primary rounded flex items-center justify-center flex-shrink-0 w-70 h-8"
              (click)="downloadReadList(documentInfo())">
        <span>Benutzer Liste Herunterladen</span>
        <soft-icon [name]="'fa-regular fa-cloud-arrow-down'" class="px-2 text-base"></soft-icon>
      </button>
    }
  </div>

  <span class="font-light text-primary-400 my-1">Informationen</span>
  <div class="soft-card hard mb-4">
    <div class="flex flex-row justify-between w-full p-2">
      <div class="flex flex-col flex-grow">
        <span class="text-xs text-light">{{ documentInfo()?.owner }}</span>
        <span class="font-semibold mt-1 mb-1 break-all">{{ documentInfo()?.name }}</span>
        <span
          class="text-xs text-light text-nowrap">Zuletzt geändert: {{ changedAt() | format:'DATE_TIME.DEFAULT' }}</span>
        <span
          class="text-xs text-light text-nowrap">Größe: {{ (documentInfo()?.sizeInKiloBytes ?? 0 | filesize:{ format: '1.0' }) }}</span>
      </div>

      @if (!!downloadFn) {
        <div class="flex p-2 items-center flex-shrink-0 w-16" (click)="download()">
          <soft-icon [name]="'fa-regular fa-cloud-arrow-down'" class="px-2 text-2xl"></soft-icon>
        </div>
      }
    </div>
  </div>

  <span class="font-light text-primary-400 mb-1">Benutzer</span>
  <soft-filter-and-sort
    [filter]="filter()"
    [sort]="sort()"
    [values]="documentInfo()?.userInformationResponseList ?? []"
    [sortConfig]="sortConfig"
    [filterConfig]="filterConfig"
    (filterChange)="filter.set($event)"
    (sortChange)="sort.set($event)"
  ></soft-filter-and-sort>

  <div class="overflow-auto h-[50vh] mt-2">
    @for (person of filteredItems(); track person) {
      <div class="border-b last:border-b-0">
        <div class="flex flex-row items-center justify-between w-full px-2 py-1">
          <div class="flex flex-col flex-grow gap-0.5">
            <span class="text-xs text-light name">{{ person.anwender.anwender }}</span>
            <span class="font-semibold text-sm name">{{ person.anwender.anwenderName }}</span>
            @if (person.downloadHistory.downloadedAt !== null) {
              <span
                class="text-xs text-light text-nowrap name">Zuletzt geöffnet: {{ person.downloadHistory.downloadedAt | format:'DATE_TIME.DEFAULT' }}</span>
            }
          </div>

          <div class="flex flex-col p-2 justify-between items-end flex-shrink-0 w-40">
            <span
              class="text-xs text-light text-right text-nowrap name">Favorit: {{ person.webDocFavorit.favourite ? 'Ja' : 'Nein' }}</span>
            <span
              class="text-xs text-nowrap text-right name">Gelesen: {{ person.downloadHistory.downloadedAt !== null ? 'Ja' : 'Nein' }}</span>
          </div>
        </div>
      </div>
    }
  </div>
</div>
