import { inject, Pipe, PipeTransform, signal } from '@angular/core';
import { TranslationService } from '../translation.service';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform{
  private prevValue: string | null = null;
  private translationService = inject(TranslationService);
  private state = signal<string | null>(null).asReadonly();

  transform(value: string | undefined | null): string | null | undefined {
    if (!value)
      return value;

    if (this.prevValue !== value) {
      // This must be untracked because we want to create signals inside of template execution.
      this.state = this.translationService.translate(value);
      this.prevValue = value;
    }
    return this.state();
  }
}
