import { Component, EventEmitter, input, output, Signal, viewChild, WritableSignal } from '@angular/core';
import { FieldOkComponent } from '@softline/dynamic';
import {ControlValueAccessor} from '@angular/forms';
import {DefaultFieldOkConfiguration} from './field-ok-configuration';
import { Subject } from 'rxjs';

export abstract class FieldOkWrapper<T extends DefaultFieldOkConfiguration> implements ControlValueAccessor {

  abstract value: Signal<object | null>;
  abstract configuration: Signal<T | null>;
  abstract parameters: Signal<object>;
  abstract readonly: Signal<boolean>;

  /*
  override value = input<object | null>(null);
  override configuration = input<T | null>(null);
  override parameters = input<object>({});
  override readonly = input<boolean>(false);
  */

  valueChange = new EventEmitter<object | null>;

  abstract fieldOkComponent: Signal<FieldOkComponent<any>>;

  registerOnChange(fn: any): void {
    this.fieldOkComponent().registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.fieldOkComponent().registerOnTouched(fn);
  }

  writeValue(obj: any): void {
    this.fieldOkComponent().writeValue(obj);
  }
}
