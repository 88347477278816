import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  RendererFactory2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FileService, Store } from '@softline/core';
import { Observable } from 'rxjs';
import { SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT } from '../../pdf-editor.shared';
import {
  DynamicParams,
  PdfEditorComponentStore,
} from '../../store/pdf-editor-component.store';
import { Modal, ModalStore, SOFTLINE_FEATURE_MODAL } from '@softline/ui-core';
import { CommonModule } from '@angular/common';
import { PdfEditorToolsComponent } from '../pdf-editor-tools/pdf-editor-tools.component';
import { fabric } from 'fabric';
import * as pdfjsLib from 'pdfjs-dist';
import { PdfEditorToolType } from '../../data/pdf-editor-tool';
import { Canvas, IEvent } from 'fabric/fabric-impl';

@Component({
    selector: 'soft-pdf-editor',
    imports: [CommonModule, PdfEditorToolsComponent],
    templateUrl: './pdf-editor.component.html',
    styleUrls: ['./pdf-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfEditorComponent implements OnInit, AfterViewInit, Modal<any> {
  private viewInitialized = false;
  toolboxToggle = false;

  private _document?: Blob | null;
  @Input() get document(): Blob | undefined | null {
    return this._document;
  }
  set document(value: Blob | undefined | null) {
    this._document = value;
    if (value && this.viewInitialized) this.renderDocument(value);
  }

  @Output() submit = new EventEmitter<Blob>();
  @ViewChild('content') content!: ElementRef;

  loading$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.loading
  );

  saving$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.saving
  );

  downloading$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.downloading
  );

  hasSelectedObjects$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.hasSelectedObjects
  );

  close!: (result: any) => void;

  constructor(
    private store: Store,
    private fileService: FileService,
    private rendererFactory: RendererFactory2
  ) {
    // fileService.openBlob(); // Downloads the blob on browser
  }

  async ngOnInit(): Promise<void> {
    // Preload custom font family
    const text = new fabric.Text('', { fontFamily: 'Font Awesome 5 Free' });
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      '/assets/lib/pdf.js/build/pdf.worker.js';
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.reset
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.actions.loadCustomTools
    );
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.document) await this.renderDocument(this.document);
    this.viewInitialized = true;
  }

  async renderDocument(document: Blob): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.loading,
      true
    );
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.setDocument,
      document
    );

    const dataUrl = await this.blobToDataUrl(document);
    const pdf = await pdfjsLib.getDocument(dataUrl).promise;
    const renderer = this.rendererFactory.createRenderer(null, null);

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({
        scale: 1.0,
      });

      const div = renderer.createElement('div') as HTMLDivElement;
      div.style.position = 'relative'
      renderer.appendChild(this.content.nativeElement, div);

      const pdfCanvas = renderer.createElement('canvas') as HTMLCanvasElement;
      pdfCanvas.id = `page-${i}-pdf`;
      pdfCanvas.width = viewport.width;
      pdfCanvas.height = viewport.height;
      pdfCanvas.style.position = 'absolute';
      renderer.appendChild(div, pdfCanvas);

      const canvasContext = pdfCanvas.getContext('2d') as CanvasRenderingContext2D;
      await page.render({
        canvasContext,
        viewport,
      }).promise;

      const canvas = renderer.createElement('canvas') as HTMLCanvasElement;
      canvas.id = `page-${i}`;
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      canvas.style.position = 'absolute';
      renderer.appendChild(div, canvas);

      const fCanvas = new fabric.Canvas(canvas, {
        selection: true,
        preserveObjectStacking: true,
      });
      fCanvas.renderAll();
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.addOriginalData,
        fCanvas.toJSON()
      );

      fCanvas.on('object:added', (e) => this.onCanvasObjectAdded(e));
      fCanvas.on(
        'selection:created',
        (e: IEvent & { selected?: fabric.Object[] }) =>
          this.onCanvasSelectionCreated(e)
      );
      fCanvas.on(
        'selection:updated',
        (e: IEvent & { selected?: fabric.Object[] }) =>
          this.onCanvasSelectionUpdated(e)
      );
      fCanvas.on('selection:cleared', (e) => this.onCanvasSelectionCleared());
      fCanvas.on('mouse:up', (e) => this.onCanvasMouseUp(fCanvas, e));

      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.addCanvas,
        fCanvas
      );
    }

    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.loading,
      false
    );
  }

  private onCanvasObjectAdded(e: IEvent<Event>) {
    // console.log('object:added', e);
    const selectedTool = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.getters.selectedTool
    );
    if (e?.target && selectedTool) {
      (e.target as fabric.Object & DynamicParams)['toolType'] = selectedTool.type;
    }
  }

  private onCanvasSelectionCreated(e: IEvent<Event> & { selected?: object[] }) {
    if(!e.selected)
      throw new Error('[PdfEditorComponent]onCanvasSelectionCreated: no canvas selected')
    // console.log('selection:created', e);
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.setHasSelectedObjects,
      true
    );
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.selectTool,
      undefined
    );

    if (e.selected?.length === 1) {
      const canvasObject = e.selected[0] as fabric.Object & DynamicParams;

      if (canvasObject['toolType'] === PdfEditorToolType.TextBox) {
        const toolGroups = this.store.get(
          SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
          PdfEditorComponentStore.getters.toolGroups
        );
        for (const toolGroup of toolGroups) {
          const tool = toolGroup.tools.find(
            (t) => t.type === canvasObject['toolType']
          );
          if (tool) {
            this.store.commit(
              SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
              PdfEditorComponentStore.mutations.selectTool,
              tool
            );
            break;
          }
        }
      }
    }
  }

  private onCanvasSelectionUpdated(e: IEvent<Event> & { selected?: object[] }) {
    if(!e.selected)
      throw new Error('[PdfEditorComponent]onCanvasSelectionCreated: no canvas selected')
    // console.log('selection:updated', e);
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.setHasSelectedObjects,
      true
    );
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.selectTool,
      undefined
    );

    if (e.selected?.length === 1) {
      const canvasObject = e.selected[0] as fabric.Object & DynamicParams;

      if (canvasObject['toolType'] === PdfEditorToolType.TextBox) {
        const toolGroups = this.store.get(
          SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
          PdfEditorComponentStore.getters.toolGroups
        );
        for (const toolGroup of toolGroups) {
          const tool = toolGroup.tools.find(
            (t) => t.type === canvasObject['toolType']
          );
          if (tool) {
            this.store.commit(
              SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
              PdfEditorComponentStore.mutations.selectTool,
              tool
            );
            break;
          }
        }
      }
    }
  }

  private onCanvasSelectionCleared() {
    // console.log('selection:cleared', e);
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.setHasSelectedObjects,
      false
    );
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.selectTool,
      undefined
    );
  }

  private onCanvasMouseUp(fCanvas: Canvas, e: IEvent<MouseEvent>) {
    // console.log('mouse:up', e);
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.setActiveCanvas,
      fCanvas
    );

    const selectedTool = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.getters.selectedTool
    );
    const hasSelectedObjects = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.getters.hasSelectedObjects
    );

    if (!hasSelectedObjects) {
      if (selectedTool?.type === PdfEditorToolType.TextBox) {
        this.store.commit(
          SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
          PdfEditorComponentStore.mutations.renderTextBox,
          {
            left: e.pointer?.x || 50,
            top: e.pointer?.y || 50,
          }
        );
      } else if (selectedTool?.type === PdfEditorToolType.Note) {
        this.store.commit(
          SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
          PdfEditorComponentStore.mutations.renderNote,
          {
            left: e.pointer?.x || 50,
            top: e.pointer?.y || 50,
          }
        );
      } else if (selectedTool?.type === PdfEditorToolType.Image) {
        this.store.commit(
          SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
          PdfEditorComponentStore.mutations.renderImage,
          {
            left: e.pointer?.x || 50,
            top: e.pointer?.y || 50,
          }
        );
      }
    }
  }

  async onSubmit(): Promise<void> {
    const pdfDocument = await this.store.dispatch(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.actions.prepareDocument
    );
    const result = await pdfDocument.save();
    const blob = new Blob([result], { type: 'application/pdf' });
    this.submit.emit(blob);
  }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async removeSelectedObjects(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        question: '#PDF_EDITOR.ASK.CONFIRM_DELETE',
        dismiss: { escape: true, backdrop: true },
      }
    );

    if (result === 'YES') {
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.removeSelectedObjects
      );
    }
  }

  async clear(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        question: '#PDF_EDITOR.ASK.CONFIRM_DELETE',
        dismiss: { escape: true, backdrop: true },
      }
    );

    if (result === 'YES') {
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.clearCanvases
      );
    }
  }

  async download(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.actions.downloadDocument
    );
  }

  @HostListener('document:keyup', ['$event'])
  private async onKeyup(e: KeyboardEvent): Promise<void> {
    if (e.key === 'Delete') {
      this.removeSelectedObjects();
    }
  }

  private blobToDataUrl(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}
