import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive({
  selector: '[softToolTip]',
  standalone: true,
})
export class ToolTipDirective implements OnInit, OnDestroy {
  @Input('toolTipVisible') isVisible = true;
  @Input('softToolTip') content: string | TemplateRef<any> = '';
  @Input('toolTipContext') context?: any;
  @Input('toolTipPosition') position: 'top' | 'right' | 'bottom' | 'left' =
    'top';

  private overlayRef?: OverlayRef;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit(): void {
    let position: ConnectedPosition = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    };
    switch (this.position) {
      case 'top':
        position = {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        };
        break;
      case 'right':
        position = {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        };
        break;
      case 'bottom':
        position = {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        };
        break;
      case 'left':
        position = {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
        };
        break;
    }

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([position]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    if (this.overlayRef) this.overlayRef.detach();
  }

  @HostListener('mouseenter')
  show(): void {
    if (!this.overlayRef || !this.isVisible || !this.content) return;

    const tooltipPortal = new ComponentPortal(ToolTipComponent);
    const tooltipRef: ComponentRef<ToolTipComponent> =
      this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.content = this.content;
    tooltipRef.instance.context = this.context;
  }

  @HostListener('mouseleave')
  @HostListener('mouseup')
  hide(): void {
    if (this.overlayRef) this.overlayRef.detach();
  }
}

@Component({
    selector: 'soft-tool-tip',
    imports: [CommonModule],
    template: `
    <div class="tool-tip">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'string'">{{ content }}</ng-container>
        <ng-container *ngSwitchCase="'template'">
          <ng-container
            *ngTemplateOutlet="$any(content); context: context"
          ></ng-container>
        </ng-container>
      </ng-container>
    </div>
  `,
    styles: ['']
})
export class ToolTipComponent {
  private _content: string | TemplateRef<any> = '';

  type: 'string' | 'template' = 'string';

  @Input() context: any;

  @Input()
  get content(): string | TemplateRef<any> {
    return this._content;
  }
  set content(content: string | TemplateRef<any>) {
    this._content = content;
    this.type = content instanceof TemplateRef ? 'template' : 'string';
  }

  constructor() {}
}
