import { TranslationFile } from './data/translation-file';
import {
  createAction,
  createGetter,
  createMutation,
  distinct,
  isDefined,
  mutate,
  NestedError,
  on,
  select,
  StoreFeature
} from '@softline/core';
import { TranslationLoader } from './services/translation-loader';
import { SOFTLINE_CONFIG_DEFAULT_LANGUAGE } from './i18n.shared';

export interface State {
  translations: TranslationFile[];
}

export const mutations = {
  add: createMutation<State, TranslationFile>('add'),
  addMany: createMutation<State, TranslationFile[]>('addMany'),
  clear: createMutation<State>('clear'),
};

export const getters = {
  translation: createGetter<State, string, string>('translation'),
  translateMany: createGetter<State, string[], string[]>('translate many'),
};

export const actions = {
  load: createAction<
    State,
    { language?: string; module?: string; priority?: number } | undefined
  >('load'),
  loadLanguage: createAction<State, { language: string }>('loadLanguage'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    translations: []
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => {
      const translations = [...state.translations];
      const index = translations.findIndex(
        (o) => o.language === params.language && o.module === params.module
      );
      if (index > -1) translations.splice(index, 1);
      translations.push(params);
      return { ...state, translations };
    }),
    mutate(mutations.addMany, ({ state, params }) => {
      const translations = [...state.translations];
      for (const translation of params) {
        const index = translations.findIndex(
          (o) =>
            o.language === translation.language &&
            o.module === translation.module
        );
        if (index > -1) translations.splice(index, 1);
        translations.push(translation);
      }
      return { ...state, translations };
    }),
    mutate(mutations.clear, ({ state }) => ({ ...state, translations: [] })),
  ],
  actions: [
    on(actions.load, async ({  params, injector, featureName, commit }) => {
      const service = injector.get(TranslationLoader);
      const defaultLanguage = injector.get(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);
      try {
        const file = await service.load(
          params?.language ?? defaultLanguage,
          params?.module
        );
        commit(featureName, mutations.add, file);
      } catch (e: any) {
        throw new NestedError(
          `TranslationStore: Unable to load translation '${
            params?.language ?? defaultLanguage
          }' for module '${params?.module}'`,
          e
        );
      }
    }),
    on(
      actions.loadLanguage,
      async ({ state, params, injector, featureName, commit, get }) => {
        const modules = distinct(state.translations.map((o) => o.module));
        const service = injector.get(TranslationLoader);
        const defaultLanguage = injector.get(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);

        const newTranslations: TranslationFile[] = [];
        for (const module of modules) {
          const index = state.translations.findIndex(
            (o) => o.module === module && o.language === params.language
          );
          if (index > -1) continue;
          const file = await service.load(
            params.language ?? defaultLanguage,
            module
          );
          newTranslations.push(file);
        }
        if (newTranslations.length > 0)
          commit(featureName, mutations.addMany, newTranslations);
      }
    ),
  ],
  getters: [
    select(getters.translation, ({ state, params, get, featureName, injector }) => {
      let translation: string | undefined;
      let priority: number | undefined;

      for (const file of state.translations.filter(
        (o) => o.language === 'de'
      )) {
        const tempTranslation = findTranslation(params, file);
        if (
          isDefined(tempTranslation) &&
          (file.priority ?? Number.NEGATIVE_INFINITY) >=
            (priority ?? Number.NEGATIVE_INFINITY)
        ) {
          translation = tempTranslation;
          priority = file.priority;
        }
      }
      return translation ?? params;
    }),
    select(getters.translateMany, ({ state, params, get, featureName }) => {
      const translations: string[] = [];
      for (const text of params)
        translations.push(get(featureName, getters.translation, text));
      return translations;
    }),
  ],
};

function findTranslation(
  key: string,
  file: TranslationFile
): string | undefined {
  const parts = key.split('.');
  let translations = file.translations as any;
  let translation: string | undefined;
  while (parts.length > 0) {
    const partName = parts.shift() ?? '';
    if(!translations)
      break;
    const part = translations[partName];

    if (typeof part === 'object') translations = part;
    else if (parts.length === 0 && typeof part === 'string') translation = part;
    else break;
  }
  return translation;
}
