import { Pipe, PipeTransform } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { isDefined } from '@softline/core';

@Pipe({
    name: 'isValid',
    pure: false,
    standalone: false
})
export class IsValidPipe implements PipeTransform {
  transform(control: AbstractControl): unknown {
    const stack: AbstractControl[] = [control];

    while (stack.length > 0) {
      const current = stack.pop();
      if (!current) continue;

      if (
        current instanceof UntypedFormGroup ||
        current instanceof UntypedFormArray
      )
        stack.push(...Object.values(current.controls));

      if (!current.errors) continue;

      for (const value of Object.values(current.errors).filter(isDefined)) {
        if (!isDefined(value.isValid) || value.isValid === false) return false;
      }
    }
    return true;
  }
}
