import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ResourceModule } from "./data/resource.module";
import { StoreModule } from "./store/store.module";
import { SOFTLINE_CONFIG_COOKIE_PREFIX, SOFTLINE_SERVICE_HTTP, SOFTLINE_SERVICE_UUID } from "./core.shared";
import { DateService } from "./date-time/date.service";
import { MomentDateService } from "./date-time/moment-date.service";
import { Logger } from "./log/logger";
import { WrappedResourceService } from "./data/specialized/wrapped-resource.service";
import { ConnectionHttpService } from "./data/specialized/http/connection-http.service";
import { ResponseResult } from "./data/specialized/http/response-result";
import { v4 as uuidv4 } from "uuid";
import { Mediator } from "./mediator/mediator";
import { filterProviders } from "./filter/filter.providers";
import { sortProviders } from "./sort/sort.providers";
import { telemetryProviders } from "./telemetry/telemetry.providers";
import { store2Providers } from './store2/store2.providers';

@NgModule({
  imports: [CommonModule, ResourceModule],
  providers: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreRootModule> {
    return {
      ngModule: CoreRootModule,
      providers: [
      ],
    };
  }
}

@NgModule({
  imports: [CommonModule, ResourceModule.forRoot(), StoreModule.forRoot()],
  providers: [
    Logger,
    Mediator,
    ...store2Providers,
    ...filterProviders,
    ...sortProviders,
    ...telemetryProviders,
    { provide: SOFTLINE_CONFIG_COOKIE_PREFIX, useValue: 'Softline' },
    { provide: SOFTLINE_SERVICE_UUID, useValue: () => uuidv4() },
    { provide: DateService, useClass: MomentDateService },
    {
      provide: SOFTLINE_SERVICE_HTTP,
      useFactory: (service: ConnectionHttpService) =>
        new WrappedResourceService(
          service,
          (response: ResponseResult<any>) => response?.result
        ),
      deps: [ConnectionHttpService],
    },
  ],
})
export class CoreRootModule { }
