import {
  Component, computed,
  effect,
  inject,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  untracked,
  ViewContainerRef
} from '@angular/core';
import {
  AddOnService,
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  showRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import { base64Encode, containsText, SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import { asapScheduler, BehaviorSubject, combineLatest, observeOn, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE } from '../../gesamtabfrage.api';
import { Beleg } from '../../types/beleg.model';
import {
  GesamtabfrageConfig,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL
} from '../../gesamtabfrage.shared';
import { DefinitionStore, DynamicModule, SOFTLINE_FEATURE_DEFINITIONS } from '@softline/dynamic';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { Belegart } from '../../types/belegart.model';
import { BelegStore } from '../../store/beleg.store';
import { GeschaeftsfallStore } from '../../store/geschaeftsfall.store';
import { DownloadCommand } from '../../commands/download.command';
import { BelegartStore } from '../../store';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'soft-business-case',
    templateUrl: './business-case.component.html',
    styleUrls: ['./business-case.component.scss'],
    imports: [CommonModule, UiCoreModule, DynamicModule]
})
export class BusinessCaseComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;
  private paramSubscription?: Subscription;


  route = inject(ActivatedRoute)
  belegartStore = inject(BelegartStore);

  belegartName = toSignal(this.route.paramMap.pipe(
      map((o) => o.get('type') ?? '')
    ), {initialValue: ''}
  );

  belegarten = this.belegartStore.collection.all;
  belegart = computed(() => this.belegartStore.collection.dict()[this.belegartName()]);
  belegart$ = toObservable(this.belegart);

  search$ = new BehaviorSubject<string>('');
  filteredSearch$ = this.search$.pipe(
    map((o) => (o.length >= 3 ? o : '')),
    debounceTime(200),
    distinctUntilChanged()
  );

  selected$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
    GeschaeftsfallStore.getters.selected
  );

  anySelected$ = this.selected$.pipe(map((o) => o.length > 0));

  definition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE
  );

  data$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
    GeschaeftsfallStore.getters.all
  );

  loading$ = this.store
    .observe(
      SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
      GeschaeftsfallStore.getters.querying
    )
    .pipe(observeOn(asapScheduler));


  geschaeftsfallLength$ = this.store
    .observe(
      SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
      GeschaeftsfallStore.getters.ids
    )
    .pipe(map((o) => o.length));

  filteredData$ = combineLatest([this.data$, this.filteredSearch$]).pipe(
    map(([rows, text]) => rows.filter((o) => containsText(o, text)))
  );

  private readonly _onBelegartChange = effect(() => {
    const belegart = this.belegart();

    if (!belegart)
      return;

    untracked(() => {
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setTitle,
        belegart?.kundenName ?? 'Geschäftsfall'
      );

      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        {
          name: BusinessCaseComponent,
          commands: this.createCommands(belegart),
        }
      );
    })
  });

  constructor(
    private store: Store,
    protected router: Router,
    private backNavigationService: BackNavigationService,
    private addOnService: AddOnService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    private injector: Injector,
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig,
    public vcRef: ViewContainerRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    await this.addOnService.attachTo(this);

    this.subscription = this.route.paramMap
      .pipe(map((o) => ({ belegart: { id: o.get('type') }, id: o.get('id') })))
      .subscribe(async (query) => {
        try {
          await this.store.dispatch(
            SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
            GeschaeftsfallStore.actions.query,
            { query, clear: true }
          );
        } catch (e) {
          showRequestErrors(this.store, e);
        }
      });
  }

  async ngOnDestroy(): Promise<void> {
    this.backNavigationService.set(undefined);
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.paramSubscription && !this.paramSubscription.closed)
      this.paramSubscription.unsubscribe();
    this.paramSubscription = undefined;
    await this.addOnService.detachFrom(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      BusinessCaseComponent
    );
  }

  async navigateBack(): Promise<void> {
    const queryParams = this.store.get(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.getters.query.query
    );
    const type = this.route.snapshot.paramMap.get('type');
    if (!queryParams)
      await this.router.navigate(['/gesamtabfrage', type, 'find']);
    else {
      const query = base64Encode(JSON.stringify(queryParams));
      await this.router.navigate(['/gesamtabfrage', type, 'list'], {
        queryParams: { query },
      });
    }
  }

  onSelectedItemsChange(entities: Beleg[]): void {
    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
      GeschaeftsfallStore.mutations.setSelected,
      entities.map((o) => o.id)
    );
  }

  protected createCommands(type: Belegart): Command[] {
    return [
      {
        name: type.kundenName,
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fa-regular fa-magnifying-glass',
        name: '#GESAMTABFRAGE.MENU.FIND',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage/', type.id, 'find'],
      },
      {
        icon: 'fa-regular fa-list',
        name: '#GESAMTABFRAGE.MENU.RESULTS',
        class: 'menu main-menu main-menu-top',
        execute: () => this.navigateBack(),
      },
      new DownloadCommand(
        this.store,
        this.selected$,
        this.uuid,
        this.config,
        this.injector
      ),
    ];
  }

  private exportExchangeFile(): void {}
}
