import { importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK,
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE, SOFTLINE_PERMISSION_MDE_ETIKETTENDRUCK
} from "./etikettendruck.shared";
import {EtikettendruckStore} from './store/etikettendruckStore';
import {
  SOFTLINE_API_MDE_ETIKETTENDRUCK_ARTIKEL,
  SOFTLINE_API_MDE_ETIKETTENDRUCK_BEWEGUNGEN
} from './etikettendruck.api';
import {EtikettendruckBeweStore} from './store/etikettendruck-bewe.store';
import {EtikettendruckService} from './services/etikettendruck.service';
import {EtikettendruckArtikelDetailStore} from './store/etikettendruck-artikel-detail.store';
import {EtikettendruckResolver} from './resolver/etikettendruck-resolver.service';

export const etikettendruckProviders = [
  provideEnvironmentInitializer(async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeEtikettendruck',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Etikettendruck',
          icon: 'fa-regular fa-print',
          route: '/mde-etikettendruck',
          permission: SOFTLINE_PERMISSION_MDE_ETIKETTENDRUCK,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE,
        EtikettendruckBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    }),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK,
      feature: EtikettendruckStore.feature,
      providers: [{
        provide: EtikettendruckService,
        useClass: EtikettendruckService
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE,
      feature: EtikettendruckBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_ETIKETTENDRUCK_BEWEGUNGEN
        )
      }]
    })
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_ARTIKEL_DETAILS,
      feature: EtikettendruckArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_ETIKETTENDRUCK_ARTIKEL,
              'detail'
            );
          }
        }
      ]
    })
  ),
  EtikettendruckResolver,
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK, fields: ['art'] },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE, fields: ['entities', 'ids'] },
    multi: true
  },
]
