import { importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from '@softline/ui-core';
import {
  ApplicationStore,
  SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
  SOFTLINE_FEATURE_APPLICATIONS
} from '@softline/application';
import {default as de} from '../i18n/de.json';
import {
  SOFTLINE_FEATURE_OFFENE_POSTEN,
  SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
  SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_DEBITOREN,
  SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN,
  SOFTLINE_PERMISSIONS_OFFENE_POSTEN,
  SOFTLINE_PERMISSIONS_OFFENE_POSTEN_DEBITOR_WIDGET,
  SOFTLINE_PERMISSIONS_OFFENE_POSTEN_KREDITOR_WIDGET,
  SOFTLINE_PERMISSIONS_OFFENE_POSTEN_WIDGET
} from './offene-posten.shared';
import {OffenePostenWidgetComponent} from './widgets/offene-posten/offene-posten.widget';
import {JopostenStore, OffenePostenStore} from './store';
import {SOFTLINE_API_JOPOSTEN, SOFTLINE_API_OFFENE_POSTEN} from './offene-posten.api';
import {OffenePostenDebitorenWidgetComponent} from './widgets/offene-posten-debitoren/offene-posten-debitoren.widget';
import {
  OffenePostenKreditorenWidgetComponent
} from './widgets/offene-posten-kreditoren/offene-posten-kreditoren.widget';

export const offenePostenProviders = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_OFFENE_POSTEN,
      feature: OffenePostenStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_OFFENE_POSTEN),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
      feature: JopostenStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_JOPOSTEN),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_DEBITOREN,
      feature: JopostenStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_JOPOSTEN),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN,
      feature: JopostenStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_JOPOSTEN),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    })
  ),
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'offenePosten',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#OFFENE_POSTEN.TITLE',
          icon: 'fa-regular fa-money-check',
          route: '/offene-posten',
          permission: SOFTLINE_PERMISSIONS_OFFENE_POSTEN,
        }
      );
    }),
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: OffenePostenWidgetComponent,
      permission: SOFTLINE_PERMISSIONS_OFFENE_POSTEN_WIDGET
    },
  },
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: OffenePostenDebitorenWidgetComponent,
      permission: SOFTLINE_PERMISSIONS_OFFENE_POSTEN_DEBITOR_WIDGET
    },
  },
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: OffenePostenKreditorenWidgetComponent,
      permission: SOFTLINE_PERMISSIONS_OFFENE_POSTEN_KREDITOR_WIDGET
    },
  }
];
