import { Component, HostListener, TemplateRef } from '@angular/core';
import { Modal } from '../../data/modal';
import { DeleteResult } from '../../../types/delete-result';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../../i18n/i18n.module';
import { IconComponent } from '../../../../components/icon/icon.component';

@Component({
    selector: 'soft-question-modal',
    imports: [CommonModule, UiCorePipesModule, I18nModule, IconComponent],
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements Modal<DeleteResult> {
  private close!: (result: DeleteResult) => void;

  title: string | undefined;
  content?: TemplateRef<any>;
  question = '';
  showCancel = false;
  params?: object;

  registerCloseHandler(handler: (result: DeleteResult) => void): void {
    this.close = handler;
  }


  @HostListener('window:keydown.enter')
  onClick(result: DeleteResult = 'DELETE'): void {
    this.close(result);
  }
}
