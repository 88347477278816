import { CommonModule } from '@angular/common';
import { EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { SOFTLINE_CONFIG_CONNECTION_DEFAULT_STATE } from './connection.shared';
import { StoreModule } from '../store/store.module';
import * as ConnectionStore from './connection.store';
import { Store } from '../store/store';
import { Connection, isConnection } from './types/connection';
import { ConnectionLoader } from './abstraction';
import { ConstantConnectionLoader } from './services/constant-connection-loader.service';

export const SOFTLINE_FEATURE_CONNECTION = 'connection';

export interface ConnectionModuleConfig {
  loader: Provider;
  saver?: Provider;
  default?: ConnectionStore.State;
}

@NgModule({
  imports: [CommonModule],
})
export class ConnectionModule {
  static forRoot(
    config: ConnectionModuleConfig | Connection
  ): ModuleWithProviders<ConnectionRootModule> {
    const providers: Array<Provider | EnvironmentProviders> = [];
    if (isConnection(config))
      providers.push({
        provide: ConnectionLoader,
        useFactory: () => new ConstantConnectionLoader(config),
      });
    else {
      providers.push(config.loader, {
        provide: SOFTLINE_CONFIG_CONNECTION_DEFAULT_STATE,
        useValue: config.default,
      });
      if (config.saver) providers.push(config.saver);
    }
    return {
      ngModule: ConnectionRootModule,
      providers,
    };
  }
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_CONNECTION,
      feature: ConnectionStore.feature,
    }),
  ],
})
export class ConnectionRootModule {
  constructor(store: Store) {
    store.dispatch(SOFTLINE_FEATURE_CONNECTION, ConnectionStore.actions.load);
  }
}
