import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Store } from "@softline/core";
import { EmptyEntityInputStrategy, EntityInputStrategy } from "../entity-input/strategies/entity-input.strategy";
import { Subscription } from "rxjs";
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
    selector: 'soft-entity-list-input',
    templateUrl: './entity-list-input.component.html',
    styleUrls: ['./entity-list-input.component.scss'],
    imports: [CommonModule, UiCorePipesModule, I18nModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EntityListInputComponent),
            multi: true,
        },
    ],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('222ms ease-out', style({ opacity: 1 })),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityListInputComponent<TEntity, TParams>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  private subscription?: Subscription;
  displayAll = false;

  @ContentChild('entityTemplate') entityTemplate: TemplateRef<TEntity> | null =
    null;

  @Input() title?: string | null;

  @Input() input = '';
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() value: TEntity[] | null = null;
  @Output() valueChange: EventEmitter<TEntity[] | null> = new EventEmitter<
    TEntity[] | null
  >();

  @Input() parameters?: TParams;

  @Input() strategy: EntityInputStrategy<any, TParams> =
    new EmptyEntityInputStrategy();
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() placeholder?: string | null;

  @Input() maxDisplayed: number = Number.POSITIVE_INFINITY;

  @Output() validate: EventEmitter<string> = new EventEmitter<string>();
  @Output() open = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  @ViewChild('inputComponent') inputComponent!: ElementRef;

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  constructor(private store: Store) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  @HostListener('keydown.f4', ['$event']) async onF4(
    event?: KeyboardEvent
  ): Promise<void> {
    this.input = this.inputComponent.nativeElement.value;
    await this.onOpen();
  }

  @HostListener('keydown.tab', ['$event']) async onTab(
    event?: KeyboardEvent
  ): Promise<void> {
    this.input = this.inputComponent.nativeElement.value;
    await this.onValidate(this.input);
  }
  @HostListener('keydown.enter', ['$event']) async onEnter(
    event?: KeyboardEvent
  ): Promise<void> {
    this.input = this.inputComponent.nativeElement.value;
    await this.onValidate(this.input);
  }
  @HostListener('keydown', ['$event']) preventKeydownPropagation(
    event?: KeyboardEvent
  ): void {
    event?.stopPropagation();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      this.input = obj;
      this.value = null;
    } else {
      this.value = obj;
      this.input = '';
    }
  }

  onInput(input: string): void {
    this.input = input;
    this.inputChange.emit(input);
  }

  async onOpen(): Promise<void> {
    const value = await this.strategy.open(this.input, this.parameters);
    this.open.emit();
    if (value !== this.value && value !== 'DISMISSED' && value !== 'CANCELED')
      this.setValue(value);
    else this.onTouch(this.value);
  }

  onClose(): void {
    this.close.emit();
    this.onTouch(this.value);
  }

  async onCancel(): Promise<void> {
    const loading = this.strategy.loading();
    if (loading) await this.strategy.cancel();
    else if (this.input && this.input.length > 0) {
      this.input = '';
      this.inputChange.emit(this.input);
    } else if (this.value && this.value.length > 0) {
      this.setValue(null);
    }
  }

  async onValidate(input: string): Promise<void> {
    try {
      const value = await this.strategy.validate(input, this.parameters);
      if (value === 'CANCELED') return;
      this.setValue(value);
      this.validate.emit(this.inputComponent.nativeElement.value);
    } catch (e) {
      console.error(e);
    }
  }

  setValue(value: TEntity[] | null): void {
    if (value === this.value) return;
    this.value = value;
    this.input = '';

    this.onChange(this.value);
    this.onTouch(this.value);
    this.valueChange.emit(this.value);
  }

  onDelete(item: TEntity): void {
    const value = [...(this.value ?? [])];
    const index = value.indexOf(item);
    value.splice(index, 1);
    this.value = value;
    this.onChange(this.value);
    this.onTouch();
    this.valueChange.emit(this.value);
  }
}
