import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {Store} from "@softline/core";
import {SOFTLINE_FEATURE_TITLE, TitleStore} from "@softline/application";
import {SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT} from "@softapps/wws/warenuebernahme";
import {WwsLieferantStore} from "../../store/lieferant.store";
import {UiCoreModule} from "@softline/ui-core";

@Component({
    selector: 'soft-bestellung',
    imports: [CommonModule, RouterModule, UiCoreModule],
    templateUrl: './bestellung.component.html',
    styleUrls: ['./bestellung.component.scss']
})
export class BestellungComponent implements OnInit, OnDestroy {

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      '#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_BESTELLUNG_ABBUCHEN'
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      WwsLieferantStore.actions.loadMany
    );
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
  }
}
