import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";
import {Bewe} from "../../types/bewegung";
import {MengenEingabeComponent} from "@softline/application";
import {Store} from "@softline/core";
import {SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE} from "@softapps/wws/warenuebernahme";
import {WwsBewegungStore} from "../../store/bewegung.store";

@Component({
    selector: 'soft-position-erledigen-dialog',
    imports: [CommonModule, MengenEingabeComponent, UiCoreModule],
    templateUrl: './position-erledigen-dialog.component.html',
    styleUrls: ['./position-erledigen-dialog.component.scss']
})
export class PositionErledigenDialogComponent implements Modal<Bewe>{
  public anzahlEtiketten = 0;
  public bewe!: Bewe;

  constructor(private store: Store) {}

  set bewegung (bewegung: Bewe) {
    this.bewe = bewegung;
  }

  close!: (result: Bewe) => void;

  registerCloseHandler(handler: (result: Bewe) => void): void {
    this.close = handler;
  }

  async positionErledigen(anzahlEtiketten: number) {
    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
      WwsBewegungStore.actions.positionErledigen,
      {
        anzahlEtiketten: anzahlEtiketten,
        bewegung: this.bewe
      }
    );
    this.close(this.bewe);
  }
}
