import { Component, computed, inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import {
  RecordTimestampDialogComponent,
  SOFTLINE_FEATURE_TIMESTAMP,
  SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
  TimestampStore
} from "@softapps/pms/stempelerfassung";
import { TimestampCurrentEmployeeStore } from "../../store/current-employee.store";
import { DateService, Store, Trace } from "@softline/core";
import { Subscription } from "rxjs";
import {
  MessageBarStore, ModalService,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from '@softline/ui-core';
import { map } from "rxjs/operators";
import moment from "moment/moment";
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from "@softline/auth";
import { Timestamp } from "../../data/timestamp.model";
import { showRequestErrors } from "@softline/application";

@Component({
    selector: 'soft-stempelerfassung-widget',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './stempelerfassung.widget.html',
    styleUrls: ['./stempelerfassung.widget.scss']
})
export class StempelerfassungWidget implements OnInit, OnDestroy {

  private modalService = inject(ModalService)

  private subscription?: Subscription;

  currentEmployee$ = this.store.observe(
    SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
    TimestampCurrentEmployeeStore.getters.data
  );
  loaded = this.store.signal(
    SOFTLINE_FEATURE_TIMESTAMP,
    TimestampStore.getters.loaded
  );
  loading = this.store.signal(
    SOFTLINE_FEATURE_TIMESTAMP,
    TimestampStore.getters.loading
  );


  private allTimestamps = this.store.signal(SOFTLINE_FEATURE_TIMESTAMP, TimestampStore.getters.all);

  timestamps = computed(() => {
    const timestamps = this.allTimestamps()
    const date = this.dateService.today();
    return timestamps
      .filter((o) => moment(o.timestamp).startOf('day').isSame(date))
      .sort((a, b) =>
        a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0
      );
  });

  constructor(private store: Store,
              private dateService: DateService,
              private injector: Injector) { }

  async ngOnInit(): Promise<void> {
    const context = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    );
    const userId = (context as any).userId;
    await this.store.dispatch(
      SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
      TimestampCurrentEmployeeStore.actions.loadEmployee,
      { userId }
    );
    this.subscription = this.currentEmployee$
      .subscribe(async (employee) => {
      if (!employee)
        return;
      const date = this.dateService.today();
      this.store.commit(
        SOFTLINE_FEATURE_TIMESTAMP,
        TimestampStore.mutations.clear
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIMESTAMP,
        TimestampStore.actions.loadMany,
        {
          queryParams: {
            from: date.substr(0, 10),
            to: date.substr(0, 10),
            employeeid: employee.id,
          },
        }
      );
    });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  @Trace()
  async onCreate(): Promise<void> {
    const result = await this.modalService.open(RecordTimestampDialogComponent)

    if(result === 'DISMISSED')
      return;
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIMESTAMP,
        TimestampStore.actions.create,
        { entity: result }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#TIMESTAMP.MESSAGES.ADD_SUCCESS.TITLE',
          message: '#TIMESTAMP.MESSAGES.ADD_SUCCESS.MESSAGE',
        }
      );
    } catch (e) {
      showRequestErrors(this.store, e);
      }
  }
}
