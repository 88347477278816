import { computed, DestroyRef, ElementRef, inject, Injector, Signal, signal, ViewRef } from '@angular/core';
import { TitleService } from './title.service';

interface TitleSet {
  title: string;
  subtitle?: string;
}

export function title(title: string): Signal<string>
export function title(computation: (() => string)): Signal<string>
export function title(computation: (() => string) | string): Signal<string> {
  const destroyRef = inject(DestroyRef);
  const service = inject(TitleService);
  const nativeElement = inject(ElementRef).nativeElement;
  const title = typeof computation === 'string' ? signal(computation) :computed(computation);
  service.addTitle(nativeElement, title)
  destroyRef.onDestroy(() => service.removeTitle(nativeElement));
  return title
}
export function subtitle(subtitle: string)
export function subtitle(computation: (() => string))
export function subtitle(computation: (() => string) | string) {
  const destroyRef = inject(DestroyRef);
  const service = inject(TitleService);
  const nativeElement = inject(ElementRef).nativeElement;
  const subtitle = typeof computation === 'string' ? signal(computation) :computed(computation);
  service.addSubtitle(nativeElement, subtitle)
  destroyRef.onDestroy(() => service.removeSubtitle(nativeElement));
  return subtitle
}
