import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { RequestEvent } from '../../../data-access/types/request';
import { Response, Request } from '../../../data-access/types/request';

export function convertHttpResponse<TLocation, TResponse, TRequest = undefined>(
  httpResponse: HttpResponse<TResponse>,
  request: Request<TLocation, TRequest>
): Response<TLocation, TResponse, TRequest> {
  return {
    request,
    body: httpResponse.body,
    ok: httpResponse.ok,
    meta: httpResponse.headers
      .keys()
      .map((key) => ({ key, value: httpResponse.headers.get(key) }))
      .reduce((p, c) => ({ ...p, [c.key]: c.value }), {}),
    status: httpResponse.status,
    message: httpResponse.statusText,
  };
}

export function convertHttpRequestEvent<T, TResponse>(
  httpEvent: HttpEvent<TResponse>
): RequestEvent<TResponse> | undefined {
  switch (httpEvent.type) {
    case HttpEventType.Response:
      return { type: 'response', response: httpEvent.body };
    case HttpEventType.Sent:
      return { type: 'sent' };
    case HttpEventType.DownloadProgress:
      return {
        type: 'progress',
        direction: 'downstream',
        loaded: httpEvent.loaded,
        total: httpEvent.total ?? null,
      };
    case HttpEventType.UploadProgress:
      return {
        type: 'progress',
        direction: 'upstream',
        loaded: httpEvent.loaded,
        total: httpEvent.total ?? null,
      };
    default:
      return undefined;
  }
}
