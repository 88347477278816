import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, ApiResourceLocation, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Observable} from 'rxjs';
import {SOFTLINE_API_MDE_ETIKETTENDRUCK_ABSCHLUSS, SOFTLINE_API_MDE_ETIKETTENDRUCK_ARTEN} from '../etikettendruck.api';
import {EtikettenArt} from '../data/etiketten-art';

@Injectable()
export class EtikettendruckService {

  private get location(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_MDE_ETIKETTENDRUCK_ABSCHLUSS,
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  abschluss(payload: object): Observable<void> {
    return this.httpService.create(this.location, payload);
  }

  loadArten(): Observable<EtikettenArt[]> {
    return this.httpService.get({
      path: SOFTLINE_API_MDE_ETIKETTENDRUCK_ARTEN,
    })
  }
}
