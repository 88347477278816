import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {Modal} from '../../data/modal';
import {DatePickerComponent} from '../../../../components/picker/date-picker/date-picker.component';
import {I18nModule} from '../../../../i18n/i18n.module';
import {NgIf} from '@angular/common';
import { DateService } from '@softline/core';

@Component({
    selector: 'soft-date-input-dialog',
    templateUrl: './date-picker-modal.component.html',
    styleUrls: ['./date-picker-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DatePickerComponent,
        I18nModule,
        NgIf
    ],
    providers: []
})
export class DatePickerModalComponent implements Modal<string | null> {

  dateService = inject(DateService);
  today = this.dateService.today();

  title?: string;
  subtitle?: string;
  value: string | null = null

  close!: (result: string | null) => void;

  registerCloseHandler(handler: (result: (string | null)) => void) {
    this.close = handler;
  }
}
