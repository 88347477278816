import { Component, Input, OnInit, Type } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldOkItemComponent, FieldOkViewComponent } from "../../../../data/field-ok";
import { UiCoreModule } from "@softline/ui-core";
import { FieldOkItemOutletDirective } from "../../../../directives/field-ok-item-outlet.directive";
import { FilterAndSortComponent, FilterConfig, SortConfig } from "@softline/application";
import { Sort } from "@softline/core";
import { WithFilterAndSort } from "../field-ok-view-filter-and-sort.mixin";

export interface FieldOkListViewConfig {
  component: Type<FieldOkItemComponent<any>>;
  filter?: FilterConfig;
  sort?: SortConfig;
  defaultSort?: Sort;
}

@Component({
    selector: 'soft-field-ok-list-view',
    imports: [CommonModule, UiCoreModule, FieldOkItemOutletDirective, FilterAndSortComponent],
    templateUrl: './field-ok-list-view.component.html',
    styleUrls: ['./field-ok-list-view.component.scss']
})
export class FieldOkListViewComponent extends WithFilterAndSort() implements OnInit, FieldOkViewComponent<FieldOkListViewConfig>{

  @Input() config!: FieldOkListViewConfig;

  constructor() {
    super();
  }

  async ngOnInit(): Promise<void> {
    if(this.config.defaultSort)
      await super.onSortChange(this.config.defaultSort);
  }
}
