<div class="flex flex-col gap-2">
  <h2 class="text-2xl font-semibold mt-2">Paletten</h2>

  @if(bundles(); as bundles) {

    <div class="flex flex-row flex-grow gap-2 w-full min-h-[500px] max-h-[500px]">
      <div
        class="w-full sm:w-1/3 flex flex-col flex-grow sm:border-r max-h-inherit overflow-auto"
        [ngClass]="{
            'hidden sm:flex': !!selectedBundle(),
          }"
      >
        <ul class="flex flex-col flex-grow">
          @for (bundle of bundles; track bundle.sscc) {
            <li

              class="flex flex-row justify-between hover:bg-hover items-center gap-2 px-2"
              [ngClass]="selectedBundle()?.sscc === bundle.sscc ? 'bg-selected' : ''"
            >
              <soft-checkbox [value]="bundle.sscc | in:checked()" (valueChange)="checkedBundle(bundle)" class="px-2"></soft-checkbox>

              <div class="flex w-full" (click)="checkedBundle(bundle)">
                <ng-container *ngTemplateOutlet="masterTemplate; context: { data: bundle }"></ng-container>
              </div>

              <soft-icon class="px-4 py-8 bg-red" (click)="selectedBundle.set(bundle)" [name]="'fa-regular fa-chevron-right'"></soft-icon>

            </li>
          }
        </ul>
      </div>

      @if(selectedBundle(); as selected) {
        <div
          class="flex flex-col flex-grow w-full sm:w-2/3 sm:px-2 max-h-inherit overflow-auto"
          [ngClass]="{
            'hidden sm:block': !selectedBundle()
          }"
        >
          <div class="flex flex-row items-center sm:hidden justify-between w-full gap-2 px-2 pointer hover:bg-hover"
               (click)="selectedBundle.set(null)">
            <soft-icon [name]="'fa-regular fa-chevron-left'"></soft-icon>
            <ng-container *ngTemplateOutlet="masterTemplate; context: { data: selected }"></ng-container>
          </div>
          <div class="w-full border-b my-2 sm:hidden"></div>


          <div class="flex flex-col flex-grow">
            <ng-container *ngTemplateOutlet="detailTemplate; context: { data: selected }"></ng-container>
          </div>
        </div>
      }

    </div>
  }

  <button class="soft-button primary mt-6 rounded-sm h-16 w-full"
          (click)="submit()">
    Speichern
  </button>
</div>

<ng-template #masterTemplate let-data="data">
  <div class="flex flex-col gap-0.5 w-full py-2">
    <span class="soft-overline">SSCC: {{data.sscc}}</span>

    <span>REF-NR: {{ data.refnr }}</span>

    <div class="flex flex-row justify-between">
      <div class="flex flex-col gap-0.5">
        <span class="font-light text-sm">Menge: {{data.totalPieces}}</span>
        <span class="font-light text-sm">Höhe: {{data.height}}</span>
      </div>

      <div class="flex flex-col gap-0.5">
        <span class="font-light text-sm">{{data.totalBoardFeet}} bft</span>
        <span class="font-light text-sm">{{data.totalCubicMeters}} m&#179;</span>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #detailTemplate let-data="data">
  <ul class="flex flex-col gap-2 divide-y">
    @for(board of data.boards; track board) {
      <li class="flex flex-col gap-0.5 justify-between px-2 pt-2">
        <span class="font-semibold">Menge: {{board.amount}}</span>
        <span class="text-sm text-light">{{board.massUnitId}}</span>
        <span class="text-sm">{{board.height}} * {{board.width}} * {{board.depth}}</span>
      </li>
    }
  </ul>
</ng-template>
