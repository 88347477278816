import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  BackNavigable,
  BackNavigationService,
  showRequestErrors,
  HeaderComponent,
  StepHeaderComponent
} from '@softline/application';
import {SOFTLINE_STEPS_SEND_NOTIFICATION} from '../../send-notification.shared';
import {
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
  Validators,
} from '@softline/ui-core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@softline/core';
import {
  SendNotificationStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION,
} from '../../store/send-notification.store';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { Empfaenger } from '../../types/empfaenger';
import { EditEmpfaengerDialogComponent } from '../../dialogs/edit-empfaenger-dialog/edit-empfaenger-dialog.component';

@Component({
    selector: 'soft-nachricht-verfassen',
    imports: [CommonModule, StepHeaderComponent, HeaderComponent, UiCoreModule, ReactiveFormsModule],
    templateUrl: './nachricht-verfassen.component.html',
    styleUrls: ['./nachricht-verfassen.component.scss']
})
export class NachrichtVerfassenComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;

  readonly loading$ = new BehaviorSubject(false);

  readonly steps = SOFTLINE_STEPS_SEND_NOTIFICATION;

  readonly form = new FormGroup({
    betreff: new FormControl<string | null>(null, Validators.required()),
    inhalt: new FormControl<string | null>(null, Validators.required()),
  });

  readonly empfaenger$ = this.store
    .observe(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.getters.data
    )
    .pipe(map((o) => o?.empfaenger ?? []));

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe((value) => {
      this.store.commit(
        SOFTLINE_FEATURE_SEND_NOTIFICATION,
        SendNotificationStore.mutations.patch,
        {
          betreff: value?.betreff ?? undefined,
          nachricht: value?.inhalt ?? undefined,
        }
      );
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  onDelete(empfaengerId: number): void {
    const current =
      this.store.get(
        SOFTLINE_FEATURE_SEND_NOTIFICATION,
        SendNotificationStore.getters.data
      ) ?? {};

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.mutations.set,
      {
        ...current,
        empfaenger: (current?.empfaenger ?? []).filter(
          (o) => o.id !== empfaengerId
        ),
      }
    );
  }

  async openEmpfaengerDialog(empfaenger: Empfaenger[]): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Empfaenger[], { empfaenger: Empfaenger[] }>(),
      {
        component: EditEmpfaengerDialogComponent,
        dismiss: false,
        data: {
          empfaenger,
        },
      }
    );

    if (!result || result === 'DISMISSED') return;

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.mutations.patch,
      {
        empfaenger: result,
      }
    );
  }
}
