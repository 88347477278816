<ng-container *ngIf="searchHistory$ | async as searchHistory; else noItemsTemplate">
  <ng-container *ngIf="searchHistory && searchHistory?.length || 0 > 0; else noItemsTemplate">
    <h6 class="font-semibold soft-overline mt-4">Suchverlauf</h6>
    <div class="full-width bg-surface border-t border-t-solid border-t-separator mt-0.5 overflow-y-auto">
      <ul class="w-full bg-surface">
        <li *ngFor="let item of searchHistory; let last = last; trackBy: trackBySearchEntryFn"
            class="w-full flex justify-between items-center px-4 py-2 border-b border-b-solid border-b-separator cursor-pointer">
          <div class="flex flex-col gap-1 w-full" (click)="onSelect(item.text)">
            <h6 class="font-semibold text-sm">{{ item.text }}</h6>
            <h6 class="font-normal text-sm text-light">{{ item.date | date:'dd.MM.YYYY' }} ({{ item.date | date:'HH:mm' }})</h6>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 opacity-20 scale-90">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-container>
<ng-template #noItemsTemplate>
  <div class="full-width h-full py-12 px-8 bg-surface text-light flex items-center text-center line-height-2 border-b border-b-solid border-b-separator">
    Bitte scannen Sie einen Artikel oder nutzen Sie die Artikelsuche.
  </div>
</ng-template>
