import { Component, Input, OnInit } from '@angular/core';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-dynamic-string-value',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.scss'],
  imports: [UiCoreModule],
})
export class StringComponent implements OnInit {
  @Input() value?: any;
  @Input() format?: string;

  constructor() {}

  ngOnInit(): void {}
}
