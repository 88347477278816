import {Inject, Injectable} from "@angular/core";
import {ConnectionHttpService, ApiResourceLocation, SOFTLINE_SERVICE_HTTP} from "@softline/core";
import {Emballage} from "../types/warenuebernahme";
import {Bewe, WarenuebernahmeBeweEtikett} from "../types/bewegung";
import {
  SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_ERLEDIGEN,
  SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_OEFFNEN,
  SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN_HINZUFUEGEN
} from "../warenuebernahme.api";
import {Observable} from "rxjs";


@Injectable()
export class BewegungenService {
  private positionErledigenlocation(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_ERLEDIGEN
    }
  }

  private positionOeffnenlocation(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_OEFFNEN
    }
  }

  private emballageHinzufuegenLocation(idwu: string): ApiResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN_HINZUFUEGEN,
      pathParams: { idwu }
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  positionErledigen(payload: WarenuebernahmeBeweEtikett): Observable<Bewe> {
    const location = this.positionErledigenlocation();

    return this.httpService.create<WarenuebernahmeBeweEtikett, Bewe>(location, payload);
  }

  positionOeffnen(payload: Bewe): Observable<Bewe> {
    const location = this.positionOeffnenlocation();

    return this.httpService.create<Bewe, Bewe>(location, payload);
  }

  emballageHinzufuegen(payload: Emballage, idwu: string): Observable<Bewe> {
    const location = this.emballageHinzufuegenLocation(idwu);

    return this.httpService.create<Emballage, Bewe>(location, payload);
  }
}
