import {
  FieldOkConfig,
  FieldOkListViewComponent, FieldOkMasterDetailViewComponent, FieldOkMultiselectListViewComponent,
  FieldOkQueryViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ArtstammListView } from "./list-view/artstamm.list-view";
import { ArtstammQueryView } from "./query-view/artstamm.query-view";
import { ArtstammMasterView } from "./master-detail-view/master-view/artstamm.master-view";
import { ArtstammDetailView } from "./master-detail-view/detail-view/artstamm.detail-view";
import { ArtstammTemplate } from "./template/artstamm.template";
import { FilterConfig, SortConfig } from "@softline/application";
import {FieldOkSearchConfig} from '@softapps/core';
import {ArtstammFieldOkSearchResultViewComponent} from '@softapps/wws/core';
import {
  ArtstammSearchResultQueryModalComponent
} from './search-result-query-modal/artstamm-search-result-query-modal.component';

const defaultSort= {property: 'cartikelnummer', direction: 'asc'}
const sort: SortConfig = {
  properties: [
    {property: 'cartikelnummer', title: 'Nummer'},
    {property: '5', title: "Artikelbezeichnung"},
    {property: '26', title: "Artikelbezeichnung 2"},
    {property: '27', title: "Artikelbezeichnung 3"},
    {property: '28', title: "Artikelbezeichnung 4"},
    {property: '29', title: "Artikelbezeichnung 5"},
  ]
};
const filter: FilterConfig = {
  properties: [
    {property: 'cartikelnummer', title: 'Nummer'},
    {property: '5', title: "Artikelbezeichnung"},
    {property: '26', title: "Artikelbezeichnung 2"},
    {property: '27', title: "Artikelbezeichnung 3"},
    {property: '28', title: "Artikelbezeichnung 4"},
    {property: '29', title: "Artikelbezeichnung 5"},
  ]
}

export const artstammFieldOk: FieldOkConfig = {
  name: 'artstamm',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: ArtstammTemplate,
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: ArtstammQueryView,
    },
  }, {
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtstammListView,
      sort,
      filter,
      defaultSort
    }
  }, {
    name: 'masterDetail',
    component: FieldOkMasterDetailViewComponent,
    title: 'Detail',
    config: {
      masterComponent: ArtstammMasterView,
      detailComponent: ArtstammDetailView,
      sort,
      filter,
      defaultSort
    }
  }]
};
export const artstammMultiselectFieldOk: FieldOkConfig = {
  name: 'artstamm',
  type: 'multi',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: ArtstammTemplate,
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: ArtstammQueryView,
    },
  }, {
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: ArtstammListView,
      sort,
      filter,
      defaultSort
    },
  }]
};
export const artstammQueryFieldOk: FieldOkConfig = {
  name: 'artstamm',
  type: 'query',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: ArtstammTemplate,
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: ArtstammQueryView,
    },
  }, {
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: ArtstammListView,
      sort,
      filter,
      defaultSort
    },
  }]
};

export const artstammSearchPageConfig: FieldOkSearchConfig<any> = {
  name: 'artstamm',
  view: ArtstammFieldOkSearchResultViewComponent,
  queryModalView: ArtstammSearchResultQueryModalComponent
}
