export const SOFTLINE_API_WWS_WARENUEBERNAHME = 'v1/wws/warenuebernahme-kontrolle/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_SPERREN = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/sperren';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_OEFFNEN = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/oeffnen';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_ABSCHLIESSEN = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/abschluss';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/bewegungen/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_ERLEDIGEN = 'v1/wws/warenuebernahme-kontrolle/bewegung/erledigt';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_OEFFNEN = 'v1/wws/warenuebernahme-kontrolle/bewegung/oeffnen';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN = 'v1/wws/warenuebernahme-kontrolle/emballagen/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN_HINZUFUEGEN = 'v1/wws/warenuebernahme-kontrolle/warenuebernahme/entities/{{idwu}}/emballage';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_LIEFERANTEN = 'v1/wws/warenuebernahme-kontrolle/bestellung/lieferant/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG = 'v1/wws/warenuebernahme-kontrolle/bestellung/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_BEWES = 'v1/wws/warenuebernahme-kontrolle/bestellung/entities/{{idbestellung}}/bewegungen/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_UEBERNEHMEN = 'v1/wws/warenuebernahme-kontrolle/bestellung/entities/uebernahme';

// Lagerplatz auswahl
export const SOFTLINE_API_WWS_WARENUEBERNAHME_LAGERPLATZ_AUSWAHL = 'v1/wws/warenuebernahme-kontrolle/lagerplaetze-anlieferung/{{idwu}}';

// Paletten/Bundels
export const SOFTLINE_API_WWS_WARENUEBERNAHME_PALETTEN_DETAIL = 'v1/wws/paletteninfo/entities/{{belegtyp}}/{{id}}';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_PALETTEN_KONTROLLE = 'v1/wws/paletteninfo/kontrolle';

// Reklamation
export const SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA_GUENDE = 'v1/reklamationswf/gruende';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA ='v1/reklamationswf/entity/{{belegtyp}}/{{id}}';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_REKLA_CREATE ='v1/reklamationswf/create';

// Direkte-Einlagerung
export const SOFTLINE_API_WWS_WARENUEBERNAHME_EINLAGERN_BEAN= 'v1/wws/warenuebernahme-kontrolle/bewegung/einlagern/{{idwu}}';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_EINLAGERN= 'v1/wws/warenuebernahme-kontrolle/bewegung/einlagern2';

// Etiketten drucken
export const SOFLTINE_API_WWS_WARENUEBERNAHME_ETIKETTEN_PRINT =  'v1/wws/warenuebernahme-kontrolle/bewegung/print/{{idwu}}'
