import { Component, OnInit } from '@angular/core';
import { SelectDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import {
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import {
  FieldValidationDirective,
  RadioGroupDirective,
  RadioOptionComponent,
  SelectComponent,
  SelectOptionDirective,
} from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';

@Component({
  selector: 'soft-dynamic-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  imports: [
    BooleanRulePipe,
    NgSwitch,
    NgSwitchCase,
    SelectComponent,
    ReactiveFormsModule,
    FormControlPipe,
    FieldValidationDirective,
    SelectOptionDirective,
    NgForOf,
    NgIf,
    NgSwitchDefault,
    RadioGroupDirective,
    RadioOptionComponent,
  ],
})
export class SelectInputComponent
  extends DynamicInputAtom<SelectDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
