import { GetResourceService, ResourceService } from '../../data/abstraction';
import { ApiResourceLocation } from '../../data-access/types/api.location';
import { LoadObjectParameters, ReadonlyRepository } from '../abstraction';
import { Observable } from 'rxjs';

export class ReadonlyHttpApiRepository<TResult> implements ReadonlyRepository<TResult> {

  constructor(protected service: ResourceService<ApiResourceLocation>,
              protected path: string | string[]) {
  }

  load(params?: LoadObjectParameters): Observable<TResult> {
    const location: ApiResourceLocation = {
      path: this.path,
      queryParams: params?.queryParams,
      pathParams: params?.pathParams,
    }
    return this.service.get(location, params?.body);
  }
}
