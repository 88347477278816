<ng-container *ngIf="lagerplatzInhalt">
  <div *ngFor="let artikelMenge of lagerplatzInhalt.inhalt; let i = index"
       class="flex flex-row gap-2 soft-card border mt-1 p-6 rounded hover:bg-hover cursor-pointer"
       (click)="onSelect(artikelMenge)">
    <soft-artikel-menge class="w-full" [artikelMenge]="artikelMenge"></soft-artikel-menge>
    <div class="flex flex-col justify-center items-center">
      <i class="fa-regular fa-chevron-right color-light"></i>
    </div>
  </div>
  <div *ngIf="lagerplatzInhalt.inhalt.length === 0" class="color-light">
    {{'#LAGERPLATZ_CORE.COMPONENTS.ARTIKEL_MENGE_AUSWAHL.NO_ITEMS' | translate}}
  </div>
</ng-container>
