import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { BlockingLoadingSpinnerComponent } from '@softline/application';
import { ApplicationRef, inject, Injector, runInInjectionContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export async function block<T = void>(callback: () => Promise<T>, injector: Injector): Promise<T> {
  const document = injector.get(DOCUMENT);
  const appRef = injector.get(ApplicationRef);
  const portal = new ComponentPortal(BlockingLoadingSpinnerComponent);
  const outlet = new DomPortalOutlet(document.body, undefined, appRef)
  outlet.attach(portal);
  const result = await callback();
  outlet.detach();
  return result;
}
