<div class="flex flex-row gap-4 justify-between items-start">
  <div class="flex flex-row gap-4 items-center">
    <div class="bg-surface-tertiary rounded w-20 h-20">
      &nbsp;
    </div>
    <div class="flex flex-col">
      <h6 class="soft-overline !mb-0 p-0">{{ product()?.key }}</h6>

      <div class="flex flex-row items-center gap-2">
        <h6 class="text-base font-semibold">
          {{ product()?.language?.name }}
        </h6>
        @if(product()?.status | isDefinedNotEmpty) {
          <div class="flex !text-wrap min-height-75 max-height-70">
            <soft-chip
              [ngStyle]="{'background' : statusColor(product()?.status?.type)}">
              {{ product()?.status?.description }}
            </soft-chip>
          </div>
        }
      </div>
      @if (product()?.language?.description1 | isDefinedNotEmpty) {
        <h6 class="text-sm text-light">{{ product()?.language?.description1 }}</h6>
      }
      @if (product()?.language?.description2 | isDefinedNotEmpty) {
        <h6 class="text-sm text-light">{{ product()?.language?.description2 }}</h6>
      }
      @if (product()?.language?.description3 | isDefinedNotEmpty) {
        <h6 class="text-sm text-light">{{ product()?.language?.description3 }}</h6>
      }
      @if (product()?.language?.description4 | isDefinedNotEmpty) {
        <h6 class="text-sm text-light">{{ product()?.language?.description4 }}</h6>
      }
      @if (product()?.language?.description5 | isDefinedNotEmpty) {
        <h6 class="text-sm text-light">{{ product()?.language?.description5 }}</h6>
      }
    </div>
  </div>
</div>
