import { Component, computed, Inject, inject, linkedSignal, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FileInputComponent,
  I18nModule,
  IconComponent, MessageBarService,
  Modal,
  MultilineInputComponent,
  SelectComponent,
  SelectOptionDirective
} from '@softline/ui-core';
import { Bewe } from '../../types/bewegung';
import { ReklamationService } from '../../services/reklamations-service';
import { Reklamation, ReklamationGrund } from '../../types/reklamation';
import { PalettenInfo } from '../../types/paletten-info';
import { ArchiveFile, showRequestErrors, SOFTLINE_FEATURE_ARCHIVE } from '@softline/application';
import { SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import * as ArchiveStore from '../../../../../../../framework/application/src/lib/archive/archive.store';

@Component({
  selector: 'soft-reklamation-dialog',
  imports: [
    CommonModule,
    IconComponent,
    I18nModule,
    SelectComponent,
    SelectOptionDirective,
    MultilineInputComponent,
    FileInputComponent,
  ],
  templateUrl: './reklamation-dialog.component.html',
  styleUrl: './reklamation-dialog.component.scss',
  providers: [ReklamationService],
})
export class ReklamationDialogComponent implements Modal<'submitted'>, OnInit {
  readonly store = inject(Store);
  readonly messageBarService = inject(MessageBarService);
  readonly reklaService = inject(ReklamationService);
  private uuid = inject(SOFTLINE_SERVICE_UUID)

  readonly _idwu = signal<string | null>(null);
  readonly _bewe = signal<Bewe | null>(null);
  readonly openedReklamation = signal<Reklamation | null>(null);
  readonly reklaGruende = signal<ReklamationGrund[] | null>(null);
  readonly uploadedFiles = signal<File[] | null>(null);

  readonly reklaKommentar = computed(() => {
    const openedRekla = this.openedReklamation()

    if(!openedRekla?.beschreibungsText) return signal(null as unknown as (string | null))

    return signal(openedRekla.beschreibungsText)
  })

  readonly selectedGrund = computed(() => {
    const openedRekla = this.openedReklamation()

    if(!openedRekla?.reklamationsgrundBean) return signal(this.reklaGruende()?.[0])

    return signal(openedRekla.reklamationsgrundBean)
  })

  set idwu(idwu: string) {
    this._idwu.set(idwu);
  }

  set bewe(bewe: Bewe) {
    this._bewe.set(bewe);
  }

  async ngOnInit(): Promise<void> {
    const gruende = await this.reklaService.loadGruende();

    this.reklaGruende.set(gruende);

    const idReklamation = this._bewe()?.idReklamation;
    const beweId = this._bewe()?.id;
    if (!idReklamation || !beweId) return;

    const rekla = await this.reklaService.loadReklamation(beweId);
    this.openedReklamation.set(rekla);
  }

  async submit() {
    const uploadedFiles = this.uploadedFiles();
    const grund = this.selectedGrund()();
    const belegid = this._bewe()?.id;
    const reklamationsmenge = this._bewe()?.artikelMenge.menge ?? null;
    let archiveKey: null | string = null;
    let id: null | number = null;
    if (!grund || !belegid) return;

    const openedRekla = this.openedReklamation();

    if (openedRekla) {
      archiveKey = openedRekla.archiveKey;
      id = openedRekla.id;
    }

    if((uploadedFiles?.length ?? 0 ) > 0 && uploadedFiles) {
      console.log('files: ', uploadedFiles)
      const succeeded: ArchiveFile[] = [];
      const failed: File[] = [];
      for (const file of uploadedFiles) {
        try {
          const token = this.uuid();
          const result = await this.store.dispatch(
            SOFTLINE_FEATURE_ARCHIVE,
            ArchiveStore.actions.upload,
            {
              archiveKey: archiveKey ?? '',
              files: [file],
              token,
            }
          );
          succeeded.push(result);
        } catch (e) {
          failed.push(file);
        }
      }
      this.messageBarService.success('Es wurden ' + succeeded.length + '/' + uploadedFiles.length + ' Files geuploaded!')
    }

    const body: Reklamation = {
      reklamationsgrundBean: grund,
      beschreibungsText: this.reklaKommentar()() ?? '',
      belegtyp: 'bewewu',
      belegid: belegid,
      archiveKey,
      id,
      reklamationsmenge
    };

    console.log(body)

    try {
      await this.reklaService.createReklamation(body);
      this.close('submitted');
    } catch (error) {
      showRequestErrors(this.store, error);
    }
  }

  close!: (result: any) => void;

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }
}
