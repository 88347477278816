export interface DocumentInformation {
  owner: string,
  name: string,
  sizeInKiloBytes: number,
  userInformationResponseList: UserInformation[]
}

export interface UserInformation {
    anwender: {
      anwender: string,
      anwenderName: string
    },
    webDocFavorit: {
      favourite: boolean,
      createdAt: string | null
    },
    downloadHistory: {
      anwender: string | null,
      downloadedAt: string | null
    }
}
