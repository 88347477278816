import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";

@Component({
    selector: 'soft-warenuebernahme-abschliessen-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './warenuebernahme-abschliessen-dialog.component.html',
    styleUrls: ['./warenuebernahme-abschliessen-dialog.component.scss']
})
export class WarenuebernahmeAbschliessenDialogComponent implements Modal<boolean> {

  close!: (result: boolean) => void;

  registerCloseHandler(handler: (result: boolean) => void): void {
    this.close = handler;
  }
}
