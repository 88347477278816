import {
  DEFAULT_CURRENCY_CODE,
  Inject,
  LOCALE_ID,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  formatCurrency,
  formatNumber,
  getCurrencySymbol,
} from '@angular/common';
import { isDefined } from '@softline/core';
import { SOFTLINE_CONFIG_DEFAULT_CURRENCY } from '../../ui-core.tokens';
import { strings } from '@angular-devkit/core';

const CURRENCY_CHAR = '¤';

@Pipe({
    name: 'currencyFormat',
    standalone: false
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Optional()
    @Inject(SOFTLINE_CONFIG_DEFAULT_CURRENCY)
    private _defaultCurrencyCode: string = 'EUR'
  ) {}

  transform(
    value: number | string | null | undefined,
    currencyCode: string = this._defaultCurrencyCode,
    display: 'code' | 'symbol' | 'symbol-narrow' = 'code',
    currencyPosition: 'before' | 'after' = 'before',
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (!isDefined(value)) return null;

    locale = locale || this._locale;

    let currency: string = currencyCode ?? this._defaultCurrencyCode ?? 'EUR';
    if (display === 'symbol' || display === 'symbol-narrow')
      currency = getCurrencySymbol(
        currency,
        display === 'symbol' ? 'wide' : 'narrow',
        locale
      );

    try {
      if (typeof value === 'string') value = parseFloat(value);
      value = formatCurrency(
        value,
        locale,
        CURRENCY_CHAR,
        currencyCode,
        digitsInfo
      );
      value = value.replace(CURRENCY_CHAR, '').trim();
      if (currencyPosition === 'before') value = `${currency} ${value}`;
      else value = `${value} ${currency}`;
      return value;
    } catch (error) {
      throw Error((error as Error).message);
    }
  }
}
