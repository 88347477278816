import {
  createGetter,
  createMutation,
  interpolate,
  isDefined,
  mutate,
  select,
  StoreFeature,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';

export interface State {
  title: string;
  subTitle: string;
  params: object | null;
}

export const mutations = {
  setTitle: createMutation<
    State,
    string | { title: string; params?: object | null }
  >('setTitle'),
  setSubTitle: createMutation<
    State,
    string | { subTitle: string; params?: object | null }
  >('setSubTitle'),
  setParams: createMutation<State, object | null>('setParams'),
};

export const getters = {
  title: createGetter<State, string>('title'),
  subTitle: createGetter<State, string>('subTitle'),
  params: createGetter<State, object>('params'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    title: '',
    subTitle: '',
    params: null,
  },
  mutations: [
    mutate(mutations.setTitle, ({ state, params }) => {
      if (typeof params === 'string')
        return { ...state, title: params, params: null };
      else return { ...state, ...params };
    }),
    mutate(mutations.setSubTitle, ({ state, params }) => {
      if (typeof params === 'string')
        return { ...state, subTitle: params, params: null };
      else return { ...state, ...params };
    }),
    mutate(mutations.setParams, ({ state, params }) => {
      return { ...state, params };
    }),
  ],
  getters: [
    select(getters.title, ({ state, get }) => {
      let title = get(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.getters.translation,
        state.title
      );
      if (isDefined(state.params)) title = interpolate(title, state.params);
      return title;
    }),
    select(getters.subTitle, ({ state, get }) => {
      let title = get(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.getters.translation,
        state.subTitle
      );

      if (isDefined(state.params)) title = interpolate(title, state.params);
      return title;
    }),
  ],
  actions: [],
};
