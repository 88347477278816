import { DateSelectionService } from '@softapps/core';
import { inject, linkedSignal } from '@angular/core';
import { DateService } from '@softline/core';

export class DashboardDateSelectionService extends DateSelectionService{
    dateService = inject(DateService);
    date = linkedSignal(() => this.dateService.today());
    constructor() {
      super();
    }

  override setDate(date: string) {
    this.date.set(date);
  }
}
