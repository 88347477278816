import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren
} from '@angular/core';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import {Subscription} from 'rxjs';
import { AccordionItemBase } from './components/accordion-item-base';
import { CommonModule } from '@angular/common';

// TODO: Check if accordion is working as before (fixed circular dependency for Storybook by creating AccordionItemBase)
@Component({
    selector: 'soft-accordion',
    imports: [CommonModule, CdkAccordionModule],
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent implements AfterViewInit, OnDestroy {

  private subscription?: Subscription;

  @Input() multi = false;

  @ContentChildren(AccordionItemBase) readonly accordionItems?: QueryList<AccordionItemBase>

  @ViewChildren('accordionItem') cdkAccordionItems?: QueryList<CdkAccordionItem>

  @HostBinding('class')
  @Input() class = '';

  constructor(private cdRef: ChangeDetectorRef) {}

  toggle(item: AccordionItemBase): void {
    const cdkAccordionItem = this.getCdkAccordionItem(item);
    cdkAccordionItem?.toggle();
  }

  isExpanded(item: AccordionItemBase): boolean {
    const cdk = this.getCdkAccordionItem(item);
    return cdk?.expanded ?? false;
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();

    this.subscription = this.accordionItems?.changes?.subscribe(() => {
      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }

  private getCdkAccordionItem(itemComponent: AccordionItemBase): CdkAccordionItem | undefined {
    const index = this.accordionItems?.toArray()?.findIndex(o => o === itemComponent);

    if (index === undefined || index < 0) {
      return undefined;
    }

    const cdkAccordionItem = this.cdkAccordionItems?.get(index);

    if (!cdkAccordionItem) {
      return undefined;
    }

    return cdkAccordionItem;
  }
}
