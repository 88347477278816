
import { EnvironmentProviders, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { BlobErrorHttpInterceptor } from '../data-access/interceptors/blob-http.interceptor';
import * as DownloadStore from './stores/download.store';
import {
  SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE,
  SOFTLINE_STORE_HTTP_DOWNLOAD_SERVICE
} from './stores/download.store';
import { provideStoreFeature } from '../store/store.providers';
import * as TransactionStore from './stores/transaction.store';
import { HttpService } from './specialized/http/http.service';
import { ConnectionHttpService } from './specialized/http/connection-http.service';
import { IndexedDbService } from './specialized/indexed-db/indexed-db.service';
import { SOFTLINE_FEATURE_DOWNLOAD } from './resource.module';
import {
  SOFTLINE_CONFIG_INDEXED_DB,
  SOFTLINE_FEATURE_TRANSACTION,
  SOFTLINE_SERVICE_RESPONSE_CONVERTER
} from './resource.shared';
import { IndexedDBConfig } from './specialized/indexed-db/indexed-db.config';

export const provideResources: () => Array<Provider | EnvironmentProviders> = () => {
  return [
    HttpService,
    ConnectionHttpService,
    IndexedDbService,
    provideHttpClient(withInterceptorsFromDi()),
    provideStoreFeature({
      name: SOFTLINE_FEATURE_DOWNLOAD,
      feature: DownloadStore.feature,
    }),
    provideStoreFeature({
      name: SOFTLINE_FEATURE_TRANSACTION,
      feature: TransactionStore.feature,
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: SOFTLINE_SERVICE_RESPONSE_CONVERTER,
      useValue: (result: any) => result.result,
    },
    {
      provide: SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE,
      useClass: ConnectionHttpService,
    },
    {
      provide: SOFTLINE_STORE_HTTP_DOWNLOAD_SERVICE,
      useClass: HttpService,
    },
  ]
}

export const provideIndexedDb: (config: IndexedDBConfig) => Provider = (config: IndexedDBConfig) => {
  return {
    provide: SOFTLINE_CONFIG_INDEXED_DB,
    useValue: config,
    multi: true,
  }
}
