import {Component, OnInit} from '@angular/core';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
  UtilitiesAccountStore,
} from '../../../../utilities.shared';
import {map, switchMap} from 'rxjs/operators';
import {PatchContextStore} from '../../../patch-context.store';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

export type AccountContext = { accountid?: number };

@Component({
    selector: 'lib-account-context-editor',
    templateUrl: './account-context-editor.component.html',
    styleUrls: ['./account-context-editor.component.scss'],
    imports: [CommonModule, UiCoreModule]
})
export class AccountContextEditorComponent implements OnInit {
  expanded = false;

  accounts$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
    UtilitiesAccountStore.getters.all
  );
  accountContext$ = this.store
    .observe(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.getters.data
    )
    .pipe(map((o) => o as AccountContext));
  activeAccount$ = this.store
    .observe(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.getters.data
    )
    .pipe(
      switchMap((o: AccountContext) =>
        this.store.observe(
          SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
          UtilitiesAccountStore.getters.entity,
          o.accountid
        )
      )
    );

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
      UtilitiesAccountStore.actions.loadMany,
      { clear: true }
    );
  }

  async onChange(accountid: number): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.mutations.patch,
      { accountid }
    );
    this.expanded = false;
  }
}
