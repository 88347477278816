import { equals } from "../../functions/equals.function";
import { isDefined } from "../../functions/is-defined.function";
import { SelectStrategy } from "./select.strategy";
import { Injectable } from "@angular/core";

export class StringSelectStrategy implements SelectStrategy<string | null> {
  select(value: string | null, current?:  string | null): string | null {
    if(!isDefined(current))
      return value;
    if(!isDefined(value))
      return current;

    const result = current.split(',');
    const addValues = value.split(',');
    for(let newValue of addValues) {
      if(!result.includes(newValue))
        result.push(newValue);
    }
    return result.sort().join(',');
  }

  unselect(value: string | null, current?:  string | null): string | null {
    if(!isDefined(current))
      return '';
    if(!isDefined(value))
      return current;

    const currentValues = current.split(',');
    const removeValues = value.split(',');
    const result = currentValues.filter(o => !removeValues.includes(o));

    return result.sort().join(',');
  }
}
