import { Component, Input, OnInit } from '@angular/core';
import { CheckboxComponent } from '@softline/ui-core';

@Component({
  selector: 'soft-dynamic-boolean-value',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss'],
  imports: [CheckboxComponent],
})
export class BooleanComponent implements OnInit {
  @Input() value?: any;
  @Input() control?: 'checkbox' | 'switch';
  @Input() label?: string;

  constructor() {}

  ngOnInit(): void {}
}
