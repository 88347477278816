import { ChangeDetectionStrategy, Component } from '@angular/core';

export interface LoadingBarConfiguration {
  backgroundColor: string;
  animation: string;
}

@Component({
    selector: 'soft-loading-bar',
    templateUrl: './loading-bar.component.html',
    styleUrls: ['./loading-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: []
})
export class LoadingBarComponent {
}
