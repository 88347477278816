import { DestroyRef, inject, Signal, signal } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { BackNavigationService } from './back-navigation.service';
import { BackNavigable, NavigateBackOptions } from '@softline/application';

export interface BackNavigationOptions {
  canNavigateBack?: Signal<boolean>;
  canNavigateParentBack?: Signal<boolean>;
}

export function backNavigation()
export function backNavigation(route: string, options?: BackNavigationOptions)
export function backNavigation(routeFn: (() => string), options?: BackNavigationOptions)
export function backNavigation(callback: (() => Promise<void>), options?: BackNavigationOptions)
export function backNavigation(backNavigable: BackNavigable)
export function backNavigation(navigation?: string | (() => string) | (() => Promise<void>) | BackNavigable, options?: BackNavigationOptions): void {
  const router = inject(Router);
  const destroyRef = inject(DestroyRef);
  const backNavigationService = inject(BackNavigationService);
  let backNavigable: BackNavigable;
  if(!navigation || typeof navigation === 'string')
    backNavigable = {
      canNavigateBack: options?.canNavigateBack,
      navigateBack: async (options?: NavigateBackOptions) => {
        if(options?.savepoint)
          await router.navigateByUrl(options.savepoint);
        else
          await router.navigate([navigation ?? '/']);
      }
    };
  else if(typeof navigation === 'function') {
    backNavigable = {
      canNavigateBack: options?.canNavigateBack,
      navigateBack: async (options?: NavigateBackOptions) => {
        const route = navigation();
        if(typeof route === 'string')
          if(options?.savepoint)
            await router.navigateByUrl(options.savepoint);
          else
            await router.navigate([route]);
        else
          await route;
      }
    };
  }
  else
    backNavigable = navigation

  backNavigationService.addNode(backNavigable);
  destroyRef.onDestroy(() => backNavigationService.removeNode(backNavigable));
}
