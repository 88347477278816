import { importProvidersFrom, inject, Injector, Provider, provideEnvironmentInitializer } from '@angular/core';
import { SOFTLINE_CONFIG_INDEXED_DB, Store, StoreModule } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import {
  SOFTLINE_CONFIG_PDF_EDITOR,
  SOFTLINE_DB_PDF_EDITOR,
  SOFTLINE_FEATURE_PDF_EDITOR,
  SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
} from './pdf-editor.shared';
import {
  PdfEditorToolGroupName,
  PdfEditorToolType,
} from './data/pdf-editor-tool';
import { v4 as uuid } from 'uuid';
import { default as de } from '../i18n/de.json';
import { PdfEditorComponentStore } from './store/pdf-editor-component.store';
import { PdfEditorStore } from './store/pdf-editor.store';
import { SOFTLINE_CONFIG_OFFLINE_FEATURE } from '@softline/application';

export const pdfEditorProviders = [
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_PDF_EDITOR, fields: ['files'] },
    multi: true,
  },
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.registerFeature(
        SOFTLINE_FEATURE_PDF_EDITOR,
        PdfEditorStore.feature,
        inject(Injector)
      );
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'pdfEditor',
          language: 'de',
          translations: de,
        }
      );
    }),
  {
    provide: SOFTLINE_CONFIG_INDEXED_DB,
    useValue: {
      name: SOFTLINE_DB_PDF_EDITOR,
      version: 1,
      objectStoreConfigs: [
        {
          name: 'customTools',
          parameters: { keyPath: 'groupName', autoIncrement: false },
          schema: [],
        },
      ],
    },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_PDF_EDITOR,
    useValue: {
      key: 'tools',
      value: {
        [PdfEditorToolGroupName.Stamp]: [
          /*{
            id: uuid(),
            type: PdfEditorToolType.Image,
            url: '/assets/stamps/stamp-3.png',
          },
          {
            id: uuid(),
            type: PdfEditorToolType.Image,
            url: '/assets/stamps/stamp-4.png',
          },*/
        ],
      },
    },
    multi: true,
  },
];
