import { Component, computed, input, Resource, ResourceStatus, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, IconComponent, IsDefinedNotEmptyPipe, UiCorePipesModule } from '@softline/ui-core';
import { CancelableResource, CancelledError } from '@softline/core';
import { requestErrorMessageFactory } from '@softline/application';

@Component({
  selector: 'soft-resource-container',
  imports: [CommonModule, I18nModule, IconComponent, IsDefinedNotEmptyPipe, UiCorePipesModule],
  templateUrl: './resource-container.component.html',
  styleUrl: './resource-container.component.scss',
})
export class ResourceContainerComponent<T> {
  availableStates = ResourceStatus;
  resource = input.required<Resource<T>>();
  loadingTemplate = input<TemplateRef<any> | null>(null);

  status = computed(() => this.resource().status());
  isCancelableResource = computed(() => (this.resource() as CancelableResource<T>).cancel !== undefined);
  isCancelled = computed(() => this.resource().error() instanceof CancelledError);

  errorMessage = computed(() => {
    const error = this.resource().error() as Error;
    if(!error)
      return null;
    return requestErrorMessageFactory(error);
  })

  cancel(): void {
    if(!this.isCancelableResource())
      return;
    (this.resource() as CancelableResource<T>).cancel();
  }
}
