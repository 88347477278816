import {
  ChangeDetectorRef,
  ComponentRef, computed,
  Directive, effect,
  ElementRef, Host, HostListener, inject, Injector, input,
  Input,
  OnDestroy,
  OnInit,
  Renderer2, runInInjectionContext, signal, SkipSelf,
  ViewContainerRef
} from '@angular/core';
import { ValidationContainerComponent } from "../components/validation-container/validation-container.component";
import {
  AbstractControl, FormArray,
  FormControl,
  FormControlStatus,
  FormGroup,
  FormGroupDirective,
  ValidationErrors
} from '@angular/forms';
import { ValidationIconComponent } from "../validation/components/validation-icon/validation-icon.component";
import { distinct, of, Subscription } from 'rxjs';
import {
  isDefined,
  isGroupValidation,
  isValidation,
  Validation,
  ValidationMessage,
  ValidationType
} from '@softline/core';
import { boolean } from 'mathjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[softFieldValidation]',
  standalone: true,
})
export class FieldValidationDirective implements OnInit, OnDestroy {

  private groupSubscription: Subscription | null = null;
  private controlSubscription: Subscription | null = null;
  private validationContainer: ComponentRef<ValidationContainerComponent> | null = null;
  private validationIcon: ComponentRef<ValidationIconComponent> | null = null;

  touched = signal(false);
  submitted = signal(false);
  messagesVisible = signal(false);
  controlStatus = signal<FormControlStatus | null>(null, {equal: () => false});

  vcRef = inject(ViewContainerRef);
  formGroup = inject(FormGroupDirective);
  renderer = inject(Renderer2);
  elementRef = inject(ElementRef);
  cdRef = inject(ChangeDetectorRef);
  injector = inject(Injector);

  formControlInput = input<FormControl | null>(null, {alias: 'formControl'});
  formControlName = input<string | null>(null);
  messagesVisibleInput = input<boolean>(false, {alias: 'messagesVisible'});
  trigger = input<'onTouched' | 'onSubmit' | 'always'> ('onTouched');

  formControl = computed(() => {
    const input = this.formControlInput();
    const name = this.formControlName();
    const control = input ?? (name ? this.formGroup.control.controls[name] : null);
    if(control instanceof FormControl)
      return control;
    return null;
  })

  validations = computed(() => {
    const formGroup = this.formGroup.control;
    const formControl = this.formControl();
    const controlStatus = this.controlStatus();

    if(!formControl)
      return [];

    const groupValidations = this.getGroupValidations(formControl, formGroup);
    const controlValidations = Object.values(formControl.errors ?? {})
      .filter(o => isValidation(o)) as Validation[];
    return [...controlValidations, ...groupValidations];
  });

  validationMessages = computed(() => {
    const validations = this.validations();
    return validations.flatMap(o => o.messages);
  });

  messagesVisibleInputEffect = effect(() => {
    const visible = this.messagesVisibleInput();
    this.messagesVisible.set(visible);
  });

  messagesVisibleEffect = effect(() => {
    if(!this.validationContainer)
      return;
    const visible = this.messagesVisible();
    this.renderer.removeClass(this.validationContainer.location.nativeElement, 'visible')
    this.renderer.removeClass(this.validationContainer.location.nativeElement, 'hidden')
    this.renderer.addClass(this.validationContainer.location.nativeElement, visible ? 'visible' : 'hidden');
  });

  showValidation = computed(() => {
    const touched = this.touched();
    const submitted = this.submitted();
    const trigger = this.trigger();

    return trigger === 'always'
      || (trigger === 'onSubmit' && submitted)
      || (trigger === 'onTouched' && (touched || submitted))
  });

  statusChangedEffect = effect(() => {
    const messages = this.validationMessages();
    const showValidation = this.showValidation();

    if(!this.validationIcon || !this.validationContainer)
      return;

    this.renderer.removeClass(this.elementRef.nativeElement, 'horizontal-shaking');
    this.renderer.removeClass(this.elementRef.nativeElement, 'error');
    this.renderer.removeClass(this.elementRef.nativeElement, 'warning');
    this.renderer.removeClass(this.elementRef.nativeElement, 'info');
    this.renderer.removeClass(this.elementRef.nativeElement, 'success');
    this.validationIcon.instance.type = null;
    this.validationContainer.instance.validation = null;

    const highestValidationType = this.getHighestValidationType(messages);
    if(showValidation && highestValidationType) {
      this.validationIcon.instance.type = highestValidationType;
      this.validationContainer.instance.validation = {messages: messages};
      this.renderer.addClass(this.elementRef.nativeElement, highestValidationType);
    }

    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    this.validationIcon.changeDetectorRef.markForCheck();
    this.validationIcon.changeDetectorRef.detectChanges();
    this.validationContainer.changeDetectorRef.markForCheck();
    this.validationContainer.changeDetectorRef.detectChanges();
  });

  constructor() { }

  ngOnInit() {
    this.validationIcon = this.vcRef.createComponent(ValidationIconComponent);
    this.validationContainer = this.vcRef.createComponent(ValidationContainerComponent);

    this.renderer.setStyle(this.validationIcon.location.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(this.validationIcon.location.nativeElement, 'top', '-0.75rem');
    this.renderer.setStyle(this.validationIcon.location.nativeElement, 'right', '0.25rem');

    this.renderer.appendChild(this.elementRef.nativeElement, this.validationIcon.location.nativeElement);
    this.renderer.addClass(this.validationContainer.location.nativeElement, 'soft-property-validation');

    this.renderer.listen(this.validationIcon.location.nativeElement, 'click', () => {
      this.messagesVisible.set(!this.messagesVisible());
    });

    runInInjectionContext(this.injector, () => {
      this.formGroup.ngSubmit
        .pipe(
          distinct(),
          takeUntilDestroyed()
        )
        .subscribe(() => {
          this.submitted.set(true);
        });
        toObservable(this.formControl)
          .pipe(
            distinct(),
            switchMap(o => o?.statusChanges ?? of(null)),
            takeUntilDestroyed(),
          )
          .subscribe((o) => {
            this.controlStatus.set(o);
          });
        this.formGroup.control.statusChanges.pipe(
          takeUntilDestroyed(),
          )
          .subscribe((o) => {
            this.controlStatus.set(o);
          });
    });
  }

  ngOnDestroy() {
    if (this.controlSubscription && !this.controlSubscription.closed)
      this.controlSubscription.unsubscribe();
    this.controlSubscription = null;
    if (this.groupSubscription && !this.groupSubscription.closed)
      this.groupSubscription.unsubscribe();
    this.groupSubscription = null;
  }

  @HostListener('focusout')
  onBlur() {
    this.touched.set(true);
  }

  private getGroupValidations(control: FormControl, form: FormGroup | FormArray | null): Validation[] {
    const validations: Validation[] = [];
    let name: string | null = null;
    while(form) {
      if (form instanceof FormGroup) {
        const controlName = Object
          .keys(form.controls)
          .find(o => form && form.controls[o] === control);
        if(!controlName)
          break;

        name = name ? `${controlName}.${name}` : controlName;
      }
      else if(form instanceof FormArray) {
        const controlIndex = form.controls.indexOf(control);
        if(controlIndex === -1)
          break;

        name = name ? `${controlIndex}.${name}` : '' + controlIndex;
      }

      if(!name)
        break;

      for(const validation of Object.values(form.errors ?? {})) {
        if(!isGroupValidation(validation))
          continue;
        if(validation.properties.includes(name))
          validations.push(validation);
      }
      form = form.parent;
    }
    return validations;
  }

  private getHighestValidationType(messages: ValidationMessage[]): ValidationType | null {
    const types = [null, 'info', 'success', 'warning', 'error'];
    return messages
      .reduce((acc, val) => {
        const accIndex = types.indexOf(acc ?? null);
        const valIndex = types.indexOf(val.type);
        if(valIndex > accIndex)
          return val.type;
        return acc
      }, null as ValidationType | null)
  }
}
