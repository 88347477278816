import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-list',
    imports: [CommonModule],
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ListComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent<T> implements OnInit {
  private _value: T[] | null | undefined;
  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  get value(): T[] | null | undefined {
    return this._value;
  }
  @Input()
  set value(value: T[] | null | undefined) {
    this.setValue(value);
  }
  @Output() valueChange: EventEmitter<T[] | null | undefined> =
    new EventEmitter<T[] | null | undefined>();

  @Input() selected?: T;
  @Output() selectedChange: EventEmitter<T | undefined> = new EventEmitter<
    T | undefined
  >();

  @Input() strategy: 'multi' | 'single' = 'multi';

  @ContentChild(TemplateRef) template?: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  setValue(value: T[] | null | undefined): void {
    if (equals(this.value, value)) return;

    this._value = value;
    this.onChange(this._value);
    this.onTouch(this._value);
    this.valueChange.emit(this.value);
  }

  add(value: T): void {
    this.value = this._value ? [...this._value, value] : [value];
  }

  remove(value: T): void {
    if (!this._value) return;

    this.value = this._value.filter((o) => o !== value);
  }

  replace(oldValue: T, newValue: T): void {
    if (!this._value) return;

    const index = this._value.indexOf(oldValue);
    const value = [...this._value];
    value.splice(index, 1, newValue);
    this.value = value;
  }

  onItemClick(value: T): void {
    if (this.selected === value) this.selected = undefined;
    else this.selected = value;
    this.selectedChange.emit(value);
  }
}
