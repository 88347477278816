import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConnectionHttpService, ApiResourceLocation, ResponseResult} from '@softline/core';
import {
  SOFTLINE_API_UMSATZABFRAGE_AUFGLIEDERUNG,
  SOFTLINE_API_UMSATZABFRAGE_EINKAUF_AUFGLIEDERUNG, SOFTLINE_API_UMSATZABFRAGE_EINKAUF_FILIALEN,
  SOFTLINE_API_UMSATZABFRAGE_FILIALEN
} from '../artikel-info.api';
import {map} from 'rxjs/operators';
import {LoadUmsatzabfrageParams} from '../store/umsatzabfrage.store';

@Injectable()
export class UmsatzabfrageService {

  private get location(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_UMSATZABFRAGE_AUFGLIEDERUNG
    }
  }

  private get einkaufLocation(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_UMSATZABFRAGE_EINKAUF_AUFGLIEDERUNG
    }
  }

  constructor(private service: ConnectionHttpService) {}

  monthlyLastYear(params: LoadUmsatzabfrageParams): Observable<any> {
    return this.service.create<unknown, ResponseResult<any>>(
      this.location,
      this.createBody({
        artikel: params.artikel,
        vondat: params.from,
        bisdat: params.to,
        filialen: params.filialen,
        vergleich: false
      })
    ).pipe(map(o => o?.result))
  }

  monthlyCompare(params: LoadUmsatzabfrageParams): Observable<any> {
    return this.service.create<unknown, ResponseResult<any>>(
      this.location,
      this.createBody({
        artikel: params.artikel,
        vondat: params.from,
        bisdat: params.to,
        filialen: params.filialen,
        vergleich: false
      })
    ).pipe(map(o => o?.result))
  }

  monthlyCompareVergleich(params: LoadUmsatzabfrageParams): Observable<any> {
    const fromParts = params.from.split('-');
    const vergleichFrom = `${(+(fromParts[0] ?? 0) - 1)}-${fromParts[1]}-${fromParts[2]}`;
    const toParts = params.to.split('-');
    const vergleichTo = `${(+(toParts[0] ?? 0) - 1)}-${toParts[1]}-${toParts[2]}`;
    return this.service.create<unknown, ResponseResult<any>>(
      this.location,
      this.createBody({
        artikel: params.artikel,
        vondat: vergleichFrom,
        bisdat: vergleichTo,
        filialen: params.filialen,
        vergleich: false
      })
    ).pipe(map(o => o?.result))
  }

  monthlyCompareEinkauf(params: LoadUmsatzabfrageParams): Observable<any> {
    return this.service.create<unknown, ResponseResult<any>>(
      this.einkaufLocation,
      this.createEinkaufBody({
        artikel: params.artikel,
        vondat: params.from,
        bisdat: params.to,
        filialen: params.filialen,
        vergleich: false
      })
    ).pipe(map(o => o?.result))
  }

  monthlyCompareEinkaufVergleich(params: LoadUmsatzabfrageParams): Observable<any> {
    const fromParts = params.from.split('-');
    const vergleichFrom = `${(+(fromParts[0] ?? 0) - 1)}-${fromParts[1]}-${fromParts[2]}`;
    const toParts = params.to.split('-');
    const vergleichTo = `${(+(toParts[0] ?? 0) - 1)}-${toParts[1]}-${toParts[2]}`;
    return this.service.create<unknown, ResponseResult<any>>(
      this.einkaufLocation,
      this.createEinkaufBody({
        artikel: params.artikel,
        vondat: vergleichFrom,
        bisdat: vergleichTo,
        filialen: params.filialen,
        vergleich: false
      })
    ).pipe(map(o => o?.result))
  }

  getFilialen(): Observable<any[]> {
    const location = { path: SOFTLINE_API_UMSATZABFRAGE_FILIALEN }
    return this.service.get<ResponseResult<any[]>>(location).pipe(
      map(o => o?.result)
    );
  }

  getEinkaufFilialen(): Observable<any[]> {
    const location = { path: SOFTLINE_API_UMSATZABFRAGE_EINKAUF_FILIALEN }
    return this.service.get<ResponseResult<any[]>>(location).pipe(
      map(o => o?.result)
    );
  }

  private createBody(params: { artikel: string, filialen: any[], vergleich: boolean, vondat: string, bisdat: string }): Record<string, unknown> {
    return {
      umsatzart: "Gesamt",
      ebene: "Vkforg",
      artikel: params.artikel,
      vondat: params.vondat,
      bisdat: params.bisdat,
      vergleich: params.vergleich,
      filialen: params.filialen,
      aufgliederung: "MONAT",
      menge: 2,
    }
  }

  private createEinkaufBody(params: { artikel: string, filialen: any[], vergleich: boolean, vondat: string, bisdat: string }): Record<string, unknown> {
    return {
      artikel: params.artikel,
      vondat: params.vondat,
      bisdat: params.bisdat,
      vergleich: params.vergleich,
      filialen: params.filialen,
      aufgliederung: "MONAT",
      menge: 2,
    }
  }
}
