import { Injectable } from "@angular/core";
import { TelemetryBaggageService } from "./telemetry-baggage.service";

@Injectable()
export class RouteBaggageService extends TelemetryBaggageService {
  constructor() {
    super()
  }

  override get(): object {
    return {
      route: window.location.href
    }
  }
}
