import { inject, Injectable } from '@angular/core';
import { ApiResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP } from '@softline/core';
import { ItemScan, Scan } from '@softline/application';
import { SOFTLINE_API_MDE_WARENVERPROBUNG_ITEM_SCAN } from '../warenverprobung.api';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class WarenverprobungScanService {
  private readonly httpService = inject<ResourceService<ApiResourceLocation>>(SOFTLINE_SERVICE_HTTP);

  async getItem(scan: Scan): Promise<ItemScan> {
    const location: ApiResourceLocation = {
      path: SOFTLINE_API_MDE_WARENVERPROBUNG_ITEM_SCAN,
      pathParams: {
        type: scan.labelType,
        code: scan.data
      }
    };

    return firstValueFrom(
      this.httpService.get(location)
    );
  }

}
