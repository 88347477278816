<form [formGroup]="form"
      (ngSubmit)="onSubmit()">
  <div class="flex flex-col mt-2">
    <label class="soft-label top required">Passwort</label>
    <soft-password-input formControlName="password" softFieldValidation></soft-password-input>
  </div>

  <div class="flex no-wrap mt-3">
    <button type="submit" class="soft-button accent ok h-14 w-full"
            [disabled]="form.invalid">
      {{ '#UI_CORE.DIALOGS.OK' | translate }}
    </button>
  </div>
</form>
