import { Component, OnInit } from '@angular/core';
import { DateDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import {
  DateInputComponent as UiCoreDIC,
  DateTimeInputComponent,
  FieldValidationDirective,
  TimeInputComponent,
} from '@softline/ui-core';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import { FormatRulePipe } from '../../../../pipes/format-rule.pipe';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'soft-dynamic-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  imports: [
    DateTimeInputComponent,
    NgSwitch,
    BooleanRulePipe,
    FormControlPipe,
    ReactiveFormsModule,
    FieldValidationDirective,
    NgSwitchCase,
    TimeInputComponent,
    FormatRulePipe,
    NgSwitchDefault,
    UiCoreDIC,
    NgIf,
  ],
})
export class DateInputComponent
  extends DynamicInputAtom<DateDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
