import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import { Modal, UiCoreModule } from '@softline/ui-core';
import { SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT } from '../../pdf-editor.shared';
import { PdfEditorComponentStore } from '../../store/pdf-editor-component.store';
import {
  PdfEditorToolGroup,
  PdfEditorToolType,
} from '../../data/pdf-editor-tool';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-pdf-editor-signature-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './pdf-editor-signature-dialog.component.html',
    styleUrls: ['./pdf-editor-signature-dialog.component.scss']
})
export class PdfEditorSignatureDialogComponent implements OnInit, Modal<any> {
  @ViewChild('signaturepad') signaturepad!: any;
  @ViewChild('remember') remember!: any;
  toolGroup!: PdfEditorToolGroup;

  close!: (result: any) => void;

  constructor(
    private store: Store,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async save(value: string): Promise<void> {
    const customTool = {
      id: this.uuid(),
      type: PdfEditorToolType.Image,
      url: value,
      isCustom: true,
    };
    const params = {
      groupName: this.toolGroup.name,
      tool: customTool,
    };

    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.addTool,
      params
    );
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.selectTool,
      customTool
    );
    if (this.remember.value) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.actions.saveCustomTool,
        params
      );
    }

    this.close(customTool);
  }

  clear() {
    this.signaturepad.clear();
  }
}
