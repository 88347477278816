import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FilterValueComponentBase } from "../filter-value-component-base";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  ModalStore,
  SetFocusDirective,
  SOFTLINE_FEATURE_MODAL, TranslatePipe,
  UiCoreComponentsModule, UiCoreModule,
  UiCorePipesModule,
  Validators
} from "@softline/ui-core";
import { Store } from "@softline/core";
import { FilterOperatorPipe } from "../../filter-operator.pipe";

@Component({
    selector: 'soft-number-filter-value',
    imports: [CommonModule, ReactiveFormsModule, SetFocusDirective, UiCoreComponentsModule, UiCorePipesModule, UiCoreModule],
    providers: [FilterOperatorPipe],
    templateUrl: './number-filter-value.component.html',
    styleUrls: ['./number-filter-value.component.scss']
})
export class NumberFilterValueComponent  extends FilterValueComponentBase implements OnInit {

  form = new FormGroup({
    value: new FormControl<number | null>(null, Validators.required()),
  });

  @ViewChild('dialogRef', { static: true }) dialogRef?: TemplateRef<any>;

  constructor(private store: Store,
              private translatePipe: TranslatePipe,
              private operatorPipe: FilterOperatorPipe) {
    super();
  }

  ngOnInit(): void {
  }

  async onValueClick(): Promise<void> {
    if (!this.filter)
      return;

    this.form.reset(this.filter as any);
    const value = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.template(),
      {
        title: `${this.translatePipe.transform(this.config?.title)} ${this.translatePipe.transform(this.operatorPipe.transform(this.filter.operator)?.title)}`,
        template: this.dialogRef
      })
    if (value === 'DISMISSED')
      return;
    this.onChange({ ...this.filter, ...this.form.value });
  }
}
