import { inject, Injectable } from '@angular/core';
import { DictionaryStore2 } from '../dictionary.store2';
import { Dictionary } from '../../../../types/dictionary';


export interface DictionaryResponseCommit<T, TMany = Dictionary<T>> {
  set(dictionary: TMany): void;
  setItem(key: string, value: T): void;
  clear(): void;
}

@Injectable()
export class DefaultDictionaryResponseCommit<T extends object> implements DictionaryResponseCommit<T> {
  dictionaryStore = inject(DictionaryStore2<T>);

  set(dictionary: Dictionary<T>): void {
    this.dictionaryStore.set(dictionary)
  }

  setItem(key: string, value: T): void {
    this.dictionaryStore.setItem(key, value)
  }

  clear(): void {
    this.dictionaryStore.clear()
  }
}

@Injectable()
export class ResultDictionaryResponseCommit<T extends object> implements DictionaryResponseCommit<{ result: T }, { result: Dictionary<T> }> {
  set(dictionary: { result: Dictionary<T>; }): void {
    this.dictionaryStore.set(dictionary.result)
  }
  setItem(key: string, value: { result: T; }): void {
    this.dictionaryStore.setItem(key, value.result)
  }
  clear(): void {
    this.dictionaryStore.clear()
  }

  dictionaryStore = inject(DictionaryStore2<T>);


}
