import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Lagerplatz, LagerplatzInhalt } from '@softapps/lager/core';
import { ResolvableModal, UiCorePipesModule } from '@softline/ui-core';
import { StockAmountComponent } from '../../components/stock-amount/stock-amount.component';

@Component({
  selector: 'soft-lagerplatz-auswahl',
  imports: [CommonModule, StockAmountComponent, UiCorePipesModule],
  templateUrl: './lagerplatz-auswahl.dialog.html',
  styleUrl: './lagerplatz-auswahl.dialog.scss',
})
export class LagerplatzAuswahlDialog extends ResolvableModal<Lagerplatz> {
  lagerplatzInhalte = input.required<LagerplatzInhalt[]>();
}
