import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-time-picker',
    imports: [CommonModule],
    templateUrl: './time-picker.component.html',
    styleUrl: './time-picker.component.scss'
})
export class TimePickerComponent {}
