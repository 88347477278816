import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterValueComponentBase } from '../filter-value-component-base';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
  TranslatePipe,
  UiCoreComponentsModule,
  UiCoreModule,
  Validators,
} from '@softline/ui-core';
import { equals, Store } from '@softline/core';
import { FilterOperatorPipe } from '../../filter-operator.pipe';

@Component({
  selector: 'soft-number-filter-value',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiCoreComponentsModule,
    UiCoreModule,
  ],
  providers: [FilterOperatorPipe, TranslatePipe],
  templateUrl: './select-filter-value.component.html',
  styleUrls: ['./select-filter-value.component.scss'],
})
export class SelectFilterValueComponent
  extends FilterValueComponentBase<
    unknown,
    { formatFn: (o: unknown) => string }
  >
  implements OnInit
{
  form = new FormGroup({
    value: new FormControl<object | null>(null, Validators.required()),
  });

  @ViewChild('dialogRef', { static: true }) dialogRef?: TemplateRef<any>;

  constructor(
    private store: Store,
    private translatePipe: TranslatePipe,
    private operatorPipe: FilterOperatorPipe
  ) {
    super();
  }

  ngOnInit(): void {}

  async onValueClick(): Promise<void> {
    if (!this.filter) return;

    this.form.reset(this.filter as any);
    const value = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose(),
      {
        title: `${this.translatePipe.transform(
          this.config?.title
        )} ${this.translatePipe.transform(
          this.operatorPipe.transform(this.filter.operator)?.title
        )}`,
        dismiss: true,
        options:
          this.values
            .filter((o, i, a) => a.findIndex((p) => equals(o, p)) === i)
            .map((o) => ({
              label: (this.config?.params?.formatFn ?? this.defaultTransformFn)(
                o
              ),
              value: o,
            }))
            .sort((f, s) =>
              f.label > s.label ? 1 : f.label < s.label ? -1 : 0
            ) ?? [],
      }
    );
    if (value === 'DISMISSED') return;
    this.onChange({ ...this.filter, value });
  }

  defaultTransformFn(value: unknown): string {
    if (typeof value === 'object' && value && value['id']) return value['id'];
    else if (value === 'object' && value) return JSON.stringify(value);
    else return '' + value;
  }
}
