import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileTypeIconPipe, IconComponent, L10nModule, UiCorePipesModule } from '@softline/ui-core';

@Component({
  selector: 'soft-document',
  imports: [
    CommonModule,
    FileTypeIconPipe,
    IconComponent,
    L10nModule,
    UiCorePipesModule,
  ],
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss',
})
export class DocumentComponent {
  fileName = input.required<string>();
  lastChanged = input.required<string>();
  read = input.required<boolean>();
  favorite = input.required<boolean>();

  userName = input<string>();
  fileBetreff = input<string>();
  fileSize = input<number>();
  fileDescription = input<string>();

  fileIconClick = output<void>();
}
