import { inject, Provider, provideEnvironmentInitializer, EnvironmentProviders, Injector } from '@angular/core';
import { Store2 } from './store2';
import { SOFTLINE_STORE_FEATURES } from './store2.shared';

export const store2Providers: Array<Provider | EnvironmentProviders> = [
  { provide: Store2, useClass: Store2 },
  provideEnvironmentInitializer(() => {
      const store = inject(Store2);
      const features = inject(SOFTLINE_STORE_FEATURES, {optional: true}) ?? [];
      for(const feature of features) {
        store.registerFeature(feature);
      }
    })
];
