import { computed, Injectable, Signal, signal } from '@angular/core';
import { BackNavigable } from './back-navigable';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

class BackNavigationNode {
  readonly savepoint = signal<UrlTree | undefined>(undefined);
  readonly child = signal<BackNavigationNode | undefined>(undefined);


  canNavigateBack = computed(() => {
    const child = this.child();
    if(child) {
      const canNavigateBack = child.canNavigateBack();
      if(!canNavigateBack)
        return false;
    }
    if(this.backNavigable.canNavigateBack)
      return this.backNavigable.canNavigateBack();
    return true;
  });

  constructor(public backNavigable: BackNavigable, protected parent?: BackNavigationNode) {}

  async navigateBack(): Promise<void> {
    const child = this.child();
    if(child && child.canNavigateBack())
      await child.navigateBack();
    else if (!child) {
      await this.backNavigable.navigateBack({
        savepoint: this.savepoint(),
        parent: this.parent?.backNavigable
      });
    }
  }

  addNode(node: BackNavigable): void {
    const child = this.child();
    if(child)
      child.addNode(node)
    else
      this.child.set(new BackNavigationNode(node, this));
  }

  removeNode(node: BackNavigable): void {
    const child = this.child();
    if(child)
      child.removeNode(child);
    if(child?.backNavigable === node)
      this.child.set(undefined);
  }

  setSavepoint(route: UrlTree): void {
    const child = this.child();
    if(child)
      child.setSavepoint(route);
    else
      this.savepoint.set(route);
  }

  removeSavepoint(route: UrlTree): void {
    const child = this.child();
    if(child)
      child.removeSavepoint(route);
    else if(this.savepoint() === route)
      this.savepoint.set(undefined);
  }
}

@Injectable({providedIn: 'root'})
export class BackNavigationService {

  private root = signal<BackNavigationNode | undefined>(undefined)

  canNavigateBack = computed(() => {
    const root = this.root();
    if(root)
      return root.canNavigateBack();
    return false;
  });

  set(backNavigable?: BackNavigable): void {
    if(backNavigable)
      this.root.set(new BackNavigationNode(backNavigable));
    else
      this.root.set(undefined);
  }

  clear(): void {
    this.root.set(undefined);
  }

  addNode(backNavigable: BackNavigable): void {
    const root = this.root();
    if(!root)
      this.root.set(new BackNavigationNode(backNavigable));
    else
      root.addNode(backNavigable);
  }

  removeNode(backNavigable: BackNavigable): void {
    const root = this.root();
    if(root?.backNavigable === backNavigable)
      this.root.set(undefined);
    else if(root)
      root.removeNode(backNavigable);
  }

  setSavepoint(route: UrlTree): void {
    const root = this.root();
    if(root)
      root.setSavepoint(route);
  }

  removeSavepoint(route: UrlTree): void {
    const root = this.root();
    if(root)
      root.removeSavepoint(route);
  }

  async navigateBack(): Promise<void> {
    const root = this.root();
    if(root)
      await root.navigateBack();
  }
}
