import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-chip',
    imports: [
        CommonModule,
    ],
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  @Input() canDelete = false;
  @Output() delete = new EventEmitter<this>();
}
