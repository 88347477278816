import { Observable } from 'rxjs';
import { RequestEvent } from '../data-access/types/request';

export interface DownloadRequestOptions {
  responseType?: 'arraybuffer' | 'blob';
}

export type ResourceService<TLocation> = GetResourceService<any> &
  DownloadResourceService<TLocation> &
  SaveResourceService<TLocation>;

export type SaveResourceService<TLocation> = CreateResourceService<TLocation> &
  UpdateResourceService<TLocation> &
  PatchResourceService<TLocation> &
  DeleteResourceService<TLocation> &
  UploadResourceService<TLocation>;

export interface GetResourceService<TLocation> {
  get<T, TPayload = undefined>(
    location: TLocation,
    payload?: TPayload
  ): Observable<T>;
}
export interface CreateResourceService<TLocation> {
  create<T, TResponse>(location: TLocation, resource: T): Observable<TResponse>;
}
export interface UpdateResourceService<TLocation> {
  update<T, TResponse>(location: TLocation, resource: T): Observable<TResponse>;
}
export interface DeleteResourceService<TLocation> {
  delete<TResponse, TPayload = undefined>(
    location: TLocation,
    payload?: TPayload
  ): Observable<TResponse>;
}
export interface PatchResourceService<TLocation> {
  patch<T, TResponse>(
    location: TLocation,
    changes: Partial<T>
  ): Observable<TResponse>;
}
export interface DownloadResourceService<TLocation> {
  download<T>(
    location: TLocation,
    payload?: unknown,
    options?: DownloadRequestOptions
  ): Observable<RequestEvent<Blob | ArrayBuffer>>;
}
export interface UploadResourceService<TLocation> {
  upload<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>>;
}
