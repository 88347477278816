import { Component, Input, OnInit } from '@angular/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
  selector: 'soft-dynamic-text-value',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault],
})
export class TextComponent implements OnInit {
  @Input() value?: any;
  @Input() definition: any;

  constructor() {}

  ngOnInit(): void {}
}
