import {
  createAction,
  createGetter,
  createMutation, Dictionary,
  isDefined,
  mutate,
  on,
  select,
  StoreFeature
} from "@softline/core";
import { JwtHelper } from './utilities/jwt-helper';
import { JwtAuthenticationService } from './services/jwt-authentication.service';
import { SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from '../authentication.shared';
import { AuthenticationContextStore } from '../authentication-context.store';

export interface State {
  token: string | null;
  loading: boolean;
  loaded: boolean;
  stayAuthenticated: boolean;
  forwardRoute: string | null;
  expired: boolean;
}

export const mutations = {
  setToken: createMutation<State, string>('setToken'),
  setExpired: createMutation<State, boolean>('setExpired'),
  setLoading: createMutation<State, boolean>('setLoading'),
  setLoaded: createMutation<State, boolean>('setLoaded'),
  setStayAuthenticated: createMutation<State, boolean>('setStayAuthenticated'),
  setForwardRoute: createMutation<State, string>('setForwardRoute'),
};

export const actions = {
  authenticate: createAction<
    State,
    { username: string; password: string; stayAuthenticated: boolean, claims?: Dictionary<unknown> },
    boolean
  >('authenticate'),
  authenticateViaToken: createAction<
    State,
    { token: string, type: string },
    boolean
    >('authenticateViaToken'),
  change: createAction<State, object>('change'),
  refresh: createAction<State, object>('refresh'),
  load: createAction<State, object>('load'),
  logout: createAction<State, { expired?: boolean } | undefined>('logout'),
};

export const getters = {
  authenticated: createGetter<State, boolean>('authenticated'),
  token: createGetter<State, string>('token'),
  loading: createGetter<State, boolean>('loading'),
  loaded: createGetter<State, boolean>('loaded'),
  stayAuthenticated: createGetter<State, boolean>('stayAuthenticated'),
  forwardRoute: createGetter<State, string>('forwardRoute'),
  expired: createGetter<State, boolean>('expired'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    token: null,
    loading: false,
    loaded: false,
    stayAuthenticated: false,
    forwardRoute: null,
    expired: false,
  },
  mutations: [
    mutate(mutations.setToken, ({ state, params }) => {
      return { ...state, token: params, loading: false, loaded: true };
    }),
    mutate(mutations.setLoading, ({ state, params }) => ({
      ...state,
      loading: params,
    })),
    mutate(mutations.setLoaded, ({ state, params }) => ({
      ...state,
      loaded: params,
    })),
    mutate(mutations.setStayAuthenticated, ({ state, params }) => ({
      ...state,
      stayAuthenticated: params,
    })),
    mutate(mutations.setForwardRoute, ({ state, params }) => ({
      ...state,
      forwardRoute: params,
    })),
    mutate(mutations.setExpired, ({ state, params }) => ({
      ...state,
      expired: params,
    })),
  ],
  actions: [
    on(actions.load, async ({ store, featureName, injector }) => {
      const service = injector.get(JwtAuthenticationService);
      store.commit(featureName, mutations.setLoading, true);
      const token = await service.load();
      store.commit(featureName, mutations.setToken, token);

      if (isDefined(token)) {
        const context = JwtHelper.getPayload(token) ?? null;
        store.commit(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.mutations.set,
          context
        );
      } else
        store.commit(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.mutations.unset
        );
    }),
    on(
      actions.authenticate,
      async ({ featureName, params, injector, commit, dispatch }) => {
        const service = injector.get(JwtAuthenticationService);
        commit(featureName, mutations.setToken, undefined);
        commit(featureName, mutations.setExpired, false);
        commit(featureName, mutations.setLoading, true);

        commit(
          featureName,
          mutations.setStayAuthenticated,
          params.stayAuthenticated
        );
        const result = await service.authenticate(
          params.username,
          params.password,
          params.stayAuthenticated,
          params.claims
        );
        await service.save(result.token, params.stayAuthenticated);
        commit(featureName, mutations.setToken, result.token);

        if (isDefined(result.token)) {
          const context = JwtHelper.getPayload(result.token) ?? null;
          commit(
            SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
            AuthenticationContextStore.mutations.set,
            context
          );
        } else
          commit(
            SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
            AuthenticationContextStore.mutations.unset
          );
        return isDefined(result.token);
      }
    ),

    on(
      actions.authenticateViaToken,
      async ({ store, featureName, params, injector }) => {
        const service = injector.get(JwtAuthenticationService);
        store.commit(featureName, mutations.setToken, undefined);
        store.commit(featureName, mutations.setExpired, false);
        store.commit(featureName, mutations.setLoading, true);

        store.commit(
          featureName,
          mutations.setStayAuthenticated,
          false
        );
        const token = await service.authenticateViaToken(
          params.token,
          params.type
        );
        await service.save(token, false);
        store.commit(featureName, mutations.setToken, token);

        if (isDefined(token)) {
          const context = JwtHelper.getPayload(token) ?? null;
          store.commit(
            SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
            AuthenticationContextStore.mutations.set,
            context
          );
        } else
          store.commit(
            SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
            AuthenticationContextStore.mutations.unset
          );

        return isDefined(token);
      }
    ),
    on(actions.refresh, async ({ store, featureName, params, injector }) => {
      const service = injector.get(JwtAuthenticationService);
      store.commit(featureName, mutations.setLoading, true);
      const token = await service.refresh();
      await service.update(token);
      store.commit(featureName, mutations.setToken, token);

      if (isDefined(token)) {
        const context = JwtHelper.getPayload(token) ?? null;
        store.commit(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.mutations.set,
          context
        );
      } else
        store.commit(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.mutations.unset
        );
    }),
    on(actions.change, async ({ store, featureName, params, injector }) => {
      const service = injector.get(JwtAuthenticationService);
      store.commit(featureName, mutations.setLoading, true);
      const token = await service.change(params);
      await service.update(token);
      store.commit(featureName, mutations.setToken, token);

      if (isDefined(token)) {
        const context = JwtHelper.getPayload(token) ?? null;
        store.commit(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.mutations.set,
          context
        );
      } else
        store.commit(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.mutations.unset
        );
    }),
    on(actions.logout, async ({ store, state, featureName, params, injector }) => {
      const service = injector.get(JwtAuthenticationService);
      await service.deleteToken();
      store.commit(featureName, mutations.setToken, null);
      store.commit(featureName, mutations.setExpired, params?.expired ?? false);
      store.commit(
        SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
        AuthenticationContextStore.mutations.unset
      );
    }),
  ],
  getters: [
    select(getters.token, ({ state }) => state.token),
    select(getters.loading, ({ state }) => state.loading),
    select(getters.loaded, ({ state }) => state.loaded),
    select(getters.stayAuthenticated, ({ state }) => state.stayAuthenticated),
    select(getters.authenticated, ({ state }) => isDefined(state.token)),
    select(getters.forwardRoute, ({ state }) => state.forwardRoute),
    select(getters.expired, ({ state }) => state.expired),
  ],
};

function isToken(value: unknown): value is {token: string} {
  return !!(value as {token: string})?.token
}
