import { inject, Injectable } from '@angular/core';
import { TranslationStore2 } from './translation.store2';
import { TranslationService } from './translation.service';

@Injectable({providedIn: 'root'})
export class TranslationServiceStoreAdapter {
  store = inject(TranslationStore2);
  service = inject(TranslationService);

  constructor() {
    this.store.state$.subscribe(state => {
      for(const translation of state.translations) {
        if (this.service.translationFiles().findIndex(o => o.language === translation.language && o.module === translation.module) === -1)
          this.service.addFile(translation);
      }
    });
  }
}
