import {
  createAction,
  createGetter,
  createMutation,
  DownloadStore,
  mutate,
  on,
  select,
  StoreFeature,
  SOFTLINE_FEATURE_DOWNLOAD,
  ApiResourceLocation,
} from '@softline/core';
import { PrinterService } from './services/printer.service';

export interface State {
  printing: boolean;
}

export const mutations = {
  setPrinting: createMutation<State, boolean>('setPrinting'),
};

export const getters = {
  printing: createGetter<State, boolean>('printing'),
};

export const actions = {
  printText: createAction<State, string>('printText'),
  printFile: createAction<State, string>('printFile'),
  printData: createAction<State, Blob>('printData'),
  downloadAndPrint: createAction<
    State,
    {
      location: ApiResourceLocation;
      filename: string;
      payload?: unknown;
    }
  >('downloadAndPrint'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    printing: false,
  },
  mutations: [
    mutate(mutations.setPrinting, ({ state, params }) => ({
      ...state,
      printing: params,
    })),
  ],
  getters: [select(getters.printing, ({ state }) => state.printing)],
  actions: [
    on(actions.printText, async ({ store, featureName, params, injector }) => {
      const service = injector.get(PrinterService);
      store.commit(featureName, mutations.setPrinting, true);
      await service.printText(params);
      store.commit(featureName, mutations.setPrinting, false);
    }),
    on(actions.printFile, async ({ store, featureName, params, injector }) => {
      const service = injector.get(PrinterService);
      store.commit(featureName, mutations.setPrinting, true);
      await service.printFile(params);
      store.commit(featureName, mutations.setPrinting, false);
    }),
    on(actions.printData, async ({ store, featureName, params, injector }) => {
      const service = injector.get(PrinterService);
      store.commit(featureName, mutations.setPrinting, true);
      await service.printData(params);
      store.commit(featureName, mutations.setPrinting, false);
    }),
    on(
      actions.downloadAndPrint,
      async ({ store, featureName, params, injector }) => {
        const service = injector.get(PrinterService);
        store.commit(featureName, mutations.setPrinting, true);
        const result = await store.dispatch(
          SOFTLINE_FEATURE_DOWNLOAD,
          DownloadStore.actions.download,
          params
        );
        await service.printData(result.content);
        store.commit(featureName, mutations.setPrinting, false);
      }
    ),
  ],
};
