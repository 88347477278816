import { inject, Injector, runInInjectionContext } from '@angular/core';


export function injectionContext<T extends Function>(fn: T): T {
  const injector = inject(Injector);
  const wrappedFunction = (...args: any[]) => {
    return runInInjectionContext(injector, () => {
      return fn(...args);
    });
  };
  return wrappedFunction as unknown as T;
}
