import { importProvidersFrom, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import {Store, StoreModule} from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import {
  ApplicationStore,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import { SOFTLINE_PERMISSION_CUSTOMER_QUERY } from "./kundenabfrage.shared";

export const kundenabfrageProviders = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'kundenabfrage',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Kundenabfrage',
          icon: 'fa-regular fa-user',
          route: '/kundenabfrage',
          permission: SOFTLINE_PERMISSION_CUSTOMER_QUERY,
        }
      );
    }),
];
