import { Observable } from 'rxjs';
import { Entity } from '../store/specialized/entity/types/entity';
import {
  Change,
  LazySaveResult,
} from '../store/specialized/entity/types/change';
import { Dictionary } from '../types/dictionary';
import { Patch } from '../store/specialized/entity/types/patch';
import { RequestEvent } from '../data-access/types/request';
import { ValidationResult } from '../store/specialized/entity/types/validation';
import { QueryParameters } from '../store/specialized/entity/types/query';

export type EntityService<T extends Entity, TCreate = T> = GetEntityService<T> &
  CreateEntityService<TCreate, T> &
  UpdateEntityService<T> &
  PatchEntityService<T> &
  DeleteEntityService<T>;

export interface GetEntityService<T> {
  get(
    id: string | number,
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>,
    body?: unknown
  ): Observable<T>;
  getMany(
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>,
    body?: unknown
  ): Observable<T[]>;
}

export interface CreateEntityService<T, TResponse extends Entity> {
  create(entity: T, pathParams?: Dictionary<unknown>): Observable<TResponse>;
}

export interface UpdateEntityService<T extends Entity> {
  update(entity: T, pathParams?: Dictionary<unknown>): Observable<T>;
}

export interface PatchEntityService<T extends Entity> {
  patch(patch: Patch<T>, pathParams?: Dictionary<unknown>): Observable<T>;
}

export interface DeleteEntityService<T extends Entity> {
  delete(entity: T, pathParams?: Dictionary<unknown>): Observable<T>;
}

export interface UploadEntityService<T, TResponse extends Entity> {
  upload(
    entity: T,
    pathParams?: Dictionary<unknown>
  ): Observable<RequestEvent<TResponse>>;
}

export interface QueryEntityService<T extends Entity> {
  query(
    query: QueryParameters<T>,
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined
  ): Observable<T>;
}

export interface BulkEntityService<T extends Entity> {
  save(
    changes: Readonly<Change<T>[]>,
    pathParams?: Dictionary<unknown>
  ): Observable<LazySaveResult<T>>;
}

export interface TemplateService<T extends Entity> {
  loadTemplate(
    data?: Dictionary<unknown>,
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Promise<Partial<T>>;
}

export interface ValidationService<T extends Entity> {
  validate(
    entity: T,
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Promise<ValidationResult<T>>;
}
