import {ChangeDetectionStrategy, Component, OnDestroy, OnInit,} from '@angular/core';
import {DynamicModule, FieldOkResultConverter} from "@softline/dynamic";
import {BackNavigable, BackNavigationService,} from '@softline/application';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {base64Encode, Store} from '@softline/core';
import {Router} from '@angular/router';
import {SelectedCustomerStore, SOFTLINE_FEATURE_CUSTOMER_QUERY} from '../../kundenabfrage.shared';


@Component({
    selector: 'lib-customer-input',
    templateUrl: './customer-input.component.html',
    styleUrls: ['./customer-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, UiCoreModule, DynamicModule]
})
export class CustomerInputComponent
  implements OnInit, OnDestroy, BackNavigable
{

  customerConverter = new FieldOkResultConverter({ nummer: 'number', '1': 'name' });

  constructor(
    private store: Store,
    private router: Router,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
  }

  ngOnDestroy() {
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async selectCustomer(customer: any): Promise<void> {
    if (!customer || !customer?.id) {
      return;
    }

    await this.router.navigate(['/kundenabfrage', customer?.id], { queryParams: { nummer: customer?.number } });
  }
}
