import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ObjektListView } from "./list-view/objekt.list-view";
import { FieldOkSearchConfig } from '@softapps/core';
import { ObjektFieldOkSearchResultViewComponent } from './search-result-view/search-result-view.component';

export const objektFieldOk: FieldOkConfig = {
  name: 'objekt',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{objekt}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ObjektListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};
export const objektMultiselectFieldOk: FieldOkConfig = {
  name: 'objekt',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{objekt}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: ObjektListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};

export const objektQueryFieldOk: FieldOkConfig = {
  name: 'objekt',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{objekt}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ObjektListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};

export const objektSearchPageConfig: FieldOkSearchConfig<any> = {
  name: 'objekt',
  view: ObjektFieldOkSearchResultViewComponent,
  options: {
    autoLoad: true
  }
}
