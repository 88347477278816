import {ResourceService} from '../../data/abstraction';
import {ApiResourceLocation} from '../../data-access/types/api.location';
import {LoadListParameters, ReadonlyRepository} from '../abstraction';
import {Observable} from 'rxjs';
import { Dictionary } from '../../types/dictionary';

export class ReadonlyHttpApiListRepository<TResult> implements ReadonlyRepository<TResult> {

  constructor(protected service: ResourceService<ApiResourceLocation>,
              protected path: string | string[],
              protected options?: { method?: 'POST' | 'GET', queryParams?: Dictionary<string>, pathParams?: Dictionary<string> }) {
  }

  load(params?: LoadListParameters): Observable<TResult> {
    const location: ApiResourceLocation = {
      path: this.path,
      pathParams: { ...(this.options?.pathParams ?? {}), ...(params?.pathParams ?? {})},
      queryParams: this.options?.queryParams
    }

    if (this.options?.method === 'POST') {
      return this.service.get(location, params?.query);
    } else {
      location.queryParams = { ...(this.options?.queryParams ?? {}), ...(params?.query ?? {})};
      return this.service.get(location);
    }
  }
}
