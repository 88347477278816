import { inject, Injector, Signal, TemplateRef } from '@angular/core';
import { Dismissed, ResolvableModalConfig } from '../types/modal-config';
import { ModalService } from '../modal.service';
import { NotificationModalComponent } from '../components/notification-modal/notification-modal.component';
import { NotificationResult } from '@softline/ui-core';


export interface NotificationConfig2 {
  title?: string | Signal<string | null>;
  subtitle?: string | Signal<string | null>;
  content?: TemplateRef<any> | Signal<TemplateRef<any> | null>;
  contentContext?: object | Signal<object | null>;
  text: string | Signal<string>;
  class?: string;
  showCancelAction?: boolean;
  dismiss?: boolean;
}

async function notify(modalService: ModalService, config: NotificationConfig2, injector: Injector): Promise<NotificationResult | Dismissed> {
  const modalConfig: ResolvableModalConfig<NotificationModalComponent, NotificationResult> = {
    component: NotificationModalComponent,
    dismiss: config.dismiss,
    title: config.title ?? 'Benachrichtigung',
    subtitle: config.subtitle,
    template: config.content,
    templateContext: config.contentContext,
    priority: Number.POSITIVE_INFINITY,
    data: {
      text: config.text,
      showCancel: config.showCancelAction
    },
    class: config.class,
    injector: injector
  }
  return await modalService.open(modalConfig);
}

export function notifyFn(): (config: NotificationConfig2) => Promise<string | Dismissed> {
  const modalService = inject(ModalService);
  const injector = inject(Injector);
  return (config) => notify(modalService, config, injector)
}
