<div class="mt-2 mr-3 w-full">
  <div>
    <soft-file-input
      [sources]="sources() ?? 'all'"
      [accept]="accept()"
      [inputView]="inputView()"
      [selectionMode]="selectionMode()"
      [valueView]="valueView()"
      (valueChange)="onValueChange($event)"
      #fileInput></soft-file-input>
  </div>
</div>
<div class="flex no-wrap mt-3">
  <button
    class="soft-button accent ok h-14 w-full"
    [disabled]="!fileInput.value?.length"
    (click)="onSubmit(fileInput.value)"
  >
    {{ '#UI_CORE.DIALOGS.OK' | translate }}
  </button>
</div>
