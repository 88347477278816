import { ResourceService } from '../../data/abstraction';
import { CollectionRepository, CRUDCollectionParameters, Id } from '../abstraction';
import { ReadonlyHttpApiCollectionRepository } from './readonly-http-api-collection.repository';
import { ApiResourceLocation } from '../../data-access/types/api.location';
import { Observable } from 'rxjs';
import { toIdString } from '../functions/to-id-string';
import { isDefined } from '../../functions/is-defined.function';
import { isDefinedNotEmpty } from '../../functions/is-defined-not-empty.function';

export class HttpApiCollectionRepository<TItem, TResult, TManyResult> extends ReadonlyHttpApiCollectionRepository<TResult, TManyResult> implements CollectionRepository<TItem, TResult, TManyResult> {

  constructor(service: ResourceService<ApiResourceLocation>,
              path: string | string[],
              override options?: {pathSuffix?: string | string[], createIdIfEmpty?: boolean}) {
    super(service, path, options);
  }

  create(id: Id | null | undefined, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    if(this.options?.pathSuffix?.length && !this.options.createIdIfEmpty && !isDefined(id))
      throw new Error(`[HttpApiCollectionDataService]create: id is required for collections with pathSuffix`);

    const idString = toIdString(id, {createIfEmpty: this.options?.createIdIfEmpty ?? false});
    let path: string[] = Array.isArray(this.path) ? this.path : [this.path];

    if (isDefinedNotEmpty(idString)) {
      path = [...path, idString, ...this.getPathSuffix()];
    }

    const location: ApiResourceLocation = {
      path: path,
      pathParams: params?.pathParams
    }

    return this.service.create(location, value);
  }
  update(id: Id, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const path = this.getItemPath(id);
    const location: ApiResourceLocation = {
      path: path,
      pathParams: params?.pathParams
    }
    return this.service.update(location, value);
  }
  patch(id: Id, changes: Partial<TItem>, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const path = this.getItemPath(id);
    const location: ApiResourceLocation = {
      path: path,
      pathParams: params?.pathParams
    }
    return this.service.patch(location, changes);
  }
  delete(id: Id, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const path = this.getItemPath(id);
    const location: ApiResourceLocation = {
      path: path,
      pathParams: params?.pathParams
    }
    return this.service.delete(location);
  }
}
