import {
  Component,
  computed,
  inject,
  linkedSignal,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  I18nModule,
  Modal,
  SelectComponent,
  SelectOptionDirective,
} from '@softline/ui-core';
import { Bewe } from '../../types/bewegung';
import {
  MengenEingabeComponent,
  showRequestErrors,
} from '@softline/application';
import { Lagerplatz, LagerplatzSucheComponent } from '@softapps/lager/core';
import { EinlagerungService } from '../../services/einlagerung-service';
import {
  EinlagerungBean,
  EinlagerungBody,
} from '../../types/direkte-einlagerung';
import { Store } from '@softline/core';

@Component({
  selector: 'soft-direkt-einlagerung-dialog',
  imports: [
    CommonModule,
    I18nModule,
    MengenEingabeComponent,
    LagerplatzSucheComponent,
    SelectComponent,
    SelectOptionDirective,
  ],
  templateUrl: './direkt-einlagerung-dialog.component.html',
  styleUrl: './direkt-einlagerung-dialog.component.scss',
  providers: [EinlagerungService],
})
export class DirektEinlagerungDialogComponent implements Modal<any>, OnInit {
  readonly store = inject(Store);
  readonly einlagerungService = inject(EinlagerungService);

  readonly _idwu = signal<string | null>(null);
  readonly _bewe = signal<Bewe | null>(null);
  readonly _isPalette = signal<boolean>(false);
  readonly lagerplatzScan = signal<Lagerplatz | null>(null);
  readonly einlagerungBean = signal<EinlagerungBean | null>(null);

  set idwu(idwu: string) {
    this._idwu.set(idwu);
  }

  set bewe(bewe: Bewe) {
    this._bewe.set(bewe);
  }

  set isPalette(value: boolean) {
    this._isPalette.set(value);
  }

  readonly menge = linkedSignal(() => {
    return this.einlagerungBean()?.menge ?? 0;
  });

  readonly maxMenge = linkedSignal(() => {
    return this.einlagerungBean()?.menge ?? 0;
  });

  readonly paletten = linkedSignal(() =>
    this._isPalette() ? this.einlagerungBean()?.paletten.map((p) => p) : null
  );

  readonly selectedPalette = computed(() => {
    return signal(this.paletten()?.[0] ?? null);
  });

  async ngOnInit(): Promise<void> {
    const idbewe = this._bewe()?.id;
    if (!idbewe) return;

    const bean = await this.einlagerungService.loadEinlagerungBean(idbewe);
    if (!bean) return;

    this.einlagerungBean.set(bean['result']);
  }

  async submit() {
    const selectedPalette = this.selectedPalette()();
    const menge = this.menge();
    const lagerplatzScan = this.lagerplatzScan();
    const bean = this.einlagerungBean();
    const beweId = this._bewe()?.id;

    if (!lagerplatzScan || !bean || !beweId) return;

    const body: EinlagerungBody = {
      artikel: bean.artikel,
      einheit: bean.einheit,
      idbewe: beweId,
      lagerplatz: lagerplatzScan,
      palette: this._isPalette() ? selectedPalette : null,
      menge: this._isPalette() ? null : menge,
    };

    try {
      await this.einlagerungService.createEinlagerung(body);

      if (this._isPalette()) {
        const palettenList = this.paletten();
        const newPalettenList = palettenList?.filter(
          (p) => p.sscc !== selectedPalette?.sscc
        );
        if (!newPalettenList || (newPalettenList?.length ?? 0) < 1)
          this.close(null);
        this.paletten.set(newPalettenList);
      } else {
        if (menge < this.maxMenge()) {
          const remainingMenge = this.maxMenge() - menge;
          this.maxMenge.set(remainingMenge);
          this.menge.set(this.maxMenge());
        } else {
          this.close(null);
        }
      }
    } catch (error) {
      showRequestErrors(this.store, error);
    }
  }

  close!: (result: any) => void;

  setLagerplatz(lagerplatz: Lagerplatz | null): void {
    if (lagerplatz) {
      this.lagerplatzScan.set({
        lagerplatz: lagerplatz?.lagerplatz ?? '',
        id: lagerplatz?.id,
        barcode: null,
      });
    } else {
      this.lagerplatzScan.set(null);
    }
  }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }
}
