import { InjectionToken, Provider } from '@angular/core';
import { RichTextEditorConfig } from './types/rich-text-editor-config';

export const SOFTLINE_CONFIG_RICH_TEXT_EDITOR =
  new InjectionToken<RichTextEditorConfig>('SOFTLINE_CONFIG_RICH_TEXT_EDITOR');

export function provideRichTextEditorConfig(
  config: RichTextEditorConfig
): Provider {
  return { provide: SOFTLINE_CONFIG_RICH_TEXT_EDITOR, useValue: config };
}
