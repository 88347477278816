import { Inject, inject, Injectable, Injector, runInInjectionContext } from '@angular/core';
import { FieldOkStrategyFactory } from '../strategies/field-ok-strategy.factory';
import { SOFTLINE_SERVICE_UUID } from '@softline/core';
import { FieldOkConfig, SOFTLINE_PROVIDER_FIELD_OK_CONFIG } from '@softline/dynamic';
import { FieldOkStrategyOptions } from '../strategies/field-ok.strategy';


@Injectable({providedIn: 'root'})
export class FieldOkModalService {
  private injector = inject(Injector);
  private factory = inject(FieldOkStrategyFactory);
  private uuid = inject(SOFTLINE_SERVICE_UUID);
  private configProvider: (name: string, options: {type: string}) => FieldOkConfig = inject(SOFTLINE_PROVIDER_FIELD_OK_CONFIG);

  async open<T>(name: string, parameters?: object): Promise<T | null | 'DISMISSED' | 'CANCELED'> {
    return await runInInjectionContext(this.injector, async () => {
      const config = this.configProvider(name, { type: 'default' });
      const componentId = this.uuid();
      const options: FieldOkStrategyOptions = {
        componentId: componentId,
        title: '',
        subtitle: undefined,
        config: config,
        type: 'single'
      }
      const strategy = this.factory.create(name, options);
      return await strategy.open(null, { parameters });
    });
  }
}
