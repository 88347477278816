import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryShortcut } from '../query-shortcut';
import { UiCoreModule } from '@softline/ui-core';

@Component({
    selector: 'soft-query-page-shortcuts',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './query-page-shortcuts.component.html',
    styleUrl: './query-page-shortcuts.component.scss'
})
export class QueryPageShortcutsComponent<T> {
  shortcuts = input<QueryShortcut<T>[]>([]);
  shortcutClick = output<T>()
}
