import { NavigationExtras } from '@angular/router';
import { Injector } from '@angular/core';

export type Application = AppModule | LinkModule;

export interface Module {
  type?: 'module' | 'link';
  name: string;
  description?: string;
  icon?: string;
  id?: number;
  image?: string;
  permission?: string;
}

export interface LinkModule extends Module {
  type: 'link';
  href?: string;
  execute?: (_: Injector) => Promise<void>;
}

export interface AppModule extends Module {
  type?: 'module' | undefined;
  route: string;
  routeParams?: NavigationExtras;
}
