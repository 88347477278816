import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal, UiCoreModule } from '@softline/ui-core';
import { MengenEingabeComponent } from '@softline/application';

@Component({
  selector: 'soft-etiketten-drucken-dialog',
  imports: [CommonModule, MengenEingabeComponent, UiCoreModule],
  templateUrl: './etiketten-drucken-dialog.component.html',
  styleUrls: ['./etiketten-drucken-dialog.component.scss'],
})
export class EtikettenDruckenDialogComponent implements Modal<number | 'NO'> {
  public anzahlEtiketten = signal(0);

  close!: (result: number | 'NO') => void;

  registerCloseHandler(handler: (result: number | 'NO') => void): void {
    this.close = handler;
  }
}
