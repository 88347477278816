import { computed, DestroyRef, inject, Signal } from '@angular/core';
import { MenuItem } from '@softline/application';
import { MenuItemService } from './services/menu-item.service';

export function menuItems(computation: () => MenuItem[]): Signal<MenuItem[]> {
  const destroyRef = inject(DestroyRef);
  const service = inject(MenuItemService);
  const menuItems = computed(computation);
  service.add(menuItems)
  destroyRef.onDestroy(() => service.remove(menuItems));
  return menuItems
}

export function menuItem(computation: () => MenuItem | null): Signal<MenuItem | null> {
  const destroyRef = inject(DestroyRef);
  const service = inject(MenuItemService);
  const menuItem = computed(computation);
  const menuItems = computed(() => {
    const item = menuItem();
    return item ? [item] : [];
  });
  service.add(menuItems)
  destroyRef.onDestroy(() => service.remove(menuItems));
  return menuItem
}
