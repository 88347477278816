import { Component, OnDestroy, OnInit } from "@angular/core";
import { Modal } from "@softline/ui-core";
import { Scan } from '.././data/scan';
import { DateService, Store } from '@softline/core';
import { ScanHistoryComponent } from "../components/scan-history/scan-history.component";
import { NgIf } from "@angular/common";
import { SOFTLINE_FEATURE_SCANNER, ScannerStore } from "@softline/application";
import { skip, Subscription } from "rxjs";

@Component({
    selector: 'soft-scan-dialog',
    imports: [ScanHistoryComponent, NgIf],
    templateUrl: './scan.dialog.html',
    styleUrls: ['./scan.dialog.scss']
})
export class ScanDialog implements OnInit, OnDestroy, Modal<Scan> {
  close!: (result: Scan) => void;

  constructor(private store: Store, public dateService: DateService) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  onSelectScan(scan: Scan): void {
    scan = {
      ...scan,
      timestamp: this.dateService.now()
    }
    this.close(scan);
  }

  registerCloseHandler(handler: (result: Scan) => void): void {
    this.close = handler;
  }
}
