import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { Theme } from '../data/theme';
import { Appearance } from '../data/appearance';
import * as SettingsStore from '../../settings/settings.store';
import { Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  SOFTLINE_CONFIG_FONT_SIZES,
  SOFTLINE_CONFIG_THEMES,
  SOFTLINE_SETTINGS_APPEARANCE,
} from '../appearance.shared';
import { SOFTLINE_FEATURE_SETTINGS } from '../../application.shared';
import { FontSize } from '../data/font-size';

@Component({
    selector: 'lib-appearance',
    templateUrl: './appearance.component.html',
    styleUrls: ['./appearance.component.scss'],
    standalone: false
})
export class AppearanceComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;
  form = new UntypedFormGroup({
    theme: new UntypedFormControl(undefined),
    fontSize: new UntypedFormControl(undefined),
  });

  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_THEMES) public themes: Theme[],
    @Inject(SOFTLINE_CONFIG_FONT_SIZES) public fontSizes: FontSize[]
  ) {}

  ngOnInit(): void {
    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<Appearance>(),
        SOFTLINE_SETTINGS_APPEARANCE
      )
      .subscribe((o) => {
        this.form.patchValue(o ?? {}, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async set(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.mutations.setKey(),
      { key: SOFTLINE_SETTINGS_APPEARANCE, values: this.form.value }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.save
    );
  }
}
