import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AbteilungUebersicht, PersonalUebersicht} from '../../types/abteilung';
import {UiCoreModule} from '@softline/ui-core';

@Component({
    selector: 'soft-mitarbeiter-card',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './mitarbeiter-card.component.html',
    styleUrls: ['./mitarbeiter-card.component.scss']
})
export class MitarbeiterCardComponent implements OnInit {

  @Input() mitarbeiter!: PersonalUebersicht;
  @Input() selected = false;
  @Input() mode: 'selection' | 'display' = 'display';
  @Output() tapped = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
