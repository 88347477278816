import { Refreshable, RefreshService } from '@softline/application';
import { DestroyRef, inject, Signal } from '@angular/core';

export interface RefreshOptions {
  canRefresh: Signal<boolean>
}

export function refresh(callback: () => Promise<void>, options?: RefreshOptions): void {
  const destroyRef = inject(DestroyRef);
  const service = inject(RefreshService);
  const refreshable: Refreshable = {
    refresh: callback,
    canRefresh: options?.canRefresh
  }
  service.add(refreshable);
  destroyRef.onDestroy(() => service.remove(refreshable));
}
