import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import {
  FieldValidationDirective,
  LocalizationStore,
  NumberInputComponent as UiCoreNIC,
  SOFTLINE_FEATURE_LOCALIZATION,
} from '@softline/ui-core';
import { map } from 'rxjs/operators';
import { NumberDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf } from '@angular/common';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';

import { ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import { ScannerModule } from '@softline/application';
import { ScanDefinitionPipe } from '../../../../pipes/scan-definition.pipe';

@Component({
  selector: 'soft-dynamic-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  imports: [
    NgIf,
    BooleanRulePipe,
    ReactiveFormsModule,
    FormControlPipe,
    UiCoreNIC,
    ScannerModule,
    ScanDefinitionPipe,
    FieldValidationDirective,
  ],
})
export class NumberInputComponent
  extends DynamicInputAtom<NumberDefinition>
  implements OnInit
{
  locale$ = this.store
    .observe(SOFTLINE_FEATURE_LOCALIZATION, LocalizationStore.getter.locale)
    .pipe(map((o) => o ?? this.localeId));

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    super();
  }

  ngOnInit(): void {}
}
