<h2 class="text-xl font-semibold">
  Kein Barcode erfasst
</h2>

<p class="text-sm font-normal text-light mt-1 mb-1">
  Es wurde noch kein Barcode für den Lieferantenlieferschein erfasst.
  Bitte scannen Sie einen Barcode oder geben Sie diesen manuell ein.
</p>

<div class="flex flex-col mb-2 mt-2">
  <soft-scan-button [labelType]="'code39'" (scan)="barcode.set($event.data)"></soft-scan-button>
  <soft-input class="mt-3" placeholder="Barcode scannen oder eingeben" [value]="barcode()" (valueChange)="barcode.set($event ?? null)"></soft-input>
</div>

<div class="flex items-center w-full gap-3 mt-2">
  <button class="soft-button h-14 w-full secondary" (click)="closeWithout()">Weiter ohne Barcode</button>
  <button class="soft-button h-14 w-full primary"
          (click)="closeWithBarcode(barcode())"
          [disabled]="!(!!barcode() && barcode() | isDefinedNotEmpty)">Erfassen & Weiter</button>
</div>
