import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Filter, PropertyFilter, Store } from "@softline/core";
import { FormControl } from "@angular/forms";
import { FilterConfig } from "../../types/filter-config";
import { I18nModule } from "@softline/ui-core";
import { FilterComponent } from "./filter/filter.component";

@Component({
    selector: 'soft-filter-list',
    imports: [CommonModule, I18nModule, FilterComponent],
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {

  form = new FormControl()

  @Input() filters: Partial<PropertyFilter>[] = [];
  @Output() filtersChange = new EventEmitter<Partial<PropertyFilter>[]>()
  @Input() config?: FilterConfig;
  @Input() values: object[] = [];

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  async onFilterChange(filter: Partial<Filter>, index: number): Promise<void> {
    const filters = [...this.filters];
    filters.splice(index, 1, filter);
    this.filters = filters;
    this.filtersChange.emit(filters);
  }

  async onAddFilter(): Promise<void> {
    const filters = [...this.filters, {}]
    this.filters = filters;
    this.filtersChange.emit(filters);
  }

  async onRemove(filter: Partial<PropertyFilter>): Promise<void> {
    const filters = [...this.filters];
    const index = filters.indexOf(filter);
    if(index > -1)
      filters.splice(index, 1);
    this.filters = filters;
    this.filtersChange.emit(filters);
  }

  async onRemoveAll(): Promise<void> {
    const filters = []
    this.filters = filters;
    this.filtersChange.emit(filters);
  }
}
