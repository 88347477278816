import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserFileService } from './file/services/browser-file.service';
import { FileService, Store } from "@softline/core";
import {
  GeolocationService,
  SOFTLINE_SERVICE_GEOLOCATION_LINK_FACTORY,
} from '@softline/application';
import { CameraService } from '@softline/core';
import { BrowserCameraService } from './camera/browser-camera.service';
import { TitleService } from './app/title.service';
import { BrowserGeolocationService } from './geolocation/browser-geolocation.service';
import { googleGeolocationLinkFactory } from './geolocation/google-geolocation-link.factory';
import { default as deLang } from '../i18n/de.json';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { BrowserPersistService } from './storage/browser-persist.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class BrowserModule {
  static forRoot(): ModuleWithProviders<BrowserRootModule> {
    return {
      ngModule: BrowserRootModule,
      providers: [
        TitleService,
        BrowserPersistService,
        { provide: FileService, useClass: BrowserFileService },
        { provide: CameraService, useClass: BrowserCameraService },
        { provide: GeolocationService, useClass: BrowserGeolocationService },
        {
          provide: SOFTLINE_SERVICE_GEOLOCATION_LINK_FACTORY,
          useValue: googleGeolocationLinkFactory,
        },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, BrowserModule],
  exports: [],
})
export class BrowserRootModule {
  constructor(private store: Store, private titleService: TitleService, private _: BrowserPersistService) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'browser',
      language: 'de',
      translations: deLang,
    });
  }
}
