import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Arteh } from '../../types/arteh';
import { BeweLf } from '../../types/bewe-lf';
import { ItemScanBewe, SearchStornoBewe, StornoBewe } from '../../types/return-note';
import { SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import { ReturnNoteBeweModalService } from '../../services/return-note-bewe-modal.service';
import { SOFTLINE_FEATURE_RETURN_NOTE } from '../../delivery-note.shared';
import { ReturnNoteStore } from '../../stores/return-note.store';
import { Einheit } from '@softline/application';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
    selector: 'lib-return-note-scan-item',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './return-note-scan-item.component.html',
    styleUrls: ['./return-note-scan-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReturnNoteScanItemComponent implements OnInit {

  hasError = false;

  // tslint:disable-next-line:variable-name
  private _arteh: Einheit | undefined = undefined;

  @Input() item!: ItemScanBewe;
  @Input() returnNoteId!: string;

  @Output() beweChanged = new EventEmitter<ItemScanBewe>();

  get artehBean(): Einheit | undefined {
    return this._arteh;
  }

  constructor(
    private store: Store,
    private modalService: ReturnNoteBeweModalService,
    private cdRef: ChangeDetectorRef,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) { }

  ngOnInit(): void {}

  async onEditBeweClick(bewe: ItemScanBewe): Promise<void> {
    await this.modalService.editBewe(this.returnNoteId, bewe);
  }
  setArteh(arteh: Einheit | undefined) {
    this._arteh = arteh;
  }

  async onDeleteBeweClick(bewe: ItemScanBewe | undefined): Promise<void> {
    if ( this.returnNoteId && bewe)
      await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.deleteBewe, {
        id: this.returnNoteId,
        bewe
      });
  }

  async onChange(bewe: ItemScanBewe, menge: number | null | undefined, arteh: Einheit | undefined): Promise<void> {
    if (this.hasError) {
      setTimeout(() => {
        this.hasError = false;
        this.cdRef.markForCheck();
      }, 4000);
      menge = null;
    }

    bewe.itemScanBean.menge = menge ?? 0;
    this.beweChanged.emit(bewe);
  }
}
