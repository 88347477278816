import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';
import { EnvironmentProviders, Provider } from '@angular/core';
import {SOFTLINE_WWS_UMSATZSTATISTIK_QUERY_UPDATE_STRATEGY} from '../umsatzstatistik.tokens';

export interface QueryUpdateStrategy<T> {
  umsatzebene: string;
  updateFn(value: T, query: FrontendUmsatzstatistikQuery): Promise<FrontendUmsatzstatistikQuery>;
  removeFn?(query: FrontendUmsatzstatistikQuery): Promise<FrontendUmsatzstatistikQuery>;
}

export type UpdateQueryStrategyFactory<T> = () => QueryUpdateStrategy<T>;

export function provideQueryUpdateStrategies(...strategies: UpdateQueryStrategyFactory<never>[]): Array<Provider | EnvironmentProviders> {
  return strategies.map(strategyFactory => {
    return {
      provide: SOFTLINE_WWS_UMSATZSTATISTIK_QUERY_UPDATE_STRATEGY,
      multi: true,
      useFactory: strategyFactory
    }
  });
}
