<div class="flex flex-row max-h-inherit flex-grow" [ngStyle]="styles()">
  <div class="w-full sm:w-1/3 flex flex-col flex-grow sm:border-r max-h-inherit overflow-auto"
       [ngClass]="{
            'hidden sm:flex': !!selected(),
          }"
       (touchstart)="preventScrolling($event, master)" #master>
    <ng-content select=".soft-master-view-title"></ng-content>
    @if (masterHeaderTemplate(); as  masterHeaderTemplate) {
      <div class="sticky top-0 shrink-0 left-0 right-0 bg-surface border-b">
        <ng-container *ngTemplateOutlet="masterHeaderTemplate"></ng-container>
      </div>
    }
    <ul class="flex flex-col flex-grow">
      @for (value of data(); track value; let i = $index) {
        <li
          class="flex flex-row justify-between hover:bg-hover items-center gap-2 px-2"
          [class.pointer]="selectable()"
          (click)="selectable() ? select(i): undefined"
          (dblclick)="masterDblClick.emit(value)"
          [ngClass]="{
            '!bg-selected': value === selected() && selectable(),
          }"
        >
          <ng-container *ngTemplateOutlet="masterTemplate(); context: { data: value }"></ng-container>
          @if (selectable()) {
            <soft-icon [name]="'fa-regular fa-chevron-right'"></soft-icon>
          }
        </li>
      }
    </ul>

    @if (masterBottomTemplate(); as  masterBottomTemplate) {
      <div class="sticky bottom-0 shrink-0 left-0 right-0 pr-2 pb-2 bg-background">
        <ng-container *ngTemplateOutlet="masterBottomTemplate"></ng-container>
      </div>
    }
  </div>

  <div class="flex flex-col flex-grow w-full sm:w-2/3 sm:px-2 max-h-inherit overflow-auto"
       [ngClass]="{
            'hidden sm:block': !selected()
          }"
       (touchstart)="preventScrolling($event, detail)" #detail>
    @if(selected(); as selected) {
      @if(detailHeaderVisible()) {
        <div class="flex flex-row items-center sm:hidden justify-between w-full gap-2 px-2 pointer hover:bg-hover"
                    (click)="select(-1)">
          <soft-icon [name]="'fa-regular fa-chevron-left'"></soft-icon>
          <ng-container *ngTemplateOutlet="masterTemplate(); context: { data: selected }"></ng-container>
        </div>
        <div class="w-full border-b my-2 sm:hidden"></div>
      }

      <div class="flex flex-col flex-grow">
        <ng-container *ngTemplateOutlet="detailTemplate(); context: { data: selected }"></ng-container>
      </div>

      @if (detailBottomTemplate(); as detailBottomTemplate) {
        <div class="sticky bottom-0 shrink-0 left-0 right-0 pb-2 bg-background">
          <ng-container *ngTemplateOutlet="detailBottomTemplate"></ng-container>
        </div>
      }
    }
  </div>
</div>
