import { ChangeDetectionStrategy, Component, computed, effect, inject, Inject, Injector, OnDestroy, OnInit, signal } from '@angular/core';
import {
  Command,
  FilterAndSortComponent,
  showRequestErrors,
  WithAddonHost,
  WithCommands,
  WithData,
  WithDynamicBackNavigation,
  WithFilter,
  WithQuery,
  WithRefresh,
  WithSort,
  WithTitle,
} from '@softline/application';
import { base64Decode, base64Encode, Dictionary } from '@softline/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import {
  DefinitionFilterPipe,
  DefinitionSortPipe,
  DefinitionStore,
  DynamicActionStore,
  DynamicModule,
  SOFTLINE_FEATURE_DEFINITIONS,
  SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
} from '@softline/dynamic';
import { SOFTLINE_DEFINITION_VOUCHER_LIST, SOFTLINE_DEFINITION_VOUCHER_QUERY } from '../../gesamtabfrage.api';
import { Beleg } from '../../types/beleg.model';
import { ActivatedRoute } from '@angular/router';
import { Belegart } from '../../types/belegart.model';
import { GesamtabfrageConfig, SOFTLINE_CONFIG_GESAMTABFRAGE, SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG } from '../../gesamtabfrage.shared';
import { UiCoreModule } from '@softline/ui-core';
import { CommonModule } from '@angular/common';
import { BelegStore } from '../../store/beleg.store';
import { BelegartStore } from '../../store';
import { DownloadCommand } from '../../commands/download.command';
import { SOFTLINE_CONST_DYNAMIC_ACTION_DOWNLOAD } from '@softapps/migel/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { EditDeliveryNoteCommand } from '../../commands/edit-delivery-note.command';
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from '@softline/auth';

@Component({
  selector: 'soft-gesamtabfrage-list',
  templateUrl: './gesamtabfrage-list.component.html',
  styleUrls: ['./gesamtabfrage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, DynamicModule, FilterAndSortComponent, DefinitionFilterPipe, DefinitionSortPipe],
})
export class GesamtabfrageListComponent
  extends WithAddonHost(
    WithDynamicBackNavigation(
      WithTitle(
        WithRefresh(
          WithCommands(
            WithData(
              SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
              WithQuery(
                SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
                WithSort(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, WithFilter(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG))
              )
            )
          )
        )
      )
    )
  )
  implements OnInit, OnDestroy
{
  route = inject(ActivatedRoute);
  belegartStore = inject(BelegartStore);

  belegartName = toSignal(this.route.paramMap.pipe(map((o) => o.get('type') ?? '')), { initialValue: '' });

  belegarten = this.belegartStore.collection.all;
  belegart = computed(() => this.belegartStore.collection.dict()[this.belegartName()]);
  belegart$ = toObservable(this.belegart);

  private hasDeliveryNoteEditPermission = this.store.signal(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    'SOFTAPPS.DELIVERY_NOTE_EDIT'
  );

  private actionSubscription?: Subscription;

  private clickedBeleg$ = new BehaviorSubject<Beleg | null>(null);

  private downloadCommand = new DownloadCommand(
    this.store,
    this.clickedBeleg$.pipe(map((o) => (o ? [o] : []))),
    this.uuid,
    this.config,
    this.injector
  );

  data = computed(() => {
    return this.sortService.sort(this.filterService.filter(this.all(), this.filter()), this.sort());
  });

  footer = computed(() => {
    return [
      this.data().reduce(
        (p, c: any) => ({
          ...p,
          gesamt: p.gesamt + (c.gesamt ?? 0),
          gesamtIncl: p.gesamtIncl + (c.gesamtIncl ?? 0),
        }),
        { konto: { name: 'Gesamt', number: '' }, gesamt: 0, gesamtIncl: 0 }
      ),
    ];
  });

  selected$ = this.store.observe(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.selected);
  selected = this.store.signal(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.selected);
  selectedLength$ = this.selected$.pipe(map((o) => o.length));
  selectedLength = computed(() => this.selected().length);

  encodedQuery = toSignal(this.route.queryParamMap.pipe(map((o) => o.get('query') ?? '')), { initialValue: '' });

  definitions = this.store.signal(SOFTLINE_FEATURE_DEFINITIONS, DefinitionStore.getters.definitions);
  definition = computed(() => {
    const definitions = this.definitions();
    return definitions[`${SOFTLINE_DEFINITION_VOUCHER_LIST}+${this.belegart()?.id}`] ?? definitions[SOFTLINE_DEFINITION_VOUCHER_LIST];
  });
  queryDefinition = computed(() => {
    const definitions = this.definitions();
    return definitions[`${SOFTLINE_DEFINITION_VOUCHER_QUERY}+${this.belegart()?.id}`] ?? definitions[SOFTLINE_DEFINITION_VOUCHER_QUERY];
  });

  override title = signal('#GESAMTABFRAGE.TITLE');
  override commands = computed(() => this.createCommands(this.belegart()));

  belegartEffect = effect(
    async () => {
      const belegart = this.belegart();
      if (!belegart) return;
      try {
        await this.store.dispatch(SOFTLINE_FEATURE_DEFINITIONS, DefinitionStore.actions.loadOnce, {
          name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}+${belegart.id}`,
        });
        await this.store.dispatch(SOFTLINE_FEATURE_DEFINITIONS, DefinitionStore.actions.loadOnce, {
          name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+${belegart.id}`,
        });
      } catch (e) {
        showRequestErrors(this.store, e);
      }
    },
    { allowSignalWrites: true }
  );

  encodedQueryEffect = effect(
    async () => {
      const encodedQuery = this.encodedQuery();
      if (!encodedQuery) return;

      try {
        const query = JSON.parse(base64Decode(encodedQuery));
        this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.query.setQuery, query);
        await this.query();
      } catch (e) {
        showRequestErrors(this.store, e);
      }
    },
    { allowSignalWrites: true }
  );

  constructor(
    private injector: Injector,
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_DEFINITIONS, DefinitionStore.actions.loadOnce, { name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}` });
      await this.store.dispatch(SOFTLINE_FEATURE_DEFINITIONS, DefinitionStore.actions.loadOnce, { name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}` });
    } catch (e) {
      showRequestErrors(this.store, e);
    }

    this.actionSubscription = this.store
      .observe(SOFTLINE_FEATURE_DYNAMIC_ACTIONS, DynamicActionStore.getters.action, SOFTLINE_CONST_DYNAMIC_ACTION_DOWNLOAD)
      .pipe(skip(1))
      .subscribe((o) => {
        this.download(o?.params.context as Beleg);
      });
  }

  override async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy();
    if (this.actionSubscription && !this.actionSubscription.closed) this.actionSubscription.unsubscribe();
    this.actionSubscription = undefined;
  }

  override async refresh(): Promise<void> {
    await this.query();
  }

  override async executeQuery(): Promise<void> {
    const query = this.queryValue() as any;
    const queryParams: Dictionary<any> = { ...query };
    queryParams['konto'] = query.customer?.nummer
      ? {
          id: query.customer.id,
          number: query.customer.nummer,
          name: query.customer.kurzbez,
        }
      : query.konto;
    await this.store.dispatch(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.actions.query, {
      query: queryParams,
      clear: true,
    });
  }

  onSelectedItemsChange(entities: Beleg[]): void {
    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.setSelected,
      entities.map((o) => o.id)
    );
  }

  async onChangeBelegart(belegart: Belegart): Promise<void> {
    const query = JSON.parse(base64Decode(this.encodedQuery()));
    query.belegart = belegart;
    await this.router.navigate(['/gesamtabfrage', belegart.id, 'list'], {
      queryParams: { query: base64Encode(JSON.stringify(query)) },
    });
  }

  async onQueryChange(query: unknown, type: Belegart): Promise<void> {
    // this.store.commit(SOFTLINE_FEATURE_BELEG, BelegStore.mutations.query.setQuery, query as any);
    await this.router.navigate(['/gesamtabfrage', type.id, 'list'], {
      queryParams: { query: base64Encode(JSON.stringify(query)) },
    });
  }

  protected createCommands(type: Belegart | undefined): Command[] {
    if (!type) return [];

    const commands: Command[] = [
      {
        name: type.kundenName,
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fa-regular fa-magnifying-glass',
        name: '#GESAMTABFRAGE.MENU.FIND',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage', type.id, 'find'],
      },
      {
        icon: 'fa-regular fa-list',
        name: '#GESAMTABFRAGE.MENU.RESULTS',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage', type.id, 'list'],
      },
      new DownloadCommand(this.store, this.selected$, this.uuid, this.config, this.injector),
    ];
    if (this.config.geschaeftsfall)
      commands.push({
        icon: 'fa-regular fa-briefcase',
        name: '#GESAMTABFRAGE.ACTIONS.BUSINESS_CASE',
        class: 'menu action-menu action-menu-top',
        canExecute: this.selectedLength$.pipe(map((o) => o === 1)),
        execute: async () => {
          const selected = this.store.get(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.selected);
          if (selected.length === 1) await this.router.navigate(['/gesamtabfrage', type.id, 'business-case', selected[0].id]);
        },
      });
    if (this.belegartName() === 'Lieferschein' && this.hasDeliveryNoteEditPermission()) {
      commands.push(new EditDeliveryNoteCommand(this.selected$, type, this.router, this.route));
    }

    return commands;
  }

  override getBackNavigationRoute(): string[] {
    const type = this.route.snapshot.paramMap.get('type') ?? '';
    if (!type) return ['/gesamtabfrage'];
    return ['/gesamtabfrage', type, 'find'];
  }

  async download(beleg: Beleg): Promise<void> {
    this.clickedBeleg$.next(beleg);

    try {
      await this.downloadCommand.execute();
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
