import { Component } from '@angular/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'lib-unsaved-changes-info-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './unsaved-changes-info-dialog.component.html',
    styleUrls: ['./unsaved-changes-info-dialog.component.scss']
})
export class UnsavedChangesInfoDialogComponent implements Modal<'save' | 'discard'> {

  close!: (result: ('save' | 'discard')) => void;

  constructor() {}

  registerCloseHandler(handler: (result: ('save' | 'discard')) => void) {
    this.close = handler;
  }
}


