import {
  Component,
  contentChild,
  contentChildren,
  effect,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-page-header',
    imports: [CommonModule],
    templateUrl: './page-header.component.html',
    styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  template = viewChild(TemplateRef);
  constructor() {}
}
