import { computed, InjectionToken, isSignal, ResourceStatus, Signal, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { of, throwError } from 'rxjs';
import { LoadCollectionParameters, ReadonlyCollectionRepository } from '../../repositories/abstraction';
import { CancelledError } from '../../types/errors';
import { cancelableRxResource } from '../../signals/cancelable-resource';

export interface RepositoryCollectionServiceOptions<T extends object> {
  idFunc?: (item: T) => string | number;
  defaultItems?: T[];
  skipInitialLoad?: boolean;
}

export class ReadonlyRepositoryCollectionService<T extends object> {

  protected params = signal<Signal<LoadCollectionParameters> | undefined>(undefined);
  protected resolvedParams: Signal<LoadCollectionParameters | 'NO_QUERY'> = computed(() => {
    const params = this.params();
    if(!params)
      return 'NO_QUERY';
    return params();
  })

  protected _resource = cancelableRxResource({
    skipInitialLoad: this.options.skipInitialLoad,
    request: () => ({
      params: this.resolvedParams(),
    }),
    loader: (param) => {
      if(param.request.params === 'NO_QUERY')
        return of([]);
      return this.repository.loadMany(param.request.params);
    }
  });

  resource = this._resource.asReadonly();
  loading = this._resource.isLoading;
  error = this._resource.error;

  all = computed(() => this._resource.value() ?? []);
  ids = computed(() => this.all().map(o => this.getId(o)));
  count = computed(() => this.all().length);
  dict = computed(() => {
    const dict: Record<string | number, T> = {};
    for (const item of this.all())
      dict[this.getId(item)] = item;
    return dict;
  });

  item = (id: string | number | Signal<string | number>): Signal<T | undefined> => {
    return computed(() => {
      if(isSignal(id))
        id = id();
      return this.all().find(o => id === this.getId(o))
    })
  }

  items = (ids: (string | number | Signal<string | number>)[]): Signal<T[]> => {
    return computed(() => {
      const resolvedIds = ids.map(id => isSignal(id) ? id() : id);
      return this.all().filter(o => resolvedIds.includes(this.getId(o)))
    })
  }

  constructor(protected repository: ReadonlyCollectionRepository<T, T[]>,
              private options: RepositoryCollectionServiceOptions<T> = {}) {
    if(options.defaultItems)
      this._resource.set(options.defaultItems);
  }

  reload(): void {
    this._resource.reload();
  }

  cancel(): void {
    this._resource.cancel();
  }

  protected getId = (item: T): string | number => {
    if(this.options.idFunc)
      return this.options.idFunc(item);
    return item['id'];
  }

  registerParams(params: Signal<LoadCollectionParameters>): void {
    this.params.set(params)
  }

  unregisterParams() {
    this.params.set(undefined)
  }
}

export const createReadonlyRepositoryCollectionServiceToken = <T extends object>(name: string, repository: ReadonlyCollectionRepository<T, T[]>, options?: RepositoryCollectionServiceOptions<T>) => {
  return new InjectionToken(`ReadonlyRepositoryCollectionService<T>:${name}`, {factory: () => {
      return new ReadonlyRepositoryCollectionService<T>(repository, options);
    }});
}
