import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Modal } from '../../data/modal';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../../i18n/i18n.module';
import { FileInputComponent } from '../../../../components/form/file-input/file-input.component';
import { FileSourceInput } from '../../../../components/form/file-input/file-source';

@Component({
    selector: 'soft-file-modal',
  imports: [CommonModule, UiCorePipesModule, I18nModule, FileInputComponent],
    templateUrl: './file-modal.component.html',
    styleUrls: ['./file-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileModalComponent implements Modal<File[] | null> {
  close!: (result: File[] | null) => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;
  showCancel?: boolean;

  sources?: FileSourceInput;
  accept?: string;
  inputView?: 'common' | 'dragAndDrop';
  selectionMode?: 'single' | 'multi';
  valueView?: 'grid' | 'list';
  autoSubmit = false;

  constructor() {}

  registerCloseHandler(handler: (result: File[] | null) => void): void {
    this.close = handler;
  }

  onSubmit(value: File[] | null): void {
    this.close(value);
  }

  onValueChange(value: File[] | null): void {
    if(value && value.length > 0)
      this.onSubmit(value);
  }
}
