import { TreeNode } from './types/tree-node';
import { TreeState } from './types/tree-state';
import {
  effect,
  isSignal,
  linkedSignal,
  resource,
  signal,
  Signal,
  untracked,
  WritableSignal,
} from '@angular/core';

export interface TreeViewRefParams<T> {
  initialState?: TreeState<T>;
  root: Signal<string | null | undefined> | string;
  pathLoader: (node: TreeNode<T>) => Promise<TreeNode<T>[]>;
  onRootPathLoaded?: (params: {
    node: TreeNode<T>;
    childNodes: TreeNode<T>[];
  }) => Promise<void>;
}

export interface TreeViewRef<T> {
  root: Signal<string | null | undefined>;
  expandedPaths: Signal<Set<string>>;
  loadingPaths: Signal<Set<string>>;
  state: Signal<TreeState<T>>;

  isExpanded(path: string): boolean;
  isLoading(path: string): boolean;

  collapse(node: TreeNode<T>): Promise<void>;
  expand(node: TreeNode<T>): Promise<void>;
  toggle(node: TreeNode<T>): Promise<void>;
}

export function treeView<T = unknown>(params: TreeViewRefParams<T>): TreeViewRef<T> {
  return new TreeViewRefImpl<T>(params);
}

export class TreeViewRefImpl<T> implements TreeViewRef<T> {

  // ------------------------------------
  // ------------- PRIVATE --------------
  // ------------------------------------

  readonly root = isSignal(this.params.root)
    ? this.params.root
    : signal(this.params.root);

  readonly #rootNode = linkedSignal<string | null | undefined, TreeNode<T> | null>({
    source: () => this.root(),
    computation: (value, previous) => {
      if (!value)
        return null;

      if (previous?.value && previous.value.path === value)
        return previous.value;

      return {
        path: value,
        name: value,
        type: 'expandable'
      } as TreeNode<T>
    }
  });

  readonly #rootState = resource({
    request: () => this.#rootNode() ?? undefined,
    loader: async ({ request: rootNode }) => {
      if (!rootNode) {
        return Promise.resolve(undefined);
      }

      const rootContent = await this.params.pathLoader(rootNode);

      return {
        nodes: {
          [rootNode.path]: rootContent ?? [],
          ...this.createPathMap(rootContent),
        }
      } as TreeState<T>
    },
  });

  readonly #state = linkedSignal<TreeState<T> | undefined, TreeState<T>>({
    source: () => this.#rootState.value(),
    computation: (rootState) => {
      return {
        ...(rootState ?? this.params.initialState ?? { nodes: {} })
      };
    },
  });

  readonly #expandedPaths: WritableSignal<Set<string>> = linkedSignal({
    source: () => ({
      root: isSignal(this.params.root) ? this.params.root() : this.params.root,
      state: this.#state(),
    }),
    computation: (current, previous) => {
      // Remove all paths which are not in the state anymore
      if (previous?.value) {
        const prevSet = previous.value as Set<string>;

        for (const path of Array.from(prevSet)) {
          if (current.state.nodes?.[path]) continue;

          prevSet.delete(path);
        }

        return new Set(prevSet);
      }

      return new Set<string>();
    },
  });

  // ------------------------------------
  // ------------- PUBLIC --------------
  // ------------------------------------

  readonly expandedPaths = this.#expandedPaths.asReadonly();
  readonly loadingPaths = signal(new Set<string>());
  readonly state = this.#state.asReadonly();

  constructor(private params: TreeViewRefParams<T>) {
    effect(async () => {
      const root = this.#rootState.value();
      const rootNode = untracked(() => this.#rootNode());

      if (!root || !rootNode)
        return;

      await untracked(async () => {
        const state = untracked(() => this.state());
        const rootContent = state.nodes[rootNode.path];

        await this.params?.onRootPathLoaded?.({
          node: rootNode,
          childNodes: rootContent
        });
      })
    });
  }

  isExpanded(path: string): boolean {
    return this.expandedPaths().has(path) && !!this.state().nodes[path];
  }

  isLoading(path: string): boolean {
    return this.loadingPaths().has(path) && !!this.state().nodes[path];
  }

  async toggle(node: TreeNode<T>): Promise<void> {
    if (this.isExpanded(node.path)) {
      await this.collapse(node);
    } else {
      await this.expand(node);
    }
  }

  async expand(node: TreeNode<T>): Promise<void> {
    if (node.type === 'leaf')
      return;

    this.setExpanded(node, true);

    try {
      this.setLoading(node, true);
      const children = await this.params.pathLoader(node);
      this.patchState(node, children);
    } catch (e) {
      console.error('[TreeViewRef] pathLoader error: ', e);
    } finally {
      this.setLoading(node, false);
    }
  }

  async collapse(node: TreeNode<T>): Promise<void> {
    if (node.type === 'leaf')
      return;

    this.setExpanded(node, false);
  }

  /*
   * ------------------------------------
   * ------------- INTERNAL -------------
   * ------------------------------------
   */
  private patchState(node: TreeNode<T>, children: TreeNode<T>[]): void {
    const currentState = this.state();

    this.#state.set({
      ...currentState,
      nodes: {
        ...currentState.nodes,
        [node.path]: children,
        ...this.createPathMap(children),
      },
    });
  }

  private setLoading(node: TreeNode<T>, loading: boolean) {
    this.loadingPaths.set(
      loading
        ? this.addToSet(this.loadingPaths(), node.path)
        : this.removeFromSet(this.loadingPaths(), node.path)
    );
  }

  private setExpanded(node: TreeNode<T>, expanded: boolean) {
    this.#expandedPaths.set(
      expanded
        ? this.addToSet(this.#expandedPaths(), node.path)
        : this.removeFromSet(this.#expandedPaths(), node.path)
    );
  }

  private addToSet<T>(set: Set<T>, value: T): Set<T> {
    const updatedSet = set.add(value);
    return new Set(updatedSet);
  }

  private removeFromSet<T>(set: Set<T>, value: T): Set<T> {
    set.delete(value);
    return new Set(set);
  }

  private createPathMap(nodes: TreeNode<T>[]): Record<string, TreeNode<T>[]> {
    const paths = {} as Record<string, TreeNode<T>[]>;

    for (const node of nodes.filter((o) => o.type !== 'leaf')) {
      paths[node.path] = [];
    }

    return paths;
  }
}
