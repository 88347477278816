import { Lagerplatz } from "./lagerplatz";

export interface Palette {
  id: number;
  sscc: string,
  menge: number;
  lagerplatz?: Lagerplatz;
  einheit: {
    id: number;
    arteh: string
  }
}
