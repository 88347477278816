<div class="mt-2 mx-3 w-full">
  <div class="signature-modal-input" #container>
    <soft-signature-pad
      #signaturepad
      [options]="{
        minWidth: 2,
        canvasWidth: container.offsetWidth - 32,
        canvasHeight: container.offsetHeight + 2
      }"
    ></soft-signature-pad>
  </div>
</div>
<div class="flex no-wrap mt-3">
  <button
    class="soft-button accent ok h-14 w-full"
    (click)="
      signaturepad.isEmpty()
        ? onSubmit(null)
        : onSubmit(signaturepad.toDataURL())
    "
  >
    {{ '#UI_CORE.DIALOGS.OK' | translate }}
  </button>
</div>
