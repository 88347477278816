import { Component, OnInit } from '@angular/core';
import { Modal } from '@softline/ui-core';
import { Observable } from 'rxjs';
import {
  PdfEditorTool,
  PdfEditorToolGroup,
  PdfEditorToolType,
} from '../../data/pdf-editor-tool';
import { SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT } from '../../pdf-editor.shared';
import { PdfEditorComponentStore } from '../../store/pdf-editor-component.store';
import { Store } from '@softline/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-pdf-editor-tool-customization',
    imports: [CommonModule],
    templateUrl: './pdf-editor-tool-customization.component.html',
    styleUrls: ['./pdf-editor-tool-customization.component.scss']
})
export class PdfEditorToolCustomizationComponent implements OnInit, Modal<any> {
  selectedTool$: Observable<PdfEditorTool | undefined> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.selectedTool
  );

  selectedToolGroup$: Observable<PdfEditorToolGroup | undefined> =
    this.store.observe(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.getters.selectedToolGroup
    );

  pdfEditorToolType = PdfEditorToolType;

  close!: (result: any) => void;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  onOptionChange(
    selectedTool: PdfEditorTool,
    optionName: string,
    optionValue: any
  ): void {
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.updateToolOptions,
      {
        tool: selectedTool,
        options: {
          [optionName]: optionValue,
        },
      }
    );
  }
}
