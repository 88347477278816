import { inject, Injectable, Injector, isSignal, signal, Type } from '@angular/core';
import { SOFTLINE_SERVICE_UUID } from '@softline/core';
import {
  ModalConfig,
  Dismissed,
  InternalModalConfig,
  ResolvableModalConfig
} from './types/modal-config';
import { Modal2, ResolvableModal } from './abstraction/modal';

@Injectable({providedIn: 'root'})
export class ModalService {
  private uuid = inject(SOFTLINE_SERVICE_UUID);
  private injector = inject(Injector);

  configs = signal<InternalModalConfig<any, any>[]>([])

  async open<TModal extends Modal2>(config: ModalConfig<TModal>): Promise<Dismissed>;
  async open<TModal extends ResolvableModal<TResult>, TResult = Dismissed>(config: ResolvableModalConfig<TModal, TResult>): Promise<TResult | Dismissed>;
  async open<TModal extends Modal2>(component: Type<TModal>): Promise<Dismissed>;
  async open<TModal extends ResolvableModal<TResult>, TResult= Dismissed>(component: Type<TModal>): Promise<TResult | Dismissed>;
  async open<TModal extends Modal2, TResult>(params: ModalConfig<TModal> | Type<TModal>): Promise<TResult | Dismissed> {
    return new Promise<TResult | Dismissed>((resolve, reject) => {
      if(params instanceof Type)
        params = {component: params, dismiss: true}
      const title = params.title ? (isSignal(params.title) ? params.title : signal(params.title)) : signal(null);
      const subtitle = params.subtitle ? (isSignal(params.subtitle) ? params.subtitle : signal(params.subtitle)) : signal(null);
      const text = params.text ? (isSignal(params.text) ? params.text : signal(params.text)) : signal(null);
      const template = params.template ? (isSignal(params.template) ? params.template : signal(params.template)) : signal(null);
      const templateContext = params.templateContext ? (isSignal(params.templateContext) ? params.templateContext : signal(params.templateContext)) : signal(null);
      const config: InternalModalConfig<TModal, TResult> = {
        ...params,
        id: params.id ?? this.uuid(),
        title,
        subtitle,
        text,
        template,
        templateContext,
        callback: (o) => resolve(o),
        onDismiss: () => resolve('DISMISSED'),
        priority: params.priority ?? Number.NEGATIVE_INFINITY,
        injector: params.injector ?? this.injector
      };
      this.configs.set([...this.configs(), config])
    });
  }

  close(id: string): void {
    const config = this.configs().find((o) => o.id === id);
    if(!config)
      throw new Error(`[ModalService] remove: no modal with id ${id} found. (Has it already been closed?)`);
    this.configs.set(this.configs().filter((o) => o.id !== id))
  }

  resolve(id: string, result: unknown): void {
    const config = this.configs().find((o) => o.id === id);
    if(!config)
      throw new Error(`[ModalService] resolve: no modal with id ${id} found. (Has it already been closed?)`);
    if(!config.callback)
      throw new Error(`[ModalService] resolve: no callback provided for modal with id ${id}.`);

    config.callback(result);
    this.configs.set(this.configs().filter((o) => o.id !== id))
  }

  dismiss(id: string): void {
    const config = this.configs().find((o) => o.id === id);
    if(!config)
      throw new Error(`[ModalService] dismiss: no modal with id ${id} found. (Has it already been closed?)`);
    if(!config.onDismiss)
      throw new Error(`[ModalService] dismiss: no dismiss provided for modal with id ${id}.`);

    config.onDismiss();
    this.configs.set(this.configs().filter((o) => o.id !== id))
  }

/*  constructor() { }

  async open<TModal extends Modal<TResult> & TInputs, TResult, TInputs extends object = {}>(config: CommonModalConfig<TModal, TResult, TInputs>): Promise<TResult | Dismissed>;
  async open<TModal extends Modal<TResult> & TInputs, TResult, TInputs extends object = {}>(component: Type<TModal & Modal<TResult>>): Promise<TResult | Dismissed>;
  async open<TModal extends Modal<TResult> & TInputs, TResult, TInputs extends object = {}>(params: CommonModalConfig<TModal, TResult, TInputs> | Type<TModal>): Promise<TResult | Dismissed> {
    return new Promise<TResult | Dismissed>((resolve, reject) => {
      if(!isCommonModalConfig(params))
        params = {component: params, dismiss: true}

      const config: ModalConfig<TModal, TResult, TInputs> = {
        ...params,
        id: params.id ?? this.uuid(),
        callback: (o) => resolve(o),
        onDismiss: () => resolve('DISMISSED'),
        priority: params.priority ?? Number.NEGATIVE_INFINITY,
        injector: params.injector ?? this.injector
      };
      this.configs.set([...this.configs(), config])
    });
  }

  async ask(config: QuestionConfig): Promise<QuestionResult | Dismissed>
  async ask(question: string, title?: string): Promise<QuestionResult | Dismissed>;
  async ask(config: string | QuestionConfig, title?: string): Promise<QuestionResult | Dismissed> {
    if(typeof config === 'string')
      config = {question: config, title, dismiss: true};
    if (config.dismiss === true)
      config.dismiss = { backdrop: true, escape: true };

    const modalConfig: CommonModalConfig<QuestionModalComponent, QuestionResult> = {
      component: QuestionModalComponent,
      dismiss: config.dismiss,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        content: config.content,
        question: config.question,
        showCancel: config.showCancelAction ?? false,
        params: config.params,
      },
    };
    return await this.open(modalConfig);
  }

  async delete(config: DeleteConfig): Promise<DeleteResult | Dismissed>
  async delete(question: string, title?: string): Promise<DeleteResult | Dismissed>;
  async delete(config: string | DeleteConfig, title?: string): Promise<DeleteResult | Dismissed> {
    if(typeof config === 'string')
      config = {question: config, title, dismiss: true};
    if (config.dismiss === true)
      config.dismiss = { backdrop: true, escape: true };

    const modalConfig: CommonModalConfig<DeleteModalComponent, DeleteResult> = {
      component: DeleteModalComponent,
      dismiss: config.dismiss,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        content: config.content,
        question: config.question,
        params: config.params,
      }
    }
    return await this.open(modalConfig);
  }

  async notify(config: NotificationConfig): Promise<NotificationResult | Dismissed>
  async notify(message: string, title?: string): Promise<NotificationResult | Dismissed>;
  async notify(config: string | NotificationConfig, title?: string): Promise<NotificationResult | Dismissed> {
    if(typeof config === 'string')
      config = {text: config, title, dismiss: true};
    if (config.dismiss === true)
      config.dismiss = { backdrop: true, escape: true };

    const modalConfig: CommonModalConfig<NotificationModalComponent, NotificationResult> = {
      component: NotificationModalComponent,
      dismiss: config.dismiss,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        text: config.text,
        showCancel: config.showCancelAction ?? false,
        params: config.params,
      }
    }
    return await this.open(modalConfig);
  }

  async choose<T>(config: OptionModalConfig<T>): Promise<T | Dismissed>
  async choose<T>(options: ModalOption<T>[], title?: string): Promise<T | Dismissed>;
  async choose<T>(config: ModalOption<T>[] | OptionModalConfig<T>, title?: string): Promise<T | Dismissed> {
    if(Array.isArray(config))
      config = {options: config, title, dismiss: true};
    if (config.dismiss === true)
      config.dismiss = { backdrop: true, escape: true, button: true };

    const modalConfig: CommonModalConfig<OptionModalComponent<T>, T> = {
      component: OptionModalComponent,
      dismiss: config.dismiss,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        content: config.content,
        options: config.options,
        params: config.params,
      }
    }

    return await this.open(modalConfig);
  }


  async gallery(config: GalleryModalConfig): Promise<Dismissed>
  async gallery(images: GalleryImage[], title?: string, index?: number): Promise<Dismissed>;
  async gallery(config: GalleryImage[] | GalleryModalConfig, title?: string, index?: number): Promise<Dismissed> {
    if(Array.isArray(config))
      config = {images: config, title, index: index ?? 0};
    const modalConfig: CommonModalConfig<GalleryModalComponent, Dismissed> = {
      component: GalleryModalComponent,
      dismiss: true,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        content: config.content,
        images: config.images,
        selectedIndex: config.index,
        params: config.params,
      }
    }
    return await this.open(modalConfig);
  }

  async template<T>(config: TemplateModalConfig): Promise<T | Dismissed>
  async template<T>(template: TemplateRef<T>, title?: string): Promise<T | Dismissed>;
  async template<T>(config: TemplateRef<any> | TemplateModalConfig, title?: string): Promise<T | Dismissed> {
    if(config instanceof TemplateRef)
      config = {template: config, title, dismiss: true};

    const modalConfig: CommonModalConfig<TemplateModalComponent<T>, T | Dismissed> = {
      component: TemplateModalComponent<T>,
      dismiss: true,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        template: config.template,
        params: config.params,
      }
    }
    return await this.open(modalConfig);
  }

  async password<T>(config: PasswordConfig): Promise<T | Dismissed>
  async password<T>(title?: string): Promise<T | Dismissed>;
  async password<T>(config: string | undefined | PasswordConfig): Promise<string | Dismissed> {
    if(typeof config === 'string')
      config = {title: config, dismiss: true
    };
    if(config === undefined)
      config = {title: 'Passwort', dismiss: true};

    const modalConfig: CommonModalConfig<PasswordModalComponent, string | Dismissed> = {
      component: PasswordModalComponent,
      dismiss: true,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        content: config?.content,
        params: config.params,
      }
    }
    return await this.open(modalConfig);
  }

  async sign(config?: SignatureModalConfig): Promise<Blob | null | Dismissed> {
    const modalConfig: CommonModalConfig<SignatureModalComponent, Blob | null | Dismissed> = {
      component: SignatureModalComponent,
      dismiss: true,
      priority: Number.POSITIVE_INFINITY,
      class: config?.class,
      data: {
        title: config?.title,
        subtitle: config?.subtitle,
        content: config?.content,
        params: config?.params,
      }
    }
    return await this.open(modalConfig);
  }

  async draw(config: DrawModalConfig): Promise<Blob | null | Dismissed>
  async draw(drawing: Blob | null, title?: string): Promise<Blob | null | Dismissed>;
  async draw(config: Blob | null | DrawModalConfig, title?: string): Promise<Blob | null | Dismissed> {
    if(config instanceof Blob || config === null)
      config = {drawing: config, title, dismiss: true};

    const modalConfig: CommonModalConfig<DrawModalComponent, Blob | null | Dismissed> = {
      component: DrawModalComponent,
      dismiss: true,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        content: config.content,
        params: config.params,
        drawing: config.drawing
          ? await blobToDataURL(config.drawing)
          : undefined,
      }
    }
    return await this.open(modalConfig);
  }

  async date(config: DateModalConfig): Promise<string | Dismissed>
  async date(value?: string | null, title?: string): Promise<string | Dismissed>;
  async date(config?: string | null | DateModalConfig, title?: string): Promise<string | null | Dismissed> {
    if(!config || typeof config === 'string')
      config = {value: config, title, dismiss: true};

    const modalConfig: CommonModalConfig<DatePickerModalComponent, string | null> = {
      component: DatePickerModalComponent,
      dismiss: config?.dismiss,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        value: config.value ?? null
      }
    }

    return await this.open(modalConfig);
  }

  async dateRange(config: DateRangeModalConfig): Promise<DateRange | Dismissed>
  async dateRange(value?: DateRange | null, title?: string): Promise<DateRange | Dismissed>;
  async dateRange(config?: DateRange | null | DateRangeModalConfig, title?: string): Promise<DateRange | Dismissed> {
    if(!config || isDateRange(config))
      config = {value: config ?? {from: null, to: null}, title, dismiss: true};

    const modalConfig: CommonModalConfig<DateRangePickerModalComponent, DateRange> = {
      component: DateRangePickerModalComponent,
      dismiss: config.dismiss,
      priority: Number.POSITIVE_INFINITY,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        value: config.value ?? null,
        startField: config.startField ?? 'from'
      }
    }
    return await this.open(modalConfig);
  }

  async calculate(config: CalculatorModalConfig): Promise<number | Dismissed>
  async calculate(equation?: string, title?: string): Promise<number | Dismissed>;
  async calculate(config?: string | CalculatorModalConfig, title?: string): Promise<number | Dismissed> {
    if(!config || typeof config === 'string')
      config = {value: config, title, dismiss: true};

    const modalConfig: CommonModalConfig<CalculatorModalComponent, number> = {
      component: CalculatorModalComponent,
      dismiss: config.dismiss,
      priority: 1000,
      class: config.class,
      data: {
        title: config?.title,
        subtitle: config?.subtitle,
        value: config?.value ?? ''
      }
    }
    return await this.open(modalConfig);
  }

  async file(config: FileModalConfig): Promise<File[] | null | Dismissed> {
    const modalConfig: CommonModalConfig<FileModalComponent, File[] | null> = {
      component: FileModalComponent,
      dismiss: config.dismiss,
      priority: 1000,
      class: config.class,
      data: {
        title: config.title,
        subtitle: config.subtitle,
        content: config.content,
        params: config.params,
        sources: config.sources,
        accept: config.accept,
        selectionMode: config.selectionMode,
        inputView: config.inputView,
        valueView: config.valueView,
        autoSubmit: config.autoSubmit
      }
    }
    return await this.open(modalConfig);
  }

  close(id: string): void {
    const config = this.configs().find((o) => o.id === id);
    if(!config)
      throw new Error(`[ModalService] remove: no modal with id ${id} found. (Has it already been closed?)`);
    this.configs.set(this.configs().filter((o) => o.id !== id))
  }

  resolve(id: string, result: unknown): void {
    const config = this.configs().find((o) => o.id === id);
    if(!config)
      throw new Error(`[ModalService] resolve: no modal with id ${id} found. (Has it already been closed?)`);
    if(!config.callback)
      throw new Error(`[ModalService] resolve: no callback provided for modal with id ${id}.`);

    config.callback(result);
    this.configs.set(this.configs().filter((o) => o.id !== id))
  }

  dismiss(id: string): void {
    const config = this.configs().find((o) => o.id === id);
    if(!config)
      throw new Error(`[ModalService] dismiss: no modal with id ${id} found. (Has it already been closed?)`);
    if(!config.onDismiss)
      throw new Error(`[ModalService] dismiss: no dismiss provided for modal with id ${id}.`);

    config.onDismiss();
    this.configs.set(this.configs().filter((o) => o.id !== id))
  }*/
}
