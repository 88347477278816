import { Signal } from '@angular/core';
import { UrlTree } from '@angular/router';

export interface NavigateBackOptions {
  savepoint?: UrlTree;
  parent?: BackNavigable
}

export interface BackNavigable {
  canNavigateBack?: Signal<boolean>;
  navigateBack(options?: NavigateBackOptions): Promise<void>;
}

export function isBackNavigable(program: any): program is BackNavigable {
  return (program as BackNavigable)?.navigateBack !== undefined;
}
