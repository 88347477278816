import { Component, HostListener, TemplateRef } from '@angular/core';
import { Modal } from '../../data/modal';
import { QuestionResult } from '../../../types/question-result';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../../i18n/i18n.module';

@Component({
    selector: 'soft-question-modal',
    imports: [CommonModule, UiCorePipesModule, I18nModule],
    templateUrl: './question-modal.component.html',
    styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent implements Modal<QuestionResult> {
  private close!: (result: QuestionResult) => void;

  title: string | undefined;
  content?: TemplateRef<any>;
  question = '';
  showCancel = false;
  params?: object;

  registerCloseHandler(handler: (result: QuestionResult) => void): void {
    this.close = handler;
  }


  @HostListener('window:keydown.enter')
  onClick(result: QuestionResult = 'YES'): void {
    this.close(result);
  }
}
