import { Injectable } from '@angular/core';
import { Store } from '@softline/core';
import {
  fileToBlob,
  HttpResourceLocation,
  HttpService,
  RequestEvent,
} from '@softline/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  SOFTLINE_API_ARCHIVE_DOWNLOAD,
  SOFTLINE_API_ARCHIVE_READ,
  SOFTLINE_API_ARCHIVE_UPLOAD,
} from '../archive.api';
import { Archive, ArchiveFile, ArchiveUpload } from '../data/archive';
import {
  ConnectionHttpService,
  ResponseResult,
  ApiResourceLocation,
} from '@softline/core';

@Injectable()
export class ArchiveService {
  constructor(private store: Store, private service: ConnectionHttpService) {}

  getArchiveKey(location: ApiResourceLocation): Observable<string> {
    console.log('[Softline Application] archive: create key');
    return this.service.get<ResponseResult<string>>(location).pipe(
      map((o) => o.result),
      tap((o) =>
        console.log('[Softline Application] archive: create key success', o)
      ),
      catchError((e) => {
        console.log('[Softline Application] archive: create key failed', e);
        return throwError(e);
      })
    );
  }

  read(archive: Archive): Observable<ArchiveFile[]> {
    const location: ApiResourceLocation = {
      path: SOFTLINE_API_ARCHIVE_READ,
      pathParams: { key: archive.key },
    };
    console.log('[Softline Application] archive: read', archive);
    return this.service.get<ResponseResult<ArchiveFile[]>>(location).pipe(
      tap((o) =>
        console.log('[Softline Application] archive: read success', archive)
      ),
      map((o) => o.result),
      catchError((e) => {
        console.log('[Softline Application] archive: read failed', e, archive);
        return throwError(e);
      })
    );
  }

  upload(upload: ArchiveUpload): Observable<RequestEvent<ArchiveFile>> {
    const location: ApiResourceLocation = {
      path: SOFTLINE_API_ARCHIVE_UPLOAD,
    };
    console.log('[Softline Application] archive: upload');
    const blobs$ = from(this.convertManyToBlob(upload.files));
    if (typeof upload.archiveKey === 'string') {
      return blobs$.pipe(
        mergeMap((files) => {
          return this.service.upload<any, ArchiveFile>(location, {
            ...(upload.fields ?? {}),
            archiveKey: upload.archiveKey,
            files,
            filename: upload.files[0].name,
          });
        }),
        tap((o) =>
          console.log('[Softline Application] archive: upload success')
        ),
        catchError((e) => {
          console.log('[Softline Application] archive: upload failed', e);
          return throwError(e);
        })
      );
    } else {
      return combineLatest([
        this.getArchiveKey(upload.archiveKey),
        blobs$,
      ]).pipe(
        filter(([key, files]: any) => !!key && !!files),
        mergeMap(([key, files]) => {
          return this.service.upload<any, ArchiveFile>(location, {
            ...(upload.fields ?? {}),
            archiveKey: key,
            files,
            filename: upload.files[0].name,
          });
        }),
        tap((o) =>
          console.log('[Softline Application] archive: upload success')
        ),
        catchError((e) => {
          console.log('[Softline Application] archive: upload failed', e);
          return throwError(e);
        })
      );
    }
  }

  download(file: ArchiveFile): Observable<RequestEvent<Blob | ArrayBuffer>> {
    const location: ApiResourceLocation = {
      path: SOFTLINE_API_ARCHIVE_DOWNLOAD,
      pathParams: { fileKey: file.fileKey },
    };
    return this.service.download<ArchiveUpload>(location);
  }

  private async convertManyToBlob(files: File[]): Promise<Blob[]> {
    const blobs: Blob[] = [];
    for (const file of files) blobs.push(await fileToBlob(file));
    return blobs;
  }
}
