import { Component, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent, IsDefinedNotEmptyPipe, ResolvableModal } from '@softline/ui-core';
import { ScanButtonComponent } from '@softline/application';

export type BarcodeMissingModalStatus = 'IGNORE' | 'CANCEL' | 'MANUAL' | 'SCAN';

export interface IBarcodeMissingModalResult {
  status: BarcodeMissingModalStatus;
  barcode?: string;
}

export interface IgnoreBarcodeMissingModalResult extends IBarcodeMissingModalResult {
  status: 'IGNORE';
  barcode: undefined;
}

export interface CancelBarcodeMissingModalResult extends IBarcodeMissingModalResult {
  status: 'CANCEL';
  barcode: undefined;
}

export interface ManualBarcodeMissingModalResult extends IBarcodeMissingModalResult {
  status: 'MANUAL';
  barcode: string;
}

export interface ScanBarcodeMissingModalResult extends IBarcodeMissingModalResult {
  status: 'SCAN';
  barcode: string;
}

export type BarcodeMissingModalResult = IgnoreBarcodeMissingModalResult
  | CancelBarcodeMissingModalResult
  | ManualBarcodeMissingModalResult
  | ScanBarcodeMissingModalResult;

@Component({
  selector: 'soft-barcode-missing-modal',
  imports: [CommonModule, InputComponent, ScanButtonComponent, IsDefinedNotEmptyPipe],
  templateUrl: './barcode-missing-modal.component.html',
  styleUrl: './barcode-missing-modal.component.scss',
})
export class BarcodeMissingModalComponent
  extends ResolvableModal<BarcodeMissingModalResult>
{
  static MODAL_ID = 'BARCODE_MISSING_MODAL';

  readonly barcode = signal<string | null>(null);

  closeWithout(): void {
    this.resolve({
      status: 'IGNORE',
      barcode: undefined
    });
  }

  closeWithBarcode(barcode: string | null): void {
    if (!barcode)
      return;

    this.resolve({
      status: 'MANUAL',
      barcode
    })
  }
}
