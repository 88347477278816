import {
  Component,
  computed,
  effect,
  HostListener,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  output,
  runInInjectionContext,
  signal,
  viewChild,
} from '@angular/core';
import {
  FormControlStatus,
  FormGroupDirective,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { Dictionary } from '@softline/core';
import { FormDefinition, isControlDefinition } from '../../data/definitions';
import { distinct, of, Subscription } from 'rxjs';
import { DateParser } from '@softline/ui-core';
import { DynamicFormCreator } from '../../services/dynamic-form-creator';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs/operators';
import { NgClass, NgIf } from '@angular/common';
import { GroupInputComponent } from '../atoms/inputs/group/group-input.component';
import { ClassRulePipe } from '../../pipes/class-rule.pipe';
import { BooleanRulePipe } from '../../pipes/boolean-rule.pipe';
import { DynamicInputFieldDirective } from '../../directives/dynamic-input-field.directive';

@Component({
  selector: 'soft-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  imports: [
    ReactiveFormsModule,
    NgClass,
    GroupInputComponent,
    ClassRulePipe,
    BooleanRulePipe,
    DynamicInputFieldDirective,
    NgIf,
  ],
})
export class DynamicFormComponent<T> implements OnInit, OnDestroy {
  private subscription?: Subscription;

  private dateParser = inject(DateParser);
  private injector = inject(Injector);

  controlStatus = signal<FormControlStatus | null>(null, {
    equal: () => false,
  });

  _definition: FormDefinition | null = null;
  definition = input<FormDefinition | null>(null);

  form = computed(() => {
    const definition = this.definition();
    if (!definition) return new UntypedFormGroup({});
    const creator = new DynamicFormCreator(this.dateParser, this.injector);
    return creator.createForm(definition);
  });

  valid = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.valid;
  });

  invalid = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.invalid;
  });

  touched = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.touched;
  });

  untouched = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.untouched;
  });

  dirty = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.dirty;
  });

  pristine = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.pristine;
  });

  pending = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.pending;
  });

  value = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.value as Partial<T>;
  });

  status = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.status;
  });

  errors = computed(() => {
    const form = this.form();
    const status = this.controlStatus();
    return form.controls['objekt']?.errors;
  });

  formEffect = effect(() => {
    const form = this.form();
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = form.valueChanges.subscribe((_) => {
      this._value = form.value;
      this.valueChange.emit(form.value);
    });
  });

  private _value: T | null = null;
  valueInput = input<T | null>(null, { alias: 'value' });

  valueEffect = effect(() => {
    const value = this.valueInput();
    const definition = this.definition();
    const form = this.form();
    if (value === this._value) return;

    if (!definition) form.reset(null);
    else if (value) form.patchValue(value);
    else {
      const defaultValue = this.getDefaultValue(definition);
      form.reset(defaultValue);
    }
  });

  valueChange = output<Partial<T> | null>();
  formSubmit = output<Partial<T> | null>();
  formReset = output<Partial<T> | null>();

  formRef = viewChild<FormGroupDirective>('formRef');

  constructor() {}

  ngOnInit(): void {
    runInInjectionContext(this.injector, () => {
      toObservable(this.form)
        .pipe(
          distinct(),
          switchMap((o) => o?.statusChanges ?? of(null)),
          takeUntilDestroyed()
        )
        .subscribe((o) => {
          this.controlStatus.set(o);
        });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }

  onNativeSubmit(event: Event): void {
    event.stopPropagation();
  }

  submit(): void {
    const formRef = this.formRef();
    if (!formRef || this.form().disabled) return;
    formRef.onSubmit(new Event('submit'));
  }

  @HostListener('window:keydown.enter', ['$event'])
  onSubmit(): void {
    if (this.form().disabled) return;
    this._value = this.form().value;
    this.formSubmit.emit(this.form().value);
  }

  reset(): void {
    const formRef = this.formRef();
    if (!formRef || this.form().disabled) return;
    formRef.onReset();
  }

  onReset(event?: Event): void {
    const definition = this.definition();
    if (!definition) return;
    const defaultValue = this.getDefaultValue(definition);
    this.form().reset(defaultValue);
    this.formReset.emit(defaultValue);
    if (event) event.stopPropagation();
  }

  private getDefaultValue(
    definition: FormDefinition | null
  ): Partial<T> | null {
    const defaultValue: Dictionary<any> = {};
    if (!definition) return null;

    for (const propertyDefinition of definition.definitions.filter(
      isControlDefinition
    ))
      defaultValue[propertyDefinition.name] =
        propertyDefinition.default ??
        (propertyDefinition.name === 'boolean' ? false : undefined);
    return defaultValue as T;
  }
}
