import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-detail-page',
    imports: [CommonModule],
    templateUrl: './detail-page.component.html',
    styleUrl: './detail-page.component.scss'
})
export class DetailPageComponent {}
