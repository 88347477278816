<div class="flex flex-row w-full border border-primary border-solid rounded-md shadow h-20">
  <button class="soft-button primary !text-4xl w-1/4 rounded-l"
          [disabled]="disabled || (value <= 0 && !negativeValuesValid)"
          (click)="decrement(input)"> - </button>
  <div class="flex flex-col w-1/2 justify-center items-center bg-surface" [ngClass]="inputClass ? inputClass : ''" [ngStyle]="inputStyle">
    <!-- <input type="tel"
           #input
           class="text-xl !border-none w-full text-center !appearance-none font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
           (keydown)="$event.stopPropagation(); $event.preventDefault(); onKeyDown($event)"
           [value]="float ? (value | number:'1.0-10') : (value | number:'1.0-0')"> -->
    <input type="tel"
           #input
           (focus)="onFocus(input)"
           [ngClass]="inputFieldClass ? inputFieldClass : ''"
           class="text-xl !select-none !border-none w-full text-center !appearance-none font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
           (input)="onInput($event, input?.value ?? null)"
           (blur)="onBlur(input)"
           (contextmenu)="$event.preventDefault();"
           [value]="value === 0 ? '0' : (float ? (value | mengenEingabeFormat:10) : (value | mengenEingabeFormat:0))">
    <div *ngIf="einheit" class="font-semibold color-light">{{einheit}}</div>
  </div>

  <button class="soft-button primary !text-4xl w-1/4 rounded-r"
          [disabled]="disabled || (!!value && (value >= (maxValue ?? 99999999)))"
          (click)="increment()"> + </button>
</div>
