import {
  Component,
  computed,
  contentChild,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
  viewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MbscDatepickerModule, localeDe, MbscDatepicker } from '@mobiscroll/angular';
import { I18nModule } from '../../../i18n/i18n.module';
import { DateService, equals, Store } from '@softline/core';
import { DateParser } from '../../../services/date.parser';
import moment from 'moment/moment';
import { SOFTLINE_FEATURE_MODAL } from '../../../modal/legacy/modal.shared';
import * as ModalStore from '../../../modal/legacy/modal.store';
import { DateRangeFieldInput } from '../../form/date-range-input/date-range-field-input';
import { DateRange } from '../../../modal/legacy/data/date-range-modal-config';

@Component({
    selector: 'soft-date-range-picker',
    imports: [CommonModule, I18nModule, MbscDatepickerModule],
    templateUrl: './date-range-picker.component.html',
    styleUrl: './date-range-picker.component.scss'
})
export class DateRangePickerComponent {
  public localeDe = localeDe;

  dateService = inject(DateService);
  dateParser = inject<DateParser<string>>(DateParser);
  store = inject(Store);

  value = signal<DateRange | null>(null);
  valueChange = output<DateRange | null>();
  valueInput = input<DateRange | null>(null, {alias: 'value'});
  valueEffect = effect(() => {
    this.value.set(this.valueInput());
  })

  valueArray = computed(() => {
    const value = this.value();
    return value ? [value.from, value.to] : [null, null];
  });
  selectShortcut = output<DateRange>();

  startField = input<'from' | 'to'>('from');

  datePicker = viewChild<MbscDatepicker>('datepicker');
  datePickerEffect = effect(() => {
    const datePicker = this.datePicker();
    const value = untracked(() => this.value());
    const startField = untracked(() => this.startField());
    if(datePicker && value?.from && startField === 'to') {
      datePicker._activeSelect = 'end';
    }
  })

  readonly shortcuts: { value: DateRange; label: string }[] = [
    {
      label: 'Gestern',
      value: {from: 'h-1', to: 'h-1'}
    },    {
      label: 'Diese Woche',
      value: {from: 'mo', to: 'h'}
    },
    {
      label: 'Dieses Monat',
      value: {from: 'ma', to: 'h'}
    },
    {
      label: 'Letzte Woche',
      value: {from: 'mo-1', to: 'so-1'}
    },
    {
      label: 'Letztes Monat',
      value: {from: 'ma-1', to: 'me-1'}
    },
    {
      label: 'Letzten 7 Tage',
      value: {from: 'h-7', to: 'h'}
    },
    {
      label: 'Letzten 30 Tage',
      value: {from: 'h-30', to: 'h'}
    }
  ];

  constructor() { }

  onCellClick(date: string[]): void {
    this.valueChange.emit({from: date[0], to: date[1]});
  }

  onValueChange(valueArray: [string | null, string | null]): void {
    const value: DateRange = {from: valueArray[0], to: valueArray[1]};
    const currentValue = this.value();
    if(equals(currentValue, value))
      return;

    const datePicker = this.datePicker();
    if(datePicker && value.from)
      datePicker._activeSelect = 'end';

    this.value.set(value);
    this.valueChange.emit(value);
  }


  onSelectShortcut(shortcut:  { value: DateRange; label: string }): void {
    const from = shortcut.value.from ? this.dateParser.parse(shortcut.value.from) : null;
    const to = shortcut.value.to ? this.dateParser.parse(shortcut.value.to) : null;

    const value: DateRange = {from, to};
    this.value.set(value);
    this.valueChange.emit(value);
    this.selectShortcut.emit(value);
  }

  async openShortcutsList(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<DateRange>(),
      {
        title: 'Shortcuts',
        dismiss: true,
        options: this.shortcuts.map(o => ({
          value: o.value,
          label: o.label
        }))
      }
    );

    if (!result || result === 'DISMISSED')
      return;

    const shortcut = this.shortcuts.find(o => o.value === result);

    if (!shortcut)
      return;

    this.onSelectShortcut(shortcut);
  }

  private parseShortcutDate(value: string): moment.Moment | null {
    const result = this.dateParser.parse(value);

    if (!result)
      return null;

    return moment.isMoment(result) ? result : moment(result);
  }
}
