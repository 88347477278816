import { computed, Injectable } from '@angular/core';
import { Store2Feature } from '../../store2-feature';
import { InjectableStore2Feature } from '../../injectable-store2-feature';

@Injectable()
export class MultiSelectStore2<T> extends InjectableStore2Feature<T[]> {

  selected = computed(() => this.state());

  constructor() {
    super();
  }

  select(value: T): void {
    const state = [...this.state()];
    if(state.includes(value))
      state.push(value);
    this.commit(state);
  }

  selectMany(values: T[]): void {
    const state = [...this.state()];
    for(const value of values)
      if(!state.includes(value))
        state.push(value);
    this.commit(state);
  }

  toggle(value: T): void {
    const state = [...this.state()];
    const index = state.indexOf(value);
    if(index > -1)
      state.splice(index, 1);
    else
      state.push(value);
    this.commit(state);
  }

  toggleMany(values: T[]): void {
    const state = [...this.state()];
    for(const value of values) {
      const index = state.indexOf(value);
      if(index > -1)
        state.splice(index, 1);
      else
        state.push(value);
    }
    this.commit(state);
  }

  unselect(id: T): void {
    const state = [...this.state()];
    const index = state.indexOf(id);
    if(index > -1)
      state.splice(index, 1);
    this.commit(state);
  }

  unselectMany(ids: T[]): void {
    const state = [...this.state()];
    for(const id of ids) {
      const index = state.indexOf(id);
      if(index > -1)
        state.splice(index, 1);
    }
    this.commit(state);
  }

  override getDefaultState(): T[] {
    return [];
  }
}
