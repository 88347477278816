import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { UiCoreModule } from '@softline/ui-core';
import { RenderParameters } from 'pdfjs-dist/types/src/display/api';

@Component({
    selector: 'soft-pdf-viewer',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, AfterViewInit {
  private viewInitialized = false;
  private _document: Blob | undefined | null;
  private pdf?: pdfjsLib.PDFDocumentProxy;
  currentPage$ = new BehaviorSubject(1);
  scale = 0.9;
  pages = 0;

  @Input()
  get document(): Blob | undefined | null {
    return this._document;
  }
  set document(value: Blob | undefined | null) {
    this._document = value;
    if (value && this.viewInitialized) this.renderDocument(value);
  }

  @Input() loading = false;

  @ViewChild('container') container!: ElementRef;

  constructor() {}

  ngOnInit(): void {
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      '/assets/lib/pdf.js/build/pdf.worker.js';
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.document) await this.renderDocument(this.document);
    this.viewInitialized = true;
  }

  async renderDocument(blob: Blob): Promise<void> {
    const dataUrl = await this.blobToDataUrl(blob);
    this.pdf = await pdfjsLib.getDocument(dataUrl).promise;
    this.pages = this.pdf.numPages;
    await this.renderPage(1, this.scale);
  }

  async nextPage(): Promise<void> {
    if (!this.pdf || this.currentPage$.value + 1 > this.pdf.numPages) return;
    await this.renderPage(this.currentPage$.value + 1, this.scale);
  }

  async prevPage(): Promise<void> {
    if (this.currentPage$.value <= 1) return;
    await this.renderPage(this.currentPage$.value - 1, this.scale);
  }

  async zoomIn(): Promise<void> {
    await this.renderPage(this.currentPage$.value, this.scale + 0.1);
  }

  async zoomOut(): Promise<void> {
    await this.renderPage(this.currentPage$.value, this.scale - 0.1);
  }

  async renderPage(no: number, scale: number): Promise<void> {
    this.currentPage$.next(no);
    this.scale = scale;
    if (!this.pdf)
      return;
    const page = await this.pdf.getPage(no);
    const canvas = this.container.nativeElement;

    const context = canvas.getContext('2d');

    const viewport = page.getViewport({ scale });

    const outputScale = window.devicePixelRatio || 1;
    canvas.width = Math.floor(viewport.width * outputScale);
    canvas.height = Math.floor(viewport.height * outputScale);
    canvas.style.width = Math.floor(viewport.width) + 'px';
    canvas.style.height = Math.floor(viewport.height) + 'px';

    const transform =
      outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined;

    const renderContext: RenderParameters = {
      canvasContext: context,
      transform,
      viewport,
    };

    const renderer = page.render(renderContext);
  }

  blobToDataUrl(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}
