import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, output, Output} from "@angular/core";
import {CommonModule} from '@angular/common';
import {ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule} from "@softline/ui-core";
import {BehaviorSubject, combineLatestWith, startWith, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME} from "../../../application.shared";
import {Filter, isLogicalFilter, isPropertyFilter, Sort, Store} from "@softline/core";
import {FilterAndSort, FilterAndSortDialog} from "../../dialogs/filter-and-sort/filter-and-sort.dialog";
import {FilterConfig} from "../../types/filter-config";
import {SortConfig} from "../../types/sort-config";

@Component({
    selector: 'soft-filter-and-sort',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './filter-and-sort.component.html',
    styleUrls: ['./filter-and-sort.component.scss']
})
export class FilterAndSortComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  filterText$ = new BehaviorSubject<string>('');
  caseSensitive$ = new BehaviorSubject<boolean>(false);
  wholeText$ = new BehaviorSubject<boolean>(false);

  @Input() minLength = 0;
  @Input() values: object[] = [];
  @Input() filterConfig: FilterConfig | null | undefined;
  @Input() sortConfig: SortConfig | null | undefined;

  @Input()
  set filterText(value: string) {
    this.filterText$.next(value);
  }

  readonly filterTextChange = output<string>();

  filter$ = new BehaviorSubject<Filter | null>(null);
  @Input()
  get filter(): Filter | null {
    return this.filter$.value;
  }
  set filter(value: Filter | null) {
    this.filter$.next(value);
  }
  children$ = this.filter$.pipe(
    map(o => {
      if(isLogicalFilter(o) && o.operator === 'and'){
        return o.filters
          .filter((o): o is Filter & {property: string | null} => isPropertyFilter(o) && o.operator !== 'containsText' && o.property !== null)
      }
      return [];
    })
  )

  @Output() filterChange = new EventEmitter<Filter>();

  sort$ = new BehaviorSubject<Sort | null>(null);
  @Input()
  get sort(): Sort | null {
    return this.sort$.value;
  }
  set sort(value: Sort | null) {
    this.sort$.next(value);
  }
  @Output() sortChange = new EventEmitter<Sort | null>();

  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME) private debounceTime: number) {
  }

  ngOnInit(): void {
    this.subscription = this.filterText$.pipe(
      map((o) => (o.length >= this.minLength ? o : '')),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      startWith(''),
      combineLatestWith(this.caseSensitive$, this.wholeText$)
    ).subscribe(
      ([text, caseSensitive, wholeText]) => {
        const textFilter = {
          operator: 'containsText',
          value: text,
          property: null,
          caseSensitive,
          wholeText
        };
        this.updateDefaultFilter(textFilter);
        this.filterTextChange.emit(text);
      }
    )
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async onOpenDialog(): Promise<void> {
    let filter = this.filter;
    if (!isLogicalFilter(filter))
      return;
    let children = [...filter.filters];
    const index = children.findIndex(o => o?.operator === 'containsText' && (o as any).property === null) ?? -1
    if(index > -1)
      children.splice(index, 1);

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<FilterAndSort, any>(),
      {
        component: FilterAndSortDialog,
        data: {
          filterConfig: this.filterConfig,
          sortConfig: this.sortConfig,
          values: this.values,
          filters: children,
          sort: this.sort
        },
        dismiss: true
      });
    if(result === 'DISMISSED')
      return;
    this.updateCustomFilters(result.filter ?? [])
    this.sortChange.emit(result.sort);
  }

  private updateDefaultFilter(textFilter: Filter) {
    let filter = this.filter;
    if (isLogicalFilter(filter)) {
      let children = [...filter.filters];
      const index = children.findIndex(o => o?.operator === 'containsText' && (o as any).property === null) ?? -1
      if(index > -1)
        children.splice(index, 1);
      filter = {operator: 'and', filters: [textFilter, ...children]} as Filter
    }
    else
      filter = {operator: 'and', filters: [textFilter]} as Filter;
    this.filterChange.emit(filter);
  }

  private updateCustomFilters(filters: Filter[]) {
    let defaultFilter: Filter | undefined;
    if (isLogicalFilter(this.filter)) {
      defaultFilter = this.filter.filters.find(o => o?.operator === 'containsText' && (o as any).property === null);
      if(defaultFilter)
        filters = [...filters, defaultFilter]
    }
    const filter = {operator: 'and', filters: filters} as Filter;
    this.filterChange.emit(filter);
  }

  protected readonly length = length;
}
