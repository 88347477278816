import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit, output,
  Output
} from '@angular/core';
import { CommonModule } from "@angular/common";
import { DateService, SOFTLINE_SERVICE_UUID, Store } from "@softline/core";
import { ArtikelStore } from "../../store/artikel.store";
import { BehaviorSubject, skip, Subscription } from "rxjs";
import { Artikel } from "../../types/artikel";
import { UiCoreModule } from "@softline/ui-core";
import { RouterLink, RouterLinkWithHref } from "@angular/router";
import {
  BlockingLoadingSpinnerComponent,
  showRequestErrors,ItemScan,
  ItemScanStore,
  Scan,
  ScanButtonComponent,
  ScannerStore,
  SOFTLINE_FEATURE_ITEM_SCAN,
  SOFTLINE_FEATURE_SCANNER
} from '@softline/application';
import { SOFTLINE_FEATURE_WWS_ARTIKELSUCHE } from "../../artikel.shared";
import { ArtikelTextsucheComponent } from "./artikel-textsuche/artikel-textsuche.component";
import {ArtikelFavoritenListeComponent} from './artikel-favoriten-liste/artikel-favoriten-liste.component';

@Component({
    selector: 'soft-artikel-suche',
    imports: [CommonModule, UiCoreModule, RouterLink, RouterLinkWithHref, BlockingLoadingSpinnerComponent, ScanButtonComponent, ArtikelTextsucheComponent, ArtikelFavoritenListeComponent],
    templateUrl: './artikel-suche.component.html',
    styleUrls: ['./artikel-suche.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtikelSucheComponent implements OnInit, OnDestroy {

  private scanSubscription?: Subscription;

  readonly loading$ = new BehaviorSubject<boolean>(false);
  readonly scanInProgress$ = new BehaviorSubject<boolean>(false);

  @Input() disableScan: boolean = false;
  @Input() emitRawScan: boolean = false;
  @Output() selectArtikel = new EventEmitter<Artikel>();
  readonly scanResult = output<ItemScan>();

  constructor(
    private store: Store,
    private dateService: DateService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
      ArtikelStore.mutations.clear,
    );

    this.scanSubscription = this.store
      .observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(skip(1))
      .subscribe(async scan => {
        if(this.disableScan)
          return;
        await this.validateScan(scan);
      });
  }

  async ngOnDestroy(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
      ArtikelStore.actions.cancelAll,
    );
    this.store.commit(
      SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
      ArtikelStore.mutations.clear,
    );

    if (this.scanSubscription && !this.scanSubscription.closed)
      this.scanSubscription.unsubscribe();

    this.scanSubscription = undefined;
  }

  private async validateScan(scan?: Scan): Promise<void> {
    if (!scan)
      return;

    try {
      this.loading$.next(true);
      this.scanInProgress$.next(true);

      const scanItem = await this.store.dispatch(
        SOFTLINE_FEATURE_ITEM_SCAN,
        ItemScanStore.actions.load,
        scan
      );

      if (this.emitRawScan) {
        this.scanResult.emit(scanItem);
      } else {
        const artikel = await this.store.dispatch(
          SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
          ArtikelStore.actions.load,
          { id: scanItem.artikel.id }
        );

        if (artikel)
          this.selectArtikel.emit(artikel);
      }
    } catch (e) {
      showRequestErrors(this.store, e);
    } finally {
      this.loading$.next(false);
      this.scanInProgress$.next(false);
    }
  }
}
