import {
  ConnectionHttpService,
  ApiResourceLocation,
} from '@softline/core';
import { Injectable } from '@angular/core';
import { ResetPassword } from '../data/reset-password';
import { REQUEST_TOKEN_PATH, RESET_PASSWORD_PATH } from '../password.api';
import { UserValidationInfo } from '../data/user-validation';
import { Observable } from 'rxjs';

@Injectable()
export class PasswordService {
  constructor(private service: ConnectionHttpService) {}

  requestToken(value: UserValidationInfo): Observable<UserValidationInfo> {
    const location: ApiResourceLocation = { path: REQUEST_TOKEN_PATH };
    return this.service.create(location, value);
  }

  resetPassword(value: ResetPassword): Observable<unknown> {
    const location: ApiResourceLocation = { path: RESET_PASSWORD_PATH };
    const { password, username, token } = value
    return this.service.create(location, { password, username, token });
  }
}
