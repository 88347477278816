import { Component, Inject, inject, Optional, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent, MessageBarService, PasswordInputComponent, UiCoreModule, WithModal } from '@softline/ui-core';
import { Subscription } from 'rxjs';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionError, Store, Trace, UnauthorizedError } from '@softline/core';
import { Router } from '@angular/router';
import {
  JwtAuthenticationStore,
  SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION
} from '@softline/auth';
import {
  ApplicationSettings,
  Settings,
  SettingsStore, showRequestErrors,
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_FEATURE_SETTINGS, SOFTLINE_SETTINGS_APPLICATION
} from '@softline/application';
import { LoginConfig, LoginLink, SOFTLINE_CONFIG_LOGIN, SOFTLINE_CONFIG_LOGIN_LINKS } from '@softapps/allgemein/login';

@Component({
    selector: 'soft-login',
    imports: [CommonModule, ReactiveFormsModule, UiCoreModule],
    templateUrl: './login.dialog.html',
    styleUrl: './login.dialog.scss'
})
export class LoginDialog extends WithModal<boolean>() {
  private messageBarService = inject(MessageBarService);


  loading = signal(false);
  loginForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(undefined, Validators.required),
    password: new UntypedFormControl(undefined, Validators.required),
    remember: new UntypedFormControl(false),
  });

  constructor(private store: Store,) { super(); }

  @Trace({callArgs: false})
  async submit(value: any): Promise<void> {
    await this.login(value.username, value.password, value.remember);
  }

  @Trace({callArgs: false})
  async login(
    username: string,
    password: string,
    stayAuthenticated: boolean
  ): Promise<void> {
    try {
      this.loading.set(true);
      const success = await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.authenticate,
        { username, password, stayAuthenticated }
      );
      if (success)
        this.close(success);
    } catch (e) {
      if (e instanceof UnauthorizedError)
        this.messageBarService.warning('#LOGIN.MESSAGES.FAILED.UNAUTHORIZED')
      else if (e instanceof ConnectionError)
        this.messageBarService.warning('#LOGIN.MESSAGES.FAILED.NO_CONNECTION')
      else showRequestErrors(this.store, e);
    } finally {
      this.loading.set(false);
    }
  }
}
