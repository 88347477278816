import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { UiCoreModule } from "@softline/ui-core";
import { Scan } from "../../data/scan";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_SCANNER } from "../../../application.shared";
import * as ScannerStore  from "../../scanner.store";
import { LabelType } from "../../data/label-type";

@Component({
    selector: 'soft-scan-button',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './scan-button.component.html',
    styleUrls: ['./scan-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanButtonComponent implements OnInit {

  @Input() label?: string;
  @Input() disabled = false;
  @Input() labelType: LabelType | LabelType[] | undefined = undefined;
  @Output() scan = new EventEmitter<Scan>();

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  async startScan(): Promise<void> {
    const result = await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.scan, {labelType: this.labelType})
    this.scan.emit(result);
  }

}
