import { EnvironmentProviders, importProvidersFrom, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import {
  ApiResourceLocation,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_SELECTED_ACCOUNT,
  SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
  SOFTLINE_FEATURE_UTILITIES_FIRMA,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
  SOFTLINE_FEATURE_UTILITIES_VKFORGS,
  UtilitiesAccountStore,
  UtilitiesSelectedAccountStore,
} from './utilities.shared';
import {USER_ACCOUNTS_RESOURCE_PATH, USER_FIRMEN_RESOURCE_PATH, USER_VKFORGS_RESOURCE_PATH} from './utilities.api';
import {PatchContextStore} from './context/patch-context.store';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {VkforgStore} from './context/vkforg.store';
import {FirmaStore} from './context/firma.store';
import {PatchContextService} from './context/services/patch-context.service';

export const utilitiesProviders: (Provider | EnvironmentProviders)[] = [
  provideEnvironmentInitializer(async () => {
      const store = inject(Store);

      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'utilities',
          language: 'de',
          translations: de,
        }
      );
    }),
  PatchContextService,
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
      feature: UtilitiesAccountStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ResourceService<ApiResourceLocation>) =>
            new SoftlineEntityService(service, USER_ACCOUNTS_RESOURCE_PATH),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SELECTED_ACCOUNT,
      feature: UtilitiesSelectedAccountStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      feature: PatchContextStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_UTILITIES_VKFORGS,
      feature: VkforgStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service) => new SoftlineEntityService(service, USER_VKFORGS_RESOURCE_PATH),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_UTILITIES_FIRMA,
      feature: FirmaStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service) => new SoftlineEntityService(service, USER_FIRMEN_RESOURCE_PATH),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ]
    })
  ),
];
