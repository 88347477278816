import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {Modal} from '../../data/modal';
import {DatePickerComponent} from '../../../../components/picker/date-picker/date-picker.component';
import {I18nModule} from '../../../../i18n/i18n.module';
import {NgIf} from '@angular/common';
import { DateService, equals } from '@softline/core';
import { DateRangePickerComponent } from '../../../../components/picker/date-range-picker/date-range-picker.component';
import { DateRange } from '../../data/date-range-modal-config';

@Component({
    selector: 'soft-date-range-picker-modal',
    templateUrl: './date-range-picker-modal.component.html',
    styleUrls: ['./date-range-picker-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DatePickerComponent,
        I18nModule,
        NgIf,
        DateRangePickerComponent
    ],
    providers: []
})
export class DateRangePickerModalComponent implements Modal<DateRange> {
  private closed = false;
  dateService = inject(DateService);
  today = this.dateService.today();

  title?: string;
  subtitle?: string;
  value: DateRange | null = null
  startField: 'from' | 'to' = 'from';

  close!: (result: DateRange) => void;

  registerCloseHandler(handler: (result: (DateRange)) => void) {
    this.close = handler;
  }

  onValueChange(value: DateRange | null) {
    if(value && value.from && value.to && !equals(value, this.value) && value.from <= value.to) {
      this.close(value);
      this.closed = true;
    }
  }

  onSelectShortcut(value: DateRange) {
    if(!this.closed){
      this.close(value);
      this.closed = true;
    }
  }
}
