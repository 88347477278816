import { Component, HostListener } from "@angular/core";
import { Modal } from '../../data/modal';
import { NotificationResult } from '../../../types/notification-result';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from "../../../../pipes/ui-core-pipes.module";
import { I18nModule } from '../../../../i18n/i18n.module';
import { IconComponent } from '../../../../components/icon/icon.component';

@Component({
    selector: 'soft-notification-modal',
    imports: [CommonModule, UiCorePipesModule, I18nModule],
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements Modal<NotificationResult> {
  private close!: (result: NotificationResult) => void;

  title: string | undefined;
  text = '';
  showCancel = false;
  params?: object;

  registerCloseHandler(handler: (result: NotificationResult) => void): void {
    this.close = handler;
  }

  @HostListener('window:keydown.enter')
  onClick(result: NotificationResult = 'OK'): void {
    this.close(result);
  }
}
