import { computed, inject, Injectable, Injector } from '@angular/core';
import { TranslationFile } from './data/translation-file';
import { isDefined, Store2Feature } from '@softline/core';
import { SOFTLINE_FEATURE_TRANSLATION } from './i18n.shared';
import { combineLatestWith, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface TranslationState {
  translations: TranslationFile[];
}

/***@deprecated: use TranslationService instead */
@Injectable()
export class TranslationStore2 extends Store2Feature<TranslationState>{

  override name = SOFTLINE_FEATURE_TRANSLATION;
  private readonly injector = inject(Injector);

  translate = (translation: string) => computed(() => this.getTranslation(translation));

  constructor() {
    super();
  }

  override onRegister() {
    super.onRegister();
  }


  add(file: TranslationFile): void {
    const translations = [...this.state().translations];
    const index = translations.findIndex(
      (o) => o.language === file.language && o.module === file.module
    );
    if (index > -1) translations.splice(index, 1);
    translations.push(file);
    this.commitPatch({translations});
  }

  addMany(): void {

  }

  clear(): void {

  }

  load(): void {

  }

  loadLanguage(): void {

  }

  getTranslation$(translation: string): Observable<string> {
    return this.state$.pipe(
      combineLatestWith(of('de')),
      map(([state, language]) => this.getTranslation(translation))
    )
  }

  getTranslation(key: string): string {
    const code = 'de';
    const translations = this.state().translations;
    let translation = key;
    let priority: number | undefined;

    for (const file of translations.filter(
      (o) => o.language === code
    )) {
      const tempTranslation = this.findInFile(key, file);
      if (
        isDefined(tempTranslation) &&
        (file.priority ?? Number.NEGATIVE_INFINITY) >=
        (priority ?? Number.NEGATIVE_INFINITY)
      ) {
        translation = tempTranslation;
        priority = file.priority;
      }
    }
    return translation ?? key;
  }

  private findInFile(
    key: string,
    file: TranslationFile
  ): string | undefined {
    const parts = key.split('.');
    let translations = file.translations as any;
    let translation: string | undefined;

    while (parts.length > 0) {
      const partName = parts.shift() ?? '';
      if(!translations)
        break;
      const part = translations[partName];

      if (typeof part === 'object') translations = part;
      else if (parts.length === 0 && typeof part === 'string') translation = part;
      else break;
    }
    return translation;
  }

  override getDefaultState(): TranslationState {
    return {
      translations: []
    }
  }
}
