import { InjectionToken } from '@angular/core';
import { Routes } from '@angular/router';

export const SOFTLINE_NAME_USER_INDEXED_DB = 'user_{{id}}';

export const SOFTLINE_ROUTES_APPS = new InjectionToken<Routes[]>('SOFTLINE_ROUTES_APPS');
export const SOFTLINE_ROUTES_TERMINAL = new InjectionToken<Routes[]>('SOFTLINE_ROUTES_TERMINAL');
export const SOFTLINE_ROUTES_PUBLIC = new InjectionToken<Routes[]>('SOFTLINE_ROUTES_PUBLIC');


