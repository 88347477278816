import {ChangeDetectionStrategy, Component, Input, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE} from '../kundeninfo-leiste.shared';
import {KundeninfoLeisteStore} from '../store/kundeninfo-leiste.store';
import {showRequestErrors} from '@softline/application';
import {ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule} from '@softline/ui-core';
import {KundeninfoLeiste} from '../types/kundeninfo-leiste';
import {
  KontoinformationenAnzeigenDialogComponent
} from '../dialogs/kontoinformationen-anzeigen/kontoinformationen-anzeigen-dialog.component';

@Component({
    selector: 'soft-kundeninfo-leiste',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './kundeninfo-leiste.component.html',
    styleUrl: './kundeninfo-leiste.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KundeninfoLeisteComponent {

  private _idktostamm : number | null = null
  @Input({ required: true })
  get idktostamm(): number | null {
    return this._idktostamm;
  }
  set idktostamm(value: number | null) {
    this._idktostamm = value;
    this.loadInfo(this._idktostamm);
  };

  @Input() alwaysExpanded: boolean = false;

  readonly loading = signal(false);

  readonly kontoInformationen$ = this.store.observe(
    SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE,
    KundeninfoLeisteStore.getters.data
  );

  constructor(private store: Store) {}

  private async loadInfo(idktostamm: number | null) {
    const data =  this.store.get(
      SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE,
      KundeninfoLeisteStore.getters.data
    );

    if (!idktostamm || data?.id !== idktostamm)
      this.store.commit(
        SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE,
        KundeninfoLeisteStore.mutations.unset,
      );
    if(!idktostamm)
      return

    try {
      this.loading.set(true);

      await this.store.dispatch(
        SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE,
        KundeninfoLeisteStore.actions.load,
        { pathParams: { idktostamm } }
      );

      this.loading.set(false);
    }
    catch (e) {
      this.loading.set(false);
      showRequestErrors(this.store, e);
    }
  }

  async openInfoDialog(kontoInformationen: KundeninfoLeiste) {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<KundeninfoLeisteComponent, object>(),
        {
          id: 'SOFTLINE_DIALOG_KUNDENINFO_LEISTE_KONTOINFORMATIONEN_ANZEIGEN',
          component: KontoinformationenAnzeigenDialogComponent,
          data: { kontoInformationen },
          dismiss: true,
          class: '!w-full !p-0'
        }
      );
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
