<div
  (click)="goToApplication(application)"
  class="grid-item-container overflow-clip relative"
>
  <span class="application-icon">
    <soft-icon
      *ngIf="application?.icon !== ''; else defaultIcon"
      [name]="application?.icon"
      class="fa-3x mt-1"
    ></soft-icon>
  </span>
  <div class="soft-container soft-container-center set-width">
    <span class="application-title">{{ application?.name | translate }}</span>
  </div>

  @if (application?.type === 'link') {
    <div class="absolute top-2 right-2.5">
      <soft-icon name="fa-regular fa-arrow-up-right-from-square" class="text-sm opacity-50"></soft-icon>
    </div>
  }
</div>

<ng-template #defaultIcon>
  <i class="fa-regular fa-ban fa-3x"></i>
</ng-template>
