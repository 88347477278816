import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule, Validators } from "@softline/ui-core";
import { passwordValidator } from '../../validation/password.validator';
import { passwordConfirmValidator } from '../../validation/password-confirm.validator';
import { UserValidationInfo } from '../../data/user-validation';
import { Store } from '@softline/core';
import { ResetPassword } from '../../data/reset-password';
import { first, map } from 'rxjs/operators';
import { SOFTLINE_FEATURE_PASSWORD, SOFTLINE_PAGE_LOGIN } from "../../password.shared";
import { PasswordStore } from '../../store/password.store';
import { CommonModule } from '@angular/common';
import { firstValueFrom } from "rxjs";
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from "@softline/auth";

@Component({
    selector: 'soft-password-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule]
})
export class SettingsComponent implements OnInit {
  emailForm = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    email: new UntypedFormControl('', {
      validators: [Validators.required(), Validators.email()],
      updateOn: 'change',
    }),
  });

  passwordForm = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    token: new UntypedFormControl('', {
      validators: [Validators.required()],
      updateOn: 'change',
    }),
    password: new UntypedFormControl('', {
      validators: [passwordValidator({ minLength: 8, strengthLevel: 4 })],
      updateOn: 'change',
    }),
    passwordRepeat: new UntypedFormControl('', {
      validators: [Validators.required(), passwordConfirmValidator()],
      updateOn: 'change',
    }),
  });

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    const username =  await firstValueFrom(this.store
      .observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
      .pipe(map((o: any) => o.username)));
    this.emailForm.patchValue({ username });
    this.passwordForm.patchValue({ username });
  }

  async sendToken(userValidation: UserValidationInfo): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_PASSWORD,
        PasswordStore.actions.requestToken,
        userValidation
      );
      const message = '#PASSWORD.MESSAGES.CONFIRMATION_SENT';
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        message
      );
    }
    catch (e) {
      const message = '#PASSWORD.MESSAGES.CONFIRMATION_FAILED';
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        message
      );
    }
  }

  async changePassword(resetPassword: ResetPassword): Promise<void> {
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_PASSWORD,
        PasswordStore.actions.resetPassword,
        resetPassword
      );
      if (result === true) {
        const message = '#PASSWORD.MESSAGES.RESET_SUCCESS';
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          message
        );
      } else {
        const message = '#PASSWORD.MESSAGES.RESET_FAILURE';
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          message
        );
      }
    }
    catch (e) {
      const message = '#PASSWORD.MESSAGES.RESET_FAILURE';
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        message
      );
    }
  }
}
