import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { TranslationFile } from './data/translation-file';
import { interpolate, isDefined } from '@softline/core';
import { SOFTLINE_CONFIG_DEFAULT_LANGUAGE, SOFTLINE_DATA_TRANSLATIONS } from './i18n.shared';

@Injectable({providedIn: 'root'})
export class TranslationService {

  private defaultLanguage = inject(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);
  private translations = inject(SOFTLINE_DATA_TRANSLATIONS, {optional: true}) ?? [];
  private readonly language = signal(this.defaultLanguage);

  private readonly _translationFiles = signal<TranslationFile[]>(this.translations);
  private readonly _activeFiles = computed(() => {
    const language = this.language();
    return this._translationFiles().filter(o => o.language === language);
  });

  readonly translationFiles = this._translationFiles.asReadonly();

  setLanguage(language: string): void {
    this.language.set(language);
  }

  addFile(file: TranslationFile): void {
    this._translationFiles.set([...this._translationFiles(), file]);
  }

  translate(translation: string, params?: object): Signal<string> {
    return computed(() => this.getTranslation(translation, params));
  }

  private getTranslation(key: string, params?: object): string {
    let translation = key;
    let priority: number | undefined;

    for (const file of this._activeFiles()) {
      const tempTranslation = this.findInFile(key, file);
      if (isDefined(tempTranslation) && (file.priority ?? Number.NEGATIVE_INFINITY) >= (priority ?? Number.NEGATIVE_INFINITY)) {
        translation = tempTranslation;
        priority = file.priority;
      }
    }
    if(params) {
      interpolate(translation, params);
    }
    return translation ?? key;
  }

  private findInFile(
    key: string,
    file: TranslationFile
  ): string | undefined {
    const parts = key.split('.');
    let translations = file.translations as any;
    let translation: string | undefined;

    while (parts.length > 0) {
      const partName = parts.shift() ?? '';
      if(!translations)
        break;
      const part = translations[partName];

      if (typeof part === 'object')
        translations = part;
      else if (parts.length === 0 && typeof part === 'string')
        translation = part;
      else
        break;
    }
    return translation;
  }
}
