import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {
  BackNavigable,
  BackNavigationService,
  CommandStore,
  FilterAndSortComponent,
  FilterConfig,
  SOFTLINE_FEATURE_COMMANDS,
  SortConfig
} from "@softline/application";
import {Filter, FilterService, Sort, SortService, Store} from "@softline/core";
import {SOFTLINE_FEATURE_WWS_WARENUEBERNAHME} from "../../warenuebernahme.shared";
import {WwsWarenuebernahmeStore} from "../../store/warenuebernahme.store";
import {I18nModule, ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule} from "@softline/ui-core";
import {combineLatestWith, map, Observable} from "rxjs";
import {Anwender, Warenuebernahme} from "../../types/warenuebernahme";
import {
  WarenuebernahmeSperrenBestaetigenDialogComponent
} from "../../dialogs/warenuebernahme-sperren-bestaetigen/warenuebernahme-sperren-bestaetigen-dialog.component";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";

@Component({
    selector: 'soft-uebersicht',
    imports: [CommonModule, RouterModule, I18nModule, FilterAndSortComponent, UiCoreModule],
    templateUrl: './uebersicht.component.html',
    styleUrls: ['./uebersicht.component.scss']
})
export class UebersichtComponent implements OnInit, OnDestroy, BackNavigable {

  readonly anwender$: Observable<{id?: number}> = this.store.observe(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  );

  readonly warenuebernahmen$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
    WwsWarenuebernahmeStore.getters.all
  );

  readonly sort$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
    WwsWarenuebernahmeStore.getters.sort
  );

  readonly filter$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
    WwsWarenuebernahmeStore.getters.filter
  );

  readonly filteredItems$: Observable<Warenuebernahme[]> = this.warenuebernahmen$.pipe(
    combineLatestWith(this.sort$, this.filter$),
    map(([values, sort, filter]) => {
        return this.sortService.sort(this.filterService.filter(values, filter), sort);
    })
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
    WwsWarenuebernahmeStore.getters.loading
  );

  filterConfig: FilterConfig = {
    properties: [
      {
        property: 'id',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_WARENUEBERNAHME_ID',
      },
      {
        property: 'lieferant.lieferantennummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_NUMMER'
      },
      {
        property: 'lieferant.kurzbez',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_BEZEICHNUNG'
      },
      {
        property: 'belegnummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERSCHEIN_NUMMER'
      },
      {
        property: 'anwender.anwender',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_ANWENDER_BEZEICHNUNG'
      }
    ]
  };

  sortConfig: SortConfig = {
    properties: [
      {
        property: 'id',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_WARENUEBERNAHME_ID',
      },
      {
        property: 'lieferant.lieferantennummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_NUMMER'
      },
      {
        property: 'lieferant.kurzbez',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_BEZEICHNUNG'
      },
      {
        property: 'belegnummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERSCHEIN_NUMMER'
      }
    ]
  };

  constructor(private store: Store,
              private backNavigationService: BackNavigationService,
              private filterService: FilterService,
              private sortService: SortService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.mutations.setSort,
      {
        property: 'id',
        title: 'Warenübernahme-ID',
        direction: 'desc'
      } as any,
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet, {
        name: UebersichtComponent,
        commands: [
          {
            name: '#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_BESTELLUNG_ABBUCHEN',
            class: 'menu action-menu action-menu-top',
            icon: 'fa-regular fa-truck-ramp-box',
            routerLink: '/warenuebernahme/bestellung/schritt-1'
          }
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.actions.loadMany,
    );
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      UebersichtComponent,
    );

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.mutations.setFilter,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.mutations.setSort,
      null
    );
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async warenuebernahmeSperrenBestaetigen(warenuebernahme: Warenuebernahme, anwender: any) {

    if (!warenuebernahme?.anwenderExzess) {
      await this.router.navigate(['./detail', warenuebernahme.id], { relativeTo: this.activatedRoute });
      return;
    }

    if (anwender?.id !== warenuebernahme?.anwenderExzess?.id) {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<boolean, object>(),
        {
          id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_SPERREN_BESTAETIGEN',
          component: WarenuebernahmeSperrenBestaetigenDialogComponent,
          data: {
            exzess: warenuebernahme.anwenderExzess
          },
          dismiss: true
        }
      );

      if (!result || result === 'DISMISSED')
        return

      console.log('Result Dialog: ', result);
      console.log('Warenübernahme-ID: ', warenuebernahme.id);

      await this.router.navigate(['./detail', warenuebernahme.id], { relativeTo: this.activatedRoute });
    }
  }

  async onFilterChange(filter: Filter | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.mutations.setFilter,
      filter
    );
  }

  async onSortChange(sort: Sort | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.mutations.setSort,
      sort
    );
  }
}
