import { Injectable } from '@angular/core';
import {
  ConnectionHttpService,
  ApiResourceLocation,
  ResponseResult,
} from '@softline/core';
import { map } from 'rxjs/operators';
import { Definition } from '../data/definitions';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class DefinitionService {
  constructor(private service: ConnectionHttpService) {}

  load(location: ApiResourceLocation): Promise<Definition> {
    return lastValueFrom(
      this.service
        .get<ResponseResult<Definition>>(location)
        .pipe(map((o) => o.result))
    );
  }
}
