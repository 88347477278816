import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-create-page',
    imports: [CommonModule],
    templateUrl: './create-page.component.html',
    styleUrl: './create-page.component.scss'
})
export class CreatePageComponent {}
