import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'startsWith',
    pure: true,
    standalone: false
})
export class StartsWithPipe implements PipeTransform {
  transform(value: string, startsWith: string): unknown {
    return value.startsWith(startsWith);
  }
}
