import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {I18nModule, Modal} from "@softline/ui-core";
import { KundeninfoLeiste, KundeninfoLeisteComponent } from '@softapps/fibu/core';

@Component({
    selector: 'soft-kontoinformationen-anzeigen',
    imports: [CommonModule, I18nModule],
    templateUrl: './kontoinformationen-anzeigen-dialog.component.html',
    styleUrls: ['./kontoinformationen-anzeigen-dialog.component.scss']
})
export class KontoinformationenAnzeigenDialogComponent implements Modal<KundeninfoLeisteComponent> {

  public _kontoInformationen!: KundeninfoLeiste;

  set kontoInformationen(kontoInformationen: KundeninfoLeiste) {
    this._kontoInformationen = kontoInformationen;
  }

  close!: (result: KundeninfoLeisteComponent) => void;
  registerCloseHandler(handler: (result: KundeninfoLeisteComponent) => void): void {
    this.close = handler;
  }
}
