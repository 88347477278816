import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal} from '@softline/ui-core';
import {Checkliste} from '../../types/checkliste';

@Component({
    selector: 'soft-select-checkliste-dialog',
    imports: [CommonModule],
    templateUrl: './select-checkliste-dialog.component.html',
    styleUrl: './select-checkliste-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectChecklisteDialogComponent implements Modal<Checkliste | null> {

  close!: (result: (Checkliste | null)) => void;

  registerCloseHandler(handler: (result: (Checkliste | null)) => void) {
    this.close = handler;
  }
}
