import { inject, Injectable } from '@angular/core';
import { Lagerplatz, LagerplatzInhalt } from '@softapps/lager/core';
import { ApiDataAccess, Result } from '@softline/core';
import { SOFTLINE_API_LAGERPLATZ_VERFÜGBAR } from '../lager-core.api';

@Injectable()
export class StockyardRepository {

  dataAccess = inject(ApiDataAccess)

  constructor() { }

  async getAvailableStockyards(idbewekomm: number): Promise<LagerplatzInhalt[]> {
    return (await this.dataAccess.get<Result<LagerplatzInhalt[]>>({
      path: SOFTLINE_API_LAGERPLATZ_VERFÜGBAR,
      pathParams: { idbewekomm }
    }))?.result;
  }
}
