import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@softline/core';
import { FormatPipe } from "../../l10n/pipes/format.pipe";

@Pipe({
    name: 'interpolate',
    standalone: false
})
export class InterpolatePipe implements PipeTransform {
  regex: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  constructor(private formatPipe: FormatPipe) {
  }

  transform(
    value: string | null | undefined,
    params?: object | null
  ): string | null | undefined {
    if (!value || !params) return value;
    return this.interpolate(value, params);
  }

  private interpolate(value: string, params: object): string {
    return value.replace(this.regex, (substring: string, b: string) => {
      const [name, format] = b.split(':');
      let replacement = this.getValue(params, name);
      if(isDefined(replacement) && isDefined(format))
        replacement = this.formatPipe.transform(replacement, format);
      else if (!isDefined(replacement))
        replacement = substring
      return replacement;
    });
  }

  private getValue(target: any, key: string): any {
    const keys = key.split('.');
    key = '';
    do {
      key += keys.shift();
      if (
        isDefined(target) &&
        isDefined(target[key]) &&
        (typeof target[key] === 'object' || !keys.length)
      ) {
        target = target[key];
        key = '';
      } else if (!keys.length) target = undefined;
      else key += '.';
    } while (keys.length);

    return target;
  }
}
