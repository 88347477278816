import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import {Modal, UiCoreModule, Validators} from '@softline/ui-core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { DeliveryNoteConfig } from '../../types/delivery-note-config';
import {SOFTLINE_CONFIG_LIEFERSCHEIN} from '../../delivery-note.shared';
import {CommonModule} from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-close-delivery-note-dialog',
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
    templateUrl: './close-delivery-note-dialog.component.html',
    styleUrls: ['./close-delivery-note-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseDeliveryNoteDialogComponent
    implements OnInit, Modal<{ kilometerstand: number; finish: boolean; email: string | undefined | null } | null> {

  @ViewChild('input', { static: true, read: ElementRef }) private inputElement?: ElementRef<HTMLInputElement>;

  input$ = new BehaviorSubject<number | null>(null);

  readonly showKilometerInput = this.deliveryNoteConfig?.showKilometerInput ?? false;

  readonly form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email()])
  });

  isInputValid$ = this.input$.pipe(
      map(value => !!value  && value > 0)
  );

  set previouslyEnteredKilometers(value: number | null) {
    this.kilometersChanged(value);
  }

  set email(value: string | null | undefined) {
    this.form.patchValue({ email: value });
  }

  hasUnterschrift = false;

  close!: (result: { kilometerstand: number; finish: boolean; email: string | undefined | null } | null) => void;

  constructor(
    @Optional() @Inject(SOFTLINE_CONFIG_LIEFERSCHEIN) private deliveryNoteConfig: DeliveryNoteConfig
  ) { }

  ngOnInit(): void {
    if (this.deliveryNoteConfig && this.deliveryNoteConfig?.showKilometerInput && this.deliveryNoteConfig?.autofocusKilometers) {
      // immediately focus input ("static: true" is needed in order to access the element in ngOnInit!)
      this.inputElement?.nativeElement?.focus();
    }
  }

  registerCloseHandler(handler: (result: { kilometerstand: number; finish: boolean; email: string | undefined | null } | null) => void) {
    this.close = handler;
  }

  kilometersChanged(value: number | undefined | null): void {
    this.input$.next(value === undefined || value === 0 || isNaN(+(value || 0)) ? null : value);
  }

  saveAndClose(): void {
    this.close({ kilometerstand: this.input$.value ?? 0, finish: false, email: this.form.value.email });
  }

  closeDialog(): void {
    this.close({ kilometerstand: this.input$.value ?? 0, email: this.form.value.email, finish: true });
  }
}
