import { Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import {
  AuthenticationContextStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
} from '@softline/auth';
import { map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';

type UserAccount = { userFullName?: string };

@Component({
    selector: 'lib-user-context-widget',
    templateUrl: './user-context-widget.component.html',
    styleUrls: ['./user-context-widget.component.scss'],
    imports: [CommonModule, UiCoreModule]
})
export class UserContextWidgetComponent implements OnInit {
  userContext$ = this.store
    .observe(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    )
    .pipe(map((o) => o as UserAccount));

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
