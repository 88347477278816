import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal, UiCoreModule, Validators } from '@softline/ui-core';
import { Subkunde } from '../../types/subkunde';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'soft-edit-subkunde-dialog',
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
    templateUrl: './edit-subkunde-dialog.component.html',
    styleUrls: ['./edit-subkunde-dialog.component.css']
})
export class EditSubkundeDialogComponent
  implements OnInit, Modal<Partial<Subkunde>>
{
  private _subkunde: Partial<Subkunde> = {};

  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required()]),
    aktiv: new FormControl(true, [Validators.required()]),
  });

  get subkunde(): Partial<Subkunde> {
    return this._subkunde;
  }
  set subkunde(value: Partial<Subkunde>) {
    this._subkunde = value;
    this.form.patchValue(value);
  }

  private close!: (subkunde: Partial<Subkunde>) => void;

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: Partial<Subkunde>) => void): void {
    this.close = handler;
  }

  onSubmit(value: Partial<Subkunde>): void {
    this.close(value);
  }
}
