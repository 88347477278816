import { Component, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FieldValidationDirective } from '../../../directives/field-validation.directive';
import { PasswordInputComponent } from '../../../components/form/password-input/password-input.component';
import { TranslatePipe } from '../../../i18n/pipes/translate.pipe';
import { ResolvableModal } from '../../abstraction/modal';
import { Validators } from '../../../validation/validators.class';

@Component({
  selector: 'soft-password-modal',
  imports: [CommonModule, FieldValidationDirective, PasswordInputComponent, ReactiveFormsModule, TranslatePipe],
  templateUrl: './password-modal.component.html',
  styleUrl: './password-modal.component.scss',
})
export class PasswordModalComponent extends ResolvableModal<string>{

  form = new FormGroup({
    password: new FormControl<string>('', [Validators.required()]),
  })

  async onSubmit(): Promise<void> {
    if(this.form.invalid || !this.form.value.password)
      return;
    this.resolve(this.form.value.password);
  }
}
