import { Entity } from '../store/specialized/entity/types/entity';
import { TemplateService, ValidationService } from './entity.service';
import { Inject } from '@angular/core';
import { SOFTLINE_SERVICE_HTTP } from '../core.shared';
import { ResourceService } from '../data/abstraction';
import { ApiResourceLocation } from '../data-access/types/api.location';
import { Dictionary } from '../types/dictionary';
import { ValidationResult } from '../store/specialized/entity/types/validation';
import { lastValueFrom } from 'rxjs';

export class SoftlineValidationService<T extends Entity>
  implements ValidationService<T>
{
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    protected service: ResourceService<ApiResourceLocation>,
    protected path: string
  ) {}

  validate(
    entity: T,
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Promise<ValidationResult<T>> {
    return lastValueFrom(
      this.service.create<T, ValidationResult<T>>(
        { path: this.path, pathParams, queryParams },
        entity
      )
    );
  }
}
