import {
  EnvironmentProviders,
  inject,
  InjectionToken,
  provideEnvironmentInitializer,
  Provider,
} from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { LinkModule, MenuItemStore2 } from '@softline/application';

export const SOFTLINE_CONFIG_MODULES_STATIC_LINKS = new InjectionToken<
  LinkModule[]
>('SOFTLINE_CONFIG_MODULES_STATIC_LINKS')

export function provideSoftAppsLinkModule(module: Omit<LinkModule, 'type'>) {
  return {
    provide: SOFTLINE_CONFIG_MODULES_STATIC_LINKS,
    useValue: {
      ...module,
      type: 'link'
    },
    multi: true,
  };
}

export const moduleProviders: (Provider | EnvironmentProviders)[] = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'app-overview',
          language: 'de',
          translations: de,
        }
      );

      const menuItemStore = inject(MenuItemStore2);
      menuItemStore.setItem('moduleProviders', [{
        name: 'moduleRoute',
        type:'route',
        outlet: 'nav-static',
        priority: 1,
        routerLink: ['/'],
        icon: 'fa-regular fa-grid',
        title: '#APP_OVERVIEW.ALL_APPLICATIONS',
      }]);
    }),
];
