import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import { BehaviorSubject } from 'rxjs';
import {CommonModule} from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-default-comment-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './default-comment-dialog.component.html',
    styleUrls: ['./default-comment-dialog.component.scss']
})
export class DefaultCommentDialogComponent implements OnInit, Modal<string | null > {

  @ViewChild('multilineInput', { read: ElementRef, static: true }) private inputElement?: ElementRef<HTMLElement>;

  private readonly text$ = new BehaviorSubject<string | null>(null);

  close!: (result: string | null) => void;
  vermerk?: string | null;
  isItem?: boolean;

  constructor() { }

  ngOnInit(): void {
    if (!this.inputElement || this.inputElement?.nativeElement?.children?.length < 1)
      return;

    (this.inputElement?.nativeElement?.children[0] as HTMLInputElement)?.focus();
    console.log('isItem: ', this.isItem);
  }

  inputChanged(value: string): void {
    this.text$.next(value);
  }

  registerCloseHandler(handler: (result: (string | null)) => void) {
    this.close = handler;
  }

  closeDialog(): void {
    if (this.text$.value === null || this.text$.value?.length < 1)
      this.close(null);

    this.close(this.text$.value);
  }
}
