import { Component, OnInit } from '@angular/core';
import { StringDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import {
  FieldValidationDirective,
  InputComponent,
  MultilineInputComponent,
} from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import { ScannerModule } from '@softline/application';
import { ScanDefinitionPipe } from '../../../../pipes/scan-definition.pipe';

@Component({
  selector: 'soft-dynamic-string-input',
  templateUrl: './string-input.component.html',
  styleUrls: ['./string-input.component.scss'],
  imports: [
    NgSwitch,
    NgIf,
    BooleanRulePipe,
    MultilineInputComponent,
    NgSwitchCase,
    ReactiveFormsModule,
    FormControlPipe,
    InputComponent,
    NgSwitchDefault,
    ScannerModule,
    ScanDefinitionPipe,
    FieldValidationDirective,
  ],
})
export class StringInputComponent
  extends DynamicInputAtom<StringDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
