import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { MenuItem, MenuItemStore2 } from '@softline/application';

@Injectable({providedIn: 'root'})
export class MenuItemService {

  //TODO: remove if store2 is removed
  store = inject(MenuItemStore2)

  private _menuItems: WritableSignal<Signal<MenuItem[]>[]> = signal([]);

  readonly menuItems = computed(() => {
    const legacyItems = this.store.values().flat();
    const menuItems = this._menuItems().reduce((acc, menuItems) => [...acc, ...menuItems()], [] as MenuItem[]);
    return [...legacyItems, ...menuItems];
  });

  readonly outlet = (name: string) => computed(() => {
    const menuItems = this.menuItems();
    return menuItems.filter(item => item.outlet === name)
      .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  });

  add(menuItems: Signal<MenuItem[]>): void {
    this._menuItems.set([...this._menuItems(), menuItems]);
  }

  remove(menuItems: Signal<MenuItem[]>): void {
    this._menuItems.set(this._menuItems().filter(item => item !== menuItems));
  }
}
