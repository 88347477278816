import { Directive, inject, TemplateRef } from '@angular/core';
import { TreeNode } from '../types/tree-node';

export interface TreeNodeTemplateContext<T> {
  $implicit: TreeNode<T>;
}

@Directive({
  selector: '[softTreeNode]',
})
export class TreeNodeDirective<T> {
  readonly templateRef = inject<TemplateRef<TreeNodeTemplateContext<T>>>(TemplateRef);

  // Helps for typing the context of the directive (type-infer)
  static ngTemplateContextGuard<T>(
    dir: TreeNodeDirective<T>,
    ctx: any
  ): ctx is TreeNodeTemplateContext<T> {
    return true;
  }
}
