import { inject, Injectable } from '@angular/core';
import { SOFTLINE_CONFIG_DEFAULT_LANGUAGE } from '@softline/ui-core';
import { LocalStorageService } from '@softline/core';

const LANGUAGE_LOCAL_STORAGE_KEY = 'Softline Language';

@Injectable({providedIn: 'root'})
export class LanguageRepository {

  defaultLanguage = inject(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);

  constructor(private service: LocalStorageService) { }

  async load(): Promise<string> {
    const value = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
    if (value)
      return JSON.parse(value);
    return this.defaultLanguage
  }

  async save(language: string): Promise<string> {
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, JSON.stringify(language));
    return language;
  }
}
