import { Component, Input } from '@angular/core';
import { IconComponent as UICoreIC } from '@softline/ui-core';

@Component({
  selector: 'soft-dynamic-icon-value',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  imports: [UICoreIC],
})
export class IconComponent {
  @Input() value?: any;

  constructor() {}
}
