import { InjectionToken } from '@angular/core';
import { LocalizationFile } from './data/localization-file';

export const SOFTLINE_CONFIG_DEFAULT_LOCALE = new InjectionToken<string>(
  'SOFTLINE_CONFIG_DEFAULT_LOCALE',
  {
    providedIn: 'root',
    factory: () => 'de'
  }
);

export const SOFTLINE_DATA_LOCALIZATIONS = new InjectionToken<LocalizationFile[]>('SOFTLINE_DATA_LOCALIZATIONS')

export const SOFTLINE_FEATURE_LOCALIZATION = 'i10n';

export const SOFTLINE_CONFIG_LOCALIZATION_BASE_PATH =
  new InjectionToken<string>('TRANSLATION_FILE_BASE_PATH');
