import { Pipe, PipeTransform } from '@angular/core';
import { PreviewService } from '../services/preview.service';

@Pipe({
    name: 'canPreview',
    pure: true,
    standalone: false
})
export class CanPreviewPipe implements PipeTransform {
  constructor(private previewService: PreviewService) {}

  transform(value: unknown): boolean {
    return this.previewService.canOpen(value);
  }
}
