import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldOkViewComponent } from '../../../../data/field-ok';
import { ObjectDefinition } from '../../../../data/definitions';
import { UiCoreModule } from '@softline/ui-core';
import { DynamicTableComponent } from '../../../../components/dynamic-table/dynamic-table.component';
import {
  FilterAndSortComponent,
  FilterConfig,
  SortConfig,
} from '@softline/application';
import { WithFilterAndSort } from '../field-ok-view-filter-and-sort.mixin';

export interface FieldOkTableViewConfig {
  definition: ObjectDefinition;
  filter?: FilterConfig;
  sort?: SortConfig;
}

@Component({
  selector: 'soft-field-ok-table-view',
  imports: [
    CommonModule,
    UiCoreModule,
    FilterAndSortComponent,
    DynamicTableComponent,
  ],
  templateUrl: './field-ok-table-view.component.html',
  styleUrls: ['./field-ok-table-view.component.scss'],
})
export class FieldOkTableViewComponent
  extends WithFilterAndSort()
  implements OnInit, FieldOkViewComponent<FieldOkTableViewConfig>
{
  @Input() config!: FieldOkTableViewConfig;
  constructor() {
    super();
  }

  async ngOnInit(): Promise<void> {}
}
