import { computed, inject, Signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

export function routeParam(name: string): Signal<string | null> {
  const route = inject(ActivatedRoute);
  const paramMap = toSignal(route.paramMap);
  return computed(() => paramMap()?.get(name) ?? null);
}

export function routeParamAsNumber(name: string): Signal<number | null> {
  const route = inject(ActivatedRoute);
  const paramMap = toSignal(route.paramMap);
  return computed(() =>  {
    const value = paramMap()?.get(name);
    return value ? Number(value) : null;
  });
}

export function queryParam(name: string): Signal<string | null>
export function queryParam(name: string, options:{defaultValue: string}): Signal<string>
export function queryParam(name: string, options?:{defaultValue: string}): Signal<string | null> {
  const route = inject(ActivatedRoute);
  const queryParamMap = toSignal(route.queryParamMap);
  return computed(() => queryParamMap()?.get(name) ?? options?.defaultValue ?? null);
}

export function queryParamAsNumber(name: string): Signal<number | null> {
  const route = inject(ActivatedRoute);
  const queryParamMap = toSignal(route.queryParamMap);
  return computed(() =>  {
    const value = queryParamMap()?.get(name);
    return value ? Number(value) : null;
  });
}

export function routeFragment(): Signal<string | null | undefined> {
  const route = inject(ActivatedRoute);
  const fragment = toSignal(route.fragment);
  return computed(() => fragment());
}

export function routeData<T extends object>(): Signal<T | undefined> {
  const route = inject(ActivatedRoute);
  const data = toSignal(route.data);
  return computed(() => data() as T);
}
