import { ImportedNgModuleProviders, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import { SOFTLINE_STORE_FEATURES, Store } from '@softline/core';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from '@softline/application';
import { SOFTLINE_PERMISSION_GESAMTABFRAGE } from './gesamtabfrage.permissions';
import { SOFTLINE_CONFIG_GESAMTABFRAGE } from './gesamtabfrage.shared';

import { gesamtabfrageQueryDefinition } from './definitions/gesamtabfrage-query.definition';
import { default as listDefaultDefinition } from './definitions/gesamtabfrage-list.definition.json';
import { default as businessCaseDefaultDefinition } from './definitions/business-case-list.definition.json';
import {
  SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
  SOFTLINE_DEFINITION_VOUCHER_LIST,
  SOFTLINE_DEFINITION_VOUCHER_QUERY
} from './gesamtabfrage.api';
import { SOFTLINE_CONFIG_DEFINITION } from '@softline/dynamic';
import { BelegDownloadStrategy } from './commands/download/beleg-download.strategy';
import { ArchivbelegDownloadStrategy } from './commands/download/archiv-beleg-download.strategy';
import { DatentraegerDownloadStrategy } from './commands/download/datentraeger-download.strategy';
import { FremdbelegDownloadStrategy } from './commands/download/fremd-beleg-download.strategy';
import { BelegartStore } from './store';

export const gesamtabfrageProviders: (Provider |ImportedNgModuleProviders)[] = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'gesamtabfrage',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#GESAMTABFRAGE.TITLE',
          icon: 'fa-regular fa-file-invoice',
          route: '/gesamtabfrage',
          permission: SOFTLINE_PERMISSION_GESAMTABFRAGE,
        }
      );
    }),
  BelegDownloadStrategy,
  FremdbelegDownloadStrategy,
  ArchivbelegDownloadStrategy,
  DatentraegerDownloadStrategy,
  {
    provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
    useValue: { download: [BelegDownloadStrategy] },
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_VOUCHER_QUERY,
      definition: gesamtabfrageQueryDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_VOUCHER_LIST,
      definition: listDefaultDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
      definition: businessCaseDefaultDefinition
    },
    multi: true
  },

  { provide: SOFTLINE_STORE_FEATURES, useExisting: BelegartStore, multi: true},
];
