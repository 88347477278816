import { inject, Injectable } from '@angular/core';
import { TerminalModule } from '../data/terminal-module';
import { MessageBarService, ModalService, passwordFn } from '@softline/ui-core';
import { SOFTLINE_SECRET_TERMINAL_PASSWORD } from '@softapps/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@softline/core';

@Injectable()
export class TerminalService {
  #LOCAL_STORAGE_KEY = 'SoftAppsTerminal'

  localStorageService = inject(LocalStorageService);
  router = inject(Router);
  modalService = inject(ModalService);
  messageBarService = inject(MessageBarService);
  terminalPassword = inject(SOFTLINE_SECRET_TERMINAL_PASSWORD);

  private password = passwordFn();
  public terminalModule: TerminalModule | null = null;

  constructor() { }

  async init(): Promise<void> {
    this.localStorageService.create({key: this.#LOCAL_STORAGE_KEY}, this.router.url);
  }

  register(module: TerminalModule): void {
    this.terminalModule = module;
  }

  unregister(module: TerminalModule): void {
    this.terminalModule = null;
  }

  async exit(): Promise<void> {
    if(this.terminalModule?.terminalExit === 'protected') {
      const password = await this.password({ title: 'Terminal verlassen' });
      if(password === 'DISMISSED')
        return;
      if(password !== this.terminalPassword) {
        this.messageBarService.warning('Das eingegebene Passwort ist Falsch!')
        return;
      }
    }
    this.localStorageService.delete({key: this.#LOCAL_STORAGE_KEY});
    const url = '/' + this.router.url.split('/').slice(2).join('/');
    await this.router.navigate([url])
  }
}
