<div class="flex flex-col">
  <h6 class="soft-overline !mb-0 p-0">{{ supplier()?.account?.number ?? ''}}</h6>
  <div class="flex flex-row gap-2 items-center">
    <h6 class="text-base font-semibold">{{ supplier()?.account?.name ?? '' }}</h6>
    <soft-chip
      [ngClass]="((supplier()?.account?.deletedOn | isDefinedNotEmpty)
  || (supplier()?.account?.lockedOn | isDefinedNotEmpty))
  ? 'bg-error-400'
  : 'bg-success-400'">
      {{((supplier()?.account?.deletedOn | isDefinedNotEmpty)
      || (supplier()?.account?.lockedOn | isDefinedNotEmpty)) ? 'Inaktiv' : 'Aktiv'}}
    </soft-chip>
  </div>
  @if (supplier()?.account?.contact?.lastName | isDefinedNotEmpty) {
    <h6 class="text-sm text-light">{{ supplier()?.account?.contact?.lastName }}</h6>
  }
</div>
