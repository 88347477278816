import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Modal } from '../../data/modal';
import { dataUriToBlob } from '@softline/core';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../../i18n/i18n.module';
import { DrawPadComponent } from '../../../../components/draw-pad/draw-pad.component';

@Component({
    selector: 'soft-draw-modal',
  imports: [CommonModule, UiCorePipesModule, I18nModule, DrawPadComponent],
    templateUrl: './draw-modal.component.html',
    styleUrls: ['./draw-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawModalComponent implements Modal<Blob | null> {
  close!: (result: Blob | null) => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;
  showCancel?: boolean;
  drawing?: string;

  constructor() {}

  registerCloseHandler(handler: (result: Blob | null) => void): void {
    this.close = handler;
  }

  onSubmit(value: string | null): void {
    if (typeof value === 'string') {
      const blob = dataUriToBlob(value);
      this.close(blob);
    } else this.close(value);
  }
}
