import {
  ChangeDetectionStrategy,
  Component,
  input,
  Input,
} from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { AtIndexPipe } from '../../pipes/array/at-index.pipe';

export type GalleryView = 'default' | 'grid';

@Component({
  selector: 'soft-gallery',
  imports: [CommonModule, AtIndexPipe],
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent {
  private _images: (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[] = [];
  safeUrls: Observable<string>[] = [];

  @Input()
  get images(): (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[] {
    return this._images;
  }
  set images(
    value: (
      | Blob
      | string
      | Promise<Blob | string>
      | Observable<Blob | string>
    )[]
  ) {
    this._images = value;
    this.safeUrls = this.getSaveUrls(value);
  }

  imageClass = input<string>('h-64');

  @Input() selectedIndex = 0;

  view = input<GalleryView>('default');

  get hasNext(): boolean {
    return (
      !!this.selectedIndex && (this.images.length - 1 < this.selectedIndex)
    );
  }

  get hasPrevious(): boolean {
    return !!this.selectedIndex && this.selectedIndex > 0;
  }

  constructor() {}

  getSaveUrls(
    value: (
      | Blob
      | string
      | Promise<Blob | string>
      | Observable<Blob | string>
    )[]
  ): Observable<string>[] {
    return value.map((o) => {
      if (typeof o === 'string') return of(o);
      else if (o instanceof Blob) return of(URL.createObjectURL(o));
      else if (o instanceof Promise)
        return from(o).pipe(
          map((p) => (typeof p === 'string' ? p : URL.createObjectURL(p)))
        );
      else
        return o.pipe(
          map((p) => (typeof p === 'string' ? p : URL.createObjectURL(p)))
        );
    });
  }

  showNext(): void {
    this.selectedIndex++;
  }

  showPrevious(): void {
    this.selectedIndex--;
  }
}
