import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-menu-separator',
    imports: [CommonModule],
    template: '',
    styleUrl: './menu-separator.component.scss'
})
export class MenuSeparatorComponent {}
