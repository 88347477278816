<div class="flex flex-row flex-wrap items-center justify-between hover:bg-hover p-2 gap-2 border-b xs:border-0" >
  <div class="relative" (click)="fileIconClick.emit()">
    <soft-icon [name]="fileName() | fileTypeIcon"
               class="fa-2x"></soft-icon>
    @if (!read()) {
      <div class="absolute top-0 right-0">
        <soft-icon [name]="'fa-solid fa-asterisk'" class="text-warning fa-sm"></soft-icon>
      </div>
    }
    @if(favorite()) {
      <div class="absolute top-0 right-0">
        <soft-icon [name]="'fa-solid fa-heart'" class="text-danger fa-sm"></soft-icon>
      </div>
    }
  </div>
  <div class="flex-1">
    <div class="flex flex-col px-2">
      <span class="text-xs text-light name break-all">{{userName()}}</span>
      <span class="name break-all">{{fileBetreff() ?? fileName()}}</span>
      @if(fileDescription(); as description) {
        <span class="text-xs text-light name whitespace-normal" [innerHTML]="description"></span>
      }
      <div class="flex flex-wrap justify-between items-center">
        <span class="text-xs text-lighter text-nowrap name">Zuletzt geändert: {{lastChanged() | format:'DATE_TIME.DEFAULT'}}</span>
        @if(fileSize(); as size) {
          <span class="text-xs text-lighter text-nowrap name">Größe: {{(size | filesize:{format: '1.0'})}}</span>
        }
      </div>
    </div>
  </div>
  <ng-content></ng-content>

</div>

