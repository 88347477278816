import { inject, Provider, provideEnvironmentInitializer, EnvironmentProviders } from '@angular/core';
import { TelemetryService } from "./services/telemetry.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TelemetryInterceptor } from "./interceptors/telemetry.interceptor";
import { TelemetryBaggageService } from "./services/telemetry-baggage.service";
import { RouteBaggageService } from "./services/route-baggage.service";

export const telemetryProviders: Array<Provider | EnvironmentProviders> = [
  TelemetryService,
  {provide: HTTP_INTERCEPTORS, useClass: TelemetryInterceptor, multi: true},
  provideEnvironmentInitializer(() => {
      const service = inject(TelemetryService)
      window['tracer'] = service;
    }),
  {
    provide: TelemetryBaggageService,
    useClass: RouteBaggageService,
    multi: true
  }
]
