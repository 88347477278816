import { Component, OnInit, Optional } from '@angular/core';
import { LabelType, Scan } from "@softline/application";
import { Modal } from '@softline/ui-core';
import { BarcodeFormat } from '@zxing/library';
import { DateService } from '@softline/core';

@Component({
    selector: 'soft-js-scanner',
    templateUrl: './js-scanner.component.html',
    styleUrls: ['./js-scanner.component.scss'],
    standalone: false
})
export class JSScannerComponent
  implements OnInit, Modal<{ result?: Scan; error?: Error }>
{
  close!: (result: { result?: Scan; error?: any }) => void;
  labelTypes!: BarcodeFormat[];

  constructor(private dateService: DateService) {}

  ngOnInit(): void {}

  onSuccess(data: any): void {
    if(!data)
      return;
    const labelType = this.getLabelType(data.format);
    this.close({
      result: { data, labelType, timestamp: this.dateService.now() },
    });
  }

  onFailure(): void {
    this.close({ error: new Error('Can not read Barcode') });
  }

  registerCloseHandler(
    handler: (result: { result?: Scan; error?: any }) => void
  ): void {
    this.close = handler;
  }

  getLabelType(labelType?: BarcodeFormat): LabelType | undefined {
    switch (labelType) {
      case BarcodeFormat.AZTEC:
        return 'aztec';
      case BarcodeFormat.CODABAR:
        return 'codabar';
      case BarcodeFormat.CODE_39:
        return 'code39';
      case BarcodeFormat.CODE_93:
        return 'code93';
      case BarcodeFormat.CODE_128:
        return 'code128';
      case BarcodeFormat.DATA_MATRIX:
        return 'datamatrix';
      case BarcodeFormat.EAN_8:
        return 'ean8';
      case BarcodeFormat.EAN_13:
        return 'ean13';

      case BarcodeFormat.ITF:
        return 'itf';
      case BarcodeFormat.MAXICODE:
        return 'maxicode';
      case BarcodeFormat.PDF_417:
        return 'pdf417';
      case BarcodeFormat.QR_CODE:
        return 'qrcode';
      case BarcodeFormat.RSS_14:
        return 'rss14';
      case BarcodeFormat.RSS_EXPANDED:
        return 'rss_expanded';
      case BarcodeFormat.UPC_A:
        return 'upc_a';
      case BarcodeFormat.UPC_E:
        return 'upc_e';
      default:
        return undefined;
    }
  }
}
