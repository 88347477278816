import {Injectable} from '@angular/core';
import {CancelledError} from '../../../types/errors';
import {SavingState} from '../../store2.shared'
import { CRUDParameters } from '../../../repositories/abstraction';
import {ReadonlyRepositoryObjectState, ReadonlyRepositoryObjectStore2} from './readonly-repository-object.store2'
import {lastValueFrom} from 'rxjs';

export interface RepositoryObjectState extends ReadonlyRepositoryObjectState {
  savingState: SavingState;
}

export interface CreateObjectParams<T extends Record<string, any>> extends CRUDParameters {
  token?: string;
  body: T
}

export interface UpdateObjectParams<T extends Record<string, any>> extends CRUDParameters {
  token?: string;
  body: T
}

export interface PatchObjectParams<T extends Record<string, any>> extends CRUDParameters {
  token?: string;
  body: T
}

export interface DeleteObjectParams extends CRUDParameters {
  token?: string;
}

@Injectable()
export class RepositoryObjectStore2<T extends object, TState extends RepositoryObjectState = RepositoryObjectState>
  extends ReadonlyRepositoryObjectStore2<T, TState> {

  constructor() {
    super();
  }

  override onRegister() {
    super.onRegister();
    this.registerSubFeature(this.subscription);
  }

  async create<TBody extends Record<string, any>>(params: CreateObjectParams<TBody>): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();

    try {
      const value = await lastValueFrom(
        this.subscription.observe(
          token,
          this.service.create(params.body, {
            pathParams: params.pathParams
          })
        )
      );

      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  async update<T extends Record<string, any>>(params: UpdateObjectParams<T>): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();

    try {
      const value = await lastValueFrom(
        this.subscription.observe(
          token,
          this.service.update(params.body, {
            pathParams: params.pathParams
          })
        )
      );

      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  async patch<T extends Record<string, any>>(params: PatchObjectParams<T>): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();

    try {
      const value = await lastValueFrom(
        this.subscription.observe(
          token,
          this.service.patch(params.body, {
            pathParams: params.pathParams
          })
        )
      );

      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  async delete<T extends Record<string, any>>(params: DeleteObjectParams): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();

    try {
      const value = await lastValueFrom(
        this.subscription.observe(
          token,
          this.service.delete({
            pathParams: params.pathParams
          })
        )
      );

      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  override getDefaultState(): TState {
    return {
      ...super.getDefaultState(),
      savingState: 'saved'
    }
  }
}
