import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { QueryParameters } from '@softline/core';
import { BehaviorSubject } from 'rxjs';
import { EntityDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  BooleanRulePipe,
  DynamicFormComponent,
  DynamicListComponent,
  DynamicObjectComponent,
  FormatRulePipe,
  ScanDefinitionPipe,
} from '@softline/dynamic';
import {
  DataViewDirective,
  EntityInputComponent as UICoreEIC,
  EntityPickerComponent,
  FieldValidationDirective,
  MasterDetailComponent,
  QueryViewDirective,
  UiCorePipesModule,
} from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { ScannerModule } from '@softline/application';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';

@Component({
  selector: 'soft-dynamic-entity-input',
  templateUrl: './entity-input.component.html',
  styleUrls: ['./entity-input.component.scss'],
  imports: [
    NgIf,
    BooleanRulePipe,
    UICoreEIC,
    ReactiveFormsModule,
    ScannerModule,
    EntityPickerComponent,
    QueryViewDirective,
    DynamicFormComponent,
    FormatRulePipe,
    UiCorePipesModule,
    ScanDefinitionPipe,
    FormControlPipe,
    FieldValidationDirective,
    DataViewDirective,
    DynamicListComponent,
    AsyncPipe,
    MasterDetailComponent,
    DynamicObjectComponent,
    NgForOf,
  ],
})
export class EntityInputComponent
  extends DynamicInputAtom<EntityDefinition>
  implements OnInit
{
  entities$: BehaviorSubject<unknown[]> = new BehaviorSubject<unknown[]>([]);

  constructor(private service: HttpClient) {
    super();
  }

  ngOnInit(): void {}

  onQuery(queryParameters: QueryParameters<unknown>): void {
    if (!this.definition) return;
    this.service
      .get<unknown[]>(this.definition.endpoint)
      .subscribe((o) => this.entities$.next(o));
  }
}
