import { Component, TemplateRef } from '@angular/core';
import { Modal } from '../../data/modal';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../../i18n/i18n.module';
import { GalleryComponent } from '../../../../components/gallery/gallery.component';

@Component({
    selector: 'soft-gallery-modal',
  imports: [CommonModule, UiCorePipesModule, I18nModule, GalleryComponent],
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements Modal<'DISMISSED'> {
  private close!: (result: 'DISMISSED') => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;

  images: (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[] = [];
  selectedIndex = 0;

  registerCloseHandler(handler: (result: 'DISMISSED') => void): void {
    this.close = handler;
  }

  onClick(result: 'DISMISSED'): void {
    this.close(result);
  }
}
