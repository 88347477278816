import { ContextStorageInterceptor } from "./context-storage.interceptor";
import { SOFTLINE_CONFIG_INDEXED_DB, Store } from "@softline/core";
import { SOFTLINE_INDEXED_DB_CONTEXT } from "./context.indexed-db";
import { inject, provideEnvironmentInitializer } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

export const contextStorageProviders = [
  { provide: SOFTLINE_CONFIG_INDEXED_DB, useValue: SOFTLINE_INDEXED_DB_CONTEXT, multi: true },
  { provide: ContextStorageInterceptor, useClass: ContextStorageInterceptor },
  { provide: HTTP_INTERCEPTORS, useExisting: ContextStorageInterceptor, multi: true},
  provideEnvironmentInitializer(() => {
      const interceptor = inject(ContextStorageInterceptor);
      interceptor.init();
    })
]
