import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {ApiResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK_LIST_SERVICE_URL} from '../umsatzstatistik.shared';

export abstract class UmsatzstatistikListService<T> {
  abstract loadItems(): Observable<T[]>
}

@Injectable()
export class DefaultUmsatzstatistikListService<T> implements UmsatzstatistikListService<T> {

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private readonly httpService: ResourceService<ApiResourceLocation>,
    @Inject(SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK_LIST_SERVICE_URL) private readonly path: string
  ) {}

  loadItems(): Observable<T[]> {
    return this.httpService.get(
      { path: this.path },
    );
  }
}
