<div class="flex flex-col gap-2 p-2 w-full overflow-y-auto max-h-[78vh]">
  <div class="sm:pl-0 sm:pt-0 text-2xl font-semibold">
    Reklamation Melden
  </div>

  <div>
    <label class="text-light text-sm">Fehler Typ</label>
    <!--  Abfrage wegen selectedGrund null/undefined-->
    <soft-select
      [value]="selectedGrund()()"
      (valueChange)="selectedGrund().set($event)"
      class="h-full border border-primary-400 p-2 w-48 lg:w-1/2">
      @for(grund of reklaGruende(); track grund.id) {
        <soft-select-option [value]="grund">
          {{grund.reklamationsgrund }}
        </soft-select-option>
      }
    </soft-select>
  </div>

  <div>
    <label class="text-light text-sm">Kommentar</label>
    <soft-multiline-input
      [value]="reklaKommentar()()"
      (valueChange)="reklaKommentar().set($event ?? null)"
      [placeholder]="'Kommentar eingeben...'"
      [lines]="5"></soft-multiline-input>
  </div>

  <div>
    <label class="text-light text-sm">Fotos</label>
    <soft-file-input (valueChange)="uploadedFiles.set($event)" [sources]="['camera', 'cameraRoll']" selectionMode="multi"></soft-file-input>
  </div>

  <button
    class="soft-button primary h-16 w-full flex items-center justify-center"
    (click)="submit()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
  </button>

</div>
