import { Component, TemplateRef } from '@angular/core';
import { Modal } from '../../data/modal';
import { ModalOption } from '../../data/option-modal-config';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../../i18n/i18n.module';
import { IconComponent } from '../../../../components/icon/icon.component';

@Component({
    selector: 'soft-option-modal',
    imports: [CommonModule, UiCorePipesModule, I18nModule, IconComponent],
    templateUrl: './option-modal.component.html',
    styleUrls: ['./option-modal.component.scss']
})
export class OptionModalComponent<T> implements Modal<T> {
  private close!: (result: T) => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;

  options?: ModalOption<T>[];

  registerCloseHandler(handler: (result: T) => void): void {
    this.close = handler;
  }

  onClick(result: ModalOption<T>): void {
    if(result.disabled)
      return;
    this.close(result.value);
  }
}
