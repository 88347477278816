import { InjectionToken, Type } from '@angular/core';
import { GesamtabfrageDownloadStrategy } from './commands/download/gesamtabfrage-download-strategy';

export const SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG = 'gesamtabfrage/beleg';
export const SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG_WIDGET = 'gesamtabfrage/beleg/widget';
export const SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART = 'gesamtabfrage/belegart';
export const SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL = 'gesamtabfrage/geschaefftsfall';

export interface GesamtabfrageExportConfig {
  belegart: string;
  strategies: Type<GesamtabfrageDownloadStrategy>[];
}

export interface GesamtabfrageConfig {
  geschaeftsfall?: boolean;
  download?: (Type<GesamtabfrageDownloadStrategy> | GesamtabfrageExportConfig)[];
  deliveryNoteProcessing?: boolean;
  stornoAnzeigen?: boolean;
}

export const SOFTLINE_CONFIG_GESAMTABFRAGE = new InjectionToken<GesamtabfrageConfig>('SOFTLINE_CONFIG_GESAMTABFRAGE');
