<div class="flex flex-row justify-between flex-wrap gap-4">
  <div>
    <div #ref (click)="openDatePickerDialog()">
      <ng-content></ng-content>
    </div>
    @if(!ref.hasChildNodes()) {
      <div class="flex flex-col">
        <span class="soft-overline">
          {{ date | format: 'DATE.DAYNAME' }}
        </span>
        <span class="font-bold pointer hover:underline"
              (click)="openDatePickerDialog()">
          {{ date | format: 'DATE.WITH_MONTHNAME' }}
        </span>
        <span class="text-xs text-light mt-1">
        KW {{ date | format: 'DATE.WEEK' }}
        </span>
      </div>
    }
  </div>

  @if(headerContentTemplate(); as headerContentTemplate) {
    <ng-container *ngTemplateOutlet="headerContentTemplate"></ng-container>
  }


  <div class="flex flex-row flex-nowrap gap-4 justify-center items-center">
    <div class="w-8 h-8 pointer flex flex-row justify-center items-center"
         (click)="onChange(date, -1)">
      <soft-icon name="fa-regular fa-chevron-left"></soft-icon>
    </div>
    <div class="w-8 h-8 pointer flex flex-row justify-center items-center hover:underline"
         (click)="onChange(dateService.today(), 0)">
      {{'#UI_CORE.COMPONENTS.DATE_NAVIGATOR.TODAY' | translate}}
    </div>
    <div class="w-8 h-8 pointer  flex flex-row justify-center items-center"
         (click)="onChange(date, 1)">
      <soft-icon name="fa-regular fa-chevron-right"></soft-icon>
    </div>
  </div>
</div>

<!--<div class="c row space-between mt-4">
  <span class="soft-link flex flex-row flex-nowrap items-center" (click)="onChange(date, -1)">
    <i class="fa-regular fa-chevron-left"></i>
    <span class="f-1 ml-2 hidden sm:block">{{
      '#UI_CORE.COMPONENTS.DATE_NAVIGATOR.PREVIOUS_DAY' | translate
    }}</span>
  </span>
  <div class="center f-2" (click)="openDatePickerDialog()">
    <div #ref>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!ref.hasChildNodes()">
      {{ date | format: 'DATE.DEFAULT' }}
    </ng-container>
  </div>
  <span class="soft-link  flex flex-row flex-nowrap items-center" (click)="onChange(date, 1)">
    <span class="f-1 mr-2 hidden sm:block">{{
      '#UI_CORE.COMPONENTS.DATE_NAVIGATOR.NEXT_DAY' | translate
    }}</span>
    <i class="fa-regular fa-chevron-right"></i>
  </span>
</div>
-->
