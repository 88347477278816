import { Component, computed, Inject, Input, OnInit, signal, Type } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldOkItemComponent, FieldOkViewComponent } from "../../../../data/field-ok";
import { UiCoreModule } from "@softline/ui-core";
import { FieldOkItemOutletDirective } from "../../../../directives/field-ok-item-outlet.directive";
import { FilterAndSortComponent, FilterConfig, SortConfig } from "@softline/application";
import {
  Entity,
  isDefinedNotEmpty,
  MultiSelectStrategy,
  SOFTLINE_DATA_VALUE,
  StringSelectStrategy
} from "@softline/core";
import { FieldOkInputParams, FieldOkStrategy } from "../../../field-ok/strategies/field-ok.strategy";
import { combineLatestWith, firstValueFrom, map, of } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { QueryFieldOkStrategy } from "../../strategies/query-field-ok.strategy";
import { WithFilterAndSort } from "../../../field-ok/views/field-ok-view-filter-and-sort.mixin";

export interface QueryFieldOkListViewConfig {
  component: Type<FieldOkItemComponent<any>>;
  filter?: FilterConfig;
  sort?: SortConfig;
}

@Component({
    selector: 'soft-field-ok-query-list-view',
    imports: [CommonModule, UiCoreModule, FieldOkItemOutletDirective, FilterAndSortComponent],
    templateUrl: './query-field-ok-list-view.component.html',
    styleUrls: ['./query-field-ok-list-view.component.scss']
})
export class QueryFieldOkListViewComponent extends WithFilterAndSort() implements OnInit, FieldOkViewComponent<QueryFieldOkListViewConfig>{

  private selectStrategy = new MultiSelectStrategy();

  selected = signal<string>('');
  selected$ = toObservable(this.selected)
    .pipe(
      combineLatestWith(this.strategy.items$, this.queryStrategy.multipleValueName$),
      map(([selected, items, multipleValueName]) => {
        const selectedItems = selected.split(',');
        return (items ?? []).filter(o => selectedItems.includes('' + o[multipleValueName]))
      })
    );

  @Input() config!: QueryFieldOkListViewConfig;
  constructor(@Inject(FieldOkStrategy<any, any, any>) public queryStrategy: QueryFieldOkStrategy,
              @Inject(SOFTLINE_DATA_VALUE)private options: FieldOkInputParams<string, object>) {
    super();
    this.selected.set(options.current ?? '');
  }

  async ngOnInit(): Promise<void> {
  }

  async select(value: Entity): Promise<void> {
    const multipleValueName = await firstValueFrom(this.queryStrategy.multipleValueName$);
    const selected = this.selected().split(',')
      .filter(o => isDefinedNotEmpty(o));
    const selectValue = '' + value[multipleValueName];
    if(!isDefinedNotEmpty(selectValue))
      return;
    if(selected.indexOf(selectValue) === -1)
      this.selected.set(this.selectStrategy.select([selectValue], selected).join(','));
    else {
      this.selected.set(this.selectStrategy.unselect([selectValue], selected).join(','));
    }
  }
}
