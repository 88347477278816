<div class="soft-card h-full flex flex-col gap-2 min-h-[40vh] max-h-[75vh] overflow-y-hidden rounded">

  <div class="flex items-center justify-between">
    <div class="flex flex-col">
      <h3 class="text-xl font-semibold">{{ '#UMSATZSTATISTIK.TITLE' | translate }}</h3>
      <p class="flex font-normal text-sm text-light">am {{ vondatum() | format:'DATE.DEFAULT' }}</p>
    </div>
    <span class="p-2 flex items-center justify-center rounded-full h-10 w-10 badge-umsatz-statistic">
      <i class="fa-regular fa-regular fa-chart-mixed-up-circle-dollar text-accent-800"></i>
    </span>
  </div>

  <soft-main-chart
    [chartClass]="'min-h-[28vh] max-h-[28vh]'"
    [data]="chartData()"
    [loading]="loading()">
  </soft-main-chart>
  <div class="w-full soft-separator mt-2"></div>

  <soft-compact-chart class="my-2" [werte]="sum()"  [loading]="loading()"></soft-compact-chart>
</div>
