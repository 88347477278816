import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { DateService, getValue, isDefined } from '@softline/core';
import { LocalizationFile } from './data/localization-file';
import { formatCurrency, formatNumber, getCurrencySymbol } from '@angular/common';
import moment from 'moment/moment';
import { SOFTLINE_CONFIG_DEFAULT_LOCALE, SOFTLINE_DATA_LOCALIZATIONS } from './l10n.shared';

export interface CurrencyInfo {
  code: string;
  display?: 'code' | 'symbol' | 'symbol-narrow'
}

export interface FormatOptions {
  locale?: string;
  module?: string;
  currency?: CurrencyInfo;
  utcOffset?: string | null;
  preventNumberDefault?: boolean;
}

const MUSTACHE_REGEX = /{{\s?([^{}\s]*)\s?}}/g;

@Injectable({providedIn: 'root'})
export class LocalizationService {

  private dateService = inject(DateService);
  private defaultLocale = inject(SOFTLINE_CONFIG_DEFAULT_LOCALE);
  private readonly files = inject(SOFTLINE_DATA_LOCALIZATIONS, {optional: true}) ?? [];
  readonly locale = signal(this.defaultLocale);

  private readonly _localizationFiles = signal<LocalizationFile[]>(this.files);
  private readonly _activeFiles = computed(() => {
    const locale = this.locale();
    return this._localizationFiles().filter(o => o.locale === locale);
  });

  readonly localizationFiles = this._localizationFiles.asReadonly();

  addFile(file: LocalizationFile): void {
    this._localizationFiles.set([...this._localizationFiles(), file]);
  }

  format(value: unknown, format?: string | null, options?: FormatOptions): Signal<string> {
    return computed(() => {
      format = this.findFormat(format) ?? format;
      const locale = this.locale();
      switch (typeof value) {
        case 'number':
          if (!format && options?.preventNumberDefault)
            return '' + value;
          if (!format)
            return formatNumber(value, options?.locale ?? locale);
          if (options?.currency)
            return this.getCurrency(
              value,
              format,
              options?.currency,
              options?.locale ?? locale
            );
          return formatNumber(
            value,
            options?.locale ?? locale,
            format
          );
        case 'string':
          if (!format)
            return '' + value;
          const date = this.formatDate(value, format, options?.utcOffset);
          if (date)
            return date;
          break;
        case 'object':
          if (!format) return JSON.stringify(value);
          return format.replace(MUSTACHE_REGEX, (substring: string, b: string) => {
            const replacement = this.getObjectReplacement(value, b);
            return isDefined(replacement) ? replacement : substring;
          });
          break;
        default:
          break;
      }
      return '' + value;
    });
  }

  private findFormat(key: string | null | undefined): string | null {
    if(!key)
      return null;
    const files = this._activeFiles();
    for (const file of files) {
      const format = this.findInFile(key, file);
      if (format)
        return format;
    }
    return null;
  }

  private findInFile(key: string, file: LocalizationFile): string | undefined {
    const parts = key.split('.');
    let formats = file.formats as any;
    let format: string | undefined;
    while (parts.length > 0) {
      const partName = parts.shift() ?? '';

      if (!formats)
        break;

      const part = formats[partName];

      if (typeof part === 'object') formats = part;
      else if (parts.length === 0 && typeof part === 'string') format = part;
      else break;
    }
    return format;
  }

  private getCurrency(
    value: number,
    format: string,
    currencyInfo: {
      code: string;
      display?: 'code' | 'symbol' | 'symbol-narrow';
    },
    locale: string
  ): string {
    let currency: string = currencyInfo.code;
    if (
      currencyInfo.display === 'symbol' ||
      currencyInfo.display === 'symbol-narrow'
    )
      currency = getCurrencySymbol(
        currencyInfo.code,
        currencyInfo.display === 'symbol' ? 'wide' : 'narrow',
        locale
      );

    return formatCurrency(value, locale, currency, currencyInfo.code, format);
  }

  private formatDate(
    value: string,
    format: string,
    utcOffset?: string | null
  ): string | undefined {
    if (value.startsWith('PT')) {
      return moment.duration(value).format(format, { trim: false });
    }
    if (value.startsWith('T'))
      value = moment(this.dateService.today()).format('YYYY-MM-DD') + value;
    else if (/^\d{2}:\d{2}(:\d{2}(.\d+)?)?$/g.test(value))
      value = `${moment(this.dateService.today()).format(
        'YYYY-MM-DD'
      )}T${value}`;
    const date = moment(value);
    if (isDefined(utcOffset)) date.utcOffset(utcOffset);
    if (date.isValid()) return date.format(format);
    return undefined;
  }

  private getObjectReplacement(value: unknown, mustache: string): string {
    const [name, format] = mustache.replace('{', '').replace('}', '').split(':');

    let replacement = getValue(value, name);
    if (!format) return replacement;

    const resolvedFormat =  this.findFormat(format.replace("'", ''))
    if (!resolvedFormat)
      return replacement;

    switch (typeof replacement) {
      case 'string':
        const formattedDate = this.formatDate(replacement, resolvedFormat);
        if (formattedDate) replacement = formattedDate;
        break;
      case 'number':
        replacement = formatNumber(
          replacement,
          this.locale() ?? 'de',
          resolvedFormat
        );
        break;
      default:
        break;
    }
    return replacement;
  }
}
