import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-date-time-range-picker',
    imports: [CommonModule],
    templateUrl: './date-time-range-picker.component.html',
    styleUrl: './date-time-range-picker.component.scss'
})
export class DateTimeRangePickerComponent {}
