import { inject, Injector, Signal, TemplateRef } from '@angular/core';
import { Dismissed, ResolvableModalConfig } from '../types/modal-config';
import { ModalService } from '../modal.service';
import { FileModalComponent } from '../components/file-modal/file-modal.component';
import { FileSourceInput } from '../../components/form/file-input/file-source';

export interface OpenFileModalConfig2 {
  title?: string | Signal<string | null>;
  subtitle?: string | Signal<string | null>;
  text?: string | Signal<string | null>;
  content?: TemplateRef<any> | Signal<TemplateRef<any> | null>;
  contentContext?: object | Signal<object | null>;
  showCancel?: boolean;
  class?: string;
  dismiss?: boolean;

  sources?: FileSourceInput;
  accept?: string;
  inputView?: 'common' | 'dragAndDrop';
  selectionMode?: 'single' | 'multi';
  valueView?: 'grid' | 'list';
  autoSubmit?: boolean;
}

async function openFile(modalService: ModalService, config: OpenFileModalConfig2, injector: Injector): Promise<File[] | null | Dismissed> {
  const modalConfig: ResolvableModalConfig<FileModalComponent, File[] | null> = {
    component: FileModalComponent,
    dismiss: config.dismiss,
    title: config.title ?? 'Datei öffnen',
    subtitle: config.subtitle,
    text: config.text,
    template: config.content,
    templateContext: config.contentContext,
    priority: Number.POSITIVE_INFINITY,
    data: {
      sources: config.sources ?? 'all',
      accept: config.accept,
      inputView: config.inputView ?? 'dragAndDrop',
      selectionMode: config.selectionMode ?? 'single',
      valueView: config.valueView ?? 'list',
      autoSubmit: config.autoSubmit ?? false,
    },
    class: config.class,
    injector: injector
  }
  return await modalService.open(modalConfig);
}

export function openFileFn(): (config: OpenFileModalConfig2) => Promise<File[] | null | Dismissed> {
  const modalService = inject(ModalService);
  const injector = inject(Injector);
  return (config) => openFile(modalService, config, injector)
}
