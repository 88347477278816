import { Component, OnInit } from '@angular/core';
import { Dictionary, equals, Store } from '@softline/core';
import {
  CheckboxComponent,
  CheckGroupDirective,
  ChipComponent,
  ChipGroupComponent,
  FieldValidationDirective,
  IconComponent,
  ModalStore,
  MultiSelectComponent,
  SelectOptionDirective,
  SOFTLINE_FEATURE_MODAL,
  TranslatePipe,
  TrueOnlyCheckStrategy,
  UiCorePipesModule,
} from '@softline/ui-core';
import { MultiSelectDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { MultiSelectDialogComponent } from './multi-select-dialog/multi-select-dialog.component';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';

@Component({
  selector: 'soft-dynamic-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss'],
  imports: [
    NgIf,
    NgSwitch,
    BooleanRulePipe,
    CheckGroupDirective,
    NgSwitchCase,
    NgClass,
    FieldValidationDirective,
    FormControlPipe,
    CheckboxComponent,
    NgForOf,
    NgTemplateOutlet,
    TranslatePipe,
    ChipGroupComponent,
    ChipComponent,
    UiCorePipesModule,
    IconComponent,
    MultiSelectComponent,
    SelectOptionDirective,
  ],
})
export class MultiSelectInputComponent
  extends DynamicInputAtom<MultiSelectDefinition>
  implements OnInit
{
  displayAll = false;
  strategy = new TrueOnlyCheckStrategy();

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {}

  async openDialog(values: any[]): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        component: MultiSelectDialogComponent,
        data: {
          values,
          options: this.definition.options,
          title: this.definition.title,
        },
        dismiss: {
          escape: true,
          button: true,
          backdrop: true,
        },
      }
    );
    if (result !== 'DISMISSED') {
      const dictionary: Dictionary<any> = {};
      dictionary[this.definition.name] = result;
      this.form.patchValue(dictionary);
    }
  }

  findOption(option: { value: any; title: string }, value: any): boolean {
    return equals(option.value, value);
  }

  onDelete(values: any[], index: number): void {
    values = [...values];
    values.splice(index, 1);
    const dictionary: Dictionary<any> = {};
    dictionary[this.definition.name] = values;
    this.form.patchValue(dictionary);
  }
}
