import {ChangeDetectionStrategy, Component, Inject, Optional, Type} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BlockingLoadingSpinnerComponent, RefreshService, SOFTLINE_FEATURE_MASTER_DATA} from "@softline/application";
import {RemoteObjectStore, Store} from "@softline/core";
import {map} from "rxjs/operators";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {BehaviorSubject, combineLatest} from "rxjs";
import {ModalOption, ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {Router} from "@angular/router";
import {Firma} from "../data/firma";
import {
  EditContextDialogComponent,
  PatchContextStore, SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT, SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore
} from "@softapps/allgemein/utils";
import {ContextOption} from '../../types/context-option';
import {PatchContextService} from '../../services/patch-context.service';

@Component({
    selector: 'soft-firma-context-widget',
    imports: [CommonModule, BlockingLoadingSpinnerComponent],
    templateUrl: './firma-context-widget.component.html',
    styleUrls: ['./firma-context-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirmaContextWidgetComponent {

  readonly pending$ = new BehaviorSubject(false);

  firmen$ = this.store
    .observe(SOFTLINE_FEATURE_MASTER_DATA, RemoteObjectStore.getters.data)
    .pipe(map((o) => (o as { firmaModule: Firma[] })?.firmaModule ?? []));

  activeFirmaId$ = this.store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data).pipe(
    map(o => (o as any)?.firmaid)
  )

  selectedFirma$ = combineLatest([this.firmen$, this.activeFirmaId$]).pipe(
    map(([firmen, currentFirmaId]) => firmen.find(({ id }) => id === currentFirmaId))
  )

  constructor(private store: Store,
              private router: Router,
              private patchContextService: PatchContextService,
              @Optional()
              @Inject(SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT)
              public components?: Type<Component>[]) { }

  async onChangeCompany(firmen: Firma[]): Promise<void> {
    const options = firmen.map<ModalOption<string>>(o => ({
      value: o?.id + '',
      label: o?.firma ?? o?.kurzbezeichnung ?? ''
    }));

    if (this.components && this.components.length > 1) {
      options.push({
        value: 'FIRMENWECHSEL_OEFFNEN',
        label: '#UTILITIES.CONTEXT.DETAIL_CONTEXT_SETTINGS',
        icon: 'fa-regular fa-user-gear'
      })
    }

    await this.patchContextService.patchContextOptionsDialog<string>({
      title: 'Vkforg auswählen',
      options,
      editContextDialogOptionValue: 'FIRMENWECHSEL_OEFFNEN',
      setOnSelect: (contextResult) => {
        return {
          firmaid: +contextResult,
        }
      }
    });
  }
}
