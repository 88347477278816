import { base64Encode } from '@softline/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Beleg } from '../types/beleg.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Belegart } from '../types/belegart.model';

export class EditDeliveryNoteCommand {
  icon = 'fa-regular fa-edit';
  name = '#GESAMTABFRAGE.ACTIONS.EDIT-DELIVERY-NOTE';
  class = 'menu action-menu action-menu-top';
  belegart$ = this.selected$.pipe(
    map((o) => {
      const belegarten = o.map((p) => p.belegart).filter((v, i, a) => a.findIndex((q) => q.id === v.id) === i);

      if (belegarten.length === 1) return belegarten[0];
      return undefined;
    })
  );

  constructor(
    private selected$: Observable<readonly Beleg[]>,
    private belegart: Belegart,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  execute = async () => await this.navigate();

  canExecute: Observable<boolean> = this.selected$.pipe(
    map((vouchers) => vouchers.some((voucher) => voucher.fakturenfreigabe === 'N' && voucher.lieferscheinstatus !== 'F'))
  );

  async navigate(): Promise<void> {
    const vouchers = await firstValueFrom(this.selected$);
    const vouchersIds = vouchers.map((voucher) => voucher.id);

    if (vouchersIds.length === 0) return;
    const data = {
      deliveryNotes: [...vouchersIds],
      route: `/gesamtabfrage/${this.belegart.id}/list`,
      routeQueryParams: this.activatedRoute.snapshot.queryParams,
    };

    const encodedData = base64Encode(JSON.stringify(data));
    await this.router.navigate(['/delivery-note-processing'], { queryParams: { data: encodedData } });
  }
}
