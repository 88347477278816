import { computed, Injectable, signal } from '@angular/core';
import { Refreshable } from '../refreshable';

@Injectable({providedIn: 'root'})
export class RefreshService {
  private _refreshables = signal<Refreshable[]>([])
  private _refreshing = signal(false);

  refreshing = this._refreshing.asReadonly();
  canRefresh = computed(() => {
    const refreshables = this._refreshables();
    return refreshables.some((o) => !o.canRefresh || o.canRefresh());
  })

  add(refreshable: Refreshable): void {
    this._refreshables.set([...this._refreshables(), refreshable]);
  }

  remove(refreshable: Refreshable): void {
    const refreshables = [...this._refreshables()];
    refreshables.splice(refreshables.indexOf(refreshable), 1);
    this._refreshables.set(refreshables);
  }

  async refresh(): Promise<void> {
    this._refreshing.set(true);
    const refreshables = this._refreshables().filter(o => !o.canRefresh || o.canRefresh());
    await Promise.all(
      refreshables.map((o) => o.refresh())
    );
    this._refreshing.set(false);
  }
}
