import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, IconComponent, Modal } from '@softline/ui-core';
import { Bewe } from '../../types/bewegung';

export type TeillieferungResponse = 'reklemation'| 'confirmation';

@Component({
  selector: 'soft-teillieferung-dialog',
  imports: [CommonModule, I18nModule, IconComponent],
  templateUrl: './teillieferung-dialog.component.html',
  styleUrl: './teillieferung-dialog.component.scss',
})
export class TeillieferungDialogComponent
  implements Modal<TeillieferungResponse>
{
  close!: (result: TeillieferungResponse) => void;

  registerCloseHandler(handler: (result: TeillieferungResponse) => void): void {
    this.close = handler;
  }
}
