import { Signal } from '@angular/core';

export interface Refreshable {
  canRefresh?: Signal<boolean>;
  refresh(): Promise<void>;
}

export function isRefreshable(program: any): program is Refreshable {
  return (program as Refreshable)?.refresh !== undefined;
}
