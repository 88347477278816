import { Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemService } from '@softline/application';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
  selector: 'soft-menu-outlet',
  imports: [CommonModule, MenuItemComponent],
  templateUrl: './menu-outlet.component.html',
  styleUrl: './menu-outlet.component.scss',
})
export class MenuOutletComponent {
  service = inject(MenuItemService);

  name = input.required<string>();

  menuItems = computed(() => {
    const name = this.name();
    return this.service.menuItems().filter(item => item.outlet === name)
      .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  });

  constructor() {}
}
