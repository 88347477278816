import { importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG,
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE, SOFTLINE_PERMISSION_MDE_BESTELLVORSCHLAG
} from "./bestellvorschlag.shared";
import {BestellvorschlagStore} from './store/bestellvorschlag.store';
import {
  SOFTLINE_API_MDE_BESTELLVORSCHLAG_ARTIKEL,
  SOFTLINE_API_MDE_BESTELLVORSCHLAG_BEWEGUNGEN
} from './bestellvorschlag.api';
import {BestellvorschlagBeweStore} from './store/bestellvorschlag-bewe.store';
import {BestellvorschlagService} from './services/bestellvorschlag.service';
import {BestellvorschlagArtikelDetailStore} from './store/bestellvorschlag-artikel-detail.store';
import {BestellvorschlagResolver} from './resolver/bestellvorschlag.resolver';

export const bestellvorschlagProviders = [
  provideEnvironmentInitializer(async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeBestellvorschlag',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Bestellvorschlag',
          icon: 'fa-regular fa-cart-arrow-down',
          route: '/mde-bestellvorschlag',
          permission: SOFTLINE_PERMISSION_MDE_BESTELLVORSCHLAG,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE,
        BestellvorschlagBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    }),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG,
      feature: BestellvorschlagStore.feature,
      providers: [{
        provide: BestellvorschlagService,
        useClass: BestellvorschlagService
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE,
      feature: BestellvorschlagBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_BESTELLVORSCHLAG_BEWEGUNGEN
        )
      }]
    })
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS,
      feature: BestellvorschlagArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_BESTELLVORSCHLAG_ARTIKEL,
              'detail'
            );
          }
        }
      ]
    })
  ),
  BestellvorschlagResolver,
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG, fields: ['datum'] },
    multi: true
  },
]
