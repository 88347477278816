import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-duration-format';

@Pipe({
    name: 'duration',
    pure: true,
    standalone: false
})
export class DurationFormatPipe implements PipeTransform {
  transform(value: string | null | undefined, format?: string): string {
    if (!value) return '';
    const duration = moment.duration(value);
    if (!duration.isValid()) return value;
    return duration.format(format ?? 'HH:mm', {
      trim: false,
    });
  }
}
