import {
  Component,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  runInInjectionContext,
  Signal,
  signal,
  Type,
} from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { GroupDefinition } from '../../../../data/definitions';
import { DefinitionTransformer } from '../../../../services/definition.transformer';
import { Subscription } from 'rxjs';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { TabComponent, TabGroupComponent } from '@softline/ui-core';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import { DynamicInputFieldDirective } from '../../../../directives/dynamic-input-field.directive';
import { ClassRulePipe } from '../../../../pipes/class-rule.pipe';

@Component({
  selector: 'soft-dynamic-group-input',
  templateUrl: './group-input.component.html',
  styleUrls: ['./group-input.component.scss'],
  imports: [
    NgSwitch,
    TabGroupComponent,
    NgSwitchCase,
    NgClass,
    ClassRulePipe,
    TabComponent,
    BooleanRulePipe,
    NgIf,
    NgTemplateOutlet,
    NgForOf,
    DynamicInputFieldDirective,
  ],
})
export class GroupInputComponent implements OnInit, OnDestroy {
  private injector = inject(Injector);
  private subscription?: Subscription;
  private _definition: GroupDefinition | null | undefined = null;

  $definition: Signal<GroupDefinition> = signal({} as GroupDefinition);

  transformer: DefinitionTransformer<GroupDefinition> | null = null;

  get definition(): GroupDefinition | null | undefined {
    return this.$definition();
  }

  @Input({ required: true })
  set definition(value: GroupDefinition | null | undefined) {
    this._definition = value;
    if (value && value.transformer) {
      if (this.subscription && !this.subscription.closed)
        this.subscription.unsubscribe();

      this.transformer = this.createTransformer(
        value.transformer,
        value.transformerArgs
      );
      this.subscription = this.form.valueChanges.subscribe((o) => {
        if (!this.transformer || !this._definition) return;

        this.$definition = this.transformer?.transform(this._definition, o);
      });
      this.$definition = this.transformer.transform(value, this.form.value);
      return;
    }
    if (value) this.$definition = signal(value);
  }

  formGroupDirective = inject(FormGroupDirective);
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
  }

  private createTransformer(
    type: string | Type<DefinitionTransformer<GroupDefinition>>,
    args: any[] | undefined
  ): DefinitionTransformer<GroupDefinition> {
    if (typeof type === 'string') {
      return new DefinitionTransformer<GroupDefinition>();
    } else {
      return runInInjectionContext(
        this.injector,
        () => new type(...(args ?? []))
      );
    }
  }
}
