import { ArtikelMenge } from "./artikel-menge";
import { Lagerplatz } from "./lagerplatz";
import { Palette } from "./palette";

export interface MischPalette {
  id: number;
  palette?: Palette;
  artikel: ArtikelMenge[];
  lagerplatz?: Lagerplatz;
}
