import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { sortBy } from '@softline/core';
import { ModalService } from '../modal.service';
import { ModalComponent } from './modal/modal.component';

@Component({
  selector: 'soft-modal-container-2',
  imports: [CommonModule, ModalComponent],
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss',
})
export class ModalContainerComponent {
  service = inject(ModalService);

  configs = computed(() => {
    const configs = this.service.configs();
    return sortBy(configs, 'priority', 'ASC');
  })

  activeConfig = computed(() => {
    const configs = this.configs();
    if(configs.length === 0)
      return null;
    return configs.slice(-1)[0];
  });
}
