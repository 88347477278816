<div class="flex flex-col max-h-[80vh] overflow-y-auto">
  @for(option of options(); track option; let first = $first) {
    <div
      [class.separator-top]="!first"
      [class]="option?.class ?? ''"
      (click)="onClick(option)"
      class="flex flex-row items-center pt-4 pb-4 pl-3 pr-3 border-box"
      [ngClass]="{
        'cursor-pointer hover:bg-hover': !option.disabled,
        'cursor-not-allowed text-lighter': option.disabled,
        }"
    >
      @if (option.icon) {
        <i class="mr-3" [ngClass]="option?.icon"></i>
      }
      <span [ngClass]="option.disabled ? 'text-lighter' : 'soft-text'">{{option?.label}}</span>
    </div>
  }
</div>
