import { Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_GEOLOCATION } from '../geolocation.shared';
import * as GeolocationStore from '../geolocation.store';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
} from '@softline/ui-core';
import { Observable } from 'rxjs';
import { Position } from '../position';

@Component({
    selector: 'lib-geolocation-settings',
    templateUrl: './geolocation-settings.component.html',
    styleUrls: ['./geolocation-settings.component.scss'],
    standalone: false
})
export class GeolocationSettingsComponent implements OnInit {
  position$: Observable<Position> = this.store.observe(
    SOFTLINE_FEATURE_GEOLOCATION,
    GeolocationStore.getters.latest
  );

  constructor(private store: Store) {}

  ngOnInit(): void {}

  async onGetCurrentPosition(): Promise<void> {
    let messageBarId: string | undefined;
    try {
      messageBarId = await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.loading
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_GEOLOCATION,
        GeolocationStore.actions.getCurrentPosition
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.close,
        messageBarId
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#APPLICATION.GEOLOCATION.SETTINGS.MESSAGES.SUCCESS'
      );
    } catch (e) {
      console.error(e);
      if (messageBarId)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.close,
          messageBarId
        );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.warning,
        '#APPLICATION.GEOLOCATION.SETTINGS.MESSAGES.ERROR'
      );
    }
  }
}
