<div class="modal question-modal">
  <div class="flex no-wrap w-full gap-4 px-4 sm:px-0 pb-4 pt-2">
    <button
      *ngIf="showCancel()"
      class="soft-button link cancel h-16 w-full"
      (click)="onClick('CANCEL')"
    >
      {{ '#UI_CORE.DIALOGS.CANCEL' | translate }}
    </button>
    <button
      class="soft-button secondary no h-16 w-full"
      (click)="onClick('NO')"
    >
      {{ '#UI_CORE.DIALOGS.NO' | translate }}
    </button>
    <button
      class="soft-button accent yes sm:ml-3 h-16 w-full"
      (click)="onClick('YES')"
    >
      {{ '#UI_CORE.DIALOGS.YES' | translate }}
    </button>
  </div>
</div>
