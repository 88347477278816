import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  ApplicationModule,
  BackNavigable,
  BackNavigationService,
  Command, CommandStore,
  showRequestErrors, SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
  UploadFileCommand
} from '@softline/application';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatestWith, filter, first, map, switchMap} from 'rxjs/operators';
import {Dictionary, isDefined, Patch, RequestError, SOFTLINE_SERVICE_UUID, Store} from '@softline/core';
import {SOFTLINE_FEATURE_DELIVERY_NOTE, SOFTLINE_FEATURE_RETURN_NOTE} from '../../delivery-note.shared';
import {DeliveryNotesStore} from '../../stores/';
import {lastValueFrom, Observable, of, Subscription} from 'rxjs';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from '@softline/ui-core';
import {SignatureDialogComponent} from '../../dialogs/signature-dialog/signature-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {DeliveryNote} from '../../types/delivery-note';
import {
  CloseDeliveryNoteDialogComponent
} from '../../dialogs/close-delivery-note-dialog/close-delivery-note-dialog.component';
import {DefaultCommentDialogComponent} from '../../dialogs/default-comment-dialog/default-comment-dialog.component';
import {ReturnNoteStore} from '../../stores/return-note.store';
import {ReturnNote, ReturnNoteBewe} from '../../types/return-note';
import {BeweLf} from '../../types/bewe-lf';
import {CommonModule} from '@angular/common';
import {
  DeliveryNoteAddressCardComponent
} from '../../components/delivery-note-address-card/delivery-note-address-card.component';
import {
  DeliveryNoteMaterialCardComponent
} from '../../components/delivery-note-material-card/delivery-note-material-card.component';
import {
  DeliveryNoteReturnCardComponent
} from '../../components/delivery-note-return-card/delivery-note-return-card.component';

export interface ReturnNoteWithGroupedBewes extends Omit<ReturnNote, 'bewes'> {
  bewes: { type: string; text: string; bewes: ReturnNoteBewe[] }[];
}

@Component({
    selector: 'app-delivery-note-details',
    imports: [
        CommonModule,
        UiCoreModule,
        DeliveryNoteAddressCardComponent,
        DeliveryNoteMaterialCardComponent,
        DeliveryNoteReturnCardComponent,
        ApplicationModule
    ],
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy, BackNavigable {

  private subscription?: Subscription;
  private itemSubscription?: Subscription;

  readonly id$ = this.activatedRoute.paramMap.pipe(map(o => +(o.get('id') ?? '')));

  readonly item$: Observable<DeliveryNote> = this.id$.pipe(
      switchMap(id => this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.preparedEntity, id)),
  );

  saving = false;

  readonly lockedByUser$ = this.activatedRoute.queryParamMap.pipe(
    map(o => o.get('lockedByAnwender')),
  );

  readonly canEdit$: Observable<boolean> = this.lockedByUser$.pipe(
    map(name => !name || name?.length < 0)
  );

  readonly deliveryInfos$ = this.item$.pipe(
    map(o => o?.lieferhinweis),
    filter(o => !!o && o?.length > 0),
    map((o: string | undefined) => {
      const parts = o?.split('\n');
      return parts ?? [];
    })
  );

  readonly vermerkChanged$ = this.id$.pipe(
    switchMap(id => this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.change, id)),
    map(o => o?.action === 'patch' && Object.keys(o.changes).includes('vermerk'))
  );

  returnNote$: Observable<ReturnNote | undefined> = this.id$.pipe(
    switchMap(id => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, id))
  );

  // Filter non-zero quantities out (only non-zero quantities should be listed)
  readonly returnNoteWithNonZeroQuantityGroupedBewes$: Observable<ReturnNoteWithGroupedBewes | undefined> = this.returnNote$.pipe(
    filter(o => !!o),
    map(o => {
      const bewesDict = o?.bewes?.reduce(
        (prev, curr) => {
          prev[curr.typ] = (prev[curr.typ] ?? []).concat(curr);
          return prev;
        },
        {} as Record<string, ReturnNoteBewe[]>
      ) ?? {};

      const groupedBewes: { type: string; text: string; bewes: ReturnNoteBewe[] }[] = [];

      for (const [type, bewes] of Object.entries(bewesDict)) {
        let text = '';

        switch (type as ReturnNoteBewe['typ']) {
          case 'itemScan':
            text = '#DELIVERY_NOTE.RETOUR.SCAN';
            break;
          case 'search':
            text = '#DELIVERY_NOTE.RETOUR.SEARCH';
            break;
          case 'emballage':
            text = '#DELIVERY_NOTE.RETOUR.EMBALLAGEN';
            break;
          case 'storno':
            text = 'Storno-Artikel';
            break;
          case 'vermerk':
            text = '#DELIVERY_NOTE.RETOUR.VERMERKITEMS';
            break;
        }

        groupedBewes.push({ type, bewes, text });
      }

      const grouped = {
        ...o,
        bewes: groupedBewes.map(group => ({
          ...group,
          bewes: group.bewes.filter(bewe => {
            if (bewe?.typ === 'storno' || bewe?.typ === 'emballage' || bewe?.typ === 'search') {
              return !!bewe?.menge && bewe?.menge > 0;
            }

            if (bewe?.typ === 'itemScan') {
              return !!bewe?.itemScanBean?.menge && bewe?.itemScanBean?.menge > 0;
            }

            if (bewe?.typ === 'vermerk') {
              return !!bewe?.vermerk && bewe?.vermerk?.trim()?.length > 0;
            }

            return true;
          })
        }))
      } as ReturnNoteWithGroupedBewes | undefined;

      return grouped;
    }),
  );

  returnNoteChanges$: Observable<boolean> = this.returnNote$.pipe(
    switchMap(note => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.change, note?.id)),
    map(o => !!o && o?.action === 'patch' && (o.changes?.bewes?.length ?? 0) > 0)
  );

  private hasChanges$ = this.item$.pipe(
    switchMap(deliveryNote =>
      this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, deliveryNote?.id).pipe(
        switchMap(o => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.change, o?.id)),
        combineLatestWith(
          this.store.observe(
            SOFTLINE_FEATURE_DELIVERY_NOTE,
            DeliveryNotesStore.getters.collection.change,
            deliveryNote?.id
          )
        ),
        map(([o, p]) => !!o || !!p)
      )
    )
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    protected router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private backNavigationService: BackNavigationService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string) { }

  ngOnInit(): void {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#DELIVERY_NOTE.TITLE');
    this.backNavigationService.set(this);
    this.itemSubscription = this.item$
      .subscribe((item) => {
        if (!item)
          return;
        const title = item?.type?.type === 'AUSLIEFERUNG'
          ? `Lieferschein ${item?.belegnummer}`
          : `Materialschein ${item?.belegnummer}`;
        this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, title);
        this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
          name: DetailsComponent,
          commands: this.createCommands(item)
        });
      });

    this.subscription = this.route.paramMap
      .pipe(
        map(o => o.get('id')),
        combineLatestWith(this.canEdit$)
      )
      .subscribe(async ([id, lockedByAnwender]) => {
        if (!id)
          return;
        try {
          await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.collection.loadOnce, { id: +id });
          await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.loadSignature, { id: +id });
        }
        catch (e: unknown) {
          showRequestErrors(this.store, e);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.itemSubscription && !this.itemSubscription.closed)
      this.itemSubscription.unsubscribe();
    this.itemSubscription = undefined;
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, DetailsComponent);
  }

  private static dataURItoBlob(dataURI: string): Blob {
    const match = /data:(.*?);base64,(.*)/g.exec(dataURI);

    if (!match) {
      throw Error('invalidDataUri');
    }

    const byteString: string = window.atob(match[2]);
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([int8Array], {type: match[1]});
  }

  async navigateBack(): Promise<void> {
    const hasChanges = await lastValueFrom(this.hasChanges$.pipe(first()));
    const deliveryNote = await lastValueFrom(this.item$.pipe(first()));

    if (!hasChanges && deliveryNote) {
      await this.clearExzess(deliveryNote.id);
    }

    await this.router.navigate(['/lieferscheine']);
  }

  async openSignaturePad(note: DeliveryNote): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<
        { time: string, signature?: string } | null,
        { id: number, hasUnterschrift: boolean, hasGeodaten: boolean }
      >(),
      {
        id: 'TRANSPORT_SIGNATURE_DIALOG',
        component: SignatureDialogComponent,
        data: {
          id: note.id,
          hasUnterschrift: !!note?.signatureBlob,
          hasGeodaten: false // TODO: Check if we have geodaten or not
        }
      }
    );

    if (result === 'DISMISSED' || result === null || !result?.signature)
      return;

    const date = new Date().setHours(+(result.time?.toString().substr(1, 2)), +(result.time.substr(4, 2)));

    const blob = new File( [new Blob([DetailsComponent.dataURItoBlob(result.signature)], { type: 'image/png' })], 'signature.png');
    const id = +(this.route.snapshot.paramMap.get('id') ?? '');
    await this.store.dispatch(
      SOFTLINE_FEATURE_DELIVERY_NOTE,
      DeliveryNotesStore.actions.collection.preparePatch,
      { id, changes: { signatureBlob:  blob, signatureTime: new Date(date).toISOString()} }
    );
  }

  async save(id: number): Promise<void> {
    this.saving = true;
    const messageBarId = await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.progress, of({}));
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.save, { id });
      await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          {
            title: '#DELIVERY_NOTE.MESSAGES.DETAILS_SAVE_SUCCESS.TITLE',
            message: '#DELIVERY_NOTE.MESSAGES.DETAILS_SAVE_SUCCESS.MESSAGE'
          }
      );
    } catch (e) {
      showRequestErrors(this.store, e as Error);
    }
    finally {
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.close, messageBarId);
      this.saving = false;
    }
  }

  private async navigateToRetourPage(id: number): Promise<void> {
    let returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, id);
    if (!isDefined(returnNote)) {
      returnNote = {
        id: this.uuid(),
        idlf: id,
        bewes: [],
        email: true
      };
      this.store.commit(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.mutations.add, returnNote);
    }
    await this.router.navigate(['lieferscheine', 'return-note', returnNote.id]);
  }

  filterSingleBewelf(bewelf: BeweLf, id: number): boolean {
    return bewelf.id === id;
  }

  async patchNote(id: number, changes: Dictionary<any>) {
    await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.collection.preparePatch, {id, changes});
  }

  async discardChanges(id: number): Promise<void> {
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, 'Möchten Sie die Änderungen wirklich verwerfen?');
    if (result !== 'YES')
      return;
    const changes = this.store.get(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.change, id);
    if (changes)
      this.store.commit(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.mutations.collection.resetChange, id);
    const returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, id);
    const returnNoteChange = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.change, returnNote?.id);

    if (returnNote && returnNoteChange)
      this.store.commit(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.mutations.resetChange, returnNote.id);

    await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.info, 'Die Änderungen wurden zurück gesetzt');
    // await this.clearExzess(id);
  }

  private async clearExzess(idlf: number): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DELIVERY_NOTE,
        DeliveryNotesStore.actions.exzess,
        { idlf, clear: true }
      );
    } catch (e) {
      console.log('Could not clear exzess: ', e);
    }
  }

  async complete(id: number, note: DeliveryNote): Promise<void> {
    if (!id)
      return;

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<
          { kilometerstand: number; finish: boolean; email: string | null | undefined } | null,
          { hasUnterschrift: boolean; previouslyEnteredKilometers: number | null, email: string | null | undefined }
      >(),
      {
        id: 'DELIVERY_NOTE_DETAILS_CLOSE',
        component: CloseDeliveryNoteDialogComponent,
        class: 'pr-0 pl-0',
        data: {
          hasUnterschrift: !!note?.signatureBlob,
          previouslyEnteredKilometers: note?.kilometerstand ?? null,
          email: note?.mail,
        },
        dismiss: {
          backdrop: false,
          button: false,
          escape: false,
        }
      }
    );

    if (!result || result === 'DISMISSED' || result === null)
      return;

    this.saving = true;
    const messageBarId = await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.progress, of({}));
    try {
      // Save kilometerstand locally (IndexedDB)
      await this.store.dispatch(
          SOFTLINE_FEATURE_DELIVERY_NOTE,
          DeliveryNotesStore.actions.collection.preparePatch,
          { id, changes: { kilometerstand: result?.kilometerstand, mail: result?.email }}
      );

      // Only close/complete if we did NOT cancel the dialog
      if (result?.finish === false)
        return;

      await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.complete, {
        id,
        kilometerstand: result?.kilometerstand,
        email: result?.email
      });
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.success, 'Der Lieferschein wurde abgeschlossen');
      await this.router.navigate(['/lieferscheine']);
    } catch (e) {
      showRequestErrors(this.store, e as Error);
    }
    finally {
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.close, messageBarId);
      this.saving = false;
    }
  }

  createCommands(item: DeliveryNote): Command[] {
    return [
      {
        name: 'Retourschein',
        icon: 'fa-regular fa-clipboard-list',
        class: 'menu action-menu action-menu-top',
        canExecute: this.canEdit$,
        execute: async () => await this.navigateToRetourPage(item.id ?? '')
      },
      {
        name: (item?.vermerk?.length ?? 0) > 0 ? 'Vermerk bearbeiten' : 'Vermerk hinzufügen',
        icon: 'fa-regular fa-pen-to-square',
        class: 'menu action-menu action-menu-top',
        canExecute: this.canEdit$,
        execute: async () => {
          const result = await this.store.dispatch(
            SOFTLINE_FEATURE_MODAL,
            ModalStore.actions.open<string | null, { vermerk?: string | null }>(),
            {
              id: 'DELIVERY_NOTE_DETAILS_COMMENT',
              component: DefaultCommentDialogComponent,
              data: { vermerk: item?.vermerk },
              dismiss: {
                backdrop: true,
                button: true,
                escape: false
              }
            }
          );

          if (result === 'DISMISSED' || result === null)
            return;

          const patch: Patch<DeliveryNote> = {id: item.id, changes: {vermerk: result ?? ''}};
          await this.store.dispatch(
            SOFTLINE_FEATURE_DELIVERY_NOTE,
            DeliveryNotesStore.actions.collection.preparePatch,
            {...patch}
          );
        }
      },
      new UploadFileCommand(this.store, { archiveKey: item?.archiveKey, canExecute: this.canEdit$}),
      {
        name: 'Artikel beschädigt',
        icon: 'fa-regular fa-square-fragile',
        class: 'menu action-menu action-menu-top',
        canExecute: this.canEdit$,
        execute: async () => await this.navigateToRetourPage(item.id)
      },
      {
        name: 'Unterschreiben',
        icon: 'fa-regular fa-signature',
        class: 'menu action-menu action-menu-top',
        canExecute: this.canEdit$,
        execute: async () => await this.openSignaturePad(item)
      },
      {
        name: 'Änderungen verwerfen',
        icon: 'fa-regular fa-trash',
        class: 'menu action-menu action-menu-top',
        isVisible: this.canEdit$,
        canExecute: this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.change, item.id)
          .pipe(
            combineLatestWith(this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, item.id)
                .pipe(switchMap( o => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.change, o?.id)))
            ),
            map(([o, p]) => !!o || !!p)
          ),
        execute: async () => await this.discardChanges(item.id)
      },
      {
        name: 'Speichern',
        icon: 'fa-regular fa-floppy-disk',
        class: 'menu action-menu action-menu-top',
        isVisible: this.canEdit$,
        canExecute: this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.change, item.id)
          .pipe(
            combineLatestWith(this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, item.id)
              .pipe(switchMap( o => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.change, o?.id)))
            ),
            map(([o, p]) => !!o || !!p)
          ),
        execute: async () => await this.save(item.id)
      },
      {
        name: 'Abschließen',
        icon: 'fa-regular fa-check',
        class: 'menu action-menu action-menu-top',
        canExecute:  this.canEdit$,
        execute: async () => await this.complete(item.id, item)
      }
    ];
  }
}
