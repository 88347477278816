import { Component, OnInit } from '@angular/core';
import {
  CheckboxComponent,
  CheckGroupDirective,
  Modal,
  TranslatePipe,
  TrueOnlyCheckStrategy,
} from '@softline/ui-core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'soft-dynamic-multi-select-dialog',
  templateUrl: './multi-select-dialog.component.html',
  styleUrls: ['./multi-select-dialog.component.scss'],
  imports: [CheckboxComponent, CheckGroupDirective, NgForOf, TranslatePipe],
})
export class MultiSelectDialogComponent implements OnInit, Modal<any[]> {
  strategy = new TrueOnlyCheckStrategy();
  private close!: (result: any[]) => void;

  options: {
    value: any;
    title: string;
  }[] = [];
  title: string = '';
  values: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: any[]) => void): void {
    this.close = handler;
  }

  onSubmit(values: any[] | null | undefined): void {
    this.close(values ?? []);
  }
}
