import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  OnInit,
  output,
  Type,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  modalFn,
  UiCoreDirectivesModule,
  UiCoreModule,
  WithBreakpoints
} from '@softline/ui-core';
import {
  Scan,
  ScanButtonComponent,
  ScannerStore2,
  WithActivatedRoute,
} from '@softline/application';
import { SearchStrategy } from './search.strategy';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  SearchResultComponent,
  SOFTLINE_CONFIG_SEARCH_PAGE_RESULT_VIEW,
} from './abstraction/search-result.component';
import { Router } from '@angular/router';
import { PageComponent } from '../page/page.component';
import { isDefined } from '@softline/core';
import { PageTabComponent } from '../page/page-tab/page-tab.component';
import { FieldOkSearchStrategy } from './strategies/field-ok-search.strategy';

@Component({
  selector: 'soft-search-page',
  imports: [
    CommonModule,
    UiCoreDirectivesModule,
    ScanButtonComponent,
    UiCoreModule,
    PageComponent,
    PageTabComponent,
  ],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPageComponent<T extends object>
  extends WithBreakpoints(WithActivatedRoute())
  implements OnInit
{
  private readonly router = inject(Router);
  private readonly scannerStore = inject(ScannerStore2);
  private readonly modal = modalFn();

  readonly searchInput$ = new BehaviorSubject<string | null>(null);

  readonly debouncedSearchInput = toSignal(
    this.searchInput$.pipe(debounceTime(300), distinctUntilChanged()),
    { initialValue: null }
  );

  canScan = input(true);

  readonly select = output<T>();
  readonly favoriteChange = output<T>();
  readonly scan = output<Scan>();

  readonly strategy = inject(SearchStrategy);

  readonly overrideResultComponent = inject(
    SOFTLINE_CONFIG_SEARCH_PAGE_RESULT_VIEW,
    { optional: true }
  );

  get resultComponent(): Type<SearchResultComponent<T>> {
    return this.overrideResultComponent ?? this.strategy.resultViewType;
  }

  private readonly _onSearchInputChangeEffect = effect(async () => {
    const searchInput = this.debouncedSearchInput();

    if (searchInput) {
      this.strategy.searchInputChange(searchInput ?? null);
    }

    await this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: !searchInput
        ? null
        : {
            searchTerm: searchInput,
          },
      preserveFragment: true,
    });
  });

  constructor() {
    super();

    this.scannerStore.scan$
      .pipe(takeUntilDestroyed())
      .subscribe((scan) => this.scan.emit(scan));

    effect(() => {
      if (this.strategy.isManualSearchActive()) {
        this.searchInput$.next(null);
      }
    });
  }

  onFavoriteChange(value: T): void {
    this.strategy.favoriteChange(value);
    this.favoriteChange.emit(value);
  }

  async openQueryModalView(): Promise<void> {
    const component = this.strategy.queryModalViewType;

    if (!component)
      return;

    const result = await this.modal({
      component,
      title: 'Abfrage',
      dismiss: true
    });

    if (!result || result === 'DISMISSED' || result === 'Dismissed')
      return;

    await this.strategy.modalResolved(result);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    const queryParamsSearchTerm = this.routeQueryParams()['searchTerm'];

    if (queryParamsSearchTerm) {
      this.searchInput$.next(queryParamsSearchTerm);
    }

    const fragment = this.routeFragment();

    if (!isDefined(fragment))
      await this.router.navigate([], {
        fragment: 'search',
        // do not push into browser history stack so the browser back button works as expected
        skipLocationChange: true,
      });

    try {
      await this.strategy.loadFavorites();
      await this.strategy.loadLastUsed();
    } catch (e) {
      console.log(e);
    }
  }

  onSelect(value: T): void {
    this.strategy.addToLastUsed(value);
    this.select.emit(value);
  }
}
