import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { TitleStore2 } from '../title.store2';
import { interpolate, isDefinedNotEmpty } from '@softline/core';

interface TitleSet {
  key: unknown;
  title?: Signal<string>;
  subtitle?: Signal<string | null>;
}

@Injectable({ providedIn: 'root' })
export class TitleService {
  //TODO: Remove if TitleStore2 is removed
  store = inject(TitleStore2);
  storeTitle = computed(() => {
    const title = this.store.title();
    const params = this.store.params();
    return interpolate(title, params ?? {});
  });
  storeSubtitle = computed(() => {
    const subtitle = this.store.subtitle();
    const params = this.store.params();
    if (isDefinedNotEmpty(subtitle)) return interpolate(subtitle, params ?? {});
    return null;
  });

  _titles = signal<TitleSet[]>([]);

  title = computed(() => {
    const titles = this._titles();
    const set = titles.reverse().find((item) => item.title);
    if (!set || !set.title) return this.storeTitle();
    return set.title() ?? this.storeTitle();
  });

  subtitle = computed(() => {
    const titles = this._titles().reverse();
    const index = titles.findIndex((item) => item.title);
    const subtitleIndex = titles.findIndex((item) => item.subtitle);

    if (subtitleIndex === -1 || index < subtitleIndex)
      return this.storeSubtitle();

    const set = titles[subtitleIndex];
    if (!set || !set.subtitle) return this.storeSubtitle();
    return set.subtitle() ?? this.storeSubtitle();
  });

  params = computed(() => {
    return this.store.params();
  });

  concatTitle = computed(() => {
    const titles = this._titles();
    let title = '';
    let firstTitleAdded = false;
    for (const set of titles) {
      const currentTitle = set.title ? set.title() : null;
      const currentSubtitle = set.subtitle ? set.subtitle() : null;
      if (currentTitle && !firstTitleAdded) firstTitleAdded = true;
      else if (currentTitle) title += ' - ';
      if (currentTitle) title += currentTitle;
      if (currentSubtitle) title += ' (' + currentSubtitle + ')';
    }
    return title;
  });

  addTitle(key: unknown, title: Signal<string>): void {
    const titles = [...this._titles()];
    const index = titles.findIndex((item) => item.key === key);
    if (index === -1) {
      const set = { key, title };
      this._titles.set([...titles, set]);
    } else {
      const set = { ...titles[index], title };
      titles.splice(index, 1, set);
      this._titles.set(titles);
    }
  }

  removeTitle(key: unknown): void {
    const titles = [...this._titles()];
    const index = titles.findIndex((item) => item.key === key);
    if (index === -1) return;
    const set = { ...titles[index], title: undefined };
    if (set.subtitle) titles.splice(index, 1, set);
    else titles.splice(index, 1);
    this._titles.set(titles);
  }

  addSubtitle(key: unknown, subtitle: Signal<string | null>): void {
    const titles = [...this._titles()];
    const index = titles.findIndex((item) => item.key === key);
    if (index === -1) {
      const set = { key, subtitle: subtitle };
      this._titles.set([...titles, set]);
    } else {
      const set = { ...titles[index], subtitle };
      titles.splice(index, 1, set);
      this._titles.set(titles);
    }
  }

  removeSubtitle(key: unknown): void {
    const titles = [...this._titles()];
    const index = titles.findIndex((item) => item.key === key);
    if (index === -1) return;
    const set = { ...titles[index], subtitle: undefined };
    if (set.title) titles.splice(index, 1, set);
    else titles.splice(index, 1);
    this._titles.set(titles);
  }
}
