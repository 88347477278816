import { StorageResourceLocation } from '../../data/specialized/storage/storage.location';


export abstract class StorageDataAccess {

  protected constructor(protected storage: Storage) { }

  get<T>(location: StorageResourceLocation): T | null {
    const value = this.storage.getItem(location.key);
    if (!value)
      return null;
    return JSON.parse(value);
  }

  set<T>(location: StorageResourceLocation, resource: T): T {
    this.storage.setItem(location.key, JSON.stringify(resource));
    return resource;
  }

  patch<T>(location: StorageResourceLocation, resource: Partial<T>): T {
    const existing = this.get<T>(location);
    if(!existing)
      throw new Error(`[StorageDataAccess] patch: No Resource with key '${location.key}' not found`);
    const patchedValue = { ...existing, ...resource };
    this.set(location, patchedValue);
    return patchedValue;
  }

  delete<T>(location: StorageResourceLocation): T {
    const existing = this.get<T>(location);
    if(!existing)
      throw new Error(`[StorageDataAccess] delete: No Resource with key '${location.key}' not found`);
    this.storage.removeItem(location.key);
    return existing;
  }
}
