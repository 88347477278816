import { wwsCoreProviders } from '@softapps/wws/core';
import { fibuCoreProviders, kundeninfoLeisteProviders } from '@softapps/fibu/core';
import {
  BelegDownloadStrategy, FremdbelegDownloadStrategy,
  gesamtabfrageProviders, gesamtabfrageRoutes,
  SOFTLINE_CONFIG_GESAMTABFRAGE
} from '@softapps/wws/gesamtabfrage';
import { dashboardProviders, dashboardRoutes, provideKundeninfoWidget } from '@softapps/allgemein/dashboard';
import { kreditlimitProviders } from '@softapps/wws/kreditlimit';
import { offenePostenProviders } from '@softapps/fibu/offene-posten';
import { SoftlineModuleConfig } from '@configs/common';
import {subkundenProviders, subkundenRoutes} from '@softapps/wws/subkunden';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HagebauPartnerPortalConfig {}

export function provideHagebauPartnerPortalModules(config?: HagebauPartnerPortalConfig): SoftlineModuleConfig {
  const providers = [
    //Wws
    ...wwsCoreProviders,
    ...fibuCoreProviders,
    ...gesamtabfrageProviders,

    ...dashboardProviders,
    ...kundeninfoLeisteProviders,
    ...provideKundeninfoWidget(),
    ...kreditlimitProviders,
    ...offenePostenProviders,
    ...subkundenProviders,

    {
      provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
      useValue: {
        geschaeftsfall: true,
        download: [
          {
            belegart: 'Rechnung',
            strategies: [BelegDownloadStrategy, FremdbelegDownloadStrategy],
          },
          {
            belegart: 'Lieferschein',
            strategies: [BelegDownloadStrategy, FremdbelegDownloadStrategy],
          },
          {
            belegart: 'Auftrag',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Offert',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Kommisionierschein',
            strategies: [BelegDownloadStrategy],
          },
        ],
      },
    },
  ];

  const routes = {
    public: [],
    internal: [
      ...gesamtabfrageRoutes,
      ...dashboardRoutes,
      ...subkundenRoutes,
    ]
  };

  return [providers, routes];
}
