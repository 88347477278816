@if ({ data: vm() }; as vm) {
  <div class="flex flex-col w-full" #container>
    @if (!loading() && vm) {
      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <h6 class="soft-overline uppercase !mb-0.5 mt-1">{{ vm?.data?.title }}</h6>
          <span class="text-base" [ngClass]="(vm?.data?.dbproz || 1) < 0 ? 'text-danger-700' : 'text-success-700'">
            {{ ((vm?.data?.dbproz || 1) / 100) | percent:'1.2-2'}}
          </span>
        </div>
        <div class="grid grid-cols-1">
          @if( zeitraumDatum() !== null ){
            <span class="text-sm text-light whitespace-nowrap !mb-0.5 mt-1">
              Von: {{ zeitraumDatum()?.vondatum | format: 'DATE.DEFAULT'}}
            </span>
            <span class="text-sm text-light whitespace-nowrap !mb-0.5 mt-1">
              Bis: {{ zeitraumDatum()?.bisdatum | format: 'DATE.DEFAULT'}}
            </span>
          }
        </div>
        <div class="rounded w-9 h-9 p-1 flex items-center justify-center" [ngClass]="(vm?.data?.dbproz || 0) < 0 ? 'bg-danger-200' : 'bg-success-200'">
          @if ((vm?.data?.dbproz || 0) < 0) {
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current text-danger-700" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M48 56c0-13.3-10.7-24-24-24S0 42.7 0 56V408c0 39.8 32.2 72 72 72H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H72c-13.3 0-24-10.7-24-24V56zM320 296c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24v54.1L321 127c-9.4-9.4-24.6-9.4-33.9 0l-79 79-63-63c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l80 80c9.4 9.4 24.6 9.4 33.9 0l79-79L398.1 272H344c-13.3 0-24 10.7-24 24z"/></svg>
          } @else {
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current text-success-700" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M48 56c0-13.3-10.7-24-24-24S0 42.7 0 56V408c0 39.8 32.2 72 72 72H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H72c-13.3 0-24-10.7-24-24V56zm272 96c0 13.3 10.7 24 24 24h54.1L304 270.1l-79-79c-9.4-9.4-24.6-9.4-33.9 0l-80 80c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l63-63 79 79c9.4 9.4 24.6 9.4 33.9 0l111-111V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24H344c-13.3 0-24 10.7-24 24z"/></svg>
          }
        </div>
      </div>

      <div class="flex flex-col gap-0 mt-4 mb-2">
        <span class="text-xl font-bold">{{ vm.data?.umsatz | currencyFormat:undefined:'symbol-narrow' }}</span>
        <div class="flex items-center justify-between">
          <span class="text-sm text-light whitespace-nowrap">DB: {{ vm?.data?.db | currencyFormat:undefined:'symbol-narrow' }}</span>
          <div class="flex items-center justify-end my-1">
            <div class="flex items-center gap-4 justify-end">
              <div class="flex items-center gap-1 text-sm">
                <span class="w-3 h-3 rounded" [style.background-color]="colorUmsatz()"></span>
                {{ '#UMSATZSTATISTIK.COMPONENTS.COMPACT_CHART.NETTO' | translate }}
              </div>
              <div class="flex items-center gap-1 text-sm">
                <span class="w-3 h-3 rounded" [style.background-color]="colorDb()"></span>
                {{ '#UMSATZSTATISTIK.COMPONENTS.COMPACT_CHART.DB' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center flex-nowrap rounded overflow-clip">
        <div [style.background-color]="colorUmsatz()" class="h-6 w-full"></div>
        <span class="w-[1px] bg-surface h-6"></span>
        <div [style.background-color]="colorDb()" class="h-6" [style.width]="(vm?.data?.dbproz || 0) + '%'"></div>
      </div>

      <!--
            <span class="text-sm text-light mt-2 mb-1">{{ '#UMSATZSTATISTIK.COMPONENTS.COMPACT_CHART.NETTO_VGL' | translate }}: {{ vm?.data?.summeVergleich?.netto | currencyFormat:undefined:'symbol-narrow' }}</span>

             <ngx-charts-bar-horizontal-stacked
              [view]="[(container?.offsetWidth || 124), 60]"
              [results]="[{
                name: 'Summe',
                series: [
                  { name: 'Netto', value: vm?.data?.summe?.netto ?? 0 },
                  { name: 'Rohertrag', value: vm?.data?.summe?.db ?? 0 },
                ]
              }]"
              [xAxis]="false"
              [yAxis]="false"
              [legend]="false"
              [noBarWhenZero]="true"
              [showGridLines]="false"
              [scheme]="$any(chartColorScheme)"
            ></ngx-charts-bar-horizontal-stacked> -->

      <!-- @if (chartData(); as chartData) {
        <ngx-charts-bar-horizontal-stacked
          [view]="[(container?.offsetWidth || 124) - 30, ((chartData?.length || 1) * 26)]"
          [results]="chartData"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [barPadding]="8"
          [noBarWhenZero]="true"
          [showGridLines]="true"
          [scheme]="$any(chartColorScheme)">
        </ngx-charts-bar-horizontal-stacked>
      } -->
    } @else {
      <div class="flex h-[20vh] w-full skeleton">&nbsp;</div>
    }
  </div>
}


