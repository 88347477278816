import {
  Component,
  computed,
  inject,
  linkedSignal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CheckboxComponent,
  I18nModule,
  IconComponent,
  InPipe,
  Modal,
  provideMasterDetailConfig,
} from '@softline/ui-core';
import { Bundle, PalettenInfo } from '../../types/paletten-info';
import { PalettenService } from '../../services/paletten-service';
import { BreakpointService } from '../../../../../../../framework/ui-core/src/lib/utilities/breakpoints/services/breakpoint.service';
import { showRequestErrors } from '@softline/application';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE } from '../../warenuebernahme.shared';
import { WwsBewegungStore } from '../../store/bewegung.store';

@Component({
  selector: 'soft-paletten-detail-dialog',
  imports: [CommonModule, I18nModule, IconComponent, CheckboxComponent, InPipe],
  templateUrl: './paletten-detail-dialog.component.html',
  styleUrl: './paletten-detail-dialog.component.scss',
  providers: [
    provideMasterDetailConfig({
      autoHeight: false,
    }),
  ],
})
export class PalettenDetailDialogComponent implements Modal<any> {
  readonly store = inject(Store);
  readonly palettenService = inject(PalettenService);

  _palettenInfo = signal<PalettenInfo | null>(null);
  _beweId = signal<number | null>(null);
  _idwu = signal<number | null>(null);

  private readonly breakpointService = inject(BreakpointService);

  set palettenInfo(info: PalettenInfo) {
    this._palettenInfo.set(info);
  }

  set beweId(id: number) {
    this._beweId.set(id);
  }

  set idwu(id: number | null) {
    this._idwu.set(id);
  }

  readonly bundles = computed(
    () => this._palettenInfo()?.result?.bundles ?? []
  );

  readonly checked = linkedSignal({
    source: () => this.bundles(),
    computation: (value) => {
      return value.filter((o) => o.kontrolliert === true).map((o) => o.sscc);
    },
  });

  readonly selectedBundle = linkedSignal(() => {
    const initBundle = this.breakpointService.isMobile()
      ? null
      : this.bundles()?.[0] ?? null;
    return initBundle as Bundle | null;
  });

  close!: (result: any) => void;

  registerCloseHandler(handler: (result: string) => void): void {
    this.close = handler;
  }

  checkedBundle(bundle: Bundle) {
    if (this.checked().includes(bundle.sscc)) {
      this.checked.set(this.checked().filter((sscc) => sscc !== bundle.sscc));
    } else {
      this.checked.set([...this.checked(), bundle.sscc]);
    }
  }

  async submit() {
    try {
      const beweId = this._beweId();
      const idwu = this._idwu();

      if (!beweId || !idwu) return;

      const body = this.bundles().map((o) => {
        return {
          sscc: o.sscc,
          id: o.id,
          kontrolliert: this.checked().includes(o.sscc),
        };
      });

      await this.palettenService.kontrollPaletten(body);

      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
        WwsBewegungStore.actions.loadMany,
        {
          clear: true,
          pathParams: {
            idwu: idwu,
          },
        }
      );
        this.close('submit');
    } catch (error) {
      showRequestErrors(this.store, error);
    }
  }
}
