import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ArtikelMenge } from "../../types/artikel-menge";
import { LagerplatzInhalt } from "../../types/lagerplatz-inhalt";
import { UiCoreModule } from "@softline/ui-core";
import { ArtikelMengeComponent } from '../artikel-menge/artikel-menge.component';

@Component({
    selector: 'soft-artikel-menge-auswahl',
  imports: [CommonModule, UiCoreModule, ArtikelMengeComponent],
    templateUrl: './artikel-menge-auswahl.component.html',
    styleUrls: ['./artikel-menge-auswahl.component.scss']
})
export class ArtikelMengeAuswahlComponent implements OnInit {

  @Input() lagerplatzInhalt: LagerplatzInhalt | null | undefined;
  @Output() artikelMengeChange = new EventEmitter<ArtikelMenge>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(artikelMenge: ArtikelMenge | undefined): void {
    if(!artikelMenge)
      return;
    this.artikelMengeChange.emit(artikelMenge);
  }
}
