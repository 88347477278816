import { Component, OnInit } from '@angular/core';
import { BooleanDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
  CheckboxComponent,
  FieldValidationDirective,
  ToggleSwitchComponent,
} from '@softline/ui-core';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'soft-dynamic-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss'],
  imports: [
    NgIf,
    NgSwitch,
    ToggleSwitchComponent,
    FormControlPipe,
    BooleanRulePipe,
    ReactiveFormsModule,
    NgSwitchCase,
    CheckboxComponent,
    FieldValidationDirective,
    NgSwitchDefault,
  ],
})
export class BooleanInputComponent
  extends DynamicInputAtom<BooleanDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
