import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import {I18nModule, IconComponent, ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import { FilterOperatorPipe } from "../filter-list/filter/filter-operator.pipe";
import { FilterPropertyNamePipe } from "../filter-list/filter/filter-property-name.pipe";
import { Sort, Store } from "@softline/core";
import { SortPropertyNamePipe } from "./sort-property-name.pipe";
import { SortConfig, SortConfigProperty } from "../../types/sort-config";

@Component({
    selector: 'soft-sort-form',
    imports: [CommonModule, I18nModule, FilterOperatorPipe, FilterPropertyNamePipe, SortPropertyNamePipe, IconComponent],
    templateUrl: './sort-form.component.html',
    styleUrls: ['./sort-form.component.scss']
})
export class SortFormComponent implements OnInit {

  @Input() sort: Partial<Sort> | null = {}
  @Output() sortChange = new EventEmitter<Sort>;
  @Input() config?: SortConfig;

  constructor(private store: Store) { }

  ngOnInit(): void {}

  async onPropertyClick(): Promise<void>{
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<SortConfigProperty>(),
      {
        title: '#APPLICATION.FILTER_AND_SORT.SORT.FIELD_PLACEHOLDER',
        dismiss: true,
        options: this.config?.properties.map(o => ({label: o.title, value: o})) ?? []
      });
    if(result === 'DISMISSED')
      return;
    const sort = {
      ...this.sort,
      direction: this.sort?.direction ?? 'asc',
      property: result.property,
      comparator: result.comparer
    } as Sort;
    this.sortChange.emit(sort);
  }


  async onDirectionClick(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose(),
      {
        title: '#APPLICATION.FILTER_AND_SORT.SORT.FIELD_PLACEHOLDER',
        dismiss: true,
        options: [
          {value: 'asc', label: '#APPLICATION.FILTER_AND_SORT.SORT.ASC'},
          {value: 'desc', label: '#APPLICATION.FILTER_AND_SORT.SORT.DESC'}
        ]
      });
    if(result === 'DISMISSED')
      return;
    const sort = {...this.sort, direction: result} as Sort;
    this.sortChange.emit(sort);
  }

  removeFilter(): void {
    this.sortChange.emit(undefined);
  }
}
