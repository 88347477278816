import {
  Component,
  computed,
  contentChild,
  effect,
  inject,
  Injector,
  Signal,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DateParser,
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
  WithModal,
} from '@softline/ui-core';
import {
  Definition,
  DynamicFormCreator,
  DynamicModule,
  FormDefinition,
  ObjectDefinition,
} from '@softline/dynamic';
import {
  FilterInputComponent,
  showRequestErrors,
  QueryHistoryStore,
  Template,
  TemplateStore,
} from '@softline/application';
import { DateService, Store } from '@softline/core';
import { DynamicTemplateFieldListComponent } from '../../components/dynamic-template-field-list/dynamic-template-field-list.component';
import { TemplateCardComponent } from '../../components/template-card/template-card.component';

@Component({
    selector: 'soft-load-template-dialog',
    imports: [
        CommonModule,
        DynamicTemplateFieldListComponent,
        UiCoreModule,
        TemplateCardComponent,
        FilterInputComponent,
        DynamicModule,
    ],
    templateUrl: './load-template-dialog.component.html',
    styleUrl: './load-template-dialog.component.scss'
})
export class LoadTemplateDialogComponent extends WithModal() {
  private dateService = inject(DateService);
  private dateParser = inject(DateParser);
  private injector = inject(Injector);

  $group = signal<string | undefined>(undefined);
  $featureName = signal<string>('');
  $templateValue = signal<object>({});

  $loadParams = computed(() => {
    return this.$group() ?? this.$featureName();
  });
  $templates = this.store.signal(
    this.$featureName,
    TemplateStore.getters.template.templates,
    this.$loadParams,
    { initialValue: [] }
  );
  $queryHistory = this.store.signal(
    this.$featureName,
    QueryHistoryStore.getters.queryHistory.templates,
    this.$loadParams,
    { initialValue: [] }
  );

  $definition = signal({} as FormDefinition);

  $templateRef = viewChild('chooseTemplate', { read: TemplateRef });
  $editTemplateRef = viewChild('editTemplate', { read: TemplateRef });

  set group(value: string | undefined) {
    this.$group.set(value);
  }

  set featureName(value: string) {
    this.$featureName.set(value);
  }

  set definition(value: FormDefinition) {
    this.$definition.set(value);
  }

  effect = effect(async () => {
    await this.store.dispatch(
      this.$featureName(),
      TemplateStore.actions.template.loadMany,
      { group: this.$group() ?? this.$featureName() }
    );
    await this.store.dispatch(
      this.$featureName(),
      QueryHistoryStore.actions.queryHistory.loadMany,
      { group: this.$group() ?? this.$featureName() }
    );
  });

  constructor(private store: Store) {
    super();
  }

  onSubmit(template: Template<any>): void {
    this.close(template);
  }

  async onEllipsisClick(template: Template<any>): Promise<void> {
    const formCreator = new DynamicFormCreator(this.dateParser, this.injector);
    const form = formCreator.createForm(this.$definition() as FormDefinition);
    form.patchValue(template.value);
    form.updateValueAndValidity();
    const canExecute = form.valid;

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose(),
      {
        title: 'Vorlage',
        dismiss: true,
        content: this.$templateRef(),
        params: { template },
        options: [
          {
            icon: 'fa-regular fa-folder-arrow-down',
            label: 'Übernehmen',
            value: 'load',
          },
          /*{
          icon: 'fa-regular fa-folder-magnifying-glass',
          disabled: !canExecute,
          label: 'Ausführen',
          value: 'execute',
        }, */ {
            icon: 'fa-regular fa-money-check-pen',
            label: 'Editieren',
            disabled: !template.title,
            value: 'edit',
          },
          {
            icon: 'fa-regular fa-folder-xmark',
            label: 'Löschen',
            value: 'delete',
            class: 'text-danger',
          },
        ],
      }
    );

    if (result === 'DISMISSED') return;
    switch (result) {
      case 'execute':
        this.close(template);
        break;
      case 'load':
        this.close(template);
        break;
      case 'edit':
        await this.edit(template);
        break;
      case 'delete':
        await this.delete(template);
        break;
    }
  }

  private async edit(template: Template<any>): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.template(),
      {
        title: 'Vorlage bearbeiten',
        template: this.$editTemplateRef(),
        dismiss: true,
        params: { template },
      }
    );
    if (result === 'DISMISSED') return;
    try {
      await this.store.dispatch(
        this.$featureName(),
        TemplateStore.actions.template.update,
        {
          ...template,
          value: result,
          timestamp: this.dateService.now(),
        }
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  private async delete(template: Template<any>): Promise<void> {
    const confirm = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        dismiss: true,
        title: 'Löschen',
        content: this.$templateRef(),
        params: { template },
        question: `Soll die Vorlage wirklich gelöscht werden?`,
      }
    );
    if (confirm === 'DISMISSED' || confirm === 'NO') return;
    try {
      if (template.title)
        await this.store.dispatch(
          this.$featureName(),
          TemplateStore.actions.template.delete,
          template
        );
      else
        await this.store.dispatch(
          this.$featureName(),
          QueryHistoryStore.actions.queryHistory.delete,
          template
        );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        'Die Vorlage wurde gelöscht!'
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
