@let configs = this.configs();
@if (configs.length > 0) {
  <div class="modal-overlay"
       (click)="$event.stopPropagation()"
       (keydown)="$event.stopPropagation();"
       (keyup)="$event.stopPropagation();">
    @for (config of configs; track config.id) {
      <soft-modal [config]="config"
                  [active]="config === activeConfig()"
                  [style.display]="config === activeConfig() ? 'block' : 'none'"
      ></soft-modal>
    }
  </div>
}
