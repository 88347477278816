import {Injectable} from '@angular/core';
import {ConnectionHttpService, ApiResourceLocation, ResponseResult} from '@softline/core';
import {Observable} from 'rxjs';
import {SOFTLINE_API_GESAMTABFRAGE_BEWEGUNGEN} from '../artikel-info.api';
import {map} from 'rxjs/operators';

@Injectable()
export class GesamtabfrageBewegungenService {

  private get location(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_GESAMTABFRAGE_BEWEGUNGEN
    }
  }

  constructor(private connectionHttpService: ConnectionHttpService) {}

  getBestellungen(artikel: string, lager: string): Observable<any> {
    return this.connectionHttpService.create<object, ResponseResult<any>>(
      this.location,
      this.getPayload(artikel, lager, 'bestellungen')
    ).pipe(
      map(o => o?.result)
    );
  }

  getKommscheine(artikel: string, lager: string): Observable<any> {
    return this.connectionHttpService.create<object, ResponseResult<any>>(
      this.location,
      this.getPayload(artikel, lager, 'kommscheine')
    ).pipe(
      map(o => o?.result)
    );
  }

  getAuftraege(artikel: string, lager): Observable<any> {
    return this.connectionHttpService.create<object, ResponseResult<any>>(
      this.location,
      this.getPayload(artikel, lager, 'auftraege'),
    ).pipe(
      map(o => o?.result)
    )
  }

  private getPayload(artikel: string, lager: string, type: 'auftraege' | 'bestellungen' | 'kommscheine'): Record<string, unknown> {
    let belegart: object;

    if (type === 'bestellungen')
      belegart = {
        id: "Bestellung",
        name: "Bestellungen",
        kundenName: "Bestellungen"
      }
    else if (type === 'kommscheine')
      belegart = {
        id: "Kommissionierschein",
        name: "Kommissionierschein",
        kundenName: "Kommissionierschein"
      }
    else
      belegart = {
        id: "Auftrag",
        name: "Aufträge",
        kundenName: "Aufträge"
      }

    return {
      from: this.oneYearAgo(),
      to: new Date().toISOString(),
      belegart,
      bewegungen: true,
      artikel,
      nurOffeneBewegungen: true,
      alleKonten: true,
      lager
    }
  }

  private oneYearAgo(): string {
    const today = new Date();
    return new Date(today.setFullYear(today.getFullYear() - 1)).toISOString();
  }
}
