import {
  Component,
  computed, EnvironmentInjector,
  HostBinding,
  HostListener,
  inject,
  Injector,
  input,
  isSignal,
  signal, ViewContainerRef
} from '@angular/core';
import {InternalModalConfig} from "../../types/modal-config";
import {CommonModule} from "@angular/common";
import { Dictionary, Store } from '@softline/core';
import { Modal2, SOFTLINE_MODAL_RESOLVER } from '../../abstraction/modal';
import { ModalService } from '../../modal.service';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { SOFTLINE_FEATURE_MODAL } from '../../legacy/modal.shared';
import * as ModalStore from '../../legacy/modal.store'
import { IconComponent } from '../../../components/icon/icon.component';

@Component({
  selector: 'soft-modal',
  imports: [CommonModule, ClickOutsideDirective, IconComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  config = input.required<InternalModalConfig<Modal2, unknown>>();
  private modalService = inject(ModalService);
  private store = inject(Store);

  active = input<boolean>(false);

  title = computed(() => {
    const config = this.config();
    return config.title ? config.title() : null;
  });

  subtitle = computed(() => {
    const config = this.config();
    return config.subtitle ? config.subtitle() : null;
  });

  text = computed(() => {
    const config = this.config();
    return config.text ? config.text() : null;
  });

  template = computed(() => {
    const config = this.config();
    return config.template ? config.template() : null;
  });

  templateContext = computed(() => {
    const config = this.config();
    return config.templateContext ? config.templateContext() : null;
  });

  injector = computed(() => {
    return Injector.create({
      providers: [
        {provide: SOFTLINE_MODAL_RESOLVER, useValue: (result) => this.resolve(result)},
      ],
      parent: this.config().injector
    })
  })

  componentInputs = computed(() => {
    const data = this.config().data;
    const componentInputs: Dictionary<unknown> = { };
    for(const [key, value] of Object.entries(data ?? {})) {
      if(isSignal(value))
        componentInputs[key] = value();
      else
        componentInputs[key] = value;
    }
    return componentInputs;
  })

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    this.dismiss(event);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostBinding('class')
  get class() { return this.config().class }

  resolve(result: unknown): void {
    const config = this.config();
    if (config.callback)
      config.callback(result);
    this.modalService.close(config.id);
  }

  dismiss(event?: Event): void {
    if(this.store.get(SOFTLINE_FEATURE_MODAL, ModalStore.getters.count) > 0)
      return;

    const config = this.config();
    if (config.dismiss === false || !this.active())
      return;

    if (config.onDismiss)
      config.onDismiss();

    this.modalService.close(config.id);

    if(event) {
      event.stopPropagation();
    }
  }
}
