@if (pallet(); as pallet) {
  <div class="flex flex-row items-center gap-2">
    <soft-icon name="fa-regular fa-pallet-boxes"></soft-icon>
    <div class="flex flex-col w-full">
      <span class="text-lg font-semibold">{{ pallet?.sscc }}</span>
      <span class="text-sm text-light">{{ pallet?.menge }} {{ pallet?.einheit?.arteh }}</span>
    </div>
    @if (stockyard() || items()) {
      <div class="flex flex-row justify-center items-center bg-info text-info-contrast rounded-full cursor-pointer size-8 hover:bg-info-800 hover:text-info-contrast-800"
          (click)="openInfoModal(); $event.stopPropagation(); $event.preventDefault()">
        <soft-icon name="fa-regular fa-info"></soft-icon>
      </div>
    }
  </div>

  <ng-template #infoModalTemplate>
    <div class="flex flex-row gap-2 w-full soft-card hard rounded p-2 items-center">
      <soft-icon name="fa-regular fa-pallet-boxes"></soft-icon>
      <div class="flex flex-col">
        <span class="text-lg font-semibold">{{ pallet?.sscc }}</span>
        <span class="text-sm text-light">{{ pallet?.menge }} {{ pallet?.einheit?.arteh }}</span>
      </div>
    </div>
    @if(items(); as items) {
      <div class="mt-2">
        <div class="soft-overline">Artikel</div>
        @for (item of items ?? []; track item.artikel.id) {
          <div class="flex flex-row justify-between flex-nowrap" [class.pt-2]="!$first" [class.pb-2]="!$last" [class.border-b]="!$last">
            <div class="grid grid-cols-[32px_1fr] gap-2.5">
              <div class="p-1 font-semibold text-sm rounded flex flex-row items-center justify-center text-center border border-primary text-primary aspect-square">{{ $index + 1 }}</div>

              <div class="flex flex-col">
                <span class="text-sm color-light">{{item.artikel.nummer}}</span>
                <span class="font-semibold">{{item.artikel.artbez1}}</span>
                <span class="text-sm text-light">{{item.artikel?.artbez2}}</span>
                <span class="text-sm text-light">{{item.artikel?.artbez3}}</span>
                <span class="text-sm text-light">{{item.artikel?.artbez4}}</span>
                <span class="text-sm text-light">{{item.artikel?.artbez5}}</span>
              </div>
            </div>

            <div class="flex flex-col justify-start items-end">
              <div class="flex flex-row flex-nowrap text-light font-semibold items-center text-base">
                <span>{{item.menge}}</span>
                <span class="ml-1">{{item.einheit.arteh}}</span>
              </div>

              @if (item.einheit.id !== item.einheit_lg.id) {
                <div class="flex flex-row flex-nowrap color-light items-center text-xl">
                  <span class="whitespace-nowrap">= {{item.menge_lg}}</span>
                  <span class="ml-1">{{item.einheit_lg.arteh}}</span>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </ng-template>
}
