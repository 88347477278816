import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";

@Component({
    selector: 'soft-ktostamm-lieferant-field-ok-data-view',
    imports: [CommonModule],
    templateUrl: './ktostamm-lieferant.list-view.html',
    styleUrls: ['./ktostamm-lieferant.list-view.scss']
})
export class KtostammLieferantListView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
