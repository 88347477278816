import { inject, Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { Command2Service, MenuItemStore2 } from '@softline/application';
import { TerminalService } from '../services/terminal.service';
import { TerminalModule } from '../data/terminal-module';
import { ActivatedRoute, Router } from '@angular/router';
import { askFn, ModalService } from '@softline/ui-core';

type Constructor<T> = new(...args: any[]) => T;
export interface TerminalOptions {
  exit?: 'protected' | null;
}


export const WithTerminal = <T extends Constructor<{}>>(options: TerminalOptions, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class TerminalMixin extends Base implements OnInit, OnDestroy, TerminalModule {

    #ask = askFn();

    #terminalService = inject(TerminalService, {optional: true});

    #MENU_SET_NAME = this.constructor.name+'TerminalMenuItems';
    #COMMAND_NAME = this.constructor.name+'TerminalCommand';

    #route = inject(ActivatedRoute);
    #router = inject(Router);
    #injector = inject(Injector);
    #menuItemStore = inject(MenuItemStore2);
    #commandService = inject(Command2Service);
    #modalService = inject(ModalService);

    constructor(...args: any[]) {
      super(...args);
    }

    terminalExit: 'protected' | null | undefined = options.exit;

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      if(this.#terminalService){
        this.#terminalService.register(this);
      }
      else {
        this.#menuItemStore.setItem(this.#MENU_SET_NAME,  [{
          name: this.#COMMAND_NAME,
          outlet: 'action',
          title: 'Terminal starten',
          type: 'command',
          icon: 'fa-regular fa-screencast',
        }]);
        this.#commandService.register({
          name: this.#COMMAND_NAME,
          canExecute: true,
          execute: async () => {
            if(options.exit === 'protected') {
              const result = await this.#ask({
                title: 'Terminal starten',
                question: 'Möchten Sie wirklich das Terminal starten? Dies kann nur durch ein Passwort verlassen werden.'
              })
              if(result !== 'YES')
                return;
            }
            const route = '/terminal' + this.#router.url;
            await this.#router.navigate([route]);
          }
        });
      }
    }

    ngOnDestroy(): void {
      if(this.#terminalService)
        this.#terminalService.unregister(this);
      else {
        this.#menuItemStore.removeItem(this.#MENU_SET_NAME);
        this.#commandService.remove(this.#COMMAND_NAME);
      }
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return TerminalMixin;
}
