import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  contentChild,
  contentChildren,
  effect,
  inject, input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MenuItem, menuItems,
  WithActivatedRoute,
  WithMenuItems,
  WithRouter
} from '@softline/application';
import { PageHeaderComponent } from './page-header/page-header.component';
import { MenuOutletComponent } from '../../menu/menu-outlet/menu-outlet.component';
import { PageTabComponent } from './page-tab/page-tab.component';
import { isDefined, isDefinedNotEmpty } from '@softline/core';
import { PageSettingsService } from '../../user/page-settings/page-settings.service';
import { ObserveElementSizeDirective } from '@softline/ui-core';
import { ShellService } from '../../shell/services/shell.service';

@Component({
  selector: 'soft-page',
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  imports: [CommonModule, MenuOutletComponent, ObserveElementSizeDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent
  extends WithActivatedRoute(WithRouter())
  implements OnInit, AfterContentInit
{
  private NAME = 'page';
  readonly service = inject(PageSettingsService, { optional: true });
  readonly shellService = inject(ShellService);

  blockScrollY = input(false);

  header = contentChild(PageHeaderComponent, { descendants: true });
  selectedTabName = signal<string | null>(null);

  tabs = contentChildren(PageTabComponent, { descendants: true });

  selectedTabTemplate = computed(() => {
    const tabs = this.tabs();
    const selectedTabName = this.selectedTabName();
    const selectedTab =
      tabs.find((tab) => tab.name() === selectedTabName) ?? tabs[0];
    if (isDefined(selectedTab))
      return selectedTab.contentTemplate() ?? selectedTab.template();
    return null;
  });

  menuItems = menuItems(() => {
    const tabs = this.tabs();
    return tabs.map((tab) => ({
      name: tab.name() ?? '',
      type: 'route',
      icon: tab.icon() ?? '',
      title: tab.title() ?? '',
      outlet: 'tab',
      routerLink: [],
      routerLinkParams: { fragment: tab.name() },
    }));
  });

  fragmentEffect = effect(
    () => {
      const fragment = this.routeFragment();
      const selectedTabName = untracked(() => this.selectedTabName());
      if (isDefinedNotEmpty(fragment) && fragment !== selectedTabName)
        untracked(() => this.selectedTabName.set(fragment));
    },
  );

  selectedTabNameEffect = effect(
    () => {
      const selectedTabName = this.selectedTabName();
      const service = this.service;
      if (service && isDefined(selectedTabName))
        untracked(() =>
          service.set({ name: this.NAME, settings: { selectedTabName } })
        );
    },
  );

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  ngAfterContentInit() {
    const fragment = this.routeFragment();
    const tabs = this.tabs();

    if (isDefinedNotEmpty(fragment)) {
      this.selectedTabName.set(fragment);
      this.cdRef.markForCheck();
    } else if ((tabs?.length || 0) > 0) {
      let tabName = tabs[0].name();
      if (this.service) {
        const settings = this.service
          .pageSettings()
          .components.find((o) => o.name === this.NAME)?.settings;
        if (settings && isDefined(settings['selectedTabName'])) {
          tabName = settings['selectedTabName'] as string;
        }
      }
      this.router.navigate([], { fragment: tabName });
      this.cdRef.markForCheck();
    }
  }

  contentSizeChanged(rect: DOMRect): void {
    this.shellService.setContentRect(rect);
  }
}
