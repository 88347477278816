import { LabelType, Scan, ScannerStore2 } from '@softline/application';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { skip } from 'rxjs';

export interface ScanFnOptions {
  labelTypes?: (LabelType | null | undefined)[];
  match?: RegExp;
}

export function scan(callback: (scan: Scan) => Promise<void>, options?: ScanFnOptions): void {
  const destroyRef = inject(DestroyRef);
  const store = inject(ScannerStore2);
  store.scan$
    .pipe(takeUntilDestroyed(destroyRef))
    .subscribe(async (scan) => {
      if((options?.labelTypes && !options.labelTypes.includes(scan.labelType))
        || (options?.match && !options.match.test(scan.data)))
        return;
      await callback(scan);
  });
}
