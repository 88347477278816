import { importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP, Store, StoreModule} from '@softline/core';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS} from '@softline/application';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {
  SOFTLINE_CONFIG_DEFAULT_SEND_NOTIFICATION_COMPONENT_NAME,
  SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT,
  SOFTLINE_PERMISSION_SEND_NOTIFICATION
} from './send-notification.shared';
import {
  DefaultEmpfaengerAuswahlComponent
} from './components/default-empfaenger-auswahl/default-empfaenger-auswahl.component';
import {SendNotificationStore, SOFTLINE_FEATURE_SEND_NOTIFICATION} from './store/send-notification.store';
import {
  SendNotificationFieldOkStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK
} from './store/empfaenger-field-ok.store';
import {FieldOkService, SOFTLINE_STORE_FIELD_OK_SERVICE} from '@softline/dynamic';
import {SendNotificationService} from './services/send-notification.service';
import {
  EmpfaengerSuchverlaufStore,
  SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF
} from './store/empfeanger-suchverlauf.store';

export const sendNotificationProviders = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      const defaultComponentName = inject(SOFTLINE_CONFIG_DEFAULT_SEND_NOTIFICATION_COMPONENT_NAME, { optional: true });

      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'sendNotification',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Nachricht senden',
          icon: 'fa-regular fa-paper-plane',
          route: '/send-notification',
          routeParams: defaultComponentName ? {
            fragment: defaultComponentName
          } : undefined,
          permission: SOFTLINE_PERMISSION_SEND_NOTIFICATION,
        }
      );
    }),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SEND_NOTIFICATION,
      feature: SendNotificationStore.feature
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF,
      feature: EmpfaengerSuchverlaufStore.feature
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK,
      feature: SendNotificationFieldOkStore.feature,
      providers: [
        {
          provide: SendNotificationService,
          useExisting: SendNotificationService
        },
        {
          provide: SOFTLINE_STORE_FIELD_OK_SERVICE,
          useFactory: () => new FieldOkService(
            inject(ConnectionHttpService),
            'anwender',
          )
        }
      ]
    })
  ),
  {
    provide: SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT,
    multi: true,
    useValue: {
      name: 'DEFAULT',
      component: DefaultEmpfaengerAuswahlComponent,
    }
  },
  {
    provide: SendNotificationService,
    useFactory: () => new SendNotificationService(
      inject(SOFTLINE_SERVICE_HTTP),
    )
  },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF, fields: ['history'] },
    multi: true
  },
];
