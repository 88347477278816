import { ChangeDetectionStrategy, Component, computed, Input, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../pipes/ui-core-pipes.module';

@Component({
    selector: 'soft-json-object-tree',
    imports: [CommonModule, UiCorePipesModule],
    templateUrl: './json-object-tree.component.html',
    styleUrls: ['./json-object-tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonObjectTreeComponent {

  isExpanded = signal(false);

  @Input() set expanded(value: boolean) {
    this.isExpanded.set(value);
  }

  name = input<string>('');
  value = input<unknown>(undefined);
  canToggle = input<boolean>(true);

  className = computed(() => {
    const value = this.value();
    return (value as object).constructor.name;
  })

  constructor() {}

  toggle() {
    if(!this.canToggle())
      return;
    this.isExpanded.set(!this.isExpanded());
  }
}
