import { Component, computed, inject, Injector, input, linkedSignal, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService, UiCoreModule } from '@softline/ui-core';
import {
  block, handleRequestError,
  scan,
  Scan,
  ScannerModule,
  ScannerStore,
  showRequestErrors,
  SOFTLINE_FEATURE_SCANNER
} from '@softline/application';
import { Store } from '@softline/core';
import { Lagerplatz } from '../../types/lagerplatz';
import { SOFTLINE_CONFIG_LAGERPLATZ_AUSWAHL, SOFTLINE_FEATURE_LAGERPLATZ_SUCHE } from '../../lager-core.shared';
import { LagerplatzSucheStore } from '../../store/lagerplatz-suche.store';
import { FieldOkModalService } from '@softline/dynamic';
import { number } from 'mathjs';
import { StockyardRepository } from '../../repositories/stockyard.repository';
import { LagerplatzInhalt } from '../../types/lagerplatz-inhalt';
import { LagerplatzAuswahlDialog } from '../../dialogs/lagerplatz-auswahl/lagerplatz-auswahl.dialog';

@Component({
  selector: 'soft-lagerplatz-suche',
  imports: [
    CommonModule,
    UiCoreModule,
    ScannerModule
  ],
  templateUrl: './lagerplatz-suche.component.html',
  styleUrls: ['./lagerplatz-suche.component.scss'],
  providers: [StockyardRepository]
})
export class LagerplatzSucheComponent  {
  private store = inject(Store);
  private injector = inject(Injector);

  private repository = inject(StockyardRepository);
  private fieldOkService = inject(FieldOkModalService);
  private modalService = inject(ModalService);
  config = inject(SOFTLINE_CONFIG_LAGERPLATZ_AUSWAHL);

  loading$ = this.store.observe(
    SOFTLINE_FEATURE_LAGERPLATZ_SUCHE,
    LagerplatzSucheStore.getters.loading
  );
  loading = false;

  scan = output<Scan>();
  lagerplatzChange = output<Lagerplatz>();
  lagerplatzInhaltChange = output<LagerplatzInhalt>();

  readonly changeable = input(true);
  readonly lagerplatzInput = input<Lagerplatz | null | undefined>(undefined, {alias: 'lagerplatz'});
  readonly lagerplatz = linkedSignal(this.lagerplatzInput);
  readonly idbewekomm = input<number | null>(null)

  readonly canScan = computed(() => {
    return this.changeable()
      && (this.config === 'scan' || this.config === 'scanAndSelect');
  })

  readonly canSelect = computed(() => {
    return this.changeable()
      && (this.config === 'select' || this.config === 'scanAndSelect');
  })

  constructor() {
    scan(async (scan) => {
      if (!scan || this.loading)
        return;
      this.scan.emit(scan);
      this.loading = true;
      try {
        await block(async () => {
            const lagerplatz = await this.store.dispatch(
              SOFTLINE_FEATURE_LAGERPLATZ_SUCHE,
              LagerplatzSucheStore.actions.suche,
              scan.data
            );
            this.loading = false;
            if (lagerplatz) {
              this.lagerplatz.set(lagerplatz);
              this.lagerplatzChange.emit(lagerplatz);
            }
        }, this.injector);
      } catch (e) {
        this.loading = false;
        showRequestErrors(this.store, e);
      }
    });
  }

  async onScan(): Promise<void> {
    if(!this.canScan())
      return;
    await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.scan, {});
  }

  async onOpenSelect(): Promise<void> {
    if(!this.canSelect())
      return;
    const idBeweKomm = this.idbewekomm();
    let result: Lagerplatz | 'DISMISSED' | 'CANCELED' | null = null;
    if(idBeweKomm) {
      const stockyards = await block(async () =>{
        try {
          return await this.repository.getAvailableStockyards(idBeweKomm);
        }
        catch (e) {
          handleRequestError(e, this.injector, {show: true});
        }
        return null;
      }, this.injector);
      if(!stockyards)
        return;
      const dialogResult = (await this.modalService.open({
        component: LagerplatzAuswahlDialog,
        data: {
          lagerplatzInhalte: stockyards
        }
      })) as 'DISMISSED' | Lagerplatz;

      if (dialogResult === 'DISMISSED')
        return;

      const lagerplatzInhalt = stockyards.find(o => o.lagerplatz.id === dialogResult.id);

      if (lagerplatzInhalt)
        this.lagerplatzInhaltChange.emit(lagerplatzInhalt);
    }
    else {
      result = await block(async () => {
        const result = await this.fieldOkService.open<Lagerplatz>('lagerplatz');
        return result;
      }, this.injector)
    }

    if(!result || result === 'DISMISSED' || result === 'CANCELED')
      return;

    this.lagerplatz.set(result);
    this.lagerplatzChange.emit(result);
  }
}
