import { Pipe, PipeTransform } from '@angular/core';
import { getValue } from '@softline/core';

@Pipe({
    name: 'property',
    pure: true,
    standalone: false
})
export class PropertyPipe implements PipeTransform {
  transform(value: object | null | undefined, property: string): any {
    if (!value) return value;
    return getValue(value, property);
  }
}
