import {Component, ComponentRef, computed, inject, input, OnInit, TemplateRef} from "@angular/core";
import { CommonModule } from "@angular/common";
import { asapScheduler, Observable, observeOn } from "rxjs";
import { Store } from "@softline/core";
import { NavigationExtras, Router } from "@angular/router";
import { UiCoreModule } from "@softline/ui-core";
import {
  BottomOutletService,
  BottomTabGroupService,
  Command,
  CommandStore, MenuItemStore2,
  SOFTLINE_FEATURE_COMMANDS
} from '@softline/application';
import { MenuOutletComponent } from '../../../menu/menu-outlet/menu-outlet.component';

@Component({
    selector: 'soft-bottom-bar',
    imports: [CommonModule, UiCoreModule, MenuOutletComponent],
    templateUrl: './bottom-bar.component.html',
    styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  TemplateRef = TemplateRef;

  menuItemsStore = inject(MenuItemStore2);
  bottomOutletService = inject(BottomOutletService);
  bottomTabGroupService = inject(BottomTabGroupService);

  bottomCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'bottom-menu'
    )
    .pipe(observeOn(asapScheduler));


  readonly hasBottomMenuItems = computed(() => {
    const items = this.menuItemsStore.values();
    const menuItems = items
      .flat()
      .filter((item) => {
        if(typeof item.outlet === 'string')
          return (item.outlet === 'bottom') && (item.visible ?? true);
        return (item.outlet.includes('bottom')) && (item.visible ?? true);
      })
    return menuItems.length > 0;
  });


  constructor(private store: Store,
              private router: Router) { }

  ngOnInit(): void {}

  onClick(
    execute: (() => void) | undefined,
    routerLink: any[] | string | undefined,
    canExecute: boolean,
    routerLinkParams: NavigationExtras | undefined
  ): void {
    if (!canExecute)
      return;
    if (execute)
      execute();
    else if (routerLink)
      this.router.navigate(
        typeof routerLink === 'string' ? [routerLink] : routerLink,
        routerLinkParams
      );
  }
}
