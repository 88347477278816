import { Component, Input, OnInit } from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import {
  BooleanRulePipe,
  ClassRulePipe,
  DynamicValueComponent,
} from '@softline/dynamic';

@Component({
  selector: 'soft-dynamic-container-value',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    NgSwitchDefault,
    NgForOf,
    NgIf,
    NgClass,
    ClassRulePipe,
    BooleanRulePipe,
    DynamicValueComponent,
  ],
})
export class ContainerComponent implements OnInit {
  @Input() value?: any;
  @Input() definition: any;

  constructor() {}

  ngOnInit(): void {}
}
