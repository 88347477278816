/**
 * A type that can be displayed in a `soft-tree-view`
 * Each path in a tree **must be unique**
 */
export interface TreeNode<T = unknown> {
  path: string;
  name: string;
  type: 'expandable' | 'leaf'
  data?: T
}
