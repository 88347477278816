import { Component, computed } from '@angular/core';
import { Store } from '@softline/core';
import * as ModalStore from '../modal.store';
import { SOFTLINE_FEATURE_MODAL } from '../modal.shared';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../i18n/i18n.module';
import { ModalComponent } from './modal/modal.component';

@Component({
    selector: 'soft-modal-container',
    imports: [CommonModule, UiCorePipesModule, I18nModule, ModalComponent],
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent {

  configs$ = this.store.observe(
    SOFTLINE_FEATURE_MODAL,
    ModalStore.getters.items
  );
  storeConfigs = toSignal(this.configs$, {initialValue: []});
  configs = computed(() => {
    const storeConfigs = this.storeConfigs();
    return[...storeConfigs];
  })

  constructor(private store: Store) {}
}
