import { inject, Injectable, signal } from '@angular/core';
import {
  ConnectionHttpService,
  ApiResourceLocation,
} from '@softline/core';
import { firstValueFrom, Observable } from 'rxjs';
import {
  SOFTLINE_API_WWS_WARENUEBERNAHME_PALETTEN_DETAIL,
  SOFTLINE_API_WWS_WARENUEBERNAHME_PALETTEN_KONTROLLE
} from '../warenuebernahme.api';
import { PalettenInfo } from '../types/paletten-info';

@Injectable()
export class PalettenService {
  private readonly httpService = inject(ConnectionHttpService);

  readonly isLoading = signal(false)

  async loadPaletten(id: number): Promise<PalettenInfo> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_PALETTEN_DETAIL,
      pathParams: {
        belegtyp: 'bewewu',
        id: id,
      },
    } as ApiResourceLocation;

    this.isLoading.set(true);

    const result = await firstValueFrom(this.httpService.get<PalettenInfo>(location));

    this.isLoading.set(false);

    return result
  }

  async kontrollPaletten(body: { id:number, sscc: string, kontrolliert: boolean }[]): Promise<any> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_PALETTEN_KONTROLLE,
    };

    return await firstValueFrom(this.httpService.create<any, any>(location, body));
  }
}
