import { inject, Injector, Provider, provideEnvironmentInitializer, EnvironmentProviders } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import {
  ApplicationStore,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import { default as de } from '../i18n/de.json';
import {
  SOFTLINE_FEATURE_SUBKUNDEN,
  SOFTLINE_PERMISSIONS_SUBKUNDEN,
} from './subkunden.shared';

export const subkundenProviders: Array<Provider | EnvironmentProviders> = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'subkunden',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#SUBKUNDEN.TITLE',
          icon: 'fa-regular fa-house-chimney-user',
          route: '/subkunden',
          permission: SOFTLINE_PERMISSIONS_SUBKUNDEN,
        }
      );
    }),
];
