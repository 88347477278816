<div class="flex flex-col px-4 mt-2 sm:px-0">
  <div class="text-lg font-semibold">
    {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_ETIKETTEN_DRUCKEN' | translate}}
  </div>

  <div class="mt-4">
    <div class="font-bold text-sm text-primary-400 mb-1">
      {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_ANZAHL_ETIKETTEN' | translate}}
    </div>
    <soft-mengen-eingabe #mengenEingabe
                         [einheit]="('#WWS_WARENUEBERNAHME.DIALOG.ETIKETTEN_LABEL' | translate) ?? undefined"
                         [value]="anzahlEtiketten()"
                          (valueChange)="anzahlEtiketten.set($event)">
    </soft-mengen-eingabe>
  </div>
</div>

<div class="flex flex-row gap-4 w-full">
  <button class="soft-button bg-primary-300 mt-6 rounded-sm h-16 w-full"
          (click)="close('NO')">
    <soft-icon [name]="'fa-regular fa-xmark'"></soft-icon>
  </button>

  <button class="soft-button primary mt-6 rounded-sm h-16 w-full"
          [disabled]="mengenEingabe.value < 0"
          (click)="close(anzahlEtiketten())">
    <soft-icon [name]="'fa-regular fa-check'"></soft-icon>
  </button>
</div>

