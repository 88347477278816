import { Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT } from '../../../../utilities.shared';
import { map } from 'rxjs/operators';
import { PatchContextStore } from '../../../patch-context.store';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';

export type UserAccount = { userFullName?: string };

@Component({
    selector: 'lib-account-context-editor',
    templateUrl: './user-context.component.html',
    styleUrls: ['./user-context.component.scss'],
    imports: [CommonModule, UiCoreModule]
})
export class UserContextComponent implements OnInit {
  userContext$ = this.store
    .observe(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.getters.data
    )
    .pipe(map((o) => o as UserAccount));

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {}
}
