import {
  ChangeDetectorRef,
  Component,
  computed,
  Inject,
  Injector,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WithStaticTitle } from '@softline/application';
import {
  EmpfaengerComponentRegistration,
  SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT,
  SOFTLINE_STEPS_SEND_NOTIFICATION,
} from '../../send-notification.shared';
import { BehaviorSubject, map } from 'rxjs';
import { base64Encode, Store } from '@softline/core';
import {
  SendNotificationStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION,
} from '../../store/send-notification.store';

@Component({
  selector: 'soft-empfaenger-auswahl',
  imports: [CommonModule],
  templateUrl: './empfaenger-auswahl.component.html',
  styleUrls: ['./empfaenger-auswahl.component.scss'],
})
export class EmpfaengerAuswahlComponent
  extends WithStaticTitle('#SEND_NOTIFICATION.TITLE')
  implements OnInit
{
  readonly steps = SOFTLINE_STEPS_SEND_NOTIFICATION;

  private readonly fragment$ = this.route.fragment;

  readonly headerTemplate$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  readonly component$ = this.fragment$.pipe(
    map((fragment) => fragment ?? 'DEFAULT'),
    map((fragment) =>
      this.empfaengerComponents.find((o) => o?.name === fragment)
    ),
    map((componentRegistration) =>
      componentRegistration
        ? componentRegistration.component
        : EmpfaengerAuswahlComponent
    )
  );

  readonly data = this.store.signal(
    SOFTLINE_FEATURE_SEND_NOTIFICATION,
    SendNotificationStore.getters.data
  );

  readonly empfaenger = computed(() => this.data()?.empfaenger ?? []);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
    readonly injector: Injector,
    private cdRef: ChangeDetectorRef,
    @Inject(SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT)
    readonly empfaengerComponents: EmpfaengerComponentRegistration[]
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async nachrichtVerfassen(): Promise<void> {
    const from = this.route.snapshot.queryParamMap.get('from');

    await this.router.navigate(
      ['/send-notification/nachricht-verfassen'],
      from
        ? {
            queryParams: {
              from: base64Encode(
                JSON.stringify({
                  url: ['/send-notification'],
                  extras: {
                    queryParams: { from },
                    fragment: this.route.snapshot.fragment,
                  },
                })
              ),
            },
          }
        : {}
    );
  }

  attachHeader(template: TemplateRef<any>): void {
    this.headerTemplate$.next(template);
    this.cdRef.detectChanges();
  }
}
