import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'soft-dynamic-number-value',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  imports: [DecimalPipe],
})
export class NumberComponent implements OnInit {
  @Input() value?: any;
  @Input() format?: string;

  constructor() {}

  ngOnInit(): void {}
}
