import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent, IsDefinedNotEmptyPipe } from '@softline/ui-core';
import { Product } from '@softapps/wws/core';

@Component({
    selector: 'soft-product-card',
    imports: [CommonModule, IsDefinedNotEmptyPipe, ChipComponent],
    templateUrl: './product-card.component.html',
    styleUrl: './product-card.component.scss'
})
export class ProductCardComponent {
  product = input<Product | null>(null);

  statusColor(statusType: string | undefined) {
    switch (statusType) {
      case 'LISTED':
        return '#8aec8e'
      case 'ORDER':
        return '#faef77'
      case 'DISCUNTINUED':
        return '#dd9a9a'
      case 'DELISTED':
        return '#ef7474'
      default:
        return 'secondary'
    }
  }
}
