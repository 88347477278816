import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'last',
    standalone: false
})
export class LastPipe<T> implements PipeTransform {
  transform(array: readonly T[] | null | undefined): T | undefined {
    if (!array) return undefined;
    const index = array.length - 1;
    return array[index];
  }
}
