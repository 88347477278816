import { Component, Inject, OnInit } from '@angular/core';
import {
  CloudMessagingTopic,
  SOFTLINE_CONFIG_CLOUD_MESSAGING_TOPICS,
  SOFTLINE_FEATURE_CLOUD_MESSAGING,
} from '../cloud-messaging.shared';
import { Store } from '@softline/core';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule
} from "@softline/ui-core";
import * as CloudMessagingStore from '../store';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'lib-cloud-messaging-settings',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './cloud-messaging-settings.component.html',
    styleUrls: ['./cloud-messaging-settings.component.scss']
})
export class CloudMessagingSettingsComponent implements OnInit {

  permission$ = this.store.observe(
    SOFTLINE_FEATURE_CLOUD_MESSAGING,
    CloudMessagingStore.getters.permission)

  topics$ = this.store.observe(
    SOFTLINE_FEATURE_CLOUD_MESSAGING,
    CloudMessagingStore.getters.topics
  );

  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_CLOUD_MESSAGING_TOPICS)
    public topics: CloudMessagingTopic[]
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_CLOUD_MESSAGING,
        CloudMessagingStore.actions.loadTags
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#CAPACITOR.CLOUD_MESSAGING.MESSAGES.ERRORS.LOAD_TAGS'
      );
    }
  }

  async onSubscribe(): Promise<void> {
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_CLOUD_MESSAGING, CloudMessagingStore.actions.subscribe)
    }
    catch (e) {
      console.error(e);
    }
  }

  async onTopicChange(key: string, value: boolean): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_CLOUD_MESSAGING,
        CloudMessagingStore.actions.setTopic,
        { key, value }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#CAPACITOR.CLOUD_MESSAGING.MESSAGES.ERRORS.SAVE_TAGS'
      );
    }
  }
}
