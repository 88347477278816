import { GetResourceService, ResourceService } from '../../data/abstraction';
import {
  Id,
  LoadCollectionItemParameters,
  LoadCollectionParameters, QueryCollectionParameters,
  ReadonlyCollectionRepository
} from '../abstraction';
import { Observable } from 'rxjs';
import { ApiResourceLocation } from '../../data-access/types/api.location';
import { toIdString } from '../functions/to-id-string';

export class ReadonlyHttpApiCollectionRepository<TResult, TManyResult> implements ReadonlyCollectionRepository<TResult, TManyResult> {

  constructor(protected service: ResourceService<ApiResourceLocation>,
              protected path: string | string[],
              protected options?: {pathSuffix?: string | string[], loadManyMethod?: 'get' | 'post'}) {
  }

  loadItem(id: Id, params: LoadCollectionItemParameters): Observable<TResult> {
    const path = this.getItemPath(id);
    const location: ApiResourceLocation = {
      path: path,
      pathParams: params.pathParams,
    }
    return this.service.get(location);
  }

  loadMany(params?: LoadCollectionParameters): Observable<TManyResult> {
    switch (this.options?.loadManyMethod) {
      case 'post':
        const postLocation: ApiResourceLocation = {
          path: this.path,
          pathParams: params?.pathParams,
          queryParams: {limit: params?.limit, offset: params?.offset, sort: params?.sort }
        }
        return this.service.get(postLocation, params?.query ?? {});
      default:
        const location: ApiResourceLocation = {
          path: this.path,
          pathParams: params?.pathParams,
          queryParams: {...(params?.query ?? {}), limit: params?.limit, offset: params?.offset, sort: params?.sort }
        }
        return this.service.get(location);
    }
  }

  protected getItemPath(id: Id): string[] {
    if(Array.isArray(this.path))
      return [...this.path, toIdString(id), ...this.getPathSuffix()];
    else
      return [this.path, toIdString(id), ...this.getPathSuffix()];
  }

  protected getPathSuffix(): string[] {
    if(!this.options?.pathSuffix)
      return []
    if(Array.isArray(this.options?.pathSuffix))
      return this.options.pathSuffix;
    else
      return [this.options?.pathSuffix];
  }
}
