import { Component, computed, inject, Inject, input, OnInit, signal } from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainChartComponent} from '../../components/main-chart/main-chart.component';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatestWith, filter, firstValueFrom, map, Observable, of } from 'rxjs';
import {ApiResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP, Store} from '@softline/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
  showRequestErrors,
  RemoteConfigStore,
  SOFTLINE_FEATURE_REMOTE_CONFIG,
  RemoteConfig
} from '@softline/application';
import {UmsatzstatistikQuery} from '../../types/umsatzstatistik.query';
import moment from 'moment';
import {I18nModule, UiCoreModule} from '@softline/ui-core';
import {Umsatzstatistik} from '../../types/umsatzstatistik';
import {UmsatzstatistikService} from '../../services/umsatzstatistik-service';
import {extractUmsatzstatistikRemoteConfig} from '../../utils/extract-remote-config';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG} from '../../umsatzstatistik.api';
import {toChartData} from '../../utils/to-chart-data';
import { DateSelectionService } from '@softapps/core';
import { UmsatzstatistikRemoteConfig } from '../../types/remote-config';
import { SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK } from '@softapps/wws/umsatzstatistik';
import { CompactChartComponent } from '../../components/compact-chart/compact-chart.component';
import { Zeitraum } from '../../types/zeitraum';

@Component({
    selector: 'soft-umsatzstatistik-full-width-widget',
  imports: [CommonModule, MainChartComponent, I18nModule, UiCoreModule, CompactChartComponent],
    templateUrl: './umsatzstatistik-full-width-widget.component.html',
    styleUrl: './umsatzstatistik-full-width-widget.component.scss'
})
export class UmsatzstatistikFullWidthWidgetComponent implements OnInit {

  config = inject(SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK, {optional: true});
  dateSelectionService = inject(DateSelectionService);

  readonly vondatum = computed(() => this.dateSelectionService.date());
  readonly bisdatum = computed(() => this.dateSelectionService.date());

  readonly loading = signal(false);
  readonly idktostamm = input<number | null>(null);

  authenticationContext = this.store.signal(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  );

  vkfOrgId = computed(() => this.authenticationContext()?.['vkforgid']);

  remoteConfig = signal<UmsatzstatistikRemoteConfig | null>(null);
  branches = rxResource({
    request: () => ({
      remoteConfig: this.remoteConfig()
    }),
    loader: (params) => {
      const accountId = this.store.get(
        SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
        AuthenticationContextStore.getters.data
      )?.['accountid'];

      const location: ApiResourceLocation = {
        path: SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG,
        queryParams: (this.idktostamm() ?? accountId)
          ? { idktostamm: (this.idktostamm() ?? accountId) }
          : undefined
      }
      return this.httpService.get<any>(location)
    }
  })

  data = rxResource({
    request: () => ({
      remoteConfig: this.remoteConfig(),
      vondatum: this.vondatum(),
      bisdatum: this.bisdatum(),
      filialen: this.branches.value(),
      vkforgid: this.vkfOrgId()
    }),
    loader: (params) => {
      if(!params.request.remoteConfig || !params.request.filialen)
        return of(undefined);

      let filialen: any[] = [];
      if(!this.config || this.config.widget.branch === 'all')
        filialen = params.request.filialen
      else {
        const filiale = params.request.filialen.find((f) => f.id === params.request.vkforgid);
        if(filiale)
          filialen = [filiale];
        else
          filialen = params.request.filialen;
      }

      const query = {
        ebene: params.request.remoteConfig?.defaultEbene ?? 'VKFORG',
        umsatzart: params.request.remoteConfig?.defaultUmsatzart ?? 'Gesamt' ,
        vondat: params.request.vondatum,
        bisdat: params.request.bisdatum,
        filialen: filialen,
        menge: 0,
        vergleich: false
      }

      return this.service.loadUmsatzstatistik(query);
    }
  })

  readonly chartData = computed(() => {
    const data = this.data.value();
    const remoteConfig = this.remoteConfig();
    if(!data || !remoteConfig)
      return [];
    return toChartData(data.ebeneresult, remoteConfig)
  })


  readonly period = computed(() => {
    return  { vondatum: this.vondatum(), bisdatum: this.bisdatum() };
  });

  readonly sum = computed(() => {
    const data = this.data.value();
    return data?.ebeneresult['Alle'].arten?.['Summe'] ?? null;
  });

  constructor(
    private readonly store: Store,
    private readonly service: UmsatzstatistikService,
    @Inject(SOFTLINE_SERVICE_HTTP)
    private readonly httpService: ResourceService<ApiResourceLocation>,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const remoteConfig = await this.store.dispatch(
        SOFTLINE_FEATURE_REMOTE_CONFIG,
        RemoteConfigStore.actions.load
      );

      const umsatzstatistikRemoteConfig = extractUmsatzstatistikRemoteConfig(remoteConfig);
      this.remoteConfig.set(umsatzstatistikRemoteConfig);
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
