import { inject, Injectable } from '@angular/core';
import { LoginService } from '@softline/auth';
import { Router } from '@angular/router';
import { ModalService } from '@softline/ui-core';
import { LoginDialog } from '../components/dialogs/login/login.dialog';

@Injectable({
  providedIn: 'root',
})
export class SoftAppsLoginService extends LoginService {

  router = inject(Router);
  modalService = inject(ModalService);

  constructor() {
    super();
  }

  override async login(): Promise<boolean> {
    await this.router.navigate(['/login']);
    return true;
  }

  override async showLoginDialog(): Promise<boolean> {
    const result = await this.modalService.open({
      component: LoginDialog,
      dismiss: true
    })
    if(result === 'DISMISSED')
      return false;
    return true;
  }

}
