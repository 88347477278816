import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
  Validators,
} from '@softline/ui-core';
import { UserValidationInfo } from '../../../data/user-validation';
import { Store } from '@softline/core';
import { Router } from '@angular/router';
import {
  SOFTLINE_FEATURE_PASSWORD,
  SOFTLINE_PAGE_FORGOT_PASSWORD,
  SOFTLINE_PAGE_RESET_PASSWORD,
} from '../../../password.shared';
import { PasswordStore } from '../../../store/password.store';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-password-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule]
})
export class UserFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(undefined, Validators.required()),
    email: new UntypedFormControl(undefined, [
      Validators.required(),
      Validators.email(),
    ]),
  });

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  async submit(userValidation: UserValidationInfo): Promise<void> {
    try {
      const action = PasswordStore.actions.requestToken;
      await this.store.dispatch(
        SOFTLINE_FEATURE_PASSWORD,
        action,
        userValidation
      );

      const message = '#PASSWORD.MESSAGES.CONFIRMATION_SENT';
      const queryParams = {
        queryParams: { username: userValidation.username },
      };
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        message
      );
      await this.router.navigate(
        [SOFTLINE_PAGE_FORGOT_PASSWORD, SOFTLINE_PAGE_RESET_PASSWORD],
        queryParams
      );
    } catch (e) {
      const message = '#PASSWORD.MESSAGES.CONFIRMATION_FAILED';
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        message
      );
    }
  }
}
