import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import {BeweLf} from '../../types/bewe-lf';
import {SearchStornoBewe, StornoBewe} from '../../types/return-note';
import {ReturnNoteBeweModalService} from '../../services/return-note-bewe-modal.service';
import {SOFTLINE_SERVICE_UUID, Store} from '@softline/core';
import {SOFTLINE_CONFIG_LIEFERSCHEIN, SOFTLINE_FEATURE_RETURN_NOTE} from '../../delivery-note.shared';
import {ReturnNoteStore} from '../../stores/return-note.store';
import {Arteh} from '../../types/arteh';
import {CommonModule} from '@angular/common';
import {NumberInputComponent, UiCoreModule} from '@softline/ui-core';
import {DeliveryNoteConfig} from '../../types/delivery-note-config';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-return-note-storno-bewe-item',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './return-note-storno-bewe-item.component.html',
    styleUrls: ['./return-note-storno-bewe-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReturnNoteStornoBeweItemComponent {

  hasError = false;
  hasSplit = false;

  // tslint:disable-next-line:variable-name
  _arteh: Arteh | undefined = undefined;

  private _value!: StornoBewe | SearchStornoBewe;

  @Input() bewelf!: BeweLf;
  @Input() returnNoteId!: string;
  @Input() addedFromSearch = false;

  @Input()
  get value(): StornoBewe | SearchStornoBewe {
    return this._value;
  }
  set value(value: StornoBewe | SearchStornoBewe) {
    this._value = value;

    if ((value?.menge || 0) > value?.artehBean?.maxRetourmenge) {
      this.hasSplit = true;
    } else if ((value?.menge || 0) < 0) {
      this.hasError = true;
    }
  }

  @Output() valueChange = new EventEmitter<StornoBewe>();
  @Output() valueDelete = new EventEmitter<StornoBewe>();

  get artehBean(): Arteh | undefined {
    return this._arteh ?? this.value?.artehBean ?? this.bewelf?.artehBeans?.find(o => o.arteh === this.bewelf?.einheit);
  }

  constructor(private store: Store,
              private modalService: ReturnNoteBeweModalService,
              private cdRef: ChangeDetectorRef,
              @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
              @Optional() @Inject(SOFTLINE_CONFIG_LIEFERSCHEIN) readonly deliveryNoteConfig: DeliveryNoteConfig) {}

  async onEditBeweClick(bewe: StornoBewe | SearchStornoBewe | undefined): Promise<void> {
    if (!bewe) {
      bewe = this.createEmptyBewe();
    }

    await this.modalService.editBewe(this.returnNoteId, bewe, this.bewelf);
  }

  async onDeleteBeweClick(bewe: StornoBewe | SearchStornoBewe | undefined): Promise<void> {
    if ( this.returnNoteId && bewe)
     await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.deleteBewe, {
       id: this.returnNoteId,
       bewe
     });
  }

  isSearchItem(value: SearchStornoBewe | StornoBewe): value is SearchStornoBewe {
    return !!(value as SearchStornoBewe)?.deliveryNote;
  }

  async onChange(bewe: StornoBewe | SearchStornoBewe | undefined, menge: number | null | undefined, arteh: Arteh | undefined, numberInput: NumberInputComponent): Promise<void> {
    const maxRetourmenge = arteh?.maxRetourmenge ?? Number.MAX_VALUE;
    const oldValue = bewe?.menge ?? numberInput?.value ?? 0;
/*
    if (this.deliveryNoteConfig?.openDialogMaxMenge && ((menge ?? 0) > maxRetourmenge)) {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open(),
        {
          id: 'CONFIRM_MENGE_ABOVE_MAX_MENGE_DIALOG',
          component: ConfirmMengeAboveMaxMengeDialogComponent,
          dismiss: true,
        }
      );

      if (!result)
        menge = oldValue;

      // Request
    } */

    console.log('bewe: ', bewe);

    // this.hasError = (menge ?? 0) < 0 || (menge ?? 0) > maxRetourmenge;
    this.hasSplit = (menge ?? 0) > maxRetourmenge;

    if (this.hasError) {
      setTimeout(() => {
        this.hasError = false;
        this.cdRef.markForCheck();
      }, 4000);
      menge = null;
    }
    if (!bewe)
      bewe = this.addedFromSearch ? this.createEmptyStornoBewe() : this.createEmptyBewe();
    else
      bewe = {...bewe};

    bewe.menge = menge;
    bewe.artehBean = arteh;

    numberInput.value = menge;

    if (numberInput.inputElement) {
      numberInput.inputElement.value = menge?.toString() ?? '';
    }

    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id: this.returnNoteId, bewe});
  }

  private createEmptyBewe(): StornoBewe {
    return {
      id: this.uuid(),
      menge: 0,
      files: [],
      typ: 'storno' ,
      artehBean: this.artehBean,
      idbewelf: this.bewelf.id,
      vermerk: ''
    };
  }

  private createEmptyStornoBewe(): SearchStornoBewe {
    return {
      id: this.uuid(),
      menge: 0,
      files: [],
      typ: 'search',
      artehBean: this.artehBean,
      bewelf: {
        id: this.bewelf?.id ?? 0,
        artehBeans: this.bewelf?.artehBeans && Array.isArray(this.bewelf?.artehBeans)
          ? this.bewelf.artehBeans
          : [],
        menge: this.bewelf?.menge,
        vermerk: this.bewelf.vermerk,
        idartstamm: this.bewelf?.idartstamm,
        arteheingaben: [],
        artikelnummer: this.bewelf?.artikelnummer,
        beschreibung: this.bewelf?.beschreibung,
        bezeichnung1: this.bewelf?.bezeichnung1,
        bezeichnung2: this.bewelf?.bezeichnung2,
        bezeichnung3: this.bewelf?.bezeichnung3,
        bezeichnung4: this.bewelf?.bezeichnung4,
        bezeichnung5: this.bewelf?.bezeichnung5,
        einheit: this.artehBean?.arteh ?? this.bewelf?.einheit,
        editable: false,
        zeilentext: this.bewelf?.zeilentext,
      },
      vermerk: '',
    };
  }
}
