<div class="flex flex-col">
  <div class="content pr-4 pl-4 pb-4 sm:pl-0">
    {{ text() }}
  </div>
  <div class="flex no-wrap">
    @if (showCancel()) {
      <button class="soft-button link cancel h-14 w-full"
              (click)="onClick('CANCEL')">
        {{ '#UI_CORE.DIALOGS.CANCEL' | translate }}
      </button>
    }
    <button class="soft-button accent ok h-14 w-full" (click)="onClick('OK')">
      {{ '#UI_CORE.DIALOGS.OK' | translate }}
    </button>
  </div>
</div>
