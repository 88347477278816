import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DeliveryNote } from '../../types/delivery-note';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {DeliveryNoteChangedPipe} from '../../pipes/delivery-note-changed.pipe';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-delivery-note-card',
    imports: [CommonModule, UiCoreModule, DeliveryNoteChangedPipe],
    templateUrl: './delivery-note-card.component.html',
    styleUrls: ['./delivery-note-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryNoteCardComponent implements OnInit {

  @Input() deliveryNote?: DeliveryNote;

  constructor() { }

  ngOnInit(): void {}
}
