import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import {AnzahlMitarbeiterGesamtPipe} from '../../pipes/anzahl-mitarbeiter-gesamt.pipe';
import {AbteilungUebersicht} from '../../types/abteilung';
import {UiCoreModule} from '@softline/ui-core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'soft-abteilung-card',
    imports: [CommonModule, RouterLink, AnzahlMitarbeiterGesamtPipe, UiCoreModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './abteilung-card.component.html',
    styleUrls: ['./abteilung-card.component.scss'],
    animations: [
        trigger('animateIn', [
            transition(':enter', [
                style({ opacity: 0.5, width: 0 }),
                animate('300ms ease-in', style({ opacity: 1, width: '*' })),
            ]),
        ])
    ]
})
export class AbteilungCardComponent {
  @Input() abteilung!: AbteilungUebersicht;
  @Input() selected = false;
  @Input() mode: 'selection' | 'display' = 'display';
  @Output() tapped = new EventEmitter<void>();

  get abteilungStatusDasharrayData() {
    const anwesend = (this.abteilung.status?.anwesend || 0);
    const abwesendRot = (this.abteilung.status?.abwesendrot || 0);
    const abwesendGelb = (this.abteilung.status?.abwesendgelb || 0);

    const total = anwesend + abwesendRot + abwesendGelb;

    const per1 = anwesend / total * 100;
    const per2 = abwesendRot / total * 100;
    const per3 = abwesendGelb / total * 100;
    const offset = 25;

    const anwesendDasharray = `${per1} ${(100 - per1)}`;
    const anwesendDasharrayOffset = offset;

    const abwesendRotDasharray = `${per2} ${(100 - per2)}`;
    const abwesendRotDasharrayOffset = (100 - per1) + offset;

    const abwesendGelbDasharray = `${per3} ${(100 - per3)}`;
    const abwesendGelbDasharrayOffset = (100 - (per1 + per2)) + offset;

    return {
      total,
      anwesend: {
        dasharray: anwesendDasharray,
        dasharrayOffset: anwesendDasharrayOffset,
      },
      abwesendRot: {
        dasharray: abwesendRotDasharray,
        dasharrayOffset: abwesendRotDasharrayOffset,
      },
      abwesendGelb: {
        dasharray: abwesendGelbDasharray,
        dasharrayOffset: abwesendGelbDasharrayOffset,
      },
    }
  }
}
