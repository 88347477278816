import {
  createAction,
  createGetter,
  createMutation,
  Dictionary,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { SettingsService } from './services/settings.service';
import { Settings } from "./settings";
import { SOFTLINE_CONFIG_SETTINGS } from "../application.shared";
import { combineLatest, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";

export interface State {
  settings: Dictionary<object>;
  components: Settings[];
}

export const mutations = {
  setComponents: createMutation<State, Settings[]>('setComponents'),
  set: createMutation<State, object>('set'),
  setKey: <T = object>() =>
    createMutation<State, { key: string; values: T }>('setKey'),
};

export const actions = {
  init: createAction<State, undefined, undefined>('init'),
  load: createAction<State, object, Dictionary<object>>('load'),
  save: createAction<State, undefined, Dictionary<object>>('save'),
  reset: createAction<State, object, Dictionary<object>>('set'),
};

export const getters = {
  values: <T = object>() => createGetter<State, T, string>('values'),
  components: createGetter<State, Settings[]>('components'),
  visibleComponents$: createGetter<State, Observable<Settings[]>>('visibleComponents'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    settings: {},
    components: []
  },
  mutations: [
    mutate(mutations.setComponents, ({ state, params }) => ({
      ...state,
      components: params,
    })),
    mutate(mutations.set, ({ state, params }) => ({
      ...state,
      settings: { ...state.settings, ...params },
    })),
    mutate(mutations.setKey(), ({ state, params }) => ({
      ...state,
      settings: { ...state.settings, [params.key]: params.values },
    })),
  ],
  actions: [
    on(actions.init, async ({ commit, injector, featureName }) => {
      const components = injector.get<Settings[]>(SOFTLINE_CONFIG_SETTINGS);
      commit(featureName, mutations.setComponents, components);
    }),
    on(actions.load, async ({ commit, injector, featureName }) => {
      const service = injector.get(SettingsService);
      const settings = await service.load();
      commit(featureName, mutations.set, settings);
      return settings;
    }),
    on(actions.save, async ({ state, injector }) => {
      const service = injector.get(SettingsService);
      return await service.save(state.settings);
    }),
  ],
  getters: [
    select(getters.values(), ({ state, params }) => state.settings[params]),
    select(getters.components, ({ state, params }) => state.components),
    select(getters.visibleComponents$, ({state, get}) => {
      const settingsSort = (a: Settings, b: Settings) => {
        if ((a.priority ?? 0) > (b.priority ?? 0)) return -1;
        else if ((a.priority ?? 0) < (b.priority ?? 0)) return 1;

        const titleA = get(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.getters.translation,
          a.title ?? ''
        );
        const titleB = get(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.getters.translation,
          b.title ?? ''
        );
        if (titleA < titleB) return -1;
        else if (titleA > titleB) return 1;

        return 0;
      };

      const components$ = combineLatest(
        state.components
          .filter(o => !!o.component)
          .map(o => o.visible?.pipe(map(p => p ? o : undefined)) ?? of(o))
      ).pipe(
        map(o => o.filter(p => !!p) as Settings[]),
        map( o => o.sort((a, b) => settingsSort(a, b)))
      )

      return components$;
    })
  ],
};
