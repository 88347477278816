import { inject, InjectionToken } from '@angular/core';

export const SOFTLINE_MODAL_RESOLVER = new InjectionToken<(result: unknown) => void>('SOFTLINE_MODAL_RESOLVER')

export abstract class Modal2 { }

export abstract class ResolvableModal<TResult> extends Modal2 {
  private resolver = inject(SOFTLINE_MODAL_RESOLVER, {optional: true});

  resolve(result: TResult) {
    if(!this.resolver)
      throw new Error('[ResolvableModal] Resolver not found, Are you sure this modal is opened by ModalService?');
    return this.resolver(result);
  }
}
