<div class="segmented-control mt-3" *ngIf="segments && selected">
  <div class="segmented-control-active-indicator" [style]="activeIndicatorStyle">&nbsp;</div>
  <div class="segmented-control-option" *ngFor="let segment of segments"
       [class.segment-active]="segment?.value === selected"
       (click)="onChangeSegment(segment)">
    <div class="text-wrapper">
      <span>{{ segment?.label | translate }}</span>
      <ng-container *ngIf="segment?.hasEntries">
        <!-- <span class="text-danger-700">*</span> -->
        <!-- <div class="dot-container">
            <i class="fa-regular fa-caret-down text-2xl text-tertiary-600"></i>
        </div> -->
      </ng-container>
    </div>
  </div>
</div>
