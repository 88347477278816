import {Inject, Injectable} from '@angular/core';
import {ApiResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Observable} from 'rxjs';
import {SOFTLINE_API_SEND_NOTIFICATION} from '../send-notification.api';
import {SendNotificationResult} from '../types/send-notification-result';

@Injectable()
export class SendNotificationService {

  private get sendLocation(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_SEND_NOTIFICATION,
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) readonly service: ResourceService<unknown>
  ) {}

  send(payload: { empfaengerIdanwender: number[]; betreff: string; inhalt?: string; }): Observable<SendNotificationResult> {
    return this.service.create<typeof payload, SendNotificationResult>(this.sendLocation, payload);
  }
}
