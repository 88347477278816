import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  SOFTLINE_SERVICE_DURATION_PARSER,
  Parser,
} from '../../../ui-core.tokens';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
    selector: 'soft-duration-input',
    templateUrl: './duration-input.component.html',
    styleUrls: ['./duration-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DurationInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UiCorePipesModule, I18nModule]
})
export class DurationInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input() value?: string | null;
  @Input() format?: string;
  @Input() placeholder?: string;
  @Input() @HostBinding('class.readonly') readonly?: string;

  @Output() valueChange = new EventEmitter<string | null>();

  constructor(
    @Inject(SOFTLINE_SERVICE_DURATION_PARSER)
    private parser: Parser<string, string>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string): void {
    if (!value) this.value = value;
    else this.value = this.parser(value);

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
