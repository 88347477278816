import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileInputComponent } from '../../../components/form/file-input/file-input.component';
import { TranslatePipe } from '../../../i18n/pipes/translate.pipe';
import { ResolvableModal } from '../../abstraction/modal';
import { FileSourceInput } from '../../../components/form/file-input/file-source';

@Component({
  selector: 'soft-file-modal',
  imports: [CommonModule, FileInputComponent, TranslatePipe],
  templateUrl: './file-modal.component.html',
  styleUrl: './file-modal.component.scss',
})
export class FileModalComponent extends ResolvableModal<File[] | null>{
  sources = input<FileSourceInput>('all');
  accept = input<string | undefined>();
  inputView = input<'common' | 'dragAndDrop'>('dragAndDrop');
  selectionMode = input<'single' | 'multi'>('single');
  valueView = input<'grid' | 'list'>('list');
  autoSubmit = input<boolean>(false);

  onSubmit(value: File[] | null): void {
    this.resolve(value);
  }

  onValueChange(value: File[] | null): void {
    if(value && value.length > 0)
      this.onSubmit(value);
  }
}
