import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { isDefinedNotEmpty, Store } from "@softline/core";
import { EmptyEntityInputStrategy, EntityInputStrategy } from "../entity-input/strategies/entity-input.strategy";
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
    selector: 'soft-entity-query-input',
    imports: [CommonModule, I18nModule],
    templateUrl: './entity-query-input.component.html',
    styleUrls: ['./entity-query-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EntityQueryInputComponent),
            multi: true,
        },
    ],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('222ms ease-out', style({ opacity: 1 })),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityQueryInputComponent<TParams>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() title?: string | null;

  @Input() input = '';
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() value?: string | null;
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Input() parameters?: TParams;

  @Input() strategy: EntityInputStrategy<any, TParams> = new EmptyEntityInputStrategy();
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() placeholder?: string | null;

  @Output() validate: EventEmitter<string> = new EventEmitter<string>();
  @Output() open = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  @ViewChild('inputComponent') inputComponent!: ElementRef;

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  constructor(private store: Store) {}

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  @HostListener('keydown.f4', ['$event']) async onF4(event?: KeyboardEvent): Promise<void> {
    this.input = this.inputComponent.nativeElement.value;
    await this.onOpen();
  }

  @HostListener('keydown.tab', ['$event']) async onTab(event?: KeyboardEvent): Promise<void> {
    this.input = this.inputComponent.nativeElement.value;
    await this.onValidate(this.input);
  }
  @HostListener('keydown.enter', ['$event']) async onEnter(
    event?: KeyboardEvent
  ): Promise<void> {
    this.input = this.inputComponent.nativeElement.value;
    await this.onValidate(this.input);
  }
  @HostListener('keydown', ['$event']) preventKeydownPropagation(event?: KeyboardEvent): void {
    event?.stopPropagation();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.input = '';
  }

  onInput(input: string): void {
    this.input = input;
    this.inputChange.emit(input);
  }

  async onOpen(): Promise<void> {
    const value = await this.strategy.open(this.input, this.parameters);
    this.open.emit();
    if(value !== this.value && value !== 'DISMISSED' && value !== 'CANCELED')
      this.setValue(value);
    else
      this.onTouch(this.value);
  }

  onClose(): void {
    this.close.emit();
    this.onTouch(this.value);
  }

  async onValidate(input: string): Promise<void> {
    try {
      const value = await this.strategy.validate(input, this.parameters);
      if(value === 'CANCELED')
        return;
      this.setValue(value);
      this.validate.emit(this.inputComponent.nativeElement.value);
    }
    catch (e) {
      console.error(e);
    }
  }

  async onCancel(): Promise<void> {
    const loading = this.strategy.loading();
    if(loading)
      await this.strategy.cancel();
    else {
      this.setValue(null);
    }
  }

  setValue(value: string | null): void {
    if (value === this.value) return;
    this.value = value;
    this.input = '';

    this.onChange(this.value);
    this.onTouch(this.value);
    this.valueChange.emit(this.value);
  }
}
