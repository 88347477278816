import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-lstfix-fieldok-list-view',
    imports: [CommonModule],
    templateUrl: './list-view.component.html',
    styleUrl: './list-view.component.scss'
})
export class LstfixListViewComponent {

  @Input() item!: Record<string, unknown>
}
