import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from '../language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  languageService = inject(LanguageService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const language = this.languageService.selected()
    if (!language)
      return next.handle(request);

    request = request.clone({
      setHeaders: {
        'Accept-Language': language.code ?? ''
      },
    });
    return next.handle(request);
  }
}
