import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreModule, Store } from '@softline/core';
import { PdfEditorComponent } from '../../components/pdf-editor/pdf-editor.component';
import {
  BackNavigable,
  BackNavigationService,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { SOFTLINE_FEATURE_PDF_EDITOR } from '../../pdf-editor.shared';
import { PdfEditorStore } from '../../store/pdf-editor.store';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-pdf-editor-page',
    imports: [CommonModule, CoreModule, PdfEditorComponent],
    templateUrl: './pdf-editor-page.component.html',
    styleUrls: ['./pdf-editor-page.component.scss']
})
export class PdfEditorPageComponent
  implements OnInit, OnDestroy, BackNavigable
{
  document$ = this.route.paramMap.pipe(
    map((o) => o.get('id')),
    switchMap((id) =>
      this.store.observe(
        SOFTLINE_FEATURE_PDF_EDITOR,
        PdfEditorStore.getters.document,
        id
      )
    ),
    map((o) => o?.data)
  );

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'PDF Editor'
    );
    this.backNavigationService.set(this);
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    const file = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.getters.document,
      id
    );
    await this.router.navigate(file.fromRoute.url, file.fromRoute.extras);
  }

  async onSaveDocument(data?: Blob): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    if(!id || !data)
      throw new Error('[PdfEditorPageComponent] onSaveDocument: no id or data')
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.mutations.edit,
      { id, data }
    );
    const file = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.getters.document,
      id
    );
    await this.router.navigate(file.fromRoute.url, file.fromRoute.extras);
  }
}
