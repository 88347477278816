import { Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_DEFINITION_USER_CONTACT,
  SOFTLINE_FEATURE_USER_CONTACT,
  UserContactStore,
} from '../../utilities.shared';
import {
  DefinitionStore,
  DynamicModule,
  SOFTLINE_FEATURE_DEFINITIONS,
} from '@softline/dynamic';
import { SOFTLINE_API_USER_CONTACT_DEFINITION } from '../user-contact.api';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { showRequestErrors } from '@softline/application';
import {
  SOFTLINE_FEATURE_MESSAGE_BAR,
  MessageBarStore,
  UiCoreModule,
} from '@softline/ui-core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'lib-user-contact',
    templateUrl: './user-contact.component.html',
    styleUrls: ['./user-contact.component.scss'],
    imports: [CommonModule, UiCoreModule, DynamicModule]
})
export class UserContactComponent implements OnInit {
  loading$ = combineLatest([
    this.store.observe(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.getters.loading,
      SOFTLINE_DEFINITION_USER_CONTACT
    ),
    this.store.observe(
      SOFTLINE_FEATURE_USER_CONTACT,
      UserContactStore.getters.loading
    ),
  ]).pipe(map((o) => o.some((p) => p)));

  definition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_USER_CONTACT
  );
  value$ = this.store.observe(
    SOFTLINE_FEATURE_USER_CONTACT,
    UserContactStore.getters.data
  );
  saving$ = this.store.observe(
    SOFTLINE_FEATURE_USER_CONTACT,
    UserContactStore.getters.saving
  );

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        {
          name: SOFTLINE_DEFINITION_USER_CONTACT
        }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_USER_CONTACT,
        UserContactStore.actions.load
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  async onSubmit(value: object): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_USER_CONTACT,
        UserContactStore.actions.patch,
        { changes: value }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#UTILITIES.SETTINGS.USER_CONTACT.SUCCESS'
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
