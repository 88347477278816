import {SortDirection} from "../types/sort-direction";

export function sortBy<T extends object, TField extends keyof T>(
  array: readonly T[] | null | undefined,
  field: TField,
  direction: 'ASC' | 'DESC' | null = SortDirection.Ascending,
  comparer?: (a: T[TField], b: T[TField]) => number
): T[] {
  if (!array || !Array.isArray(array))
    return [];
  const multiplier = direction === SortDirection.Ascending ? 1 : -1;

  const returnValue = [...array];
  returnValue.sort((a: T, b: T) => {
    if (!comparer) comparer = defaultComparer;
    return comparer(a[field], b[field]) * multiplier;
  });
  return returnValue;
}

function defaultComparer(a: any, b: any): number {
  if ((a ?? 0) < (b ?? 0)) return -1;
  else if ((a ?? 0) > (b ?? 0)) return 1;
  else return 0;
}
