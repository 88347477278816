import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Command,
  CommandStore,
  isRefreshable,
  Refreshable,
  RefreshService,
  SOFTLINE_FEATURE_COMMANDS
} from '@softline/application';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {DateService, Store} from '@softline/core';
import {Router, RouterModule} from '@angular/router';
import {SOFTLINE_FEATURE_DELIVERY_NOTE} from '../delivery-note.shared';
import {DeliveryNotesStore} from '../stores';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-delivery-note',
    imports: [CommonModule, UiCoreModule, RouterModule],
    styles: [],
    template: `<router-outlet></router-outlet>`
})
export class DeliveryNoteComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  constructor(private store: Store,
              protected router: Router,
              private refreshService: RefreshService,
              private dateService: DateService) { }

  async ngOnInit(): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.mutations.setViewedDate, this.dateService.now());
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: DeliveryNoteComponent,
      commands: this.createCommands()
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
        this.subscription.unsubscribe();

    this.subscription = undefined;
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, DeliveryNoteComponent);
  }

  protected createCommands(): Command[] {
    return [
      {
        name: 'Alle Lieferscheine',
        icon: 'fa-regular fa-truck-arrow-right',
        class: 'menu main-menu main-menu-top',
        execute: async () => {
            await this.router.navigate(['/lieferscheine']);
        }
      }
    ];
  }
}
