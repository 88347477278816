import { Pipe, PipeTransform } from '@angular/core';
import { isImage } from '../functions/is-image.function';

@Pipe({
    name: 'isImage',
    standalone: false
})
export class IsImagePipe implements PipeTransform {
  transform(filename: string): boolean {
    return isImage(filename);
  }
}
