import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent, L10nModule } from '@softline/ui-core';
import { PriceCardComponent } from '../price-card/price-card.component';
import { SupplierPromotion } from '../../types/supplier-promotion';
import { PromotionCardComponent } from '../promotion-card/promotion-card.component';

@Component({
    selector: 'soft-supplier-promotion-card',
    imports: [CommonModule, L10nModule, PriceCardComponent, PromotionCardComponent, IconComponent],
    templateUrl: './supplier-promotion-card.component.html',
    styleUrl: './supplier-promotion-card.component.scss'
})
export class SupplierPromotionCardComponent {
  supplierPromotion = input.required<SupplierPromotion>();
}
