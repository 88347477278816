import {SOFTLINE_CONFIG_WIDGETS} from '@softline/ui-core';
import {SOFTLINE_WIDGET_SIDE_MENU_USER_INFO} from '@softline/application';
import {VkforgContextWidgetComponent} from './widgets/vkforg-context-widget/vkforg-context-widget.component';
import { ImportedNgModuleProviders, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import {
  SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_CONFIG_STORED_CONTEXT_FIELD,
  SOFTLINE_FEATURE_UTILITIES_VKFORGS
} from "../../utilities.shared";
import {VkforgContextEditorComponent} from './components/vkforg-context-editor/vkforg-context-editor.component';
import {Store} from '@softline/core';
import {VkforgStore} from '../vkforg.store';

export const vkforgContextProviders: (Provider | ImportedNgModuleProviders)[] =
  [
    provideEnvironmentInitializer(async () => {
        const store = inject(Store);

        await store.dispatch(
          SOFTLINE_FEATURE_UTILITIES_VKFORGS,
          VkforgStore.actions.loadMany,
          {
            clear: true,
            queryParams: {},
          }
        )
      }),
    {
      provide: SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
      useValue: VkforgContextEditorComponent,
      multi: true,
    },
    { provide: SOFTLINE_CONFIG_STORED_CONTEXT_FIELD, useValue: 'vkforgid', multi: true},
    { provide: SOFTLINE_CONFIG_STORED_CONTEXT_FIELD, useValue: 'firmaid', multi: true},
    {
      provide: SOFTLINE_CONFIG_WIDGETS,
      multi: true,
      useValue: {
        outlet: SOFTLINE_WIDGET_SIDE_MENU_USER_INFO,
        component: VkforgContextWidgetComponent,
      },
    },
  ];
