import { EnvironmentProviders, Provider } from '@angular/core';
import {
  SOFTLINE_CONFIG_CONTEXT_ACCOUNT,
  SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_CONFIG_STORED_CONTEXT_FIELD
} from "../../utilities.shared";
import { AccountContextEditorComponent } from "./components/account-context-editor/account-context-editor.component";
import { SOFTLINE_CONFIG_WIDGETS } from "@softline/ui-core";
import { SOFTLINE_WIDGET_SIDE_MENU_USER_INFO } from "@softline/application";
import { AccountContextWidgetComponent } from "./widgets/account-context-widget/account-context-widget.component";

export const accountContextProviders: Array<Provider | EnvironmentProviders> = [
  {
    provide: SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
    useValue: AccountContextEditorComponent,
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_WIDGETS,
    multi: true,
    useValue: {
      outlet: SOFTLINE_WIDGET_SIDE_MENU_USER_INFO,
      component: AccountContextWidgetComponent,
    },
  },
  { provide: SOFTLINE_CONFIG_CONTEXT_ACCOUNT, useValue: { widgetLinkColor: 'var(--colorLink)' } },
  { provide: SOFTLINE_CONFIG_STORED_CONTEXT_FIELD, useValue: 'accountid', multi: true}
];
