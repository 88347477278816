import { NavigationExtras } from '@angular/router';
import { Signal } from '@angular/core';

export type MenuItem = CommandMenuItem | RouteMenuItem | LinkMenuItem | ExecutableMenuItem;

 interface IMenuItem {
  type: string;
  name: string;
  outlet: string | string[];
  priority?: number;
  title?: string;
  description?: string;
  icon?: string;
  src?: string;
  badge?: string;
  class?: string;
  styles?: { [key: string]: any } | null;
  visible?: boolean;
  tooltip?: boolean;
  group?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  children?: MenuItem[];
}

export interface CommandMenuItem extends IMenuItem {
  type: 'command';
}

export interface RouteMenuItem extends IMenuItem {
  type: 'route';
  routerLink: any[];
  routerLinkParams?: Pick<NavigationExtras, 'queryParams' | 'queryParamsHandling' | 'fragment' | 'onSameUrlNavigation' | 'preserveFragment' | 'replaceUrl' | 'skipLocationChange'>;
  target?: string;
}

export interface LinkMenuItem extends IMenuItem {
  type: 'link';
  src: string;
  target?: string;
}

export interface ExecutableMenuItem extends IMenuItem {
   type: 'execute';
   canExecute: boolean | Signal<boolean>;
   execute: () => void | PromiseLike<void>;
}
