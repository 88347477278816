import {
  Component,
  contentChild,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Command2Service, menuItems } from '@softline/application';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from '../page/page.component';
import { PageHeaderComponent } from '../page/page-header/page-header.component';
import { UiCoreModule } from '@softline/ui-core';
import { QueryShortcut } from './query-shortcut';
import { QueryPageShortcutsComponent } from './query-page-shortcuts/query-page-shortcuts.component';

@Component({
  selector: 'soft-query-page',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PageComponent,
    PageHeaderComponent,
    UiCoreModule,
    QueryPageShortcutsComponent,
  ],
  templateUrl: './query-page.component.html',
  styleUrl: './query-page.component.scss',
})
export class QueryPageComponent<T extends object> implements OnInit, OnDestroy {
  formRef = inject(FormGroupDirective);
  commmandService = inject(Command2Service);

  header = contentChild(PageHeaderComponent, { descendants: true });
  shortcuts = input<QueryShortcut<T>[]>([]);

  #menuItems = menuItems(() => {
    return [
      {
        type: 'command',
        name: 'QueryPageComponentQueryCommand',
        outlet: 'responsive',
        class: 'soft-button accent',
        icon: 'fa-regular fa-magnifying-glass',
        title: '#SOFTAPPS_CORE.MENU_ITEMS.QUERY',
      },
    ];
  });

  query = output<T>();

  async ngOnInit(): Promise<void> {
    this.commmandService.register({
      name: 'QueryPageComponentQueryCommand',
      canExecute: true,
      execute: async () => {
        this.formRef.onSubmit(this.formRef.form.value);
        if (this.formRef.form.valid) this.query.emit(this.formRef.form.value);
      },
    });
  }

  ngOnDestroy() {
    this.commmandService.remove('QueryPageComponentQueryCommand');
  }

  onShortcutClick(query: T): void {
    this.formRef.form.patchValue(query);
    this.formRef.onSubmit(this.formRef.form.value);
    if (this.formRef.form.valid) this.query.emit(this.formRef.form.value);
  }
}
