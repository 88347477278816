import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Observable } from "rxjs";
import { ArtikelSuchverlaufStore, SearchEntry, SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF } from "@softapps/wws/core";
import { map } from "rxjs/operators";
import { Store } from '@softline/core';

@Component({
    selector: 'soft-artikel-suchverlauf',
    imports: [CommonModule],
    templateUrl: './artikel-suchverlauf.component.html',
    styleUrls: ['./artikel-suchverlauf.component.scss']
})
export class ArtikelSuchverlaufComponent implements OnInit {

  @Output() selectVerlauf = new EventEmitter<string>()

  readonly searchHistory$: Observable<SearchEntry[]> = this.store.observe(
    SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF,
    ArtikelSuchverlaufStore.getters.all
  ).pipe(map(o => o.reverse()));

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  trackBySearchEntryFn(index: number, entry: SearchEntry): string {
    return entry.text;
  }

  async onSelect(text: string): Promise<void> {
    this.selectVerlauf.emit(text);
  }

}
