import {
  computed,
  effect,
  EventEmitter,
  inject,
  Injectable,
  Injector,
  output,
  runInInjectionContext
} from '@angular/core';
import { InjectableStore2Feature, Store2Feature } from '@softline/core';
import { Scan } from './data/scan';
import { ScannerService } from './services/scanner.service';
import { LabelType } from './data/label-type';
import { SOFTLINE_CONFIG_SCANNER, SOFTLINE_CONFIG_SCANNER_SELECTOR } from './scanner.shared';
import { TitleState } from '../program/title.store2';

export interface ScannerState {
  scanning: boolean;
  scans: Scan[];
}

@Injectable()
export class ScannerStore2 extends Store2Feature<ScannerState> {

  override name = 'scanner';

  injector = inject(Injector);

  scans = computed(() => {
    return this.state().scans;
  })
  scanning = computed(() => {
    return this.state().scanning;
  })
  scan$ = new EventEmitter<Scan>();

  scansEffect = effect(() => {
    const scans = this.scans();
    if(scans.length > 0) {
      const lastScan = scans[scans.length - 1];
      this.scan$.emit(lastScan);
    }
  });

  constructor() {
    super();
  }

  addScan(scan: Scan) {
    this.commitPatch({scans: [...this.scans(), scan]});
  }

  async startScan(labelType?: LabelType | LabelType[] | null, options?: {service: ScannerService}): Promise<Scan> {
    return await runInInjectionContext(this.injector, async () => {
      const services = inject(SOFTLINE_CONFIG_SCANNER);
      const selector = inject(SOFTLINE_CONFIG_SCANNER_SELECTOR);
      const service = options?.service ?? selector(services);
      this.commitPatch({scanning: true});
      try {
        const scan = await service.scan(labelType);
        this.commitPatch({scans: [...this.scans(), scan], scanning: false});
        return scan;
      } catch (e) {
        this.commitPatch({scanning: false});
        throw e;
      }
    })
  }

  async cancel(): Promise<void> {
    const services = inject(SOFTLINE_CONFIG_SCANNER);
    const selector = inject(SOFTLINE_CONFIG_SCANNER_SELECTOR);
    const service = selector(services);
    await service.cancel();
    this.commitPatch({scanning: false});
  }

  override getDefaultState(): ScannerState {
    return {
      scanning: false,
      scans: []
    }
  }
}
