import { Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as ArchiveStore from '../../../archive.store';
import { SOFTLINE_FEATURE_ARCHIVE } from '../../../../application.shared';
import { map } from 'rxjs/operators';
import { UploadCommandOptions } from '../../../data/upload-command-options';
import { SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import { ArchiveFile } from '../../../data/archive';
import {
  SOFTLINE_FEATURE_MESSAGE_BAR,
  MessageBarStore,
} from '@softline/ui-core';

@Component({
    selector: 'lib-upload-file-dialog',
    templateUrl: './upload-file-dialog.component.html',
    styleUrls: ['./upload-file-dialog.component.scss'],
    standalone: false
})
export class UploadFileDialogComponent implements OnInit {

  uploading = false

  uploads$ = new BehaviorSubject<{ file: File; token: string }[]>([]);
  uploadState$ = combineLatest([
    this.uploads$,
    this.store.observe<ArchiveStore.State>(SOFTLINE_FEATURE_ARCHIVE),
  ]).pipe(
    map(([uploads, archive]) => {
      return uploads.map((upload) => {
        const state = archive.actions[upload.token];
        const progress = archive.progresses[upload.token];
        return {
          file: upload.file,
          state: state?.state ?? 'pending',

          uploaded: progress?.loaded ?? 0,
          total: progress?.total ?? upload.file.size,
        };
      });
    })
  );

  close!: (result: boolean) => void;
  options!: UploadCommandOptions;

  constructor(
    private store: Store,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: boolean) => void): void {
    this.close = handler;
  }

  async onUpload(files: File[]): Promise<void> {
    const succeeded: ArchiveFile[] = [];
    const failed: File[] = [];
    this.uploading = true
    for (const file of files) {
      try {
        const token = this.uuid();
        const result = await this.store.dispatch(
          SOFTLINE_FEATURE_ARCHIVE,
          ArchiveStore.actions.upload,
          {
            archiveKey: this.options.archiveKey ?? '',
            files: [file],
            token,
          }
        );
        this.uploads$.next([...this.uploads$.value, { file, token }]);
        succeeded.push(result);
      } catch (e) {
        failed.push(file);
      }
    }
    this.uploading = false
    this.onUploadCompleted(succeeded, failed);
  }

  onUploadCompleted(succeeded: ArchiveFile[], failed: File[]): void {
    if (!failed.length)
      this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#APPLICATION.UPLOAD.MESSAGES.ALL_SUCCEEDED.MESSAGE'
      );
    else if (!succeeded.length)
      this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.UPLOAD.MESSAGES.ALL_FAILED.MESSAGE'
      );
    else
      this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.warning,
        '#APPLICATION.UPLOAD.MESSAGES.SOME_FAILED.MESSAGE'
      );

    if (succeeded.length && this.options.onSuccess) {
      this.options.onSuccess(succeeded);
    }
    if (failed.length && this.options.onFailure) {
      this.options.onFailure(failed);
    }

    this.close(true);
  }
}
