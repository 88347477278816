@let treeRef = ref();

<ul class="w-full">
  @for (node of nodes(); track trackByFn()($index, node)) {
    <li class="w-full" [ngClass]="treeRef.isLoading(node.path) ? 'cursor-wait' : ''">
      <ng-container *ngTemplateOutlet="nodeTemplate()?.templateRef || defaultTemplate; context: { $implicit: node }"></ng-container>

      @if (node | isNodeExpanded:treeRef) {
        <soft-tree-view [ref]="treeRef" [path]="node.path" [trackByFn]="trackByFn()" [ngClass]="nestedClass() ?? 'pl-5 w-full'">
          <div *softTreeNode="let childNode" class="contents">
            <ng-container *ngTemplateOutlet="nodeTemplate()?.templateRef || defaultTemplate; context: { $implicit: childNode }"></ng-container>
          </div>
        </soft-tree-view>
      }
    </li>
  }
</ul>

<ng-template #defaultTemplate let-node>
  {{ node?.name || (node|json) }}
</ng-template>
