import { Command2, showRequestErrors } from '@softline/application';
import { firstValueFrom, Observable } from 'rxjs';
import { inject, Injector, runInInjectionContext } from '@angular/core';
import { Store } from '@softline/core';
import { MessageBarService } from '@softline/ui-core';
import { EslLinkFlashService } from '../services/esl-flash.service';

export const ESL_FLASH_COMMAND_NAME = 'esl-link/command/esl-flash';

export interface EslFlashCommandDependencies {
  store: Store;
  service: EslLinkFlashService;
  messageBarService: MessageBarService;
}

export function eslFlashCommand(config: {
  productId: number;
  injector: Injector;
  canExecute?: boolean | Observable<boolean>;
}): Command2 {
  const deps = runInInjectionContext<EslFlashCommandDependencies>(
    config.injector,
    () => {
      return {
        service: inject(EslLinkFlashService),
        store: inject(Store),
        messageBarService: inject(MessageBarService),
      };
    }
  );

  return {
    name: ESL_FLASH_COMMAND_NAME,
    canExecute: config.canExecute,
    execute: () => flashEsl(config.productId, deps),
  };
}

export async function flashEsl(
  articelId: number,
  deps: EslFlashCommandDependencies
) {
  if (!articelId) return;

  const body = [
    {
      idartstamm: articelId,
    },
  ];

  try {
    const result = await firstValueFrom(deps.service.flash(body));

    if (result.find((o) => o.idartstamm === articelId)) {
      deps.messageBarService.success(
        'Verknüpfte ESL werden in Kürze blinken/leuchten'
      );
    } else {
      deps.messageBarService.warning(
        'Es wurden keine verknüpften ESL zu diesem Artikel gefunden'
      );
    }
  } catch (e) {
    showRequestErrors(deps.store, e);
  }
}
