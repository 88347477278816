import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional, Type,} from '@angular/core';
import {
  SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
} from '../../../utilities.shared';
import {Modal, UiCoreModule,} from '@softline/ui-core';
import {Store} from '@softline/core';
import {PatchContextStore} from '../../patch-context.store';
import {CommonModule} from '@angular/common';
import {BehaviorSubject} from "rxjs";
import {PatchContextService} from '../../services/patch-context.service';

@Component({
    selector: 'lib-edit-context-dialog',
    templateUrl: './edit-context-dialog.component.html',
    styleUrls: ['./edit-context-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, UiCoreModule]
})
export class EditContextDialogComponent implements Modal<any>, OnInit {
  private close!: (result: any) => void;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private patchContextService: PatchContextService,
    @Optional()
    @Inject(SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT)
    public components?: Type<Component>[]
  ) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.actions.load
    );
  }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async onSave(): Promise<void> {
    const isEqual = await this.patchContextService.updateContext();
    this.close(isEqual ? isEqual : undefined);
  }
}
