import { Component } from '@angular/core';
import { SubmitDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@softline/ui-core';

@Component({
  selector: 'soft-dynamic-submit-input',
  templateUrl: './submit-input.component.html',
  styleUrls: ['./submit-input.component.scss'],
  imports: [NgIf, ReactiveFormsModule, TranslatePipe],
})
export class SubmitInputComponent extends DynamicInputAtom<SubmitDefinition> {
  constructor() {
    super();
  }
}
