@if(contact(); as contact) {
  <div class="flex flex-row justify-between items-center py-2">
    <div class="flex flex-col">
      <span class="font-semibold text-lg whitespace-break-spaces">{{ contact.firstName }} {{contact.lastName}}</span>
      @if(contact["contactType"].value === 'H') {
        <div class="flex !text-wrap">
          <soft-chip class="bg-primary-200">{{ contact["contactType"].description }}</soft-chip>
        </div>
      }
      <span class="text-sm text-primary uppercase whitespace-break-spaces">{{contact.position}}</span>
    </div>
  </div>
}
