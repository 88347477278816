import {
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  LOCALE_ID,
  provideEnvironmentInitializer,
  Provider,
} from '@angular/core';
import { NavigationExtras, Routes, ROUTES } from '@angular/router';
import {
  AuthenticationGuard,
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  SOFTLINE_ROUTES_APPS,
  SOFTLINE_ROUTES_PUBLIC,
  SOFTLINE_ROUTES_TERMINAL,
} from './softapps-core.shared';
import {
  Connection,
  ConnectionModule,
  CoreModule,
  Dictionary,
  Store,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import {
  AppearanceModule,
  ApplicationModule,
  ApplicationStore,
  ConsoleModule,
  DeveloperModule,
  ItemScanModule,
  MasterDataModule,
  OfflineModule,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DynamicModule } from '@softline/dynamic';
import { SoftappsCoreModule } from './softapps-core.module';
import { terminalGuard } from './terminal/guards/terminal.guard';

export interface SoftAppsConfig {
  connection: Connection;
  locale?: string;
}

export interface SoftAppsFeature {
  providers: Array<EnvironmentProviders | Provider>
}

export function provideSoftApps(config: SoftAppsConfig, ...features: SoftAppsFeature[]): Array<EnvironmentProviders | Provider> {
  return [
    importProvidersFrom(
      BrowserAnimationsModule,

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(config.connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      DeveloperModule.forRoot(),
      ConsoleModule.forRoot(),
      ItemScanModule.forRoot(),
      OfflineModule.forRoot(),
      SoftappsCoreModule.forRoot()
    ),
    {provide: LOCALE_ID, useValue: config.locale ?? 'de'},
    ...features.flatMap(o => o.providers),
    {
      provide: ROUTES, useFactory: () => {
        const routes: Routes = [{path: 'empty', redirectTo: '/'}];
        const terminalRoutes = (inject(SOFTLINE_ROUTES_TERMINAL, {optional: true}) ?? []).flat();
        if(terminalRoutes)
          routes.push({
            path: 'terminal',
            loadComponent: () =>
              import('./terminal/components/terminal.component').then((o) => o.TerminalComponent),
            children: terminalRoutes,
            canActivate: [AuthenticationGuard, terminalGuard],
          })
        const shellRoutes = (inject(SOFTLINE_ROUTES_APPS, {optional: true}) ?? []).flat();
        if(shellRoutes)
          routes.push({
            path: '',
            loadComponent: () =>
              import('./shell/components/shell.component').then((o) => o.ShellComponent),
            children: shellRoutes,
            canActivate: [AuthenticationGuard],
          })
        const publicRoutes = (inject(SOFTLINE_ROUTES_PUBLIC, {optional: true}) ?? []).flat();
        if(publicRoutes)
          routes.push({
            path: '',
            loadComponent: () =>
              import('./shell/public-shell/components/public-shell.component').then((o) => o.PublicShellComponent),
            children: publicRoutes,
          })
        return routes;
    }, multi: true}
  ]
}

export interface SoftAppsModuleConfig {
  name: string;
  title: string;
  icon: string;
  route: string;
  permission?: string;
  routeParams?: NavigationExtras | undefined;
}

export enum SoftAppsModuleFeatureKind {
  ROUTES = 0,
  TRANSLATIONS = 1,
}

export interface SoftAppsModuleFeature<T extends SoftAppsModuleFeatureKind> {
  kind: T
}

export interface SoftAppsRoutesFeature extends SoftAppsModuleFeature<SoftAppsModuleFeatureKind.ROUTES> {
  kind: SoftAppsModuleFeatureKind.ROUTES;
  app?: Routes;
  public?: Routes;
  terminal?: Routes;
}

export interface SoftAppsTranslationsFeature extends SoftAppsModuleFeature<SoftAppsModuleFeatureKind.TRANSLATIONS> {
  kind: SoftAppsModuleFeatureKind.TRANSLATIONS;
  translations: Dictionary<object>
}

export type SoftAppsModuleFeatures = SoftAppsRoutesFeature | SoftAppsTranslationsFeature;

export function provideSoftAppsTranslations(name: string, translationMap: Dictionary<object>): Array<EnvironmentProviders | Provider> {
  return [
    provideEnvironmentInitializer(() => {
      const store = inject(Store);
      for (const [language, translations] of Object.entries(translationMap)) {
        if (!translations)
          continue;
        store.commit(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.mutations.add,
          {
            module: name,
            language,
            translations,
          }
        );
      }
    })
  ]
}

export interface SoftAppsRoutes {
  app?: Routes;
  public?: Routes;
  terminal?: Routes;
}

export function withSoftAppsModuleRoutes(routes: SoftAppsRoutes): SoftAppsRoutesFeature {
  return {
    kind: SoftAppsModuleFeatureKind.ROUTES,
    app: routes.app,
    public: routes.public,
    terminal: routes.terminal,
  }
}

export function withSoftAppsModuleTranslation(translations: Dictionary<object>): SoftAppsTranslationsFeature {
  return {
    kind: SoftAppsModuleFeatureKind.TRANSLATIONS,
    translations: translations
  }
}

export function provideSoftAppsRoutes(routes: SoftAppsRoutes): Array<EnvironmentProviders | Provider> {
  const providers: Array<EnvironmentProviders | Provider> = [];
  if(routes.app)
    providers.push({provide: SOFTLINE_ROUTES_APPS, useValue: routes.app, multi: true});
  if(routes.public)
    providers.push({provide: SOFTLINE_ROUTES_PUBLIC, useValue: routes.public, multi: true});
  if(routes.terminal)
    providers.push({provide: SOFTLINE_ROUTES_TERMINAL, useValue: routes.terminal, multi: true});
  return providers;
}

export function provideSoftAppsModule(config: SoftAppsModuleConfig, ...features: SoftAppsModuleFeatures[]): Array<EnvironmentProviders | Provider> {
  const providers: Array<EnvironmentProviders | Provider> = [];
  providers.push(
    provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: config.title,
          icon: config.icon,
          route: config.route,
          routeParams: config?.routeParams,
          permission: config?.permission
        }
      );
    })
  );
  for(const feature of features) {
    switch (feature.kind) {
      case SoftAppsModuleFeatureKind.ROUTES:
        providers.push(
          provideSoftAppsRoutes(feature)
        );
        break;
      case SoftAppsModuleFeatureKind.TRANSLATIONS:
        providers.push(
          provideSoftAppsTranslations(config.name, feature.translations)
        );
        break;
    }
  }
  return providers;
}
