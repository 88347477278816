import {
  inject,
  Injectable,
  Injector,
  OnDestroy,
  OnInit,
  runInInjectionContext,
  Signal,
} from '@angular/core';
import { MenuItemStore2 } from '../menu-item.store';
import { MenuItem } from '../menu-item';
import { toObservable } from '@angular/core/rxjs-interop';
import { distinct, Subject, takeUntil } from 'rxjs';

/*** @deprecated use menuItems(() => computed) instead */
type Constructor<T extends {}> = new (...args: any[]) => T;
export const WithMenuItems = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class MenuItemsMixin extends Base implements OnInit, OnDestroy {

    #SET_NAME = this.constructor.name+'MenuItems';
    #injector = inject(Injector);
    #menuItemStore = inject(MenuItemStore2);

    readonly #destroy$ = new Subject<void>();

    abstract menuItems: Signal<MenuItem[]>;

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      runInInjectionContext(this.#injector, () => {
        toObservable(this.menuItems)
          .pipe(
            takeUntil(this.#destroy$),
          )
          .subscribe(o => {
            this.#menuItemStore.setItem(this.#SET_NAME, o ?? []);
          })
      });
    }

    ngOnDestroy(): void {
      this.#destroy$.next()
      this.#destroy$.complete();

      if (this.#menuItemStore.state()[this.#SET_NAME])
        this.#menuItemStore.removeItem(this.#SET_NAME);

      if (super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }

  return MenuItemsMixin;
}
