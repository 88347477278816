
@if(lagerplatz(); as lagerplatz) {
  <div class="soft-card border mt-1 p-6 rounded">
    <div class="flex flex-row justify-between flex-nowrap">
      <div class="flex flex-col justify-center items-center"
           [ngClass]="{'cursor-pointer hover:underline': canSelect()}"
           (click)="onOpenSelect()">
        <span class="text-lg font-semibold">{{lagerplatz.lagerplatz}}</span>
      </div>
      @if(canScan()) {
        <div class="flex flex-col justify-center align-middle items-center">
          <button class="soft-button primary rounded-full" (click)="onScan()">
            <i class="fa-regular fa-barcode-scan mr-2"></i>
            {{'#KOMMISSIONIERUNG.PAGES.ARTIKEL_MENGE.CHANGE' | translate}}
          </button>
        </div>
      }
    </div>
  </div>
}
@else {
  <div class="flex flex-row flex-nowrap justify-between gap-2">
    @if (canScan()) {
      <div class="flex w-full">
        <soft-scan-button></soft-scan-button>
      </div>
    }
    @if (canSelect()) {
      <button class="bg-surface border border-solid border-primary rounded p-6 cursor-pointer shadow text-left w-full hover:bg-hover"
              (click)="onOpenSelect()">
        <div class="flex flex-row">
          <div class="flex items-center align-middle">
            <i class="fa-regular fa-shelves fa-3x"></i>
          </div>
          <div class="flex flex-col ml-4">
            <div class="font-semibold text-xl">
              {{ ('#LAGERPLATZ_CORE.COMPONENTS.LAGERPLATZ_SUCHE.TITLE' | translate) }}
            </div>
            <div class="color-light mt-1">
              {{'#LAGERPLATZ_CORE.COMPONENTS.LAGERPLATZ_SUCHE.DESCRIPTION' | translate}}
            </div>
          </div>
        </div>
      </button>
    }
  </div>
}
