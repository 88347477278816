import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCoreModule, Validators, WithModal } from '@softline/ui-core';
import { DateService, SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import { Template, TemplateStore } from '@softline/application';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicModule, FormDefinition } from '@softline/dynamic';
import { DynamicTemplateFieldListComponent } from '../../components/dynamic-template-field-list/dynamic-template-field-list.component';
import { DynamicTemplateFieldListEditComponent } from '../../components/dynamic-template-field-list-edit/dynamic-template-field-list-edit.component';
import { TemplateCardComponent } from '../../components/template-card/template-card.component';

@Component({
    selector: 'soft-save-template-dialog',
    imports: [
        CommonModule,
        UiCoreModule,
        ReactiveFormsModule,
        DynamicModule,
        DynamicTemplateFieldListComponent,
        DynamicTemplateFieldListEditComponent,
        TemplateCardComponent,
    ],
    templateUrl: './save-template-dialog.component.html',
    styleUrl: './save-template-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveTemplateDialogComponent extends WithModal() {
  dateService = inject(DateService);
  uuid = inject(SOFTLINE_SERVICE_UUID);

  form: FormGroup = new FormGroup({
    title: new FormControl<string>('', [Validators.required()]),
  });

  $group = signal<string | undefined>(undefined);
  $featureName = signal<string>('');
  $templateValue = signal<object>({});

  $loadParams = computed(() => {
    return this.$group() ?? this.$featureName();
  });
  $templates = this.store.signal(
    this.$featureName,
    TemplateStore.getters.template.templates,
    this.$loadParams,
    { initialValue: [] }
  );
  $definition = signal({} as FormDefinition);

  set group(value: string | undefined) {
    this.$group.set(value);
  }

  set featureName(value: string) {
    this.$featureName.set(value);
  }

  set templateValue(value: object) {
    this.$templateValue.set(value);
  }

  set definition(value: FormDefinition) {
    this.$definition.set(value);
  }

  effect = effect(async () => {
    await this.store.dispatch(
      this.$featureName(),
      TemplateStore.actions.template.loadMany,
      { group: this.$group() ?? this.$featureName() }
    );
  });

  constructor(private store: Store) {
    super();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.updateValueAndValidity();
      return;
    }

    const template: Template<object> = {
      id: this.uuid(),
      title: this.form.value.title,
      group: this.$group() ?? this.$featureName(),
      timestamp: this.dateService.now(),
      value: this.$templateValue(),
    };
    this.close(template);
  }
}
