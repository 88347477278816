import { Component, input, Input, OnInit } from '@angular/core';
import {
  Definition,
  FormDefinition,
  ListDefinition,
  ObjectDefinition,
  PageDefinition,
  TableDefinition
} from '../../data/definitions';
import { CommonModule } from "@angular/common";
import { UiCoreDirectivesModule } from '@softline/ui-core';
import { BooleanRulePipe } from '../../pipes/boolean-rule.pipe';
import { ClassRulePipe } from '../../pipes/class-rule.pipe';

@Component({
    selector: 'soft-dynamic-skeleton',
    imports: [CommonModule, BooleanRulePipe, UiCoreDirectivesModule, ClassRulePipe],
    templateUrl: './dynamic-skeleton.component.html',
    styleUrls: ['./dynamic-skeleton.component.scss']
})
export class DynamicSkeletonComponent implements OnInit {

  definition = input<ListDefinition | FormDefinition | TableDefinition | PageDefinition | Definition | null | undefined>(undefined);
  rows = input(10)

  constructor() {}

  ngOnInit(): void {}
}
