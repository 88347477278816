import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortFunction',
    standalone: false
})
export class SortFunctionPipe implements PipeTransform {
  transform<T extends object, U>(
    array: readonly T[] | null | undefined,
    comparer?: (a: any, b: any) => number
  ): T[] {
    if (!array || !Array.isArray(array)) return [];

    const returnValue = [...array];
    if (!comparer) comparer = this.defaultComparer;
    returnValue.sort(comparer);
    return returnValue;
  }

  private defaultComparer(a: any, b: any): number {
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
  }
}
