import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { DateService, isDefined } from '@softline/core';
import { DateInputComponent } from '../date-input/date-input.component';
import { TimeInputComponent } from '../time-input/time-input.component';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-date-time-input',
    templateUrl: './date-time-input.component.html',
    styleUrls: ['./date-time-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DateInputComponent, TimeInputComponent, UiCorePipesModule, CommonModule]
})
export class DateTimeInputComponent implements OnInit, ControlValueAccessor {
  private _value: string | undefined;
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input()
  get value(): string | undefined {
    return this._value;
  }
  set value(value: string | undefined) {
    this._value = value;
    if (value === this._value) return;
  }

  @Output() valueChange = new EventEmitter<string>();
  @Input() readonly = false;

  get date(): string | undefined {
    return this.value;
  }

  get time(): string | undefined {
    return this.value;
  }

  constructor(private dateService: DateService) {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (moment(obj).isValid()) this.value = obj;
  }

  setDate(value: string | null): void {
    const source = moment(value);
    if (!source.isValid()) return;

    const target = moment(this._value ?? this.dateService.today());

    target.year(source.year());
    target.month(source.month());
    target.date(source.date());

    this._value = target.toISOString(true);
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  setTime(value: string | null): void {
    const source = moment(moment(this._value).format('yyyy-MM-DD') + value);
    if (!source.isValid()) return;

    const target = moment(this._value ?? this.dateService.today());

    target.hours(source.hours());
    target.minutes(source.minutes());
    target.seconds(source.seconds());
    target.milliseconds(source.milliseconds());

    this._value = target.toISOString(true);
    this.onChange(this._value);
    this.valueChange.emit(this._value);
  }
}
