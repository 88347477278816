import { inject, Injector, Signal, TemplateRef } from '@angular/core';
import { Dismissed, ModalOption, ResolvableModalConfig } from '../types/modal-config';
import { OptionModalComponent } from '../components/option-modal/option-modal.component';
import { ModalService } from '../modal.service';

export interface OptionModalConfig2<T> {
  title?: string | Signal<string | null>;
  subtitle?: string | Signal<string | null>;
  content?: TemplateRef<any> | Signal<TemplateRef<any> | null>;
  contentContext?: object | Signal<object | null>;
  options: ModalOption<T>[];
  class?: string;
  dismiss?: boolean;
}

async function choose<T>(modalService: ModalService, config: OptionModalConfig2<T>, injector: Injector): Promise<T | Dismissed> {
  const modalConfig: ResolvableModalConfig<OptionModalComponent<T>, T> = {
    component: OptionModalComponent,
    dismiss: config.dismiss,
    title: config.title ?? 'Auswahl',
    subtitle: config.subtitle,
    template: config.content,
    templateContext: config.contentContext,
    priority: Number.POSITIVE_INFINITY,
    class: config.class,
    data: {
      options: config.options,
    },
    injector: injector
  }
  return await modalService.open(modalConfig);
}

export function chooseFn(): <T>(config: OptionModalConfig2<T>, title?: string) => Promise<T | Dismissed> {
  const modalService = inject(ModalService);
  const injector = inject(Injector);
  return (config, title) => choose(modalService, config, injector)
}
