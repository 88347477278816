import {Component, computed, EventEmitter, inject, input, Output, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrencyFormatPipe, UiCoreModule} from '@softline/ui-core';
import {chartPrimaryAccentColorScheme} from '../../utils/chart-color-scheme';
import {BarChartModule} from '@swimlane/ngx-charts';
import {GroupedBarChartEntry} from '../../types/grouped-bar-chart-data';

@Component({
    selector: 'soft-main-chart',
    imports: [CommonModule, UiCoreModule, BarChartModule],
    templateUrl: './main-chart.component.html',
    styleUrl: './main-chart.component.scss',
    providers: [CurrencyFormatPipe]
})
export class MainChartComponent {

  private readonly currencyFormatPipe = inject(CurrencyFormatPipe);

  protected readonly chartColorScheme = chartPrimaryAccentColorScheme;

  data = input.required<GroupedBarChartEntry[]>();
  loading = input(false);
  chartClass = input<string | null>(null);

  @Output() selected = new EventEmitter<{
    event: { name: string; series: string; label: string; value: number; extra: { key: string | null } };
    series: GroupedBarChartEntry[]
  }>();

  readonly maxXAxisValue = computed(() => {
    if (this.data().length < 1)
      return 1;

    return this.data()[0]?.series.find(o => o.name === 'Umsatz')?.value ?? 1
  })

  readonly itemsPerPage = signal(50);
  readonly currentPage = signal(1);

  readonly pageCount = computed(() => {
    return Math.ceil(this.data().length / this.itemsPerPage())
  });

  readonly pages = computed(() => {
    const pages: number[] = [];

    for (let i = 0; i < this.pageCount(); i++) {
      pages.push(i + 1);
    }

    if (pages.length < 1)
      pages.push(1);

    return pages;
  })

  readonly activeItems = computed(() => {
    const start = (this.currentPage() - 1) * this.itemsPerPage();
    const end = this.currentPage() * this.itemsPerPage()
    return this.data().slice(start, end);
  });

  readonly formatValue = (value: number): string | null => {
    return this.currencyFormatPipe.transform(value, undefined, 'symbol-narrow');
  }

  pageChanged(page: number): void {
    this.currentPage.set(page);
  }

  async onSelect(
    event: { name: string; series: string; label: string; value: number; extra: { key: string | null} },
    series: GroupedBarChartEntry[],
  ): Promise<void> {
    this.selected.emit({ series, event });
  }
}
