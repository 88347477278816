import { Pipe, PipeTransform } from '@angular/core';
import { Group, groupBy } from '@softline/core';

@Pipe({
    name: 'groupBy',
    standalone: false
})
export class GroupByPipe<T, TKey> implements PipeTransform {
  transform(
    value: readonly T[],
    propertyName: string,
    comparer?: (first: TKey, second: TKey) => boolean
  ): Group<TKey, T>[] {
    return [...groupBy(value, (o: any) => o[propertyName], comparer)];
  }
}
