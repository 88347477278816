import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuOutletComponent } from '../menu-outlet/menu-outlet.component';

@Component({
    selector: 'soft-responsive-menu',
    imports: [CommonModule, MenuOutletComponent],
    template: '<soft-menu-outlet class="flex-row flex-nowrap justify-center items-center" name="responsive"></soft-menu-outlet>',
    styleUrl: './responsive-menu.component.scss'
})
export class ResponsiveMenuComponent {}
