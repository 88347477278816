import { inject, Injectable } from '@angular/core';
import { ApiResourceLocation, ConnectionHttpService } from '@softline/core';
import { firstValueFrom } from 'rxjs';
import { SOFTLINE_API_WWS_WARENUEBERNAHME_LAGERPLATZ_AUSWAHL } from '../warenuebernahme.api';
import { Lagerplatz } from '@softapps/lager/core';

@Injectable()
export class LagerplatzService {
  private readonly httpService = inject(ConnectionHttpService);

  async loadLagerplatzAuswahl(idwu: string): Promise<Lagerplatz[]> {
    const location = {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_LAGERPLATZ_AUSWAHL,
      pathParams: {
        idwu,
      },
    } as ApiResourceLocation;

    return (await firstValueFrom(this.httpService.get<Lagerplatz[]>(location)))['result'];
  }
}
