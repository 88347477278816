import { computed, InjectionToken, linkedSignal, signal, Signal, ValueEqualityFn } from '@angular/core';

export interface SingleSelectServiceOptions<T> {
  equals?: ValueEqualityFn<T>
  selectFirst?: boolean;
  keepPreviousSelection?: undefined | 'equality' | 'index';
}

export class SingleSelectService<T> {

  private _selectedIndex = linkedSignal<T[] | null | undefined, number | null>(
    {
      source: () => this.items(),
      computation: (items, previous ) => {
        if(!items || !items.length)
          return null;

        if(previous && this.options?.keepPreviousSelection === 'index') {
          if(items.length && items[previous.value ?? -1])
            return previous.value;
        }
        if (previous && this.options?.keepPreviousSelection === 'equality') {
            const previousItems = previous.source;
            const previousIndex = previous.value;
            if(previousItems && previousIndex) {
              const previousItem = previousItems[previousIndex];
              const index = items.findIndex(o => this.options?.equals ? this.options.equals(o, previousItem) : o === previousItem);
              if(index > -1)
                return index;
            }
        }
        if(this.options?.selectFirst && items.length)
          return 0;
        return null;
      }
  });

  selectedIndex = this._selectedIndex.asReadonly();
  selected = computed(() => {
    const items = this.items();
    const selectedIndex = this.selectedIndex();
    if(!items || !items.length || selectedIndex === null)
      return null;

    return items[selectedIndex];
  });

  constructor(protected items: Signal<T[] | null | undefined>, private options?: SingleSelectServiceOptions<T>) { }

  select(item: T): void {
    const items = this.items();
    if(!items || !items.length)
      return;

    const index = items.findIndex(o => this.options?.equals ? this.options.equals(o, item) : o === item);
    if(index > -1)
      this._selectedIndex.set(index);
    else
      this._selectedIndex.set(null);
  }

  unselect(): void{
    this._selectedIndex.set(null);
  }
}

export const createSingleSelectServiceToken = <T>(name: string, itemsFunc: () => Signal<T[]>, options?: SingleSelectServiceOptions<T>) => {
  return new InjectionToken(`SingleSelectService<T>:${name}`, {factory: () => {
      const items = itemsFunc();
      return new SingleSelectService<T>(items, options);
    }});
}
