import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Input,
  Output
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Bewe} from "../../types/bewegung";
import {MengenEingabeComponent} from "@softline/application";
import {UiCoreModule} from "@softline/ui-core";
import { SOFTLINE_CONFIG_WARENUEBERNAHME } from '../../warenuebernahme.shared';
import { Store } from '@softline/core';
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from '@softline/auth';

@Component({
    selector: 'soft-bewegung-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MengenEingabeComponent, UiCoreModule],
    templateUrl: './bewegung-list.component.html',
    styleUrls: ['./bewegung-list.component.scss']
})
export class BewegungListComponent {

  readonly config = inject(SOFTLINE_CONFIG_WARENUEBERNAHME);

  @Input() isBestellung = false;
  @Input() bewegungen: Bewe[] = [];

  @Output() statusChange = new EventEmitter<Bewe>();
  @Output() mengeChange = new EventEmitter<{ bewe: Bewe, menge: number }>();
  @Output() mengenEingabeOeffnen = new EventEmitter<Bewe>();
  @Output() actionClick = new EventEmitter<Bewe>();

  hasReklamationPermission = input(false);

  trackByFn(_: number, bewe: Bewe): number {
    return bewe.id
  }

  isPalette(bewe: Bewe) {
    return (bewe?.anzahlPalettenSoll ?? 0) > 0;
  }
}
