import { Inject, Pipe, PipeTransform } from '@angular/core';
import {
  GeolocationLinkFactory,
  SOFTLINE_SERVICE_GEOLOCATION_LINK_FACTORY,
} from '../geolocation.shared';
import { Position } from '../position';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'geolocationLink',
    standalone: false
})
export class GeolocationLinkPipe implements PipeTransform {
  constructor(
    @Inject(SOFTLINE_SERVICE_GEOLOCATION_LINK_FACTORY)
    private factory: GeolocationLinkFactory,
    private domSanitizer: DomSanitizer
  ) {}

  transform(value: Position): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(this.factory(value));
  }
}
