import {ChangeDetectionStrategy, Component, effect, inject, input, OnDestroy, signal} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {CurrencyFormatPipe, UiCoreModule, UiCorePipesModule} from '@softline/ui-core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DiagramTabelleViewComponent} from '../../components/diagram-tabelle-view/diagram-tabelle-view.component';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN} from '../../offene-posten.shared';
import {JopostenStore} from '../../store';
import {showRequestErrors} from '@softline/application';
import {SOFTLINE_SERVICE_UUID, Store} from '@softline/core';

@Component({
    selector: 'soft-offene-posten-widget',
    imports: [CommonModule, UiCoreModule, NgxChartsModule, UiCorePipesModule, DiagramTabelleViewComponent],
    templateUrl: './offene-posten.widget.html',
    styleUrl: './offene-posten.widget.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CurrencyFormatPipe, DatePipe]
})
export class OffenePostenWidgetComponent implements OnDestroy {

  idktostamm = input<number | null>();

  readonly loading = signal(false);

  private store = inject(Store);
  private uuid = inject(SOFTLINE_SERVICE_UUID);

  private token: string | null = this.uuid();

  private readonly authenticationContext = this.store.signal(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  );

  loadEffect = effect(async () => {
    let idktostamm = this.authenticationContext()?.['accountid'];

    if (this.idktostamm()) {
      idktostamm = this.idktostamm()
    }

    try {
      const queryParams = { idktostamm, kzoffen: 'J' }

      const inProgress = this.store.get(
        SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
        JopostenStore.getters.subscription.active,
        this.token
      );

      if (inProgress) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
          JopostenStore.actions.cancel,
          this.token
        );
      }

      this.token = this.uuid();
      this.loading.set(true);

      await this.store.dispatch(
        SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
        JopostenStore.actions.loadMany,
        {
          clear: true,
          queryParams: { query: queryParams },
          token: this.token
        }
      );

      this.loading.set(false);
    } catch (e) {
      this.token = null;
      this.loading.set(false);
      showRequestErrors(this.store, e);
    }
  }, { allowSignalWrites: true });

  readonly offenePosten = this.store.signal(
    SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
    JopostenStore.getters.all
  );

  ngOnDestroy(): void {
    this.loadEffect.destroy();
  }
}
