import { CollectionStore2 } from './collection.store2';
import { lastValueFrom } from 'rxjs';
import { SubscriptionStore2 } from '../observable/subscription.store2';
import { computed, inject, Injectable, InjectionToken } from '@angular/core';
import { SOFTLINE_SERVICE_UUID } from '../../../core.shared';
import { CancelledError } from '../../../types/errors';
import { NestedStore2Feature } from '../../nested-store2-feature';
import { LoadingState, SavingState, SOFTLINE_COLLECTION_REPOSITORY } from '../../store2.shared';
import { LoadCollectionItemParameters, LoadCollectionParameters } from '../../../repositories/abstraction';
import { CollectionResponseCommit, ResultCollectionResponseCommit } from './strategies/collection-response-commit';
import { isDefined } from '../../../functions/is-defined.function';


export interface ReadonlyRepositoryCollectionState {
  loadingState: LoadingState;
  loadedAllOnce: boolean;
  savingState: SavingState;
  loadingError: Error | null;
}

export interface CollectionLoadActionParameters extends LoadCollectionItemParameters {
  token?: string;
}

export interface CollectionLoadManyActionParameters extends LoadCollectionParameters {
  clear?: boolean;
  token?: string;
}

export const SOFTLINE_FACTORY_COLLECTION_RESPONSE_COMMIT = new InjectionToken<() => CollectionResponseCommit<any, any>>('SOFTLINE_FACTORY_COLLECTION_RESPONSE_COMMIT',
  { providedIn: 'root', factory: () => () => new ResultCollectionResponseCommit()});


@Injectable()
export class ReadonlyRepositoryCollectionStore2<T extends object, TState extends ReadonlyRepositoryCollectionState = ReadonlyRepositoryCollectionState> extends NestedStore2Feature<TState>{

  private readonly factory = inject(SOFTLINE_FACTORY_COLLECTION_RESPONSE_COMMIT);
  protected readonly commitResponse = this.factory();

  protected readonly collection = inject(CollectionStore2<T>);
  protected readonly subscription = inject(SubscriptionStore2);
  protected readonly service = inject(SOFTLINE_COLLECTION_REPOSITORY);
  protected readonly uuid = inject(SOFTLINE_SERVICE_UUID);

  loadingState = computed(() => this.state().loadingState);
  loadingError = computed(() => this.state().loadingError);

  constructor() {
    super();
  }

  async load(id: string | number, params: CollectionLoadActionParameters): Promise<T> {
    this.commitPatch({ loadingState: 'loading', loadingError: null } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.loadItem(id, params)
      ));
      if(!isDefined(value))
        throw new Error(`[ReadonlyRepositoryCollectionStore2] load for item with id ${id} returned undefined`);
      this.commitPatch({ loadingState: 'loaded' } as Partial<TState>);
      this.commitResponse.add(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ loadingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ loadingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  async loadMany(params: CollectionLoadManyActionParameters = {}): Promise<T[]> {
    this.commitPatch({ loadingState: 'loading', loadingError: null } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.loadMany(params)
      ));

      if(params.clear)
        this.commitResponse.clear();

      this.commitResponse.addMany(value);
      this.commitPatch({ loadingState: 'loaded' } as Partial<TState>);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ loadingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ loadingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  async loadOnce(id: string | number, params: CollectionLoadActionParameters): Promise<T> {
    const existing = this.collection.dict()[id];
    if(existing)
      return existing;
    return await this.load(id, params);
  }

  async loadManyOnce(): Promise<T[]> {
    if(this.state().loadedAllOnce)
      return this.collection.all();
    return await this.loadMany();
  }

  async cancel(token: string): Promise<void> {
    this.subscription.cancel(token);
  }

  override getDefaultState(): TState {
    return {
      loadingState: null,
      loadingError: null,
      loadedAllOnce: false,
      savingState: null
    } as TState;
  }
}
