import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterValueComponentBase } from '../filter-value-component-base';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  I18nModule,
  ModalStore,
  SetFocusDirective,
  SOFTLINE_FEATURE_MODAL,
  TranslatePipe,
  UiCoreComponentsModule,
  UiCorePipesModule,
  Validators,
} from '@softline/ui-core';
import { Store } from '@softline/core';
import { FilterOperatorPipe } from '../../filter-operator.pipe';

@Component({
  selector: 'soft-text-filter-value',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiCoreComponentsModule,
    UiCorePipesModule,
    SetFocusDirective,
    I18nModule,
  ],
  providers: [FilterOperatorPipe, TranslatePipe],
  templateUrl: './text-filter-value.component.html',
  styleUrls: ['./text-filter-value.component.scss'],
})
export class TextFilterValueComponent
  extends FilterValueComponentBase
  implements OnInit
{
  form = new FormGroup({
    value: new FormControl<string | null>(null, Validators.required()),
    caseSensitive: new FormControl<boolean>(false),
  });

  @ViewChild('dialogRef', { static: true }) dialogRef?: TemplateRef<any>;

  constructor(
    private store: Store,
    private translatePipe: TranslatePipe,
    private operatorPipe: FilterOperatorPipe
  ) {
    super();
  }

  ngOnInit(): void {}

  async onValueClick(): Promise<void> {
    if (!this.filter) return;

    this.form.reset(this.filter as any);
    const value = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.template(),
      {
        title: `${this.translatePipe.transform(
          this.config?.title
        )} ${this.translatePipe.transform(
          this.operatorPipe.transform(this.filter.operator)?.title
        )}`,
        template: this.dialogRef,
      }
    );
    if (value === 'DISMISSED') return;
    this.onChange({ ...this.filter, ...this.form.value });
  }

  toggleCaseSensitive(): void {
    this.form.patchValue({ caseSensitive: !this.form.value.caseSensitive });
  }
}
