import { importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE
} from "./bestellerfassung.shared";
import {BestellerfassungStore} from './store/bestellerfassung.store';
import {
  SOFTLINE_API_MDE_BESTELLERFASSUNG_ARTIKEL,
  SOFTLINE_API_MDE_BESTELLERFASSUNG_BEWEGUNGEN
} from './bestellerfassung.api';
import {BestellerfassungBeweStore} from './store/bestellerfassung-bewe.store';
import {BestellerfassungService} from './services/bestellerfassung.service';
import {BestellerfassungArtikelDetailStore} from './store/bestellerfassung-artikel-detail.store';
import {BestellerfassungResolver} from './resolver/bestellerfassung.resolver';
import {CheckEingabeService} from './services/check-eingabe.service';

export const bestellerfassungProviders = [
  provideEnvironmentInitializer(async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeBestellerfassung',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Bestellung',
          icon: 'fa-regular fa-cart-plus',
          route: '/mde-bestellung',
          permission: 'BESTELLUNG',
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE,
        BestellerfassungBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    }),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
      feature: BestellerfassungStore.feature,
      providers: [{
        provide: BestellerfassungService,
        useClass: BestellerfassungService
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE,
      feature: BestellerfassungBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_BESTELLERFASSUNG_BEWEGUNGEN
        )
      }]
    })
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS,
      feature: BestellerfassungArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_BESTELLERFASSUNG_ARTIKEL,
              'detail'
            );
          }
        },
        {
          provide: CheckEingabeService,
          useClass: CheckEingabeService
        },
      ]
    })
  ),
  BestellerfassungResolver,
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG, fields: ['datum'] },
    multi: true
  },
]
