import { Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { base64Decode, Store } from '@softline/core';
import {
  showRequestErrors,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
  WithBackNavigation,
} from '@softline/application';
import {
  SendNotificationStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION,
} from '../store/send-notification.store';
import {
  WizardStepComponent,
  WizardComponent,
  WizardActionDirective,
} from '@softapps/core';
import { EmpfaengerAuswahlComponent } from './empfaenger-auswahl/empfaenger-auswahl.component';
import { NachrichtVerfassenComponent } from './nachricht-verfassen/nachricht-verfassen.component';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
} from '@softline/ui-core';
import fa from '@mobiscroll/angular/dist/js/i18n/fa';

@Component({
    selector: 'soft-send-notification',
    imports: [
        CommonModule,
        UiCoreModule,
        RouterOutlet,
        WizardComponent,
        WizardStepComponent,
        EmpfaengerAuswahlComponent,
        NachrichtVerfassenComponent,
        WizardActionDirective,
    ],
    templateUrl: './send-notification.component.html',
    styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent
  extends WithBackNavigation()
  implements OnInit, OnDestroy
{
  router = inject(Router);
  route = inject(ActivatedRoute);
  store = inject(Store);

  readonly data = this.store.signal(
    SOFTLINE_FEATURE_SEND_NOTIFICATION,
    SendNotificationStore.getters.data
  );

  readonly empfaenger = computed(() => this.data()?.empfaenger ?? []);

  constructor() {
    super();
  }

  override async ngOnInit(): Promise<void> {}

  override ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.mutations.unset
    );
  }

  override async navigateBack(): Promise<void> {
    const params = this.route.snapshot.queryParamMap.get('from');
    if (!params) {
      await this.router.navigate(['/']);
    } else {
      const { url, extras } = JSON.parse(base64Decode(params));
      await this.router.navigate(url, extras);
    }
  }

  async nachrichtSenden(done: (proceed: boolean) => void): Promise<void> {
    try {
      const gesendeteAnwender = await this.store.dispatch(
        SOFTLINE_FEATURE_SEND_NOTIFICATION,
        SendNotificationStore.actions.send
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: 'Nachricht gesendet!',
          message: `Die Nachricht wurde erfolgreich an ${
            gesendeteAnwender?.length || 0
          } Empfänger gesendet`,
        }
      );

      this.store.commit(
        SOFTLINE_FEATURE_SEND_NOTIFICATION,
        SendNotificationStore.mutations.unset
      );

      const from = this.route.snapshot.queryParamMap.get('from');

      if (!from) {
        await this.router.navigate(['/']);
      } else {
        const { url, extras } = JSON.parse(base64Decode(from));
        const fromPrevious = extras?.queryParams?.from;

        if (!fromPrevious) {
          await this.router.navigate(url, extras);
          done(true);
          return;
        }

        const { url: previousUrl, extras: previousExtras } = JSON.parse(
          base64Decode(fromPrevious)
        );
        await this.router.navigate(previousUrl, previousExtras);
      }
      done(true);
    } catch (e) {
      showRequestErrors(this.store, e);
      done(false);
    }
  }
}
