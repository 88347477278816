import {
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  provideEnvironmentInitializer,
  Provider
} from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from "@softline/core";
import {default as de} from './../i18n/de.json';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from "@softline/ui-core";
import {ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS} from "@softline/application";
import {
  SOFTLINE_CONFIG_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
  SOFTLINE_PERMISSION_WWS_WARENUEBERNAHME, WarenuebernahmeModuleConfig
} from './warenuebernahme.shared';
import {WwsWarenuebernahmeStore} from "./store/warenuebernahme.store";
import {
  SOFTLINE_API_WWS_WARENUEBERNAHME,
  SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG,
  SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES,
  SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN,
  SOFTLINE_API_WWS_WARENUEBERNAHME_LIEFERANTEN
} from "./warenuebernahme.api";
import {WwsBewegungStore} from "./store/bewegung.store";
import {BewegungenService} from "./services/bewegungen-service";
import {WwsEmballageStore} from "./store/emballage.store";
import {WarenuebernahmeService} from "./services/warenuebernahme-service";
import {WwsLieferantStore} from "./store/lieferant.store";
import {WwsBestellungStore} from "./store/bestellung.store";
import {BestellungService} from "./services/bestellung-service";
import { PalettenService } from './services/paletten-service';

export function provideWarenuebernahmeModule(config: WarenuebernahmeModuleConfig): Array<Provider | EnvironmentProviders> {
  return [
    ...warenuebernahmeProviders,
    {provide: SOFTLINE_CONFIG_WARENUEBERNAHME, useValue: config}
  ]
}


export const warenuebernahmeProviders = [
  provideEnvironmentInitializer(async () => {
      const store = inject(Store);

      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'wwsWarenuebernahme',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Warenübernahme',
          route: '/warenuebernahme',
          icon: 'fa-regular fa-people-carry-box',
          permission: SOFTLINE_PERMISSION_WWS_WARENUEBERNAHME
        }
      );
    }),
  importProvidersFrom(
    StoreModule.forFeature({
      feature: WwsWarenuebernahmeStore.feature,
      name: SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_WWS_WARENUEBERNAHME
          )
        },
        {
          provide: SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
          useFactory: (service = inject(SOFTLINE_SERVICE_HTTP)) => () => new SoftlineEntityDetailService(
            service,
            SOFTLINE_API_WWS_WARENUEBERNAHME,
            '{{idwu}}'
          )
        }
      ]
    }),
    StoreModule.forFeature({
      feature: WwsBewegungStore.feature,
      name: SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES
          )
        }
      ]
    }),
    StoreModule.forFeature({
      feature: WwsEmballageStore.feature,
      name: SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN
          )
        }
      ]
    }),
    StoreModule.forFeature({
      feature: WwsLieferantStore.feature,
      name: SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_WWS_WARENUEBERNAHME_LIEFERANTEN
          )
        }
      ]
    }),
    StoreModule.forFeature({
      feature: WwsBestellungStore.feature,
      name: SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG
          )
        }
      ]
    })
  ),
  WarenuebernahmeService,
  BewegungenService,
  BestellungService,
  PalettenService
]
