import { computed, inject, Injectable, linkedSignal, signal } from '@angular/core';
import { SOFTLINE_CONFIG_CONNECTION } from './connection.shared';
import { Connection } from './types/connection';
import { Store } from '../store/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { SOFTLINE_FEATURE_CONNECTION } from '@softline/core';
import * as ConnectionStore from './connection.store';

@Injectable({providedIn: 'root'})
export class ConnectionService {
  private defaultConnection = inject(SOFTLINE_CONFIG_CONNECTION, {optional: true}) ?? {host: 'http://localhost'};
  private store = inject(Store);
  private storeConnection = toSignal(this.store.observe(SOFTLINE_FEATURE_CONNECTION, ConnectionStore.getters.selected));
  private readonly _connection = linkedSignal<Connection>(() => this.storeConnection() ?? this.defaultConnection);
  readonly connection = this._connection.asReadonly();

  readonly url = computed(() => {
    const connection = this._connection();
    let url = connection.host.replace(/^\/+|\/+$/g, '');
    if (connection.port) url += `:${connection.port}`;
    if (connection.basePath)
      url += `/${connection.basePath.replace(/^\/+|\/+$/g, '')}`;
    return url;
  })
}
