import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";
import {Anwender} from "../../types/warenuebernahme";

@Component({
    selector: 'soft-warenuebernahme-sperren-bestaetigen-dialog',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './warenuebernahme-sperren-bestaetigen-dialog.component.html',
    styleUrls: ['./warenuebernahme-sperren-bestaetigen-dialog.component.scss']
})
export class WarenuebernahmeSperrenBestaetigenDialogComponent implements Modal<boolean> {

  close!: (result: boolean) => void;

  exzess!: Anwender;

  registerCloseHandler(handler: (result: boolean) => void): void {
    this.close = handler;
  }
}
