import {blobToDataURL, createAction, ListStore, on, SOFTLINE_SERVICE_UUID, StoreFeature,} from '@softline/core';
import {Dismissed, Modal} from './data/modal';
import {CommonModalConfig, ModalConfig as GenericModalConfig,} from './data/modal-config';
import {QuestionConfig} from './data/question-config';
import {NotificationConfig} from './data/notification-config';
import {QuestionModalComponent} from './components/question-modal/question-modal.component';
import {QuestionResult} from '../types/question-result';
import {NotificationResult} from '../types/notification-result';
import {NotificationModalComponent} from './components/notification-modal/notification-modal.component';
import {OptionModalConfig} from './data/option-modal-config';
import {OptionModalComponent} from './components/option-modal/option-modal.component';
import {GalleryModalConfig} from './data/gallery-modal-config';
import {GalleryModalComponent} from './components/gallery-modal/gallery-modal.component';
import {SignatureModalConfig} from './data/signature-modal-config';
import {SignatureModalComponent} from './components/signature-modal/signature-modal.component';
import {DrawModalComponent} from './components/draw-modal/draw-modal.component';
import {DrawModalConfig} from './data/draw-modal-config';
import {FileModalConfig} from './data/file-modal.config';
import {FileModalComponent} from "./components/file-modal/file-modal.component";
import {TemplateModalConfig} from "./data/template-modal-config";
import {TemplateModalComponent} from "./components/template-modal/template-modal.component";
import {DateModalConfig} from './data/date-modal-config';
import {DatePickerModalComponent} from './components/date-picker-modal/date-picker-modal.component';
import { CalculatorModalConfig } from "./data/calculator-modal-config";
import { CalculatorModalComponent } from "./components/calculator-modal/calculator-modal.component";
import { inject, Injector } from '@angular/core';
import { DeleteConfig } from './data/delete-config';
import { DeleteResult } from '../types/delete-result';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { DateRange, DateRangeModalConfig } from './data/date-range-modal-config';
import { DateRangePickerModalComponent } from './components/date-range-picker-modal/date-range-picker-modal.component';

type ModalConfig = GenericModalConfig<Modal<any>, any, object>;

export interface State extends ListStore.State<ModalConfig> {}

const modalStore = ListStore.create<ModalConfig>();

export const mutations = {
  ...modalStore.mutations,
};

export const getters = {
  ...modalStore.getters
};


/**
 * @deprecated Use ModalService instead
 */
export const actions = {
  open: <T, U extends object>() =>
    createAction<State, CommonModalConfig<Modal<T> & U, T, U>, T | Dismissed>(
      'open'
    ),
  ask: createAction<State, QuestionConfig | string, QuestionResult | Dismissed>(
    'ask'
  ),
  delete: createAction<State, DeleteConfig, DeleteResult | Dismissed>(
    'delete'
  ),
  notify: createAction<
    State,
    NotificationConfig | string,
    NotificationResult | Dismissed
  >('notify'),
  choose: <T>() =>
    createAction<State, OptionModalConfig<T>, T | Dismissed>('choose'),
  gallery: createAction<State, GalleryModalConfig, Dismissed>('gallery'),
  template: <T>() => createAction<State, TemplateModalConfig, T>('template'),
  sign: createAction<
    State,
    SignatureModalConfig | undefined,
    Blob | null | Dismissed
  >('sign'),
  draw: createAction<
    State,
    DrawModalConfig | undefined,
    Blob | null | Dismissed
  >('draw'),
  date: createAction<
    State,
    DateModalConfig | undefined,
    string | null | Dismissed
  >('date'),
  dateRange: createAction<
    State,
    DateRangeModalConfig | undefined,
    DateRange | null | Dismissed
  >('dateRange'),
  calculate: createAction<
    State,
    CalculatorModalConfig | undefined,
    number | null | Dismissed
  >('calculate'),
  file: createAction<
    State,
    FileModalConfig | undefined,
    File[] | null | Dismissed
  >('file'),

  close: createAction<State, string>('close'),
  resolve: <T>() => createAction<State, { id: string, result: T }>('resolve'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    items: [],
  },
  mutations: ListStore.feature.mutations,
  getters: ListStore.feature.getters,
  actions: [
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.open(), ({ store, featureName, params, injector }) => {
      return new Promise<any>((resolve, reject) => {
        const config: ModalConfig = {
          ...params,
          id: params.id ?? injector.get(SOFTLINE_SERVICE_UUID)(),
          callback: (o) => resolve(o),
          onDismiss: () => resolve('DISMISSED'),
          priority: params.priority ?? Number.NEGATIVE_INFINITY,
        };
        store.commit(featureName, mutations.add, config);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.ask, ({ store, featureName, params, injector }) => {
      const config: QuestionConfig =
        typeof params === 'string' ? { question: params } : params;
      if (config.dismiss === true)
        config.dismiss = { backdrop: true, escape: true };

      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: QuestionModalComponent,
          callback: (result: QuestionResult) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: config?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: config?.class,
          data: {
            title: config?.title,
            content: config?.content,
            question: config.question,
            showCancel: config?.showCancelAction ?? false,
            params: config?.params,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.delete, ({ store, featureName, params, injector }) => {
      const config: DeleteConfig =
        typeof params === 'string' ? { question: params } : params;
      if (config.dismiss === true)
        config.dismiss = { backdrop: true, escape: true };

      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: DeleteModalComponent,
          callback: (result: DeleteResult) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: config?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: config?.class,
          data: {
            title: config?.title,
            content: config?.content,
            question: config?.question,
            params: config?.params,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.notify, ({ store, featureName, params, injector }) => {
      const config: NotificationConfig =
        typeof params === 'string' ? { text: params } : params;
      if (config.dismiss === true)
        config.dismiss = { backdrop: true, escape: true };

      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: NotificationModalComponent,
          callback: (result: NotificationResult) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: config?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: config?.class,
          data: {
            title: config?.title,
            text: config.text,
            showCancel: config?.showCancelAction ?? false,
            params: config?.params,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.choose(), ({ store, featureName, params, injector }) => {
      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: OptionModalComponent,
          callback: (result: unknown) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            content: params?.content,
            options: params.options,
            params: params?.params,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.gallery, ({ store, featureName, params, injector }) => {
      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: GalleryModalComponent,
          callback: (result: 'DISMISSED') => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: true,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            content: params?.content,
            images: params.images,
            selectedIndex: params.index,
            params: params?.params,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    on(actions.template(), ({ store, featureName, params, injector }) => {
      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: TemplateModalComponent,
          callback: (result: unknown) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params.dismiss,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            template: params?.template,
            params: params?.params,
          },
        };
        //store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.sign, ({ store, featureName, params, injector }) => {
      return new Promise((resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: SignatureModalComponent,
          callback: (result: Blob | null) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            content: params?.content,
            params: params?.params,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.draw, ({ store, featureName, params, injector }) => {
      return new Promise(async (resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: DrawModalComponent,
          callback: (result: Blob | null) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            content: params?.content,
            params: params?.params,
            drawing: params?.drawing
              ? await blobToDataURL(params?.drawing)
              : undefined,
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    on(actions.date, ({ store, featureName, params, injector }) => {
      return new Promise(async (resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: DatePickerModalComponent,
          callback: (result: string | null) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            value: params?.value ?? null
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    on(actions.dateRange, ({ store, featureName, params, injector }) => {
      return new Promise(async (resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: DateRangePickerModalComponent,
          callback: (result: DateRange | null) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            value: params?.value ?? null,
            startField: params?.startField ?? 'from'
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    on(actions.calculate, ({ commit, featureName, params }) => {
      return new Promise(async (resolve, reject) => {
        const modalConfig = {
          id: inject(SOFTLINE_SERVICE_UUID)(),
          component: CalculatorModalComponent,
          callback: (result: number | null) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          dismiss: params?.dismiss,
          priority: Number.POSITIVE_INFINITY,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            value: params?.value ?? ''
          },
        };
        commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.file, ({ store, featureName, params, injector }) => {
      return new Promise(async (resolve, reject) => {
        const modalConfig = {
          id: injector.get(SOFTLINE_SERVICE_UUID)(),
          component: FileModalComponent,
          callback: (result: File[] | null) => resolve(result),
          onDismiss: () => resolve('DISMISSED'),
          //dismiss: params?.dismiss,
          priority: 1000,
          class: params?.class,
          data: {
            title: params?.title,
            subtitle: params?.subtitle,
            content: params?.content,
            params: params?.params,
            sources: params?.sources,
            accept: params?.accept,
            selectionMode: params?.selectionMode,
            inputView: params?.inputView,
            valueView: params?.valueView,
            autoSubmit: params?.autoSubmit
          },
        };
        store.commit(featureName, mutations.add, modalConfig);
      });
    }),
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.close, ({ featureName, params, injector , state, commit}) => {
      const config = state.items.find(o => o.id === params);
      if(!config)
        throw new Error('[ModalStore] Cannot find modal with id: '+ params)
      commit(featureName, mutations.remove, config);
      if(config.onDismiss)
        config.onDismiss();
    }),
    on(actions.resolve(), ({  featureName, params, injector , state, commit}) => {
      const config = state.items.find(o => o.id === params.id);
      if(!config)
        throw new Error('[ModalStore] Cannot find modal with id: '+ params.id)
      commit(featureName, mutations.remove, config);
      if(config.callback)
        config.callback(params.result);
    }),
  ],
};
