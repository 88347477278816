import { computed, effect, Injectable, OnDestroy, output, signal } from '@angular/core';
import { Subject } from 'rxjs';

export type NetworkStatus = 'online' | 'offline';

@Injectable({providedIn: 'root'})
export class NetworkStatusService implements OnDestroy {

  readonly status = signal<NetworkStatus>(navigator.onLine ? 'online' : 'offline');
  readonly online = computed(() => this.status() === 'online');
  readonly offline = computed(() => this.status() === 'offline');

  private statusEffect = effect(() => {
    this.statusChange.next(this.status());
  });

  readonly statusChange = new Subject<NetworkStatus>();

  constructor() {
    window.addEventListener("offline", this.handleNetworkChange);
    window.addEventListener("online", this.handleNetworkChange);
    this.status.set(navigator.onLine ? 'online' : 'offline');
  }

  ngOnDestroy(): void {
    window.removeEventListener('offline', this.handleNetworkChange);
    window.removeEventListener('online', this.handleNetworkChange);
  }

  private handleNetworkChange = (e: Event): void => {
    console.debug('network status changed: ', e);
    if (e.type !== 'online' && e.type !== 'offline')
      return;

    this.status.set(e.type);
  }
}
