<ng-container *ngIf="(bewegungen?.length || 0) > 0; else placeholder">
  <div class="mt-4 soft-card rounded-sm border border-primary border-solid !p-0"
       *ngFor="let bewegung of bewegungen; trackBy: trackByFn">


    <div class="flex flex-row m-3 pb-2">
      <div class="flex flex-col justify-between w-full gap-2"
         [ngClass]="{'border-b border-solid':
              bewegung.auftrag || (bewegung?.zeilentext && bewegung?.zeilentext !== '')}">
      <div class="flex flex-col w-auto md:w-2/4 lg:w-2/3 gap-0.5">
        <div class="flex items-center gap-2">
          <p class="font-semibold">
            <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) === 1">00{{bewegung?.lfdnr}}</span>
            <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) === 2">0{{bewegung?.lfdnr}}</span>
            <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) >= 3">{{bewegung?.lfdnr}}</span>
          </p>
          @if(bewegung.idReklamation) {
            <soft-icon name="fa-regular fa-triangle-exclamation"></soft-icon>
          }
          @if (bewegung?.artikelMenge?.artikel?.lplfuehrung === false) {
            <soft-icon softToolTip="Nicht Lagerplatzgeführt" name="fa-regular fa-warehouse text-danger" class="text-danger"></soft-icon>
          }
        </div>
        <p>
          <span *ngIf="bewegung.artikelMenge?.artikel?.lieferantArtikelNummer">
            {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_LIEFERANT_ARTIKELNUMMER' | translate}} {{bewegung.artikelMenge?.artikel?.lieferantArtikelNummer ?? ''}} |
          </span>
          <span *ngIf="bewegung.artikelMenge?.artikel?.ean">
            {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_EAN' | translate}} {{bewegung.artikelMenge?.artikel?.ean}} |
          </span>
          {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_ARTIKELNUMMER' | translate}} {{bewegung.artikelMenge?.artikel?.nummer ?? ''}}
        </p>
        <p class="font-semibold text-lg">
          {{bewegung.artikelMenge.artikel?.artbez1 ?? '-'}}
        </p>
        <p class="text-light"
           *ngIf="bewegung.artikelMenge.artikel?.artbez2 && bewegung.artikelMenge.artikel?.artbez2 !== ''">
          {{bewegung.artikelMenge.artikel?.artbez2}}
        </p>

        @if (bewegung?.dimension && bewegung?.dimension?.massUnitId) {
          <div class="mt-1">
            <span class="italic font-semibold text-sm mt-1">{{ bewegung?.dimension?.massUnitId }}</span>
          </div>
        }
      </div>
      <div class="flex flex-col mt-2 lg:mt-0 w-auto md:w-3/4 lg:w-2/3 gap-1">
        <div class="flex flex-col md:flex-row w-full gap-2 justify-end">
          @if(!isPalette(bewegung)){
            <soft-mengen-eingabe #mengenEingabe
                                 [disabled]="isBestellung || (bewegung.status === 'E')"
                                 [float]="true"
                                 [value]="bewegung.artikelMenge.menge ?? 0"
                                 (valueChange)="mengeChange.emit({ bewe: bewegung, menge: mengenEingabe.value })"
                                 [einheit]="(bewegung.artikelMenge?.einheit?.arteh ?? '') | uppercase"
                                 class="w-full"
                                 [ngClass]="{'soft-mengen-eingabe-order w-full': (isBestellung || (bewegung.status === 'E'))}">
            </soft-mengen-eingabe>
          }
          @if (((!isBestellung && bewegung.status !== 'E') || (isBestellung && (bewegung.status !== 'O'))) || !isBestellung) {
            <div class="flex flex-row w-full gap-2 min-h-[8vh]">
              <button
                *ngIf="(!isBestellung && bewegung.status !== 'E') || (isBestellung && (bewegung.status !== 'O'))"
                class="soft-button primary flex items-center justify-center text-white min-w-[3.5rem] rounded-sm"
                [ngClass]="!isPalette(bewegung) ? 'w-3/5 md:w-1/5' : 'w-3/5'"
                (click)="mengenEingabeOeffnen.emit(bewegung)">
                @if(isPalette(bewegung)) {
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" stroke-width="1.5" class="w-6 h-6 fill-current text-white">
                    <path d="M272 48L112 48l0 160 160 0 0-160zM112 0L272 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48l-160 0c-26.5 0-48-21.5-48-48L64 48C64 21.5 85.5 0 112 0zM528 112l-96 0 0 96 96 0 0-96zM432 64l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zM0 344c0-13.3 10.7-24 24-24l64 0 232 0 232 0 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-40 0 0 96 40 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0-232 0L88 512l-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l40 0 0-96-40 0c-13.3 0-24-10.7-24-24zM112 464l184 0 0-96-184 0 0 96zm232 0l184 0 0-96-184 0 0 96z"/>
                  </svg>
                } @else {
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                  </svg>
                }
              </button>

              <button
                *ngIf="!isBestellung"
                class="soft-button primary flex items-center justify-center text-white min-w-[3.5rem] rounded-sm"
                [ngClass]="!isPalette(bewegung) ? 'w-3/5 md:w-1/5' : 'w-3/5'"
                (click)="statusChange.emit(bewegung)">
                <svg *ngIf="bewegung.status === 'O'"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                <svg *ngIf="bewegung.status === 'E'"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                </svg>
              </button>
            </div>
          }
        </div>
        @if(isPalette(bewegung)){
          <p class="text-sm text-light">{{bewegung?.anzahlPalette}}/{{bewegung?.anzahlPalettenSoll}} Paletten</p>
        } @else {
          <p class="text-sm text-light">
            = {{bewegung?.artikelMenge?.menge_lg}} {{ (bewegung?.artikelMenge?.einheit_lg?.arteh ?? '') | uppercase}}
          </p>
        }
        <p *ngIf="bewegung?.lagerplatz && (bewegung?.lagerplatz?.length || 0) > 0"
          class="text-sm font-semibold ml-auto">
          {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_LAGER' | translate}} {{bewegung?.lagerplatz}}
        </p>
      </div>
    </div>

      @if ((config?.dierektannahme && bewegung.artikelMenge.artikel.lplfuehrung) || (config?.reklamation && hasReklamationPermission())) {
        <div class="flex m-2">
          <div class="flex justify-center text-center">
            <soft-icon
              (click)="actionClick.emit(bewegung)"
              class="p-1 size-7 text-center rounded hover:bg-gray-200 cursor-pointer"
              name="fa-regular fa-ellipsis-vertical"></soft-icon>
          </div>
        </div>
      }
    </div>

    <div class="flex flex-col m-3 gap-2" *ngIf="bewegung?.auftrag"
         [ngClass]="{'border-b border-solid pb-4': bewegung?.zeilentext && bewegung?.zeilentext !== ''}">



      <p class="text-light">
        {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_AUFTRAG' | translate}} {{bewegung?.auftrag?.auftragnr}}
      </p>
      <p class="text-light">
        {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_KUNDE' | translate}} {{bewegung.auftrag?.kunde?.kundennummer}} {{bewegung.auftrag?.kunde?.kurzbez}} |
        {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_ADRESSE' | translate}} {{bewegung.auftrag?.kunde?.strasse}}<ng-container *ngIf="bewegung.auftrag?.kunde?.strassenr"> {{bewegung.auftrag?.kunde?.strassenr}}</ng-container>,
        {{bewegung.auftrag?.kunde?.plz}} {{bewegung.auftrag?.kunde?.ort}}
      </p>
    </div>
    <div class="flex flex-row w-full p-3" *ngIf="bewegung?.zeilentext && bewegung?.zeilentext !== ''">
      <p class="text-light mb-2">
        {{bewegung?.zeilentext}}
      </p>
    </div>
  </div>
</ng-container>

<ng-template #placeholder>
  <div class="flex items-center justify-center text-center text-sm text-lighter py-4 mt-2">
    {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_KEINE_BEWEGUNGEN' | translate}}
  </div>
</ng-template>



