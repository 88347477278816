import { computed, effect, inject, Injectable, resource } from '@angular/core';
import { LanguageRepository } from './language.repository';
import { SOFTLINE_CONFIG_LANGUAGES } from './language.shared';
import { SOFTLINE_LANGUAGE_DE } from './languages';
import { SOFTLINE_CONFIG_DEFAULT_LANGUAGE, TranslationService } from '@softline/ui-core';
import { isDefined } from '@softline/core';

@Injectable({providedIn: 'root'})
export class LanguageService {

  readonly repository = inject(LanguageRepository);
  readonly defaultCode = inject(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);
  readonly languages = inject(SOFTLINE_CONFIG_LANGUAGES);
  readonly translationService = inject(TranslationService);

  private readonly _selectedCode = resource({
    loader: async () => {
      let language: string;
      try {
        language = await this.repository.load();
      } catch (e) {
        language = this.defaultCode;
      }
      return language;
    }
  })

  readonly selected = computed(() => {
    const selectedCode = this._selectedCode.value();
    const registeredCode = this.getRegisteredLanguageCode(selectedCode ?? this.defaultCode);
    return this.languages.find(o => o.code === registeredCode)
      ?? SOFTLINE_LANGUAGE_DE;
  });

  constructor() {
    effect(() => {
      const language = this.selected();
      this.translationService.setLanguage(language.code);
    });
  }


  async setLanguage(lang: string): Promise<void> {
    const index = this.languages.findIndex(o => o.code === lang);
    if(index === -1)
      throw new Error(`[LanguageStore] select: No language with code ${lang} registered`);
    this._selectedCode.set(lang);
    const selected = this.selected();
    try {
      await this.repository.save(selected.code);
    }
    catch (e) {
      throw new Error('[LanguageStore]: Cannot save language')
    }
  }

  private getRegisteredLanguageCode(language: string): string {
    const languages = this.languages;
    let code = languages.find(o => o.code === language)?.code;
    if(!isDefined(code)) {
      const languageCode = language.split('-')[0];
      code = languages.find(o => o.code === languageCode)?.code;
      if(!isDefined(code)) {
        code = languages.find(o => o.code.split('-')[0] === languageCode)?.code;
        if(!isDefined(code)) {
          code = this.defaultCode;
        }
      }
    }
    return code;
  }

}
