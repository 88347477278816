import {
  Component,
  computed,
  HostListener,
  inject,
  Injector,
  linkedSignal,
  OnDestroy,
  OnInit,
  resource,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BackNavigable,
  BackNavigationService,
  BlockingLoadingSpinnerComponent,
  Command,
  CommandStore,
  handleRequestError,
  Scan,
  ScannerStore,
  showRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_SCANNER,
  UploadFileCommand,
} from '@softline/application';
import { Store } from '@softline/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  SOFTLINE_CONFIG_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE,
} from '../../warenuebernahme.shared';
import { WwsWarenuebernahmeStore } from '../../store/warenuebernahme.store';
import {
  BehaviorSubject,
  combineLatestWith,
  firstValueFrom,
  map,
  skip,
  Subscription,
  switchMap, withLatestFrom
} from 'rxjs';
import { KopfdatenComponent } from '../../components/kopfdaten/kopfdaten.component';
import { WwsBewegungStore } from '../../store/bewegung.store';
import { BewegungListComponent } from '../../components/bewegung-list/bewegung-list.component';
import { WwsEmballageStore } from '../../store/emballage.store';
import { Bewe } from '../../types/bewegung';
import {
  MessageBarService,
  MessageBarStore,
  ModalOption,
  ModalService,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { PositionErledigenDialogComponent } from '../../dialogs/position-erledigen/position-erledigen-dialog.component';
import { EmballageHinzufuegenDialogComponent } from '../../dialogs/emballage-hinzufuegen/emballage-hinzufuegen-dialog.component';
import { FilterValue } from '../../types/filter-value';
import {
  Einheiteneingabe,
  EinheiteneingabeDialogComponent,
  EinheiteneingabeParams,
} from '@softapps/wws/core';
import { WarenuebernahmeAbschliessenDialogComponent } from '../../dialogs/warenuebernahme-abschliessen/warenuebernahme-abschliessen-dialog.component';
import { PalettenDetailDialogComponent } from '../../dialogs/paletten-detail/paletten-detail-dialog.component';
import { PalettenService } from '../../services/paletten-service';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  TeillieferungDialogComponent,
  TeillieferungResponse,
} from '../../dialogs/teillierferung-dialog/teillieferung-dialog.component';
import { ReklamationDialogComponent } from '../../dialogs/reklamation-dialog/reklamation-dialog.component';
import { DirektEinlagerungDialogComponent } from '../../dialogs/direkt-einlagerung-dialog/direkt-einlagerung-dialog.component';
import { EinlagerungService } from '../../services/einlagerung-service';
import { EtikettenDruckenDialogComponent } from '../../dialogs/etiketten-drucken-dialog/etiketten-drucken-dialog.component';
import { LagerplatzService } from '../../services/lagerplatz-service';
import { WarenuebernahmeService } from '../../services/warenuebernahme-service';
import { Warenuebernahme } from '../../types/warenuebernahme';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { Lagerplatz } from '@softapps/lager/core';
import {
  BarcodeMissingModalComponent,
  BarcodeMissingModalResult,
  ScanBarcodeMissingModalResult,
} from '../../dialogs/barcode-missing-modal/barcode-missing-modal.component';

@Component({
  selector: 'soft-warenuebernahme-detail',
  imports: [
    CommonModule,
    RouterModule,
    KopfdatenComponent,
    BewegungListComponent,
    UiCoreModule,
    BlockingLoadingSpinnerComponent,
  ],
  templateUrl: './warenuebernahme-detail.component.html',
  styleUrls: ['./warenuebernahme-detail.component.scss'],
  providers: [EinlagerungService, LagerplatzService],
})
export class WarenuebernahmeDetailComponent
  implements OnInit, OnDestroy, BackNavigable
{
  private archiveKey = '';
  private warenuebernahmeSubscription?: Subscription;
  private scanSubscription?: Subscription;

  readonly config = inject(SOFTLINE_CONFIG_WARENUEBERNAHME);
  readonly palettenService = inject(PalettenService);
  private readonly modalService = inject(ModalService);
  private readonly messageBarService = inject(MessageBarService);
  private readonly einlagerungService = inject(EinlagerungService);
  private readonly lagerplatzService = inject(LagerplatzService);
  private readonly warenuebernahmeService = inject(WarenuebernahmeService);
  private readonly injector = inject(Injector);

  private readonly idwu = signal<string | null>(null);
  readonly abschliessenLoading = signal<boolean>(false);

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  readonly warenuebernahme$ = this.activatedRoute.paramMap.pipe(
    map((o) => o.get('id')),
    switchMap((id) =>
      this.store.observe(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
        WwsWarenuebernahmeStore.getters.entity,
        id
      )
    )
  );

  readonly warenuebernahme = toSignal(this.warenuebernahme$, {
    initialValue: null,
  });

  readonly hasReklamationPermission = this.store.signal(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    'REKLAMATIONSWF'
  );

  readonly filterValues: FilterValue[] = [
    {
      id: 0,
      value: 'O',
      bezeichnung: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_OFFENE_POSITIONEN',
    },
    {
      id: 1,
      value: 'E',
      bezeichnung: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_ERLEDIGTE_POSITIONEN',
    },
    {
      id: 2,
      value: 'A',
      bezeichnung: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_ALLE_POSITIONEN',
    },
  ];

  readonly visibleStatus$ = new BehaviorSubject<FilterValue>(
    this.filterValues[0]
  );

  readonly warenuebernahmeBewegung$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
    WwsBewegungStore.getters.bewegungenMap
  );

  readonly bewegungen$ = this.warenuebernahmeBewegung$.pipe(
    combineLatestWith(this.visibleStatus$),
    map(([beweMap, status]) => {
      switch (status.value) {
        case 'O':
          return beweMap.offeneBewegungen;
        case 'E':
          return beweMap.erledigteBewegungen;
        default:
          return beweMap.alleBewegungen;
      }
    })
  );

  readonly lagerplatzAuswahlResource = resource({
    request: this.idwu,
    loader: (param) => {
      // TODO abfrage von permission - request muss nicht durchgeführt werden wenn es nicht gebraucht wird
      if (!param.request || !this.config.lagerplatzauswahl)
        return Promise.resolve(undefined);

      return this.lagerplatzService.loadLagerplatzAuswahl(param.request);
    },
  });

  readonly lagerplatzAuswahlLagerplaetze = computed(() =>
    this.lagerplatzAuswahlResource.value()
  );

  readonly selectedLagerplatzAuswahl = linkedSignal({
    source: () => ({
      values: this.lagerplatzAuswahlResource.value(),
      warenuebernahme: this.warenuebernahme(),
    }),
    computation: ({ values, warenuebernahme }) => {
      if (!values) return null;

      if (warenuebernahme && warenuebernahme?.lagerplatzAnlieferung)
        return values.find(
          (o) => o.id === warenuebernahme?.lagerplatzAnlieferung?.id
        );
      else return values[0];
    },
  });

  readonly bewegungen = toSignal(this.bewegungen$);

  readonly isTeillieferung = computed(() => {
    const bewe = this.bewegungen();
    let isTeillieferung = false;
    let teilBewe: Bewe | null = null;

    if (!bewe) return { isTeillieferung, teilBewe };

    bewe.forEach((bewe) => {
      if (this.isPalette(bewe)) {
        if ((bewe?.anzahlPalette ?? 0) < (bewe?.anzahlPalettenSoll ?? 0)) {
          isTeillieferung = true;
          teilBewe = bewe;
        }
      } else {
        if ((bewe.artikelMenge.menge ?? 0) < (bewe?.sollMenge ?? 0)) {
          isTeillieferung = true;
          teilBewe = bewe;
        }
      }
    });

    return { isTeillieferung, teilBewe };
  });

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
    WwsBewegungStore.getters.loading
  );

  // barcodeLieferschein = menuItem(() => {
  //   // TODO permission abfrage
  //   return {
  //     title: 'Lieferschein Scanner',
  //     name: 'lieferschein-scanner',
  //     type: 'execute',
  //     icon: 'fa-regular fa-barcode',
  //     outlet: 'action',
  //     tooltip: true,
  //     tooltipPosition: 'left',
  //     canExecute: true,
  //     execute: () => {
  //       return Promise.resolve();
  //     }
  //   }
  // })

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backNavigationService: BackNavigationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.warenuebernahmeSubscription = this.warenuebernahme$.subscribe(
      (warenuebernahme) => {
        this.archiveKey = warenuebernahme?.archivekey;
      }
    );

    this.scanSubscription = this.store
      .observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(skip(1), withLatestFrom(this.warenuebernahme$))
      .subscribe(([scan, warenuebernahme]) => {
        if (!scan || !warenuebernahme) return;

        const missingModalConfig = this.modalService
          .configs()
          .find((o) => o.id === BarcodeMissingModalComponent.MODAL_ID);

        if (missingModalConfig) {
          try {
            this.modalService.resolve(BarcodeMissingModalComponent.MODAL_ID, {
              status: 'SCAN',
              barcode: scan.data,
            } as ScanBarcodeMissingModalResult);
          } catch (e) {
            handleRequestError(e, this.injector);
          }
        }

        this.addEkBarcode(warenuebernahme, scan);
      });

    const uploadFileCommand = new UploadFileCommand(this.store, {
      sources: 'photo',
      selectionMode: 'multi',
      archiveKey: this.archiveKey,
    });

    uploadFileCommand.icon = 'fa-regular fa-camera';
    uploadFileCommand.name =
      '#WWS_WARENUEBERNAHME.DETAIL.LABEL_FOTOS_SPEICHERN';

    const commands: Command[] = [uploadFileCommand];

    if (this.config?.ekBarcode) {
      commands.push({
        name: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_EKBARCODE',
        class: 'menu action-menu action-menu-top',
        icon: 'fa-regular fa-scanner-gun',
        execute: async () => {
          await this.store.dispatch(
            SOFTLINE_FEATURE_SCANNER,
            ScannerStore.actions.scan,
            { labelType: ['code39', 'ean', 'itf', 'qrcode', 'upc'] }
          );
        },
      });
    }

    commands.push({
      name: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_ABSCHLIESSEN',
      class: 'menu bottom-menu primary',
      execute: async () => {
        const warenuebernahme = await firstValueFrom(this.warenuebernahme$);

        if (!warenuebernahme) return;

        await this.abschliessen(warenuebernahme);
      },
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: WarenuebernahmeDetailComponent,
        commands,
      }
    );

    try {
      const idwu = this.activatedRoute.snapshot.paramMap.get('id');

      if (!idwu) return;

      this.idwu.set(idwu);

      await Promise.all([
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
          WwsWarenuebernahmeStore.actions.sperren,
          idwu
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
          WwsBewegungStore.actions.loadMany,
          {
            clear: true,
            pathParams: {
              idwu,
            },
          }
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE,
          WwsEmballageStore.actions.loadMany
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
          WwsWarenuebernahmeStore.actions.loadOnce,
          { id: idwu }
        ),
      ]);
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.backNavigationService.set(undefined);

    if (
      this.warenuebernahmeSubscription &&
      !this.warenuebernahmeSubscription.closed
    )
      this.warenuebernahmeSubscription.unsubscribe();

    if (this.scanSubscription && !this.scanSubscription.closed)
      this.scanSubscription.unsubscribe();

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      WarenuebernahmeDetailComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
      WwsBewegungStore.mutations.clear
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.actions.oeffnen,
      this.activatedRoute.snapshot.paramMap.get('id')
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/warenuebernahme']);
  }

  async abschliessen(warenuebernahme: Warenuebernahme) {
    if (this.config?.ekBarcode && !warenuebernahme?.barcodeEk) {
      const result = await this.modalService.open<
        BarcodeMissingModalComponent,
        BarcodeMissingModalResult
      >({
        id: BarcodeMissingModalComponent.MODAL_ID,
        component: BarcodeMissingModalComponent,
        dismiss: true,
      });

      if (result === 'DISMISSED' || result.status === 'CANCEL') return;

      if (result.status === 'MANUAL') {
        await this.addEkBarcode(warenuebernahme, {
          labelType: 'code39',
          data: result.barcode,
          timestamp: '',
        });
      }
    }

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<boolean, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_ABSCHLIESSEN',
        component: WarenuebernahmeAbschliessenDialogComponent,
        dismiss: true,
      }
    );

    if (!result || result === 'DISMISSED') return;

    this.abschliessenLoading.set(true);

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.actions.abschliessen,
      this.activatedRoute.snapshot.paramMap.get('id')
    );

    this.abschliessenLoading.set(false);

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title:
          '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_WARENUEBERNAHME_ABGESCHLOSSEN',
        message:
          '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_WARENUEBERNAHME_ABGESCHLOSSEN',
      }
    );

    await this.router.navigate(['/warenuebernahme']);
  }

  async addEkBarcode(
    warenuebernahme: Warenuebernahme,
    scan: Scan
  ): Promise<void> {
    try {
      console.log('add ekBarcode', warenuebernahme, scan);
      await firstValueFrom(
        this.warenuebernahmeService.addEkBarcode(warenuebernahme, scan)
      );
      this.store.commit(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
        WwsWarenuebernahmeStore.mutations.patch,
        {
          id: warenuebernahme.id,
          changes: {
            barcodeEk: scan.data
          }
        }
      );
      this.messageBarService.success('Barcode für Lieferantenlieferschein wurde erfolgreich erfasst!');
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  async updateBeweStatus(bewe: Bewe): Promise<void> {
    if (bewe.status === 'E') {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
        WwsBewegungStore.actions.positionOeffnen,
        bewe
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title:
            '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_POSITION_GEOEFFNET',
          message:
            '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_POSITION_GEOEFFNET',
        }
      );

      return;
    }

    if (this.config.reklamation) {
      await this.teillieferung();
    }

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Bewe, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_POSITION_ERLEDIGEN',
        component: PositionErledigenDialogComponent,
        data: {
          bewegung: bewe,
        },
        dismiss: true,
      }
    );

    if (result === 'DISMISSED') return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_POSITION_ERLEDIGT',
        message:
          '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_POSITION_ERLEDIGT',
      }
    );
  }

  async updateMengeLocal(data: { bewe: Bewe; menge: number }): Promise<void> {
    const menge_lg =
      (data.menge * (data?.bewe?.artikelMenge?.einheit?.faktor ?? 1)) /
      (data?.bewe?.artikelMenge?.einheit_lg?.faktor ?? 1);

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
      WwsBewegungStore.mutations.patch,
      {
        id: data.bewe.id,
        changes: {
          artikelMenge: {
            ...data.bewe.artikelMenge,
            menge: data.menge,
            menge_lg: menge_lg,
          },
        },
      }
    );
  }

  async teillieferung() {
    const isTeillieferung = this.isTeillieferung().isTeillieferung;
    const teilBewe: Bewe | null = this.isTeillieferung().teilBewe;

    if (isTeillieferung) {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<TeillieferungResponse, any>(),
        {
          component: TeillieferungDialogComponent,
        }
      );

      if (!result || result === 'DISMISSED' || !teilBewe) return;

      if (result === 'reklemation') {
        this.openReklamationDialog(teilBewe);
      }
    }
  }

  async openMengeDialog(bewegung: Bewe) {
    if (this.isPalette(bewegung)) {
      try {
        const response = await this.palettenService.loadPaletten(bewegung.id);

        const idwu = this.idwu();

        if (!idwu) return;

        const result = await this.store.dispatch(
          SOFTLINE_FEATURE_MODAL,
          ModalStore.actions.open<any, any>(),
          {
            id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_PALETTEN_DETAIL',
            component: PalettenDetailDialogComponent,
            dismiss: true,
            data: {
              palettenInfo: response,
              beweId: bewegung.id,
              idwu: idwu,
            },
          }
        );

        if (!result || result === 'DISMISSED') return;
      } catch (e) {
        showRequestErrors(this.store, e);
      }
    } else {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<
          Einheiteneingabe | null,
          { params: EinheiteneingabeParams }
        >(),
        {
          id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_MENGEN_EINGABE',
          component: EinheiteneingabeDialogComponent,
          dismiss: true,
          data: {
            params: {
              idartstamm: bewegung.artikelMenge.artikel.id,
              menge: bewegung.artikelMenge.menge,
              idbasisEinheit: bewegung.artikelMenge.einheit.id,
            },
          },
        }
      );

      if (!result || result === 'DISMISSED') return;

      this.store.commit(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
        WwsBewegungStore.mutations.patch,
        {
          id: bewegung.id,
          changes: {
            artikelMenge: {
              ...bewegung.artikelMenge,
              menge: result.erfassungsEinheit.menge ?? 0,
              menge_lg: result.summeEinheit.menge ?? 0,
            },
          },
        }
      );
    }
  }

  async openEmballageHinzufuegenDialog() {
    const idwu = this.activatedRoute.snapshot.paramMap.get('id');

    if (!idwu) return;

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_EMBALLAGE_HINZUFUEGEN',
        component: EmballageHinzufuegenDialogComponent,
        data: { idwu },
        dismiss: true,
      }
    );

    if (result === 'DISMISSED') return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title:
          '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_EMBALLAGE_HINZUGEFUEGT',
        message:
          '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_EMBALLAGE_HINZUGEFUEGT',
      }
    );
  }

  isPalette(bewe: Bewe) {
    return (
      (bewe?.anzahlPalette ?? 0) > 0 || (bewe?.anzahlPalettenSoll ?? 0) > 0
    );
  }

  async openEtikettenDruckDialog() {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<number, any>(),
      {
        component: EtikettenDruckenDialogComponent,
        dismiss: true,
      }
    );

    console.log(result);

    if (result === 'DISMISSED' || result === null) return 'DISMISSED';

    return result;
  }

  async openReklamationDialog(bewe: Bewe) {
    console.log('rekla bewe?: ', bewe);

    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<any, any>(),
      {
        component: ReklamationDialogComponent,
        dismiss: true,
        data: {
          bewe,
          idwu: this.idwu(),
        },
      }
    );

    return;
  }

  async openDirektEinlagerungDialog(bewe: Bewe) {
    let result: any = 'YES'; // ist aus 'YES',
    // weil wenn es paletten sind die Etiketten gedruckt werden ohne zu fragen

    let anzahlEtiketten = 0;

    if (!this.isPalette(bewe)) {
      result = await this.openEtikettenDruckDialog();

      if (result === 'DISMISSED') return;

      anzahlEtiketten = result ?? 0;
    }

    console.log('result etikettendruck: ', result);
    if (result !== 'NO' && result !== 'CANCEL') {
      const idwu = this.idwu();
      if (!idwu) return;
      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
          WwsBewegungStore.actions.positionErledigen,
          {
            anzahlEtiketten: anzahlEtiketten,
            bewegung: bewe,
          }
        );
        await this.einlagerungService.printEtiketten(bewe.id);
      } catch (e) {
        showRequestErrors(this.store, e);
      }
    }

    const response = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<any, any>(),
      {
        component: DirektEinlagerungDialogComponent,
        dismiss: true,
        data: {
          bewe,
          idwu: this.idwu(),
          isPalette: this.isPalette(bewe),
        },
      }
    );

    if (response === 'DISMISSED') {
      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
          WwsBewegungStore.actions.positionOeffnen,
          bewe
        );
      } catch (e) {
        showRequestErrors(this.store, e);
      }
      return;
    }

    const id = this.idwu();

    if (id) await this.router.navigate(['/warenuebernahme/detail', id]);
    else await this.navigateBack();
  }

  async selectedLagerplatzChanged(
    warenuebernahme: Warenuebernahme,
    lagerplatz: Lagerplatz
  ): Promise<void> {
    this.selectedLagerplatzAuswahl.set(lagerplatz);

    try {
      await firstValueFrom(
        this.warenuebernahmeService.update(warenuebernahme, lagerplatz)
      );
    } catch (e) {
      handleRequestError(e, this.injector, { show: true });
    }
  }

  async openActionDialog(bewe: Bewe) {
    if (!bewe) return;

    const options: ModalOption<string>[] = [];

    if (this.config.reklamation && this.hasReklamationPermission()) {
      options.push({
        value: 'reklamation',
        icon: 'fa-regular fa-triangle-exclamation',
        label: 'Reklamation',
      });
    }

    if (this.config.dierektannahme && bewe.artikelMenge.artikel.lplfuehrung) {
      options.push({
        value: 'direkte-einlagerung',
        icon: 'fa-regular fa-forklift',
        label: 'Direkte Einlagerung',
      });
    }

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<string>(),
      {
        title: 'Aktionen',
        options: options,
        dismiss: true,
      }
    );

    if (result === 'DISMISSED' || !result) return;

    if (result === 'reklamation') {
      await this.openReklamationDialog(bewe);
    }

    if (result === 'direkte-einlagerung') {
      await this.openDirektEinlagerungDialog(bewe);
    }
  }
}
