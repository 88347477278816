import { ResourceService } from '../../data/abstraction';
import { ApiResourceLocation } from '../../data-access/types/api.location';
import {
  DeleteListParameters,
  ListRepository, PatchListParameters,
  SaveListParameters
} from '../abstraction';
import { Observable } from 'rxjs';
import { ReadonlyHttpApiListRepository } from './readonly-http-api-list.repository';

export class HttpApiListRepository<TResult>
  extends ReadonlyHttpApiListRepository<TResult>
  implements ListRepository<TResult>
{
  constructor(
    protected override service: ResourceService<ApiResourceLocation>,
    protected override path: string | string[]
  ) {
    super(service, path);
  }

  save(params?: SaveListParameters): Observable<TResult> {
    return this.service.create<any, TResult>(
      {
        path: this.path,
        pathParams: params?.pathParams,
        queryParams: undefined,
      },
      params?.body ?? {}
    );
  }

  delete(params?: DeleteListParameters<TResult>): Observable<TResult> {
    return this.service.delete<TResult>({
      path: this.path,
      pathParams: params?.pathParams,
      queryParams: undefined,
    });
  }

  patch(params?: PatchListParameters<TResult>): Observable<TResult> {
    return this.service.patch<any, TResult>({
      path: this.path,
      pathParams: params?.pathParams,
      queryParams: undefined,
    }, params?.patch ?? {});
  }
}
