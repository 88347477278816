import { Component, OnInit } from '@angular/core';
import { StringDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import {
  DrawInputComponent as UiCoreDIC,
  FieldValidationDirective,
} from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import { NgIf } from '@angular/common';

@Component({
  selector: 'soft-dynamic-draw-input',
  templateUrl: './draw-input.component.html',
  styleUrls: ['./draw-input.component.scss'],
  imports: [
    UiCoreDIC,
    ReactiveFormsModule,
    FormControlPipe,
    BooleanRulePipe,
    NgIf,
    FieldValidationDirective,
  ],
})
export class DrawInputComponent
  extends DynamicInputAtom<StringDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
