import { EnvironmentProviders, importProvidersFrom, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import { ConnectionHttpService, SOFTLINE_SERVICE_HTTP, Store, StoreModule } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import {
  ApplicationStore,
  SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import {
  SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK,
  SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK,
  SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_AUFGLIEDERUNG,
  SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_BELEGART,
  SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_EBENE, SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_FOK,
  SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_UMSATZART,
  SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG,
  SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK,
  SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK_WIDGET,
  SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK_WIDGET_FULL_WIDTH,
  UmsatzstatistikConfig
} from './umsatzstatistik.shared';
import { WwsUmsatzstatistikStore } from './store/umsatzstatistik.store';
import {
  SOFTLINE_API_WWS_UMSATZABFRAGE_AUFGLEIDERUNG,
  SOFTLINE_API_WWS_UMSATZABFRAGE_BELEGART,
  SOFTLINE_API_WWS_UMSATZABFRAGE_EBENEN,
  SOFTLINE_API_WWS_UMSATZABFRAGE_UMSATZART,
  SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG,
} from './umsatzstatistik.api';
import { UmsatzstatistikService } from './services/umsatzstatistik-service';
import {
  DefaultUmsatzstatistikListService,
  UmsatzstatistikListService,
} from './services/umsatzstatistik-optionen-list.service';
import { WwsUmsatzstatistikVkforgStore } from './store/vkforg.store';
import { WwsUmsatzstatistikAufgliederungStore } from './store/aufgliederung.store';
import { WwsUmsatzstatistikUmsatzebeneStore } from './store/umsatzebene.store';
import { WwsUmsatzstatistikUmsatzartStore } from './store/umsatzart.store';
import { UmsatzstatistikWidgetComponent } from './widgets/umsatzstatistik-widget/umsatzstatistik-widget.component';
import { UmsatzstatistikSubebenenService } from './services/umsatzstatistik-subebenen.service';
import { UmsatzstatistikQueryUpdateService } from './services/query-update.service';
import { provideQueryUpdateStrategies } from './strategies/query-update.strategy';
import { vkforgQueryUpdateStrategy } from './strategies/vkforg-query-update.strategy';
import { artikelQueryUpdateStrategy } from './strategies/artikel-query-update.strategy';
import { hautpgruppeQueryUpdateStrategy } from './strategies/hauptgruppe-query-update.strategy';
import { UmsatzstatistikFullWidthWidgetComponent } from './widgets/umsatzstatistik-full-width-widget/umsatzstatistik-full-width-widget.component';
import { untergruppeQueryUpdateStrategy } from './strategies/untergruppe-query-update.strategy';
import { unteruntergruppeQueryUpdateStrategy } from './strategies/unteruntergruppe-query-update.strategy';
import { produktgruppeQueryUpdateStrategy } from './strategies/produktgruppe-query-update.strategy';
import { kundeQueryUpdateStrategy } from './strategies/kunde-query-update.strategy';
import { kundengruppeQueryUpdateStrategy } from './strategies/kundengruppe-query-update.strategy';
import { lieferartQueryUpdateStrategy } from './strategies/lieferart-query-update.strategy';
import { WwsUmsatzstatistikBelegartStore } from './store/belegart.store';
import { QueryService } from './services/query-service';
import { UmsatzstatistikFieldOkStore } from './store/fok.store';
import { FieldOkComponentService } from '../../../../../framework/dynamic/src/lib/services/field-ok-component.service';
import { lagerplatzQueryUpdateStrategy } from './strategies/lagerplatz-query-update.strategy';
import { abteilungQueryFieldOk } from '../../../core/src/lib/field-ok/abteilung/abteilung.field-ok';
import { abteilungQueryUpdateStrategy } from './strategies/abteilung-query-update.strategy';
import { abteilungsubQueryUpdateStrategy } from './strategies/abteilungsub-query-update.strategy';
import { konditionsgruppeQueryUpdateStrategy } from './strategies/konditionsgruppe-query-update.strategy';
import { vertreterQueryUpdateStrategy } from './strategies/vertreter-query-update.strategy';

export const umsatzstatistikProviders: (Provider | EnvironmentProviders)[] = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'wws-umsatzstatistik',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Umsatzstatistik',
          icon: 'fa-regular fa-chart-mixed-up-circle-dollar',
          route: '/umsatzstatistik',
          permission: SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK,
        }
      );
    }),
  UmsatzstatistikService,
  UmsatzstatistikQueryUpdateService,
  UmsatzstatistikSubebenenService,
  provideQueryUpdateStrategies(
    vkforgQueryUpdateStrategy,
    artikelQueryUpdateStrategy,
    hautpgruppeQueryUpdateStrategy,
    untergruppeQueryUpdateStrategy,
    unteruntergruppeQueryUpdateStrategy,
    produktgruppeQueryUpdateStrategy,
    kundeQueryUpdateStrategy,
    kundengruppeQueryUpdateStrategy,
    lieferartQueryUpdateStrategy,
    lagerplatzQueryUpdateStrategy,
    abteilungQueryUpdateStrategy,
    abteilungsubQueryUpdateStrategy,
    konditionsgruppeQueryUpdateStrategy,
    vertreterQueryUpdateStrategy
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK,
      feature: WwsUmsatzstatistikStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_EBENE,
      feature: WwsUmsatzstatistikUmsatzebeneStore.feature,
      providers: [
        {
          provide: UmsatzstatistikListService,
          useFactory: () =>
            new DefaultUmsatzstatistikListService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_WWS_UMSATZABFRAGE_EBENEN
            ),
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_AUFGLIEDERUNG,
      feature: WwsUmsatzstatistikAufgliederungStore.feature,
      providers: [
        {
          provide: UmsatzstatistikListService,
          useFactory: () =>
            new DefaultUmsatzstatistikListService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_WWS_UMSATZABFRAGE_AUFGLEIDERUNG
            ),
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG,
      feature: WwsUmsatzstatistikVkforgStore.feature,
      providers: [
        {
          provide: UmsatzstatistikListService,
          useFactory: () =>
            new DefaultUmsatzstatistikListService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG
            ),
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_UMSATZART,
      feature: WwsUmsatzstatistikUmsatzartStore.feature,
      providers: [
        {
          provide: UmsatzstatistikListService,
          useFactory: () =>
            new DefaultUmsatzstatistikListService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_WWS_UMSATZABFRAGE_UMSATZART
            ),
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_BELEGART,
      feature: WwsUmsatzstatistikBelegartStore.feature,
      providers: [
        {
          provide: UmsatzstatistikListService,
          useFactory: () =>
            new DefaultUmsatzstatistikListService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_WWS_UMSATZABFRAGE_BELEGART
            ),
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_FOK,
      feature: UmsatzstatistikFieldOkStore.feature,
      providers: [
        {
          provide: FieldOkComponentService,
          useFactory: () => new FieldOkComponentService(
            inject(ConnectionHttpService)
          )
        }
      ],
    })
  ),
  QueryService,
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: UmsatzstatistikWidgetComponent,
      permission: SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK_WIDGET,
    },
  },
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: UmsatzstatistikFullWidthWidgetComponent,
      permission: SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK_WIDGET_FULL_WIDTH,
    },
  },
];

export function provideUmsatzstatistikModule(config: UmsatzstatistikConfig): Array<Provider | EnvironmentProviders> {
  return [
    ...umsatzstatistikProviders,
    {provide: SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK, useValue: config}
  ];
}
