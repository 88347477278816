import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockAmount } from '../../types/stock-amount';

@Component({
  selector: 'soft-stock-amount',
  imports: [CommonModule],
  templateUrl: './stock-amount.component.html',
  styleUrl: './stock-amount.component.scss',
})
export class StockAmountComponent {
  stockAmount = input.required<StockAmount>()
}
