import { Inject, Injectable, Optional } from '@angular/core';
import { Dictionary, LocalStorageService } from '@softline/core';
import { lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SOFTLINE_CONFIG_SETTINGS } from '../../application.shared';
import { Settings } from '../settings';

const SETTINGS_LOCAL_STORAGE_KEY = 'Application Settings';

@Injectable()
export class SettingsService {
  constructor(
    private service: LocalStorageService,
    @Optional()
    @Inject(SOFTLINE_CONFIG_SETTINGS)
    private settings: Settings[] = []
  ) {}

  load(): Promise<Dictionary<object>> {
    return lastValueFrom(
      this.service
        .get<Dictionary<object>>({ key: SETTINGS_LOCAL_STORAGE_KEY })
        .pipe(
          catchError((error: any) => {
            console.log('No settings found: applying default settings');
            const settings = this.settings.reduce(
              (p, c) => ({ ...p, [c.key]: c.default }),
              {}
            ) as Dictionary<object>;
            return of(settings);
          })
        )
    );
  }

  save(settings: Dictionary<object>): Promise<Dictionary<object>> {
    return lastValueFrom(
      this.service.create<Dictionary<object>, Dictionary<object>>(
        { key: SETTINGS_LOCAL_STORAGE_KEY },
        settings
      )
    );
  }

  reset(): Promise<Dictionary<object>> {
    const settings = this.settings.reduce(
      (p, c) => ({ ...p, [c.key]: c.default }),
      {}
    ) as Dictionary<object>;
    return this.save(settings);
  }
}
