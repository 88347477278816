<div class="flex flex-row items-center py-3 soft-card shadow rounded flex-nowrap cursor-pointer"
  (click)="goToApplication(application)"
>
  <i *ngIf="application?.icon !== ''; else defaultIcon"
    [class]="application?.icon"
    class="ml-3 fa-2x w-14 p-1"
  ></i>
  <div class="flex flex-col w-full ml-3">
    <span class="font-bold text-xl">{{ application?.name | translate }}</span>
    <p class="color-light" *ngIf="application?.description">
      {{ application?.description | translate }}
    </p>
  </div>
  <div class="flex flex-col justify-center items-center">
    @if (application?.type === 'link') {
      <soft-icon name="fa-regular fa-arrow-up-right-from-square" class="text-sm color-lighter"></soft-icon>
    } @else {
      <i class="fa-regular fa-chevron-right color-lighter"></i>
    }
  </div>
</div>

<ng-template #defaultIcon>
  <i class="fa-regular fa-ban fa-3x"></i>
</ng-template>
