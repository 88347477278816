<h4 class="text-xl font-semibold mt-3 ml-3">
  Lagerplatz auswählen
</h4>
<div class="flex flex-col">
  @for (lagerplatzInhalt of lagerplatzInhalte(); track lagerplatzInhalt.lagerplatz.id) {
    <div class="flex flex-row justify-between flex-nowrap cursor-pointer hover:bg-hover p-4 gap-2"
         (click)="resolve(lagerplatzInhalt.lagerplatz)">
      <div class="flex flex-col w-full">
        <span class="text-lg font-semibold">{{lagerplatzInhalt.lagerplatz.lagerplatz}}</span>
      </div>
      <soft-stock-amount [stockAmount]="lagerplatzInhalt.inhalt[0]"></soft-stock-amount>
      <div class="flex flex-col justify-center items-center">
        <i class="fa-regular fa-chevron-right color-light"></i>
      </div>
    </div>
  }
</div>
