import { Component, OnDestroy, OnInit } from '@angular/core';
import { Modal, ModalBase, UiCoreModule } from '@softline/ui-core';
import { Timestamp } from '../../../data/timestamp.model';
import { Store, DefaultStore, Trace, ValidationStore } from "@softline/core";
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { TimestampCode } from '../../../data/timestamp-code.model';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';
import {
  SOFTLINE_PERMISSION_TIMESTAMP_CUSTOM,
  SOFTLINE_PERMISSION_TIMESTAMP_SELECT_EMPLOYEE,
} from '../../../stempelerfassung.permissions';
import {
  SOFTLINE_FEATURE_TIMESTAMP_CODE,
  SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
  SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
  SOFTLINE_FEATURE_TIMESTAMP,
  TimestampCodeStore,
  TimestampEmployeeStore,
  TimestampStore,
} from '../../../stempelerfassung.shared';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { TimestampCurrentEmployeeStore } from '../../../store/current-employee.store';
import { CommonModule } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-record-timestamp-dialog',
    templateUrl: './record-timestamp-dialog.component.html',
    styleUrls: ['./record-timestamp-dialog.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule]
})
export class RecordTimestampDialogComponent
  extends ModalBase<Timestamp>
  implements OnInit, OnDestroy, Modal<Timestamp>
{
  private validationSubscription?: Subscription;

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      employee: new UntypedFormControl(undefined),
      flag: new UntypedFormControl('arrival'),
      code: new UntypedFormControl(undefined),
      timestamp: new UntypedFormControl(moment().toISOString()),
    },
    { asyncValidators: (control) => this.validateForm(this.store, control) }
  );

  codes$: Observable<readonly TimestampCode[]> = this.store.observe(
    SOFTLINE_FEATURE_TIMESTAMP_CODE,
    TimestampCodeStore.getters.all
  );

  employees$ = this.store.observe(
    SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
    TimestampEmployeeStore.getters.all
  );

  validation$ = this.store.observe(
    SOFTLINE_FEATURE_TIMESTAMP,
    ValidationStore.getters.validation.validation
  );

  templateLoading$ = this.store.observe(
    SOFTLINE_FEATURE_TIMESTAMP,
    TimestampStore.getters.loading
  );

  canModifyTime$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIMESTAMP_CUSTOM
  );
  canModifyEmployee$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIMESTAMP_SELECT_EMPLOYEE
  );

  constructor(private store: Store) {
    super()
  }


  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_TIMESTAMP,
      DefaultStore.actions.default.load,
      {}
    );
    this.store.observe(
      SOFTLINE_FEATURE_TIMESTAMP,
      DefaultStore.getters.default.default
    );
    this.validationSubscription = this.form.valueChanges
      .pipe(debounceTime(100))
      .subscribe(
        async (entity) =>
          await this.store.dispatch(
            SOFTLINE_FEATURE_TIMESTAMP,
            ValidationStore.actions.validation.validate,
            { entity }
          )
      );
    this.store
      .observe(
        SOFTLINE_FEATURE_TIMESTAMP,
        DefaultStore.getters.default.default
      )
      .pipe(
        filter((o) => !!o),
        take(1)
      )
      .subscribe((o) => this.form.patchValue(o));
  }

  ngOnDestroy(): void {
    if (this.validationSubscription && !this.validationSubscription.closed) {
      this.validationSubscription.unsubscribe();
    }
    this.validationSubscription = undefined;
  }

  onSubmit(timestamp: Timestamp): void {
    this.close(timestamp);
  }

  private validateForm(
    store: Store,
    control: AbstractControl
  ): Observable<any> {
    return store
      .observe(
        SOFTLINE_FEATURE_TIMESTAMP,
        ValidationStore.getters.validation.valid
      )
      .pipe(map((o) => ({ isValid: o })));
  }

  filterCodes(code: TimestampCode, flag: 'arrival' | 'departure' | undefined): boolean {
    return code.flag === flag;
  }

  onFlagChange() {
    this.form.patchValue({ code: null });
  }
}
