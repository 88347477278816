@if (!value) {
  <input
    class="mr-2"
    [value]="input || null"
    (input)="onInput(inputComponent.value)"
    [readOnly]="readonly"
    [readonly]="readonly"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.ENTITY_INPUT.PLACEHOLDER' | translate
    }}"
    #inputComponent
  />

  @if (strategy.loading()) {
    <i class="fa-regular fa-spinner fa-spin ml-4"></i>
  }

  @if (strategy.loading() || inputComponent.value.length > 0) {
    <div
      id="closeDialog"
      class="ml-4 pointer"
      (click)="onCancel(); $event.stopPropagation()"
    >
      <i class="fa-regular fa-xmark"></i>
    </div>
  }

  @if (!strategy.loading()) {
    <div
      id="openDialog"
      class="ml-4 pointer"
      *ngIf="!readonly"
      (click)="onOpen(); $event.stopPropagation()"
    >
      <i class="fa-regular fa-database"></i>
    </div>
  }
} @else {
  <div class="soft-ellipsis flex w-full">
    <span>
         <ng-container
           *ngTemplateOutlet="entityTemplate; context: $any({ entity: value })"
         ></ng-container>
    </span>
  </div>

  @if (!readonly) {
    <div class="pointer order-100 ml-4">
      <i
        class="fa-regular fa-xmark"
        (click)="setValue(null)"
      ></i>
    </div>
  }
}
