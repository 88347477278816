import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterService, SortService, Store} from '@softline/core';
import {
  FOKAnwender,
  SendNotificationFieldOkStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK
} from '../../store/empfaenger-field-ok.store';
import {FilterAndSortComponent, showRequestErrors} from '@softline/application';
import {UiCoreModule} from '@softline/ui-core';
import {BehaviorSubject, combineLatestWith, map} from 'rxjs';
import {SendNotificationStore, SOFTLINE_FEATURE_SEND_NOTIFICATION} from '../../store/send-notification.store';
import {EmpfaengerAuswahlComponent} from '../../pages/empfaenger-auswahl/empfaenger-auswahl.component';
import {sortConfig} from './default-empfaenger.component.sort';
import {filterConfig} from './default-empfaenger.component.filter';
import {
  EmpfaengerSuchverlaufStore,
  SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF
} from '../../store/empfeanger-suchverlauf.store';

@Component({
    selector: 'soft-default-empfaenger-auswahl',
    imports: [CommonModule, UiCoreModule, FilterAndSortComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './default-empfaenger-auswahl.component.html',
    styleUrls: ['./default-empfaenger-auswahl.component.scss']
})
export class DefaultEmpfaengerAuswahlComponent implements OnInit, OnDestroy {

  @ViewChild('header', { static: true }) private header?: TemplateRef<any>;

  private readonly selectedEmpfaengerIds$ = this.store.observe(
    SOFTLINE_FEATURE_SEND_NOTIFICATION,
    SendNotificationStore.getters.data
  ).pipe(
    map(o => (o?.empfaenger ?? []).map(empfaenger => empfaenger.id))
  );

  readonly loading$ = new BehaviorSubject(false);
  readonly input$ = new BehaviorSubject<string | null>(null);

  readonly empfaenger$ = this.store.observe(
    SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK,
    SendNotificationFieldOkStore.getters.list
  );

  readonly suchverlauf$ = this.store.observe(
    SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF,
    EmpfaengerSuchverlaufStore.getters.all
  );

  readonly filteredEmpfaneger$ = this.empfaenger$.pipe(
    combineLatestWith(this.selectedEmpfaengerIds$),
    map(([empfaenger, selectedIds]) => {
      return (empfaenger ?? [])
        .sort((a, b) => a.vorname.localeCompare(b.vorname))
        .map(empfaenger => ({
          ...empfaenger,
          selected: selectedIds.includes(empfaenger.id)
        }))
    })
  )

  readonly filterConfig = filterConfig;
  readonly sortConfig = sortConfig;

  readonly trackByFn = (index: number, empfaenger: FOKAnwender) => empfaenger.id

  constructor(
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
    private readonly filterService: FilterService,
    private readonly sortService: SortService,
    @Inject(EmpfaengerAuswahlComponent) private hostComponent: EmpfaengerAuswahlComponent
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.header) {
      this.hostComponent.attachHeader(this.header);
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.onEmptyTextfield();
  }

  async searchEmpfaenger(searchTerm: string | null): Promise<void> {
    if (!searchTerm || searchTerm?.length < 2) {
      return;
    }

    try {
      this.loading$.next(true);

      this.store.commit(
        SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF,
        EmpfaengerSuchverlaufStore.mutations.addOrUpdate,
        {
          date: new Date().toISOString(),
          text: searchTerm
        }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK,
        SendNotificationFieldOkStore.actions.query,
        {
          params: {
            filter: searchTerm ?? '',
            parameters: {
              kzinternet: 'J,E'
            },
            multiValued: false
          }
        }
      );
    } catch (e) {
      this.loading$.next(false);
      showRequestErrors(this.store, e);
    } finally {
      this.loading$.next(false);
    }
  }

  onEmptyTextfield(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK,
      SendNotificationFieldOkStore.mutations.reset
    );

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK,
      SendNotificationFieldOkStore.mutations.setList,
      []
    );

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK,
      SendNotificationFieldOkStore.mutations.setValue,
      null
    );
  }

  async selectOrUnselect(anwender: FOKAnwender): Promise<void> {
    const data = this.store.get(SOFTLINE_FEATURE_SEND_NOTIFICATION, SendNotificationStore.getters.data) ?? {}
    let empfaenger = data?.empfaenger || [];
    const selected = empfaenger.findIndex(o => o.id === anwender.id) > -1;

    if (selected) {
      empfaenger = empfaenger.filter(o => o.id !== anwender.id);
    } else {
      empfaenger = empfaenger.concat({ id: anwender.id, name: `${anwender.vorname} ${anwender.nachname}` });
    }

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.mutations.set,
      {
        ...data,
        empfaenger
      }
    );
  }
}
