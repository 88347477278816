import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";
import {Emballage} from "../../types/warenuebernahme";
import {MengenEingabeComponent} from "@softline/application";
import {Store} from "@softline/core";
import {
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE
} from "@softapps/wws/warenuebernahme";
import {WwsBewegungStore} from "../../store/bewegung.store";
import {WwsEmballageStore} from "../../store/emballage.store";

@Component({
    selector: 'soft-emballage-hinzufuegen-dialog',
    imports: [CommonModule, MengenEingabeComponent, UiCoreModule],
    templateUrl: './emballage-hinzufuegen-dialog.component.html',
    styleUrls: ['./emballage-hinzufuegen-dialog.component.scss']
})
export class EmballageHinzufuegenDialogComponent implements Modal<string>{

  close!: (result: string) => void;

  private _idwu = '';
  public selectedEmballage: any;

  readonly emballagen$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE,
    WwsEmballageStore.getters.all
  );

  constructor(private store: Store) {}

  set idwu (idwu: string) {
    this._idwu = idwu;
  }

  registerCloseHandler(handler: (result: string) => void): void {
    this.close = handler;
  }

  async emballageHinzufuegen(emballage: Emballage) {
    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
      WwsBewegungStore.actions.emballageHinzufuegen,
      {
        emballage: emballage,
        idwu: this._idwu
      }
    );
    this.close(this._idwu);
  }
}
