import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../page/page.component';
import { Application, WithRouter } from '@softline/application';
import { IconComponent, UiCorePipesModule } from '@softline/ui-core';

@Component({
  selector: 'soft-module-page',
  imports: [CommonModule, PageComponent, IconComponent, UiCorePipesModule],
  templateUrl: './module-page.component.html',
  styleUrl: './module-page.component.scss',
})
export class ModulePageComponent extends WithRouter() {
  modules = input.required<Application[]>();

  constructor() {
    super();
  }

  async onClick(module: Application): Promise<void> {
    if (module?.type === 'link') {
      window.open(module?.href, '_blank');
    } else {
      await this.router.navigate([module.route], module.routeParams);
    }
  }

  isAppModule = (app: Application) => {
    return app?.type !== 'link';
  }
}
