import { inject, Injector, Signal, TemplateRef } from '@angular/core';
import { Dismissed, ModalOption, ResolvableModalConfig } from '../types/modal-config';
import { OptionModalComponent } from '../components/option-modal/option-modal.component';
import { ModalService } from '../modal.service';
import { PasswordModalComponent } from '../components/password-modal/password-modal.component';

export interface PasswordModalConfig2 {
  title?: string | Signal<string | null>;
  subtitle?: string | Signal<string | null>;
  content?: TemplateRef<any> | Signal<TemplateRef<any> | null>;
  contentContext?: object | Signal<object | null>;
  class?: string;
  dismiss?: boolean;
}

async function password(modalService: ModalService, config: PasswordModalConfig2, injector: Injector): Promise<string | Dismissed> {
  const modalConfig: ResolvableModalConfig<PasswordModalComponent, string> = {
    component: PasswordModalComponent,
    dismiss: config.dismiss,
    title: config.title ?? 'Passwort',
    subtitle: config.subtitle,
    template: config.content,
    templateContext: config.contentContext,
    priority: Number.POSITIVE_INFINITY,
    class: config.class,
    injector: injector
  }
  return await modalService.open(modalConfig);
}

export function passwordFn(): (config: PasswordModalConfig2) => Promise<string | Dismissed> {
  const modalService = inject(ModalService);
  const injector = inject(Injector);
  return (config) => password(modalService, config, injector)
}
