import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_MDE_RETOUREN,
  SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_RETOUREN_BEWE
} from '../retouren.shared';
import {RetourenBeweStore} from '../store/retouren-bewe.store';
import {map, Subscription} from 'rxjs';
import {RetourenStore} from '../store/retouren.store';
import {RetourenArtikelDetailStore} from '../store/retouren-artikel-detail.store';

@Component({
    selector: 'soft-mde-retouren',
    templateUrl: './retouren.component.html',
    styleUrls: ['./retouren.component.scss'],
    imports: [RouterModule]
})
export class RetourenComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  readonly kopf$ = this.store.observe(
    SOFTLINE_FEATURE_MDE_RETOUREN,
    RetourenStore.getters.kopf
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Retouren'
    );

    this.subscription = this.activatedRoute.data.subscribe(async ({ retouren }) => {
        if (!retouren?.kopf) {
          await this.router.navigate(['/mde-retouren/eingabe-belegdatum'])
        } else {
          this.store.commit(
            SOFTLINE_FEATURE_MDE_RETOUREN,
            RetourenStore.mutations.setKopf,
            retouren?.kopf
          );

          await this.router.navigate(['/mde-retouren'])
        }
      });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: RetourenComponent,
        commands: [
          {
            name: 'Retouren',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-backward',
            isVisible: this.kopf$.pipe(map(o => !!o)),
            canExecute: this.kopf$.pipe(map(o => !!o)),
            routerLink: '/mde-retouren'
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MDE_RETOUREN_BEWE,
      RetourenBeweStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      RetourenComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_RETOUREN_BEWE,
      RetourenBeweStore.mutations.clear
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_RETOUREN,
      RetourenStore.mutations.setKopf,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_RETOUREN,
      RetourenStore.mutations.setLieferant,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_RETOUREN,
      RetourenStore.mutations.setEndeEtikett,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS,
      RetourenArtikelDetailStore.mutations.clear
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
