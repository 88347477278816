import {ConnectionHttpService, ApiResourceLocation, Dictionary, ResourceService} from '@softline/core';
import {Observable} from 'rxjs';

export class HttpObjectService {
  constructor(private service: ResourceService<ApiResourceLocation>, private path: string) {
  }

  get<T>(payload: unknown): Observable<T> {
    const location = {path: this.path};
    return this.service.get(location, payload);
  }
}
