import { Component, HostListener, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResolvableModal } from '../../abstraction/modal';
import { NotificationResult } from '../../types/notification-result';
import { TranslatePipe } from '../../../i18n/pipes/translate.pipe';

@Component({
  selector: 'soft-notification-modal',
  imports: [CommonModule, TranslatePipe],
  templateUrl: './notification-modal.component.html',
  styleUrl: './notification-modal.component.scss',
})
export class NotificationModalComponent extends ResolvableModal<NotificationResult>{

  text = input<string>('');
  showCancel = input<boolean>(false);

  @HostListener('window:keydown.enter')
  onClick(result: NotificationResult = 'OK'): void {
    this.resolve(result);
  }
}
