import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Definition,
  DynamicModule,
  Rule,
  ObjectDefinition,
} from '@softline/dynamic';
import { IsDefinedNotEmptyPipe, IsDefinedPipe } from '@softline/ui-core';

@Component({
    selector: 'soft-dynamic-template-field-list',
    imports: [CommonModule, DynamicModule, IsDefinedPipe, IsDefinedNotEmptyPipe],
    templateUrl: './dynamic-template-field-list.component.html',
    styleUrl: './dynamic-template-field-list.component.scss'
})
export class DynamicTemplateFieldListComponent {
  definition = input.required<ObjectDefinition>();
  value = input.required<object>();
  loading = input<boolean>(false);
}
