import { Component, Input, OnInit } from '@angular/core';
import { ArrayDefinition } from '../../../../data/definitions';
import {
  DynamicListComponent,
  DynamicObjectComponent,
  DynamicTableComponent,
} from '@softline/dynamic';
import {
  NgForOf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { ChipComponent } from '@softline/ui-core';

@Component({
  selector: 'soft-dynamic-array-value',
  templateUrl: './array.component.html',
  styleUrls: ['./array.component.scss'],
  imports: [
    DynamicTableComponent,
    NgSwitch,
    NgSwitchCase,
    NgForOf,
    ChipComponent,
    DynamicObjectComponent,
    DynamicListComponent,
    NgSwitchDefault,
  ],
})
export class ArrayComponent implements OnInit {
  @Input() values?: any[];
  @Input() definition?: ArrayDefinition;

  constructor() {}

  ngOnInit(): void {}
}
