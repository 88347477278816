import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@softline/core';
import * as ArchiveStore from '../archive.store';
import { Observable } from 'rxjs';
import { SOFTLINE_FEATURE_ARCHIVE } from '../../application.shared';

@Pipe({
    name: 'isArchiveDownloading',
    pure: true,
    standalone: false
})
export class IsDownloadingPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(fileKey: string, ...args: unknown[]): Observable<boolean> {
    return this.store.observe(
      SOFTLINE_FEATURE_ARCHIVE,
      ArchiveStore.getters.downloading,
      fileKey
    );
  }
}
