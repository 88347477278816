import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesPrice } from '../../types/sales-price';
import { L10nModule } from '@softline/ui-core';
import { PriceCardComponent } from '../price-card/price-card.component';

@Component({
    selector: 'soft-sales-price-card',
    imports: [CommonModule, L10nModule, PriceCardComponent],
    templateUrl: './sales-price-card.component.html',
    styleUrl: './sales-price-card.component.scss'
})
export class SalesPriceCardComponent {
  salesPrice = input.required<SalesPrice>();
}
