import { Component, inject, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import {
  showRequestErrors,
  MenuItem,
  RefreshService,
  WithCommands,
  WithMenuItems,
  WithTitle
} from '@softline/application';
import { RouterModule } from "@angular/router";
import {
  GesamtabfrageConfig,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART
} from "../gesamtabfrage.shared";
import { DefinitionStore, SOFTLINE_FEATURE_DEFINITIONS } from "@softline/dynamic";
import {
  SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
  SOFTLINE_DEFINITION_VOUCHER_LIST,
  SOFTLINE_DEFINITION_VOUCHER_QUERY
} from "../gesamtabfrage.api";
import { BelegartStore } from "../store";
import { Store } from '@softline/core';
import { WithBelegart } from '../mixins/belegart.mixin';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-gesamtabfrage',
    templateUrl: './gesamtabfrage.component.html',
    styleUrls: ['./gesamtabfrage.component.scss'],
    imports: [RouterModule]
})
export class GesamtabfrageComponent
  extends
    WithBelegart(
    WithMenuItems(
  ))
  implements OnInit, OnDestroy {

  store = inject(Store);

  override menuItems = signal<MenuItem[]>([
    {
      type: 'route',
      name: 'gesamtabfrage',
      outlet: 'nav',
      icon: 'fa-regular fa-file-invoice',
      title: '#GESAMTABFRAGE.MENU.TITLE',
      routerLink: ['/gesamtabfrage'],
      priority: 1,
    },
  ])

  constructor(
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    try {
      await this.belegartStore.remote.loadMany({clear: true})
    } catch (e) {
      showRequestErrors(this.store, e);
    }
    await this.loadDefinitions();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  async loadDefinitions(): Promise<void> {
    try {
      const promises: Promise<any>[] = [
        this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: SOFTLINE_DEFINITION_VOUCHER_LIST }
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: SOFTLINE_DEFINITION_VOUCHER_QUERY }
        ),
      ];
      if (this.config.geschaeftsfall)
        promises.push(
          this.store.dispatch(
            SOFTLINE_FEATURE_DEFINITIONS,
            DefinitionStore.actions.loadOnce,
            { name: SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE }
          )
        );

      await Promise.all(promises);
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
