import {
  afterRenderEffect,
  Directive,
  ElementRef,
  inject,
  input,
  signal, untracked
} from '@angular/core';

@Directive({
  selector: '[softHighlightText]',
})
export class HighlightTextDirective {
  private elementRef = inject(ElementRef);

  readonly inputElement = signal<HTMLInputElement | null>(null);

  readonly softHighlightTextSelector = input<string>('input');

  constructor() {
    afterRenderEffect((onCleanup) => {
      const querySelectorString = this.softHighlightTextSelector();
      this.inputElement.set(
        this.findFirstElement(
          this.elementRef.nativeElement as HTMLElement,
          querySelectorString
        )
      );

      untracked(() => {
        const element = this.inputElement();
        if (element) {
          element?.addEventListener?.('focus', this.focusElement);
          onCleanup(() => {
            element?.removeEventListener('focus', this.focusElement);
            this.inputElement.set(null);
          });
        }
      })
    });
  }

  focusElement = () => {
    this.inputElement()?.select?.();
  };

  private findFirstElement(
    host: HTMLElement,
    selector: string
  ): HTMLInputElement | null {
    let element = host.querySelector(selector) as HTMLInputElement | null;

    if (element) return element;

    if (host?.children?.length > 0) {
      for (const child of Array.from(host.children)) {
        element = this.findFirstElement(child as HTMLElement, selector);
        if (element) return element;
      }
    }

    return element;
  }
}
