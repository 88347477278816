import { Component, EventEmitter, Inject, Input, OnInit, Type } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldOkQueryComponent, FieldOkViewComponent } from "../../../../data/field-ok";
import { FieldOkItemOutletDirective } from "../../../../directives/field-ok-item-outlet.directive";
import { FieldOkInputParams, FieldOkStrategy } from "../../strategies/field-ok.strategy";
import { Entity, isDefined, SOFTLINE_DATA_VALUE } from "@softline/core";
import { FormGroup } from "@angular/forms";

export interface FieldOkQueryViewConfig {
  component: Type<FieldOkQueryComponent<any>>;
}

@Component({
    selector: 'soft-field-ok-query-view',
    imports: [CommonModule, FieldOkItemOutletDirective],
    templateUrl: './field-ok-query-view.component.html',
    styleUrls: ['./field-ok-query-view.component.scss']
})
export class FieldOkQueryViewComponent  implements OnInit, FieldOkViewComponent<FieldOkQueryViewConfig>  {

  query: object = {};

  @Input() config!: FieldOkQueryViewConfig;

  requestSubmit = new EventEmitter<void>();

  querySubmit = async (params: object) => {
    this.query = params;
    const query = {...this.query};
    for (const [key, value] of Object.entries(query))
      if(!isDefined(value))
        delete query[key];
    await this.strategy.query({
      ...this.options.parameters, ...query
    });
  }

  queryChange = async (params: object) => {
    this.query = params;
  }

  constructor(public strategy: FieldOkStrategy<any, any>,
              @Inject(SOFTLINE_DATA_VALUE)private options: FieldOkInputParams<Entity, object>) {
  }

  async ngOnInit(): Promise<void> {
  }
}
