import { ImportedNgModuleProviders, importProvidersFrom, inject, Provider, provideEnvironmentInitializer } from '@angular/core';
import {
  ConnectionHttpService, SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from '@softline/ui-core';
import {
  ApplicationStore,
  SOFTLINE_CONFIG_OFFLINE_FEATURE,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import {default as de} from '../i18n/de.json';
import {SOFTLINE_API_DELIVERY_NOTES} from './delivery-note.api';
import {SOFTLINE_FEATURE_DELIVERY_NOTE, SOFTLINE_FEATURE_RETURN_NOTE, SOFTLINE_PERMISSION_LIEFERSCHEIN} from './delivery-note.shared';
import {ReturnNoteStore} from './stores/return-note.store';
import { DeliveryNotesStore } from './stores';

export const lieferscheinProviders: (Provider | ImportedNgModuleProviders)[] = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DELIVERY_NOTE,
      feature: DeliveryNotesStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, SOFTLINE_API_DELIVERY_NOTES),
          deps: [SOFTLINE_SERVICE_HTTP],
        }
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_RETURN_NOTE,
      feature: ReturnNoteStore.feature,
      providers: [],
    }),
  ),
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'lieferschein',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#DELIVERY_NOTE.TITLE',
        icon: 'fa-regular fa-truck-arrow-right',
        route: '/lieferscheine',
        permission: SOFTLINE_PERMISSION_LIEFERSCHEIN
      });
    }), {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: {featureName: SOFTLINE_FEATURE_DELIVERY_NOTE, fields: ['ids', 'entities', 'preparedChanges', 'viewedDate']},
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_RETURN_NOTE, fields: ['ids', 'entities', 'preparedChanges']},
    multi: true
  }
];
