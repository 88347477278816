import { Component, OnInit } from '@angular/core';
import { DateService, Store } from '@softline/core';
import { SOFTLINE_FEATURE_APP_INFO } from '../../../application.shared';
import moment from 'moment';
import { Observable } from "rxjs";
import {AppInfo} from '../../data/app-info';

@Component({
    selector: 'lib-app-info',
    templateUrl: './app-info-settings.component.html',
    styleUrls: ['./app-info-settings.component.scss'],
    standalone: false
})
export class AppInfoSettingsComponent implements OnInit {
  year?: string;
  appInfo$: Observable<AppInfo> = this.store.observe(SOFTLINE_FEATURE_APP_INFO);

  constructor(private store: Store, private dateService: DateService) {}

  ngOnInit(): void {
    this.year = moment(this.dateService.today()).format('YYYY');
  }
}
