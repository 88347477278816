import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule, Validators } from "@softline/ui-core";
import { SOFTLINE_FEATURE_DEVELOPER } from "../developer.shared";
import { DeveloperStore } from "../developer.store";
import { Store } from "@softline/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
    selector: 'soft-admin-settings',
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
    templateUrl: './developer-settings.component.html',
    styleUrls: ['./developer-settings.component.scss']
})
export class DeveloperSettingsComponent implements OnInit {

  isDeveloper$ = this.store.observe(SOFTLINE_FEATURE_DEVELOPER, DeveloperStore.getters.isDeveloper);

  form = new FormGroup({
    password: new FormControl<string | null>(null, [Validators.required()]),
    remember: new FormControl<boolean>(false)
  })

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
  }

  async onSubmit(): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEVELOPER,
        DeveloperStore.actions.su,
        {password: this.form.value.password, remember: this.form.value.remember}
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        { message: "#APPLICATION.DEVELOPER.SETTINGS.LOGIN_SUCCESS", group: 'developer_login' }
      );
      // await this.router.navigate(['/']);

      this.form.reset();
    }
    catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        { message: "#APPLICATION.DEVELOPER.SETTINGS.LOGIN_ERROR", group: 'developer_login' }
      );
    }
  }

  async onExit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEVELOPER,
      DeveloperStore.actions.exit,
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      { message: "#APPLICATION.DEVELOPER.SETTINGS.LOGOUT_SUCCESS", group: 'developer_login' }
    );
  }

}
