import { Component, HostListener, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '../../../i18n/pipes/translate.pipe';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { ResolvableModal } from '../../abstraction/modal';
import { QuestionResult } from '../../types/question-result';

@Component({
  selector: 'soft-question-modal',
  imports: [CommonModule, TranslatePipe, UiCorePipesModule],
  templateUrl: './question-modal.component.html',
  styleUrl: './question-modal.component.scss',
})
export class QuestionModalComponent extends ResolvableModal<QuestionResult> {

  showCancel = input(false);

  @HostListener('window:keydown.enter')
  onClick(result: QuestionResult = 'YES'): void {
    this.resolve(result);
  }
}
