import { InjectionToken, Provider, signal, Signal, WritableSignal } from '@angular/core';
import { parseDuration } from './parser/duration.parser';
import { parseNumber } from './parser/number.parser';
import { exp } from 'mathjs';

export type Parser<T, U> = (input: T, locale?: string) => U | null;

const DEFAULT_DURATION = 10000;

export const SOFTLINE_SERVICE_DURATION_PARSER = new InjectionToken<
  Parser<string, string>
>('DURATION_PARSER', {
  providedIn: 'root',
  factory: () => parseDuration,
});
export const SOFTLINE_SERVICE_NUMBER_PARSER = new InjectionToken<
  Parser<string, number>
>('NUMBER_PARSER', {
  providedIn: 'root',
  factory: () => parseNumber,
});
export const SOFTLINE_CONFIG_MESSAGE_DURATION = new InjectionToken<number>(
  'SOFTLINE_CONFIG_MESSAGE_DURATION',
  {
    providedIn: 'root',
    factory: () => DEFAULT_DURATION,
  }
);

export const SOFTLINE_CONFIG_WIDGETS = new InjectionToken(
  'SOFTLINE_CONFIG_WIDGETS'
);

export const SOFTLINE_DATA_CONTEXT = new InjectionToken<unknown>(
  'SOFTLINE_DATA_CONTEXT'
);

export const SOFTLINE_CONFIG_DEFAULT_CURRENCY = new InjectionToken(
  'SOFTLINE_CONFIG_DEFAULT_CURRENCY'
);

export interface PreviewHandler {
  priority?: number;
  canOpen(value: unknown): boolean;
  open(value: unknown): Promise<void>;
}

export const SOFTLINE_CONFIG_PREVIEW_HANDLER = new InjectionToken<
  PreviewHandler[]
>('SOFTLINE_CONFIG_PREVIEW_HANDLER');


export interface MasterDetailConfig {
  autoHeight: boolean;
}

export const SOFTLINE_MASTER_DETAIL_HEIGHT = new InjectionToken<WritableSignal<number | null>>('SOFTLINE_MASTER_DETAIL_HEIGHT');
export const SOFTLINE_MASTER_DETAIL_CONFIG = new InjectionToken<WritableSignal<MasterDetailConfig>>('SOFTLINE_MASTER_DETAIL_CONFIG');

export function provideMasterDetailConfig(value: MasterDetailConfig): Provider {
  return {
    provide: SOFTLINE_MASTER_DETAIL_CONFIG,
    useValue: signal(value)
  }
}
