import { ChangeDetectionStrategy, Component, contentChild, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-drag-and-drop-select-item',
    imports: [CommonModule],
    templateUrl: './drag-and-drop-select-item.component.html',
    styleUrl: './drag-and-drop-select-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragAndDropSelectItemComponent<T> {
  value = input.required<T>();
  template = viewChild<TemplateRef<any>>('template');
}
