import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    pure: true,
    standalone: false
})
export class ReplacePipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    find: string | RegExp,
    replace: string | ((substring: string, ...args: any[]) => string)
  ): string {
    if(!value)
      return '';
    if (typeof replace === 'string')
      return value.replace(new RegExp(find, 'g'), replace);
    return value.replace(find, replace);
  }
}
