import { createGetter, createMutation, mutate, select } from '../../factories';
import { StoreFeature } from '../../store';
import { Dictionary } from '../../../types/dictionary';
import { isDefined } from '../../../functions/is-defined.function';
import { Observable } from 'rxjs';
import { StoreCommit } from '../../abstraction';
import { RequestEvent } from '../../../data-access/types/request';

export interface Progress {
  loaded: number;
  total: number | null;
}

export interface State {
  progresses: Dictionary<Progress>;
}

export const mutations = {
  report: createMutation<State, { key: string; progress: Progress }>(
    'report progress'
  ),
  clear: createMutation<State>('clear progresses'),
};

export const getters = {
  progress: createGetter<
    State,
    Progress & { percent: number },
    string | undefined
  >('progress'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    progresses: {},
  },
  mutations: [
    mutate(mutations.report, ({ state, params }) => {
      const progresses = { ...state.progresses };
      progresses[params.key] = params.progress;
      return { ...state, progresses };
    }),
    mutate(mutations.clear, ({ state }) => ({ ...state, progresses: {} })),
  ],
  getters: [
    select(getters.progress, ({ state, params }) => {
      let progress: Progress | undefined;
      if (!isDefined(params)) {
        progress = Object.values(state.progresses).reduce((p, c) => ({
          loaded: (p?.loaded ?? 0) + (c?.loaded ?? 0),
          total: (p?.total ?? 0) + (c?.total ?? 0),
        }));
      } else progress = state.progresses[params];

      if (progress)
        return {
          ...progress,
          percent: isDefined(progress.total)
            ? (progress.loaded ?? 0) / progress.total
            : 0,
        };
      else return { loaded: 0, total: 0, percent: 0 };
    }),
  ],
  actions: [],
};

export function handleProgressState<T>(
  observable: Observable<RequestEvent<T>>,
  featureName: string,
  commit: StoreCommit,
  token: string
): Observable<T> {
  if (isDefined(token))
    commit(featureName, mutations.report, {
      key: token,
      progress: { loaded: 0, total: null },
    });

  return new Observable<T>((observer) => {
    observable.subscribe(
      (o) => {
        switch (o.type) {
          case 'progress':
            commit(featureName, mutations.report, {
              key: token,
              progress: { loaded: o.loaded, total: o.total },
            });
            break;
          case 'response':
            if (isDefined(o.response)) observer.next(o.response);
            observer.complete();
            break;
          default:
            break;
        }
      },
      (error) => observer.error(error)
    );
  });
}
