import { Component, contentChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../page/page.component';
import { PageHeaderComponent } from '../page/page-header/page-header.component';

@Component({
    selector: 'soft-edit-page',
    imports: [CommonModule, PageComponent, PageHeaderComponent],
    templateUrl: './edit-page.component.html',
    styleUrl: './edit-page.component.scss'
})
export class EditPageComponent {
  header = contentChild(PageHeaderComponent, { descendants: true });
}
