import { EnvironmentInjector, inject, Injector, InputSignal, Type } from '@angular/core';
import { Dismissed, ModalConfig } from '../types/modal-config';
import { ModalService } from '../modal.service';
import { Modal2, ResolvableModal } from '../abstraction/modal';

async function modal<TModal extends Modal2, TResult>(modalService: ModalService, config: ModalConfig<TModal>, injector: Injector): Promise<TModal extends ResolvableModal<TResult> ? (TResult | Dismissed) : Dismissed> {
  const modalConfig = {
    component: config.component,
    dismiss: config.dismiss,
    title: config.title,
    subtitle: config.subtitle,
    template: config.template,
    templateContext: config.templateContext,
    priority: Number.POSITIVE_INFINITY,
    class: config.class,
    data: config.data,
    injector: config.injector ?? injector
  }
  return await modalService.open(modalConfig);
}


type ExtractResult<Type> = Type extends ResolvableModal<infer X> ? (X | Dismissed) : Dismissed;

export function modalFn(): <TModal extends Modal2>(config: ModalConfig<TModal>) => Promise<ExtractResult<TModal>> {
  const modalService = inject(ModalService);
  const injector = inject(EnvironmentInjector);
  return (config) => modal(modalService, config, injector);
}
