import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MitarbeiterCardComponent} from '../../mitarbeiter-card/mitarbeiter-card.component';
import {combineLatestWith, map, Observable} from 'rxjs';
import {FilterService, SortService, Store} from '@softline/core';
import {PersonalUebersicht} from '../../../types/abteilung';
import {SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER} from '../../../statusabfrage.shared';
import {PmsPersonalStore} from '../../../store/personal.store';
import {
  Empfaenger,
  SendNotificationStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION
} from '@softapps/allgemein/send-notification';

@Component({
    selector: 'soft-empfaenger-user-list',
    imports: [CommonModule, MitarbeiterCardComponent],
    templateUrl: './empfaenger-user-list.component.html',
    styleUrls: ['./empfaenger-user-list.component.scss']
})
export class EmpfaengerUserListComponent implements OnInit {

  readonly sort$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
    PmsPersonalStore.getters.sort
  );

  readonly filter$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
    PmsPersonalStore.getters.filter
  );

  readonly filteredMitarbeiter$: Observable<{ mitarbeiter: PersonalUebersicht; selected: boolean; }[]> = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
    PmsPersonalStore.getters.all
  ).pipe(
    combineLatestWith(
      this.sort$,
      this.filter$,
      this.store.observe(SOFTLINE_FEATURE_SEND_NOTIFICATION, SendNotificationStore.getters.data).pipe(
        map(o => ((o as any)?.empfaenger ?? [])?.map(o => o?.id))
      )
    ),
    map(([mitarbeiter, sort, filter, selectedIds]) => {
      return this.sortService
        .sort(this.filterService.filter(mitarbeiter, filter), sort)
        .map(ma => ({
          mitarbeiter: ma,
          selected: !!selectedIds.includes(ma?.anwender?.id ?? ma.id)
        }))
    })
  );

  readonly loaded$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
    PmsPersonalStore.getters.loaded,
  );

  readonly trackByMitarbeiterId = (
    index: number,
    data: { mitarbeiter: PersonalUebersicht; selected: boolean }
  ) => data.mitarbeiter.id;

  constructor(
    private readonly store: Store,
    private readonly sortService: SortService,
    private readonly filterService: FilterService
  ) {}

  ngOnInit(): void {}

  async mitarbeiterTapped(mitarbeiter: PersonalUebersicht): Promise<void> {
    const data = this.store.get(SOFTLINE_FEATURE_SEND_NOTIFICATION, SendNotificationStore.getters.data) ?? {}
    let empfaenger: Empfaenger[] = (data as any)?.empfaenger ?? [];

    if (empfaenger.find(o => o.id === mitarbeiter.anwender.id)) {
      empfaenger = empfaenger.filter(o => o.id !== mitarbeiter.anwender.id)
    } else {
      empfaenger = empfaenger.concat({
        id: mitarbeiter.anwender.id,
        name: `${mitarbeiter.lstfix.vorname} ${mitarbeiter.lstfix.zuname}`
      })
    }

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.mutations.set,
      {
        ...data,
        empfaenger
      }
    );
  }
}
