import { inject, Provider, provideEnvironmentInitializer, EnvironmentProviders } from '@angular/core';
import { Store } from '@softline/core';
import { CommandStore, SOFTLINE_FEATURE_COMMANDS } from '@softline/application';

export const settingsProviders: Array<Provider | EnvironmentProviders> = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
        name: 'settings',
        commands: [
          {
            name: '#UTILITIES.SETTINGS.TITLE',
            routerLink: ['settings'],
            icon: 'fa-regular fa-gear',
            class: 'menu main-menu main-menu-bottom',
          },
        ],
      });
    }),
];
