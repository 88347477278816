import { computed, Injectable } from '@angular/core';
import { InjectableStore2Feature } from '../../injectable-store2-feature';
import { Dictionary } from '../../../types/dictionary';
import { isDefined } from '../../../functions/is-defined.function';

@Injectable()
export class DictionaryStore2<T> extends InjectableStore2Feature<Readonly<Dictionary<T>>> {

  values = computed(() => Object.values(this.state()) as T[]);
  entries = computed(() => Object.entries(this.state()) as [string, T]);
  keys = computed(() => Object.keys(this.state()));

  constructor() {
    super();
  }

  set(value: Dictionary<T>): void {
    this.commit({...value});
  }

  setItem(key: string | number, value: T): void {
    this.commit({...this.state(), [key]: value});
  }

  removeItem(key: string | number): void {
    let state = this.state() as Dictionary<T>;
    if (!isDefined(state[key]))
      throw new Error(
        `DictionaryStore: Can not process mutation 'remove'. Can not find item with key '${JSON.stringify(
          key
        )}'`
      );
    state = {...state};
    delete state[key];
    this.commit(state);
  }

  clear(): void {
    this.commit({ });
  }

  override getDefaultState(): Dictionary<T> {
    return {};
  }
}
