import { Component, computed, inject, OnInit, signal } from '@angular/core';
import {
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART
} from "../../gesamtabfrage.shared";
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { showRequestErrors, WithRefresh, WithStaticBackNavigation, WithTitle } from '@softline/application';
import { BelegStore } from "../../store/beleg.store";
import { WithBelegart } from '../../mixins/belegart.mixin';
import { Store } from '@softline/core';
import { BelegartStore } from '../../store';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-belegarten',
    templateUrl: './belegarten.component.html',
    styleUrls: ['./belegarten.component.scss'],
    imports: [CommonModule, RouterModule, UiCoreModule]
})
export class BelegartenComponent
  extends
    WithTitle(
    WithRefresh(
    WithStaticBackNavigation('/')
  ))
  implements OnInit {

  route = inject(ActivatedRoute)
  belegartStore = inject(BelegartStore);

  belegartName = toSignal(this.route.paramMap.pipe(
      map((o) => o.get('type') ?? '')
    ), {initialValue: ''}
  );

  belegarten = this.belegartStore.collection.all;
  belegart = computed(() => this.belegartStore.collection.dict()[this.belegartName()]);
  belegart$ = toObservable(this.belegart);

  store = inject(Store);

  override title = signal('#GESAMTABFRAGE.TITLE');
  override subtitle = signal('#GESAMTABFRAGE.PAGES.TYPE.HEADER');

  constructor() {
    super()
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.query.setQuery, undefined);
  }

  override async refresh(): Promise<void> {
    try {
      await this.belegartStore.remote.loadMany();
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
