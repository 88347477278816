import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@softline/core';
import { ScannerModule } from '@softline/application';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { ActionInputComponent } from './components/atoms/inputs/action/action.component';
import { EntityInputComponent } from './components/atoms/inputs/entity/entity-input.component';
import { ArrayInputComponent } from './components/atoms/inputs/array/array-input.component';
import { ActionComponent } from './components/atoms/values/action/action.component';
import { IconComponent } from './components/atoms/values/icon/icon.component';
import { ArrayComponent } from './components/atoms/values/array/array.component';
import { ObjectComponent } from './components/atoms/values/object/object.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { DynamicObjectComponent } from './components/dynamic-object/dynamic-object.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicValueComponent } from './components/dynamic-value/dynamic-value.component';
import { FieldOkComponent } from './components/field-ok/field-ok.component';
import { DynamicInputFieldDirective } from './directives/dynamic-input-field.directive';
import { FormatRulePipe } from './pipes/format-rule.pipe';
import { PropertyClassPipe } from './pipes/property-class.pipe';
import { FieldOkInputComponent } from './components/atoms/inputs/field-ok/field-ok-input.component';
import { DefinitionService } from './services/definition.service';
import { GroupComponent } from './components/atoms/values/group/group.component';
import * as DynamicActionStore from './dynamic-action.store';
import * as FieldOkComponentStore from './field-ok-component.store';
import * as DefinitionStore from './definition.store';
import {
  SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER,
  SOFTLINE_CONFIG_DEFAULT_FIELD_OK_VIEW,
  SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS,
  SOFTLINE_FEATURE_DEFINITIONS,
  SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
  SOFTLINE_FEATURE_FIELD_OK,
  SOFTLINE_FEATURE_GROUP_SELECTED_FIELDS,
  SOFTLINE_PROVIDER_FIELD_OK_CONFIG,
  TYPE_ACTION_COMPONENT,
  TYPE_ACTION_INPUT_COMPONENT,
  TYPE_CONTAINER_COMPONENT,
  TYPE_ENTITY_INPUT_COMPONENT,
  TYPE_FIELD_OK_INPUT_COMPONENT,
  TYPE_LIST_COMPONENT,
  TYPE_LIST_INPUT_COMPONENT,
  TYPE_OBJECT_COMPONENT,
} from './dynamic.shared';
import { DynamicValueFieldDirective } from './directives/dynamic-value-field.directive';
import { FieldOkComponentService } from './services/field-ok-component.service';
import { DynamicFilterComponent } from './components/dynamic-filter/dynamic-filter.component';
import { ContainerComponent } from './components/atoms/values/container/container.component';
import { DynamicSkeletonComponent } from './components/dynamic-skeleton/dynamic-skeleton.component';
import { default as de } from '../i18n/de.json';
import { PermissionRuleResolver } from './rules/permission.rule';
import { fieldOkConfigFactory } from './services/field-ok-config.factory';
import { DynamicPipesModule } from './pipes/dynamic-pipes.module';
import { FieldOkStrategyFactory } from './components/field-ok/strategies/field-ok-strategy.factory';
import { MultiselectFieldOkComponent } from './components/multiselect-field-ok/multiselect-field-ok.component';
import { QueryFieldOkComponent } from './components/query-field-ok/query-field-ok.component';
import { GroupSelectedFieldsStore } from './store/group-selected-fields.store';
import { FormControlPipe } from './pipes/form-control-name.pipe';
import { MetaModule } from './meta/meta.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UiCoreModule,
    ScrollingModule,
    ScannerModule,
    MetaModule,

    // Components
    DynamicTableComponent,
    DynamicValueComponent,
    DynamicSkeletonComponent,
    FieldOkComponent,
    MultiselectFieldOkComponent,
    QueryFieldOkComponent,
    DynamicFormComponent,
    DynamicListComponent,
    DynamicObjectComponent,
    DynamicFilterComponent,
    IconComponent,

    GroupComponent,

    //Directives
    DynamicInputFieldDirective,
    DynamicValueFieldDirective,

    //Pipes
    DynamicPipesModule,
    FormControlPipe,
  ],
  providers: [DefinitionService, FormatRulePipe, FieldOkStrategyFactory],
  exports: [
    DynamicFormComponent,
    DynamicTableComponent,
    DynamicListComponent,
    DynamicObjectComponent,
    DynamicSkeletonComponent,
    DynamicValueComponent,
    DynamicFilterComponent,
    FieldOkComponent,
    MultiselectFieldOkComponent,
    QueryFieldOkComponent,
    IconComponent,
    PropertyClassPipe,
    DynamicPipesModule,
    GroupComponent,
    MetaModule,
    DynamicInputFieldDirective,
  ],
})
export class DynamicModule {
  static forRoot(): ModuleWithProviders<DynamicRootModule> {
    return {
      ngModule: DynamicRootModule,
      providers: [
        FieldOkComponentService,
        {
          provide: TYPE_FIELD_OK_INPUT_COMPONENT,
          useValue: FieldOkInputComponent,
        },
        {
          provide: TYPE_ENTITY_INPUT_COMPONENT,
          useValue: EntityInputComponent,
        },
        { provide: TYPE_LIST_INPUT_COMPONENT, useValue: ArrayInputComponent },
        { provide: TYPE_OBJECT_COMPONENT, useValue: ObjectComponent },
        { provide: TYPE_LIST_COMPONENT, useValue: ArrayComponent },
        { provide: TYPE_ACTION_COMPONENT, useValue: ActionComponent },
        {
          provide: TYPE_ACTION_INPUT_COMPONENT,
          useValue: ActionInputComponent,
        },
        { provide: TYPE_CONTAINER_COMPONENT, useValue: ContainerComponent },
        {
          provide: SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER,
          useClass: PermissionRuleResolver,
          multi: true,
        },
        { provide: SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS, useValue: false },
        {
          provide: SOFTLINE_PROVIDER_FIELD_OK_CONFIG,
          useValue: fieldOkConfigFactory,
        },
        {
          provide: SOFTLINE_CONFIG_DEFAULT_FIELD_OK_VIEW,
          useValue: {
            type: 'data',
            title: 'Abfrage',
          },
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    DynamicModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DEFINITIONS,
      feature: DefinitionStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
      feature: DynamicActionStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_FIELD_OK,
      feature: FieldOkComponentStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_GROUP_SELECTED_FIELDS,
      feature: GroupSelectedFieldsStore.feature,
    }),
  ],
  exports: [DynamicModule],
})
export class DynamicRootModule {
  constructor(store: Store) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'dynamic',
      language: 'de',
      translations: de,
    });
  }
}
