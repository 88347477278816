@let config = this.config();

<div class="relative"
     (softClickOutside)="dismiss($event);"
     [delayClickOutsideInit]="true"
     [clickOutsideEvents]="['mousedown']">
  <div class="flex flex-row flex-nowrap justify-between gap-2">
    @if(title(); as title) {
      <h4 class="text-xl font-semibold mt-3 ml-3 sm:ml-0 sm:mt-0">{{ title }}</h4>
    } @else {
      <div></div>
    }
    @if (config.dismiss !== false) {
      <div class="flex items-center justify-center size-7 hover:bg-hover rounded-full text-center cursor-pointer" (click)="dismiss($event)">
        <i class="fa-regular fa-xmark font-semibold opacity-50 group-hover:opacity-100"></i>
      </div>
    }
  </div>
  @if (subtitle(); as subtitle) {
    <h6 class="mb-1 mt-0.5 ml-3 mr-3 border-box color-light sm:ml-0">{{ subtitle }}</h6>
  }
  @if (text(); as text) {
    <div class="pr-4 pl-4 mt-0.5 pb-4 sm:pl-0">{{ text }}</div>
  }
  @if (template(); as content) {
    <p class="mb-1 mt-0.5 ml-3 mr-3 sm:ml-0 border-box">
      <ng-container *ngTemplateOutlet="content; context: (templateContext())"></ng-container>
    </p>
  }
  @if (config.component) {
    <ng-container *ngComponentOutlet="config.component; inputs: componentInputs(); injector: injector()"></ng-container>
  }
</div>
