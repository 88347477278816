<div [softScrollIntoView]="true" scrollBehavior="instant"></div>

<ng-container *ngIf="(loading$ | async) === false; else skeleton">
  <div class="text-2xl font-bold mt-3 mb-4">
    {{'#WWS_WARENUEBERNAHME.DETAIL.TITEL_WARENUEBERNAHME' | translate}}
  </div>
  <ng-container *ngIf="(warenuebernahme$ | async) as warenuebernahme">
    <soft-kopfdaten [warenuebernahme]="warenuebernahme"></soft-kopfdaten>
    <ng-container *ngIf="(warenuebernahmeBewegung$ | async) as bewegung">

      @if (config.lagerplatzauswahl) {

        <div  class="flex flex-col xs:flex-row xs:justify-between mt-4">
          <div class="flex flex-col justify-center w-full">
            <div class="font-bold text-sm soft-overline">
              {{'#WWS_WARENUEBERNAHME.DETAIL.TITEL_LAGERPLATZ_AUSWAHL' | translate}}
            </div>
          </div>
          <div class="flex w-full xs:items-end xs:justify-end">
            @if((lagerplatzAuswahlLagerplaetze()?.length ?? 0) > 0) {
              <soft-select
                #selectFilter
                [value]="selectedLagerplatzAuswahl()"
                (valueChange)="selectedLagerplatzChanged(warenuebernahme, $event)"
                class="h-full border border-primary-400 p-2 xs:w-48 lg:w-1/2">
                <soft-select-option [value]="lagerplatz" *ngFor="let lagerplatz of lagerplatzAuswahlLagerplaetze()">
                  {{lagerplatz.lagerplatz }}
                </soft-select-option>
              </soft-select>
            } @else {
              <div class="text-lighter text-sm italic">
                Es wurden keine Lagerplätze gefunden...
              </div>
            }
          </div>
        </div>
      }
      <div  class="flex flex-row justify-between mt-4">
        <div class="flex flex-col w-full">
          <div class="font-bold text-sm soft-overline">
            {{'#WWS_WARENUEBERNAHME.DETAIL.TITEL_POSITIONEN' | translate}}
          </div>

          <div class="text-lighter text-sm">
            {{bewegung?.offeneBewegungen?.length}} {{'#WWS_WARENUEBERNAHME.DETAIL.LABEL_OFFEN' | translate}} /
            {{bewegung?.erledigteBewegungen?.length}} {{'#WWS_WARENUEBERNAHME.DETAIL.LABEL_ERLEDIGT' | translate}}
          </div>
        </div>
        <div class="flex w-full items-end justify-end">
          <soft-select
            #selectFilter
            [value]="visibleStatus$ | async"
            (valueChange)="visibleStatus$.next(selectFilter.value)"
            class="h-full border border-primary-400 p-2 w-48 lg:w-1/2">
            <soft-select-option [value]="filterOption" *ngFor="let filterOption of filterValues">
              {{filterOption.bezeichnung | translate }}
            </soft-select-option>
          </soft-select>
        </div>
      </div>

      <soft-bewegung-list
        [bewegungen]="(bewegungen$ | async) ?? []"
        [hasReklamationPermission]="hasReklamationPermission()"
        (statusChange)="updateBeweStatus($event)"
        (mengeChange)="updateMengeLocal($event)"
        (mengenEingabeOeffnen)="openMengeDialog($event)"
        (actionClick)="openActionDialog($event)"
      ></soft-bewegung-list>

      <button class="soft-button secondary w-full h-[50px] mt-4 mb-3 rounded-sm"
              (click)="openEmballageHinzufuegenDialog()">
        <span class="mr-2">+</span> {{'#WWS_WARENUEBERNAHME.DETAIL.LABEL_EMBALLAGEN_HINZUFUEGEN' | translate}}
      </button>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #skeleton>
  <div class="flex flex-col w-full mt-4">
    <div class="skeleton rounded h-[32px] w-48 mb-3"></div>
    <div class="skeleton rounded h-[20px] w-16 mb-2"></div>
    <div class="skeleton rounded h-[157px] w-full mb-3"></div>
    <div class="flex flex-row w-full justify-between">
      <div class="flex flex-col w-auto">
        <div class="skeleton rounded h-[18px] w-24 mb-2" *softRepeat="2"></div>
      </div>
      <div class="flex flex-col w-auto">
        <div class="skeleton rounded h-[44px] w-[192px] mb-2"></div>
      </div>
    </div>
    <div class="skeleton rounded h-[170px] w-full mb-3" *softRepeat="5"></div>
  </div>
</ng-template>

@if(abschliessenLoading() || palettenService.isLoading()) {
  <soft-blocking-loading-spinner></soft-blocking-loading-spinner>
}
