<soft-field-ok
  name="ktostamm.kunde"
  placeholder="Kunde"
  [title]="configuration()?.title ?? ''"
  [subtitle]="configuration()?.subtitle ?? ''"
  [parameters]="parameters()"
  [readonly]="readonly()"
  [value]="value()"
  (valueChange)="valueChange.emit($event)">
</soft-field-ok>
