import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationStyleMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CarouselStrategy } from './strategies/carousel.strategy';
import { CommonCarouselStrategy } from './strategies/common-carousel.strategy';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-carousel',
    imports: [CommonModule],
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent<T> implements OnInit {
  private inTransition = false;
  private _strategy: CarouselStrategy<T> = new CommonCarouselStrategy();

  @Input()
  get strategy(): CarouselStrategy<T> {
    return this._strategy;
  }
  set strategy(value: CarouselStrategy<T>) {
    this._strategy = value;
    this.items$.next(value.getStart(this.items));
    this.canNext$.next(value.canNext(this.items));
    this.canPrevious$.next(value.canPrevious(this.items));
  }

  @Input() trackByKey?: keyof T;
  @Input() template!: TemplateRef<any>;
  @Input() fluid = false;

  @Input() animation: 'slide' | 'fade' | 'none' = 'none';
  @Input() animationDuration = 250;
  @Input() animationEasing:
    | 'ease'
    | 'ease-out'
    | 'ease-in'
    | 'linear'
    | string = 'ease';

  visibleItems: T[] = [];
  items$ = new BehaviorSubject<T[]>([]);
  canNext$ = new BehaviorSubject<boolean>(false);
  canPrevious$ = new BehaviorSubject<boolean>(false);

  _items: T[] = [];

  @Input()
  get items(): T[] {
    return this._items;
  }
  set items(value: T[]) {
    this._items = value;
    const startItems = this.strategy.getStart(value);
    this.visibleItems = startItems;
    this.items$.next(startItems);
    this.canNext$.next(this.strategy.canNext(value));
    this.canPrevious$.next(this.strategy.canPrevious(value));
  }

  readonly trackByFn = (index: number, item: T) =>
    this.trackByKey ? item[this.trackByKey] as unknown as string: `${index}`;

  constructor(private animationBuilder: AnimationBuilder) {}

  ngOnInit(): void {}

  onNext(element: HTMLElement): void {
    if (!this.canNext$.value || this.inTransition) return;

    const items = this.strategy.getNext(this.items, this.items$.value);
    this.visibleItems = items;
    this.executeAnimation(element, 'out', true);

    if (this.animation === 'none') {
      this.items$.next(items);
      this.visibleItems = items;
    } else {
      this.executeAnimation(element, 'out', true);
      this.inTransition = true;
      setTimeout(
        () => {
          this.items$.next(items);
          this.executeAnimation(element, 'in', true);
          this.visibleItems = items;
          this.inTransition = false;
        },
        this.animation === 'fade'
          ? this.animationDuration - this.animationDuration / 2
          : this.animationDuration + 10
      );
    }

    this.canNext$.next(this.strategy.canNext(this.items, items));
    this.canPrevious$.next(this.strategy.canPrevious(this.items, items));
  }

  onPrevious(element: HTMLElement): void {
    if (!this.canPrevious$.value || this.inTransition) return;

    const items = this.strategy.getPrevious(this.items, this.items$.value);

    if (this.animation === 'none') {
      this.items$.next(items);
      this.visibleItems = items;
    } else {
      this.executeAnimation(element, 'out', false);
      this.inTransition = true;
      setTimeout(
        () => {
          this.items$.next(items);
          this.executeAnimation(element, 'in', false);
          this.visibleItems = items;
          this.inTransition = false;
        },
        this.animation === 'fade'
          ? this.animationDuration - this.animationDuration / 2
          : this.animationDuration + 10
      );
    }

    this.canNext$.next(this.strategy.canNext(this.items, items));
    this.canPrevious$.next(this.strategy.canPrevious(this.items, items));
  }

  executeAnimation(
    element: HTMLElement,
    type: 'in' | 'out',
    next: boolean
  ): void {
    const animation =
      type === 'in'
        ? this.buildEnterAnimation(next ? 'right' : 'left')
        : this.buildOutAnimation(next ? 'left' : 'right');
    const player = animation.create(element);
    player.play();
  }

  private buildEnterAnimation(direction: 'left' | 'right'): AnimationFactory {
    return this.animationBuilder.build([
      this.getStartInStyles(direction),
      animate(
        `${this.animationDuration}ms ${this.animationEasing}`,
        style({ transform: 'translate(0)', opacity: 1 })
      ),
    ]);
  }

  private buildOutAnimation(direction: 'left' | 'right'): AnimationFactory {
    return this.animationBuilder.build([
      this.getStartOutStyles(),
      animate(
        `${this.animationDuration}ms ${this.animationEasing}`,
        this.getStartInStyles(direction)
      ),
    ]);
  }

  private getStartInStyles(
    direction: 'left' | 'right'
  ): AnimationStyleMetadata {
    if (this.animation === 'slide')
      return style({
        transform:
          direction === 'right' ? 'translateX(10%)' : 'translateX(-10%)',
        opacity: 0,
      });
    else if (this.animation === 'fade') return style({ opacity: 0 });
    else return style('*');
  }

  private getStartOutStyles(): AnimationStyleMetadata {
    if (this.animation === 'slide')
      return style({ transform: 'translate(0)', opacity: 1 });
    else if (this.animation === 'fade') return style({ opacity: 1 });
    else return style('*');
  }
}
