import {Inject, Injectable} from "@angular/core";
import {ApiResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP} from "@softline/core";
import {Observable, switchMap} from "rxjs";
import {Einheiteneingabe} from "../types/einheiteneingabe";
import {SOFTLINE_API_WWS_EINHEITENEINGABE, SOFTLINE_API_WWS_EINHEITENEINGABE_UMRECHNEN} from "../einheiteneingabe.api";
import {map} from 'rxjs/operators';

@Injectable()
export class EinheiteneingabeService {

  private get umrechnenLocation(): ApiResourceLocation {
    return {
      path: SOFTLINE_API_WWS_EINHEITENEINGABE_UMRECHNEN
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private readonly httpService: ResourceService<ApiResourceLocation>
  ) {}

  initialisieren(params: { idartstamm: number; menge: number; idbasisEinheit: number }): Observable<Einheiteneingabe> {
    return this.httpService.get<Einheiteneingabe>(this.initialisierenLocation(params.idartstamm)).pipe(
      map(value => {
        console.log('result: ', value);
        const einheiten = [...value.einheiten];

        for (const einheit of einheiten) {
          if (einheit.einheit.id !== params.idbasisEinheit)
            continue;

          einheit.menge = params.menge
        }

        return {
          ...value,
          einheiten,
          ideinheitEingabe: params.idbasisEinheit,
          erfassungsEinheit: {
            einheit: { id: params.idbasisEinheit, },
            menge: 0
          }
        } as Einheiteneingabe
      }),
      switchMap(value => this.umrechnen(value))
    );
  }

  umrechnen(einheiteneingabe: Einheiteneingabe): Observable<Einheiteneingabe> {
    console.log('umrechnen...', einheiteneingabe);
    return this.httpService.create(this.umrechnenLocation, einheiteneingabe)
  }

  private initialisierenLocation(id: number): ApiResourceLocation {
    return {
      path: SOFTLINE_API_WWS_EINHEITENEINGABE,
      pathParams: { id }
    }
  }
}
