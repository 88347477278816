import { Component, OnInit } from '@angular/core';
import { Position } from '../position';
import { Modal, Validators } from '@softline/ui-core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'soft-input-geolocation',
    templateUrl: './input-geolocation.component.html',
    styleUrls: ['./input-geolocation.component.scss'],
    standalone: false
})
export class InputGeolocationComponent implements OnInit, Modal<Position> {
  close!: (result: Position) => void;
  form = new UntypedFormGroup({
    gpslaenge: new UntypedFormControl(undefined, Validators.required()),
    gpsbreite: new UntypedFormControl(undefined, Validators.required()),
  });

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: Position) => void): void {
    this.close = handler;
  }

  onSubmit(position: Position): void {
    this.close(position);
  }
}
