import { inject, Injectable } from '@angular/core';
import { HttpDataAccess } from './http.data-access';

import { ConnectionService } from '../connection/connection.service';
import { Observable } from 'rxjs';
import { ApiResourceLocation } from './types/api.location';
import { DownloadRequestOptions } from '../data/abstraction';
import { createHttpResourceLocation, HttpLocationParameters, HttpResourceLocation } from './types/http.location';
import { isDefined } from '../functions/is-defined.function';
import { RequestEvent } from './types/request';

@Injectable({providedIn: 'root'})
export class ApiDataAccess {
  connectionService = inject(ConnectionService);
  http = inject(HttpDataAccess);

  async get<T>(location: ApiResourceLocation, payload?: unknown): Promise<T> {
    const httpLocation = this.convertLocation(location);
    return await this.http.get<T>(httpLocation, payload);
  }

  async create<T, TResponse>(location: ApiResourceLocation, resource: T): Promise<TResponse> {
    const httpLocation = this.convertLocation(location);
    return await this.http.create<T, TResponse>(httpLocation, resource);
  }

  async update<T, TResponse>(location: ApiResourceLocation, resource: T): Promise<TResponse> {
    const httpLocation = this.convertLocation(location);
    return await this.http.update<T, TResponse>(httpLocation, resource);
  }

  async patch<T, TResponse>(location: ApiResourceLocation, changes: Partial<T>): Promise<TResponse> {
    const httpLocation = this.convertLocation(location);
    return await this.http.patch<T, TResponse>(httpLocation, changes);
  }

  async delete<T>(location: ApiResourceLocation): Promise<T> {
    const httpLocation = this.convertLocation(location);
    return await this.http.delete<T>(httpLocation);
  }

  download<T>(location: ApiResourceLocation, payload?: unknown, options?: DownloadRequestOptions): Observable<RequestEvent<Blob  | ArrayBuffer>> {
    const httpLocation = this.convertLocation(location);
    return this.http.download(httpLocation, payload, options);
  }

  upload<T, TResponse>(location: ApiResourceLocation, resource: T): Observable<RequestEvent<TResponse>> {
    const httpLocation = this.convertLocation(location);
    return this.http.upload(httpLocation, resource);
  }

  private convertLocation(
    params: ApiResourceLocation | string
  ): HttpResourceLocation {
    const connection = this.connectionService.connection();

    const path = isDefined(connection.basePath) ? [connection.basePath] : [];

    if (typeof params === 'string')
      path.push(params);
    else if (Array.isArray(params.path))
      path.push(...params.path);
    else if (typeof params.path === 'string')
      path.push(params.path);

    const httpLocationParams: HttpLocationParameters = {
      host: connection.host,
      port: connection.port,
      path,
    };

    if (typeof params === 'object') {
      httpLocationParams.queryParams = params.queryParams;
      httpLocationParams.pathParams = params.pathParams;
    }
    return createHttpResourceLocation(httpLocationParams);
  }
}
