import { ResourceService } from '../../abstraction';
import { StorageResourceLocation } from './storage.location';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { RequestEvent } from '../../../data-access/types/request';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService
  implements ResourceService<StorageResourceLocation>
{
  get<T>(location: StorageResourceLocation): Observable<T> {
    try {
      const value = this.getValue<T>(location.key);
      return of(value);
    } catch (e) {
      return throwError(() => e);
    }
  }

  create<T, TResponse>(
    location: StorageResourceLocation,
    resource: T
  ): Observable<TResponse> {
    try {
      this.setValue(location.key, resource);
      return of(resource as any);
    } catch (e) {
      return throwError(e);
    }
  }

  update<T, TResult>(
    location: StorageResourceLocation,
    resource: T
  ): Observable<TResult> {
    try {
      this.setValue(location.key, resource);
      return of(resource as any);
    } catch (e) {
      return throwError(e);
    }
  }

  patch<T, TResult>(
    location: StorageResourceLocation,
    resource: T
  ): Observable<TResult> {
    try {
      this.setValue(location.key, resource);
      return of(resource as any);
    } catch (e) {
      return throwError(e);
    }
  }

  delete<T>(location: StorageResourceLocation): Observable<T> {
    try {
      this.deleteValue(location.key);
      return of(null as any);
    } catch (e) {
      return throwError(e);
    }
  }

  download<T>(
    location: StorageResourceLocation
  ): Observable<RequestEvent<Blob>> {
    return this.get<T>(location).pipe(
      map((o) => {
        const response = new Blob([JSON.stringify(o, null, 2)], {
          type: 'application/json',
        });
        return { type: 'response', response };
      })
    );
  }

  upload<T, TResponse>(
    location: StorageResourceLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>> {
    return this.create<T, TResponse>(location, resource).pipe(
      map((o) => ({ type: 'response', response: resource as any }))
    );
  }

  clear(): void {
    localStorage.clear();
  }

  private getValue<T>(key: string): T {
    const value = localStorage.getItem(key);
    if (value) return JSON.parse(value);

    throw new Error(
      `LocalStorageResourceService.getValue: Can not find a value for '${key}'`
    );
  }

  private setValue<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private deleteValue<T>(key: string): void {
    localStorage.removeItem(key);
  }
}
