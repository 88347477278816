import {toSignal} from '@angular/core/rxjs-interop';
import {filter, map} from 'rxjs';
import {inject, Signal} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

export function injectSearchQuery(key = 'search'): Signal<string | undefined> {
  const activatedRoute = inject(ActivatedRoute);

  return toSignal(
    activatedRoute.queryParamMap.pipe(
      map((queryParamMap) => queryParamMap.get(key) ?? '' as string),
      map(encodedFilterString => decodeURIComponent(encodedFilterString)),
      filter(o => !!o)
    )
  );
}
