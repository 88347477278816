import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE
} from '../artikelvorrat.shared';
import {ArtikelvorratBeweStore} from '../store/artikelvorrat-bewe.store';
import {map, Subscription} from 'rxjs';
import {ArtikelvorratStore} from '../store/artikelvorrat.store';
import {ArtikelvorratArtikelDetailStore} from '../store/artikelvorrat-artikel-detail.store';

@Component({
    selector: 'soft-mde-artikelvorrat',
    templateUrl: './artikelvorrat.component.html',
    styleUrls: ['./artikelvorrat.component.scss'],
    imports: [RouterModule]
})
export class ArtikelvorratComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  readonly kopf$ = this.store.observe(
    SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
    ArtikelvorratStore.getters.kopf
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Artikelvorrat'
    );

    this.subscription = this.activatedRoute.data.subscribe(async ({ artikelvorrat }) => {
      if (artikelvorrat?.kopf) {
        this.store.commit(
          SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
           ArtikelvorratStore.mutations.setKopf,
            artikelvorrat?.kopf
        );
      }

      if (!artikelvorrat?.kopf) {
        await this.router.navigate(['/mde-artikelvorrat/beleg-auswahl'])
      }
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: ArtikelvorratComponent,
        commands: [
          {
            name: 'Artikelvorrat',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-cart-plus',
            isVisible: this.kopf$.pipe(map(o => !!o)),
            canExecute: this.kopf$.pipe(map(o => !!o)),
            routerLink: '/mde-artikelvorrat'
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE,
      ArtikelvorratBeweStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      ArtikelvorratComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
      ArtikelvorratStore.mutations.setKopf,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
      ArtikelvorratStore.mutations.setItemScanResult,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE,
      ArtikelvorratBeweStore.mutations.clear
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS,
      ArtikelvorratArtikelDetailStore.mutations.clear
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
