import { effect, inject, OnInit, Signal, untracked } from '@angular/core';
import { PageSettingsStore } from '../page-settings.store';
import { Dictionary } from '@softline/core';
import { SettingsComponent } from '@softline/ui-core';
import { PageSettingsService } from '../page-settings.service';

export abstract class PageSettingsDirectiveBase<T extends SettingsComponent> {

  readonly service = inject(PageSettingsService);
  readonly store = inject(PageSettingsStore);

  abstract component: Signal<T>;
  abstract name: Signal<string>;

  componentEffect = effect(() => {
    const component = this.component();
    const name = this.name()
    component.registerOnSettingsChange(
      (value: Dictionary<any>) => this.service.set({name: name, settings: value})
    )
  });

  hostEffect = effect(() => {
    const name = this.name();
    const component = this.component();
    const pageSettings = this.service.pageSettings();
    console.log('pagesettings', pageSettings);
    const componentSettings = pageSettings.components.find(o => o.name === name);
    if(componentSettings)
      untracked(() => component.applySettings(componentSettings.settings));
  });

  constructor() {}
}
