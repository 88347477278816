import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@softline/core';
import moment from 'moment';
@Pipe({
    name: 'utcOffset',
    pure: true,
    standalone: false
})
export class UtcOffsetPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null | undefined {
    if (!isDefined(value)) return value;
    const date = moment(value);
    if (!date.isValid()) return null;
    return date.format('Z');
  }
}
