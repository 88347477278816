import { inject, StaticProvider, provideEnvironmentInitializer, EnvironmentProviders } from '@angular/core';
import { Dictionary, Store } from '@softline/core';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from '@softline/ui-core';
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from '@softline/application';
import { SOFTLINE_PERMISSION_TRANSPORTAUFTRAG } from '@softapps/wws/energietransport';

export interface ApplicationConfig {
  name: string;
  title: string;
  icon: string;
  route: string;
  permission?: string;
  translations: Dictionary<object>
}

export function registerApplication(appConfig: ApplicationConfig): EnvironmentProviders {
  return provideEnvironmentInitializer(() => {
      const store = inject(Store);
      for (const [language, translations] of Object.entries(appConfig.translations)) {
        store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
          module: appConfig.name,
          language: language,
          translations: translations ?? {}
        });
      }

      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: appConfig.title,
        icon: appConfig.icon,
        route: appConfig.route,
        routeParams: {
          fragment: undefined
        },
        permission: appConfig.permission
      });
    })
}
