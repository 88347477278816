import { Pipe, PipeTransform } from '@angular/core';
import { TreeNode } from '../types/tree-node'
import { TreeViewRef } from '../tree-view-ref';

@Pipe({
  name: 'isNodeExpanded',
  pure: false,
})
export class IsNodeExpandedPipe<T> implements PipeTransform {
  transform(value: TreeNode<T>, ref: TreeViewRef<T>): boolean {
    return ref.isExpanded(value.path)
  }
}
