import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  input,
  linkedSignal,
  output,
  TrackByFunction,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNodeDirective } from './directives/tree-node.directive';
import { TreeNode } from './types/tree-node';
import { TreeNodeExpandedChangeEvent } from './types/tree-node-expanded-change-event';
import { TreeViewRef } from './tree-view-ref';
import { IsNodeExpandedPipe } from './pipes/is-node-expanded.pipe';

@Component({
  selector: 'soft-tree-view',
  imports: [CommonModule, TreeNodeDirective, IsNodeExpandedPipe],
  templateUrl: './tree-view.component.html',
  styleUrl: './tree-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewComponent<T> {
  // Inputs
  readonly ref = input.required<TreeViewRef<T>>();
  readonly path = input<string>();
  readonly nestedClass = input<string | null>();

  readonly trackByFn = input<TrackByFunction<TreeNode<T>>>(
    (_: number, node: TreeNode<T>) => node.path
  );

  // Outputs
  readonly expandedChange = output<TreeNodeExpandedChangeEvent<T>>();

  // Templates
  readonly nodeTemplate = contentChild(TreeNodeDirective);

  readonly nodes = linkedSignal({
    source: () => this.path() ?? this.ref().root(),
    computation: (path) => {
      if (!path) return [];

      return this.ref().state().nodes?.[path] ?? [];
    },
  });

  isExpanded(path: string): boolean {
    return this.ref().isExpanded(path);
  }

  async toggle(node: TreeNode<T>): Promise<void> {
    await this.ref().toggle(node);
  }

  async expand(node: TreeNode<T>): Promise<void> {
    await this.ref().expand(node);
  }

  async collapse(node: TreeNode<T>): Promise<void> {
    await this.ref().collapse(node);
  }
}
