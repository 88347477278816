import { Routes } from '@angular/router';
import { importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import { Store, StoreModule } from '@softline/core';
import {
  SOFTLINE_CONFIG_PDF_EDITOR,
  SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
} from './pdf-editor.shared';
import { PdfEditorComponentStore } from './store/pdf-editor-component.store';
import { OfflineResolver } from '@softline/application';

export const pdfEditorRoutes: Routes = [
  {
    path: 'pdf-editor',
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('./pages/pdf-editor-page/pdf-editor-page.component').then(
            (o) => o.PdfEditorPageComponent
          ),
        pathMatch: 'full',
      },
      { path: '**', redirectTo: '' },
    ],
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
          feature: PdfEditorComponentStore.feature,
        })
      ),
      provideEnvironmentInitializer(() => {
          const store = inject(Store);
          const pdfEditorConfig = inject(SOFTLINE_CONFIG_PDF_EDITOR);
          const pdfEditorToolsConfig = pdfEditorConfig.filter(
            (config) => config.key === 'tools'
          );
          if (pdfEditorToolsConfig) {
            pdfEditorToolsConfig.forEach((config) => {
              for (const [groupName, tools] of Object.entries(config.value)) {
                if (Array.isArray(tools)) {
                  tools.forEach((tool) => {
                    store.commit(
                      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
                      PdfEditorComponentStore.mutations.addTool,
                      { groupName, tool }
                    );
                  });
                }
              }
            });
          }
        }),
    ],
    resolve: { offline: OfflineResolver },
  },
];
