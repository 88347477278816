import {CommonModule, DecimalPipe} from "@angular/common";
import {ChangeDetectionStrategy, Component, computed, input, Input, signal} from '@angular/core';

export interface StatusThemeMap {
  [value: number]: ProgressBarTheme
}

export type ProgressBarTheme = 'error' | 'success' | 'primary';

@Component({
    selector: 'soft-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    imports: [CommonModule, DecimalPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {

  _progress!: number;

  readonly displayValue = signal<number>(0);

  backgroundClass = input<string>('');
  theme = input<ProgressBarTheme | StatusThemeMap>('primary');
  markerPercentage = input<number | undefined>(undefined);

  protected activeTheme = computed(() => {
    const _theme = this.theme();
    const value = this.displayValue();

    if (typeof _theme === 'string') {
      return _theme;
    }

    const entries = Object.entries(_theme) as unknown as [number, ProgressBarTheme][];

    const result = entries.find(([targetValue, _]) => {
      return value >= targetValue;
    });

    if (!result)
      return 'primary';

    const [,theme] = result;
    return theme;
  });

  protected themeClasses = computed(() => {
    // default behaviour = negative values in error theme;
    if (this.theme() === 'primary')
      return this.displayValue() < 0 ? this.classForTheme('error') : this.classForTheme('primary');

    return this.classForTheme(this.activeTheme());
  });

  protected themeBorderClasses = computed(() => {
    const theme = this.activeTheme();

    if (this.theme() === 'primary')
      return this.displayValue() < 0 ? 'border-danger': 'border-primary';

    switch (theme) {
      case "error":
        return 'border-danger';
      case "success":
        return 'border-success';
      case "primary":
        return 'border-primary';
    }
  });

  @Input()
  public set progress(progress: number) {
    if (progress < 0) {
      this._progress = 0;
      this.displayValue.set(progress);
    }
    else if (progress > 1) {
      this._progress = 1;
      this.displayValue.set(progress);
    }
    else {
      this._progress = progress;
      this.displayValue.set(progress);
    }
  }

  @Input() customClass? = '';

  allowValuesOver100Percent = input(false);

  private classForTheme(theme: ProgressBarTheme) {
    switch (theme) {
      case "success":
        return 'bg-success';
      case "error":
        return 'bg-danger';
      case 'primary':
        return 'bg-primary';
    }
  }
}
