import { ResourceService } from '../../data/abstraction';
import {
  CollectionRepository,
  CRUDCollectionParameters,
  Id,
  LoadCollectionItemParameters,
  LoadCollectionParameters
} from '../abstraction';
import { Observable } from 'rxjs';
import { toIdString } from '../functions/to-id-string';
import { IndexedDbResourceLocation } from '../../data/specialized/indexed-db/indexed-db.location';

export class IndexedDbCollectionRepository<TItem, TResult, TManyResult> implements CollectionRepository<TItem, TResult, TManyResult> {

  constructor(protected service: ResourceService<IndexedDbResourceLocation>,
              protected databaseName: string,
              protected objectStoreName: string) { }

  loadItem(id: Id, params: LoadCollectionItemParameters): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
    };
    return this.service.get(location);
  }

  loadMany(params?: LoadCollectionParameters): Observable<TManyResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
    };
    return this.service.get(location);
  }

  create(id: Id | null | undefined, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
      key: toIdString(id)
    };
    return this.service.create(location, value);
  }
  update(id: Id, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
      key: toIdString(id)
    };
    return this.service.update(location, value);
  }
  patch(id: Id, changes: Partial<TItem>, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
      key: toIdString(id)
    };
    return this.service.patch(location, changes);
  }
  delete(id: Id, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
      key: toIdString(id)
    };
    return this.service.delete(location);
  }
}
