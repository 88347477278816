import {Component, Input, OnInit, Type} from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
    selector: 'soft-default-header',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() overline?: string | null | undefined;
  @Input() title?: string | null | undefined;
  @Input() subtitle?: string | null | undefined;

  constructor() {}

  ngOnInit(): void {}
}
