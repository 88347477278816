import { Component, computed, EventEmitter, OnInit, Output } from "@angular/core";
import { distinct, Store } from "@softline/core";
import { SOFTLINE_FEATURE_SCANNER } from "../../../application.shared";
import * as ScannerStore from '../../scanner.store'
import { Scan } from '../../data/scan'
import { UiCoreModule } from "@softline/ui-core";
import { CommonModule } from "@angular/common";

@Component({
    selector: 'soft-scan-history',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './scan-history.component.html',
    styleUrls: ['./scan-history.component.scss']
})
export class ScanHistoryComponent implements OnInit {

  @Output() scanSelect = new EventEmitter<Scan>();

  #history = this.store.signal(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.scans);
  history = computed(() => {
    let history = this.#history();
    return distinct([...history].reverse(), {comparer: (f, s) => f.data === s.data})
      .slice(0,5)
  });

  constructor(private store: Store) {}

  ngOnInit(): void {
  }

}
