import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  runInInjectionContext,
  TemplateRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Dictionary, equals, SOFTLINE_SERVICE_UUID, Store } from "@softline/core";
import { EmptyEntityInputStrategy, EntityInputStrategy, UiCoreModule } from "@softline/ui-core";
import {
  SOFTLINE_FEATURE_FIELD_OK,
  SOFTLINE_PROVIDER_FIELD_OK_CONFIG
} from "../../dynamic.shared";
import * as FieldOkComponentStore from "../../field-ok-component.store";
import { FieldOkConfig } from "../../data/field-ok";
import { CommonModule } from "@angular/common";
import { FieldOkStrategyFactory } from "../field-ok/strategies/field-ok-strategy.factory";
import { FieldOkStrategyOptions } from "../field-ok/strategies/field-ok.strategy";

@Component({
    selector: 'soft-multiselect-field-ok',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './multiselect-field-ok.component.html',
    styleUrls: ['./multiselect-field-ok.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectFieldOkComponent),
            multi: true,
        },
    ]
})
export class MultiselectFieldOkComponent<T>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  private componentId = this.uuid();

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  isOpen = false;
  config: FieldOkConfig | undefined;
  strategy: EntityInputStrategy<object> = new EmptyEntityInputStrategy();

  items = this.store
    .signal(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.list,
      this.componentId
    );


  @Input() name = '';
  @Input() title?: string | null;
  @Input() subtitle?: string | null;
  @Input() placeholder?: string | null;

  @Input() parameters: object = {};

  @Input() readonly = false;
  @Input() template?: TemplateRef<any>;
  @Input() maxDisplayed: number = 3

  private _value: T[] | null = null;
  @Input()
  get value(): T[] | null {
    return this._value;
  }
  set value(value: T[] | null) {
    this.setValue(value, true);
  }
  @Output() valueChange = new EventEmitter<T[] | null>();

  constructor(
    private store: Store,
    private injector: Injector,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    @Inject(SOFTLINE_PROVIDER_FIELD_OK_CONFIG) private configProvider: (name: string, options: {type: string}) => FieldOkConfig,
    private strategyFactory: FieldOkStrategyFactory,
  ) { }

  ngOnInit(): void {
    this.strategy = this.createStrategy();
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.addOrPatch,
      { key: this.componentId, component: {} }
    );
  }

  private createStrategy(): EntityInputStrategy<object, any> {
    if(!this.config)
      this.config = runInInjectionContext(this.injector, () => this.configProvider(this.name, {type: 'multi'}));
    const options: FieldOkStrategyOptions = {
      componentId: this.componentId,
      title: this.title ?? '',
      subtitle: this.subtitle ?? undefined,
      config: this.config,
      type: 'multi'
    }
    return runInInjectionContext(this.injector,
      () => this.strategyFactory.create(this.name, options)
    );
  }

  async ngOnDestroy(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.remove,
      this.componentId
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.cancel,
      this.componentId
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  setValue(value: any, preventValueChange: boolean = false): void {
    if (equals(this._value, value))
      return;
    this._value = value;
    this.onChange(this._value);
    this.onTouch();
    if (!preventValueChange)
      this.valueChange.emit(value);
  }
}
