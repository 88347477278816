import { computed, Inject, Injectable, OnDestroy, signal } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { Store } from '@softline/core';
import * as BreakpointStore from '../breakpoint.store';
import { Breakpoint } from '../breakpoint.store';
import {
  BreakpointConfig,
  SOFTLINE_CONFIG_BREAKPOINTS,
  SOFTLINE_FEATURE_BREAKPOINTS,
} from '../breakpoint.shared';

@Injectable()
export class BreakpointService implements OnDestroy {
  private subscription?: Subscription;

  readonly #breakpoint = signal<Breakpoint>('2xl');

  readonly breakpoint = this.#breakpoint.asReadonly();

  readonly isMobile = computed(() => {
    const breakpoint = this.breakpoint();
    return !breakpoint || breakpoint === 'sm';
  })

  constructor(
    store: Store,
    breakpointObserver: BreakpointObserver,
    @Inject(SOFTLINE_CONFIG_BREAKPOINTS) breakpoints: BreakpointConfig
  ) {
    this.subscription = breakpointObserver
      .observe([
        `(min-width: ${breakpoints.sm})`,
        `(min-width: ${breakpoints.md})`,
        `(min-width: ${breakpoints.lg})`,
        `(min-width: ${breakpoints.xl})`,
        `(min-width: ${breakpoints.xxl})`,
      ])
      .subscribe((o) => {
        let breakpoint: Breakpoint = null;
        if (o.breakpoints[`(min-width: ${breakpoints.xxl})`])
          breakpoint = '2xl';
        else if (o.breakpoints[`(min-width: ${breakpoints.xl})`])
          breakpoint = 'xl';
        else if (o.breakpoints[`(min-width: ${breakpoints.lg})`])
          breakpoint = 'lg';
        else if (o.breakpoints[`(min-width: ${breakpoints.md})`])
          breakpoint = 'md';
        else if (o.breakpoints[`(min-width: ${breakpoints.sm})`])
          breakpoint = 'sm';

        this.#breakpoint.set(breakpoint);

        store.commit(
          SOFTLINE_FEATURE_BREAKPOINTS,
          BreakpointStore.mutations.set,
          breakpoint
        );
      });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
