import { Pipe, PipeTransform } from '@angular/core';
import { isImage } from '@softline/ui-core';

@Pipe({
  name: 'fileTypeIcon',
  pure: true,
  standalone: true
})
export class FileTypeIconPipe implements PipeTransform {

  transform(value: string): string {
    if(value.includes('.doc') || value.includes('.docx')){
      return 'fa-regular fa-file-word'
    }
    if(value.includes('.xlsx') || value.includes('.xlsm')){
      return 'fa-regular fa-file-excel'
    }
    if(value.includes('.pdf')){
      return 'fa-regular fa-file-pdf'
    }
    if(isImage(value)){
      return 'fa-regular fa-file-image'
    }
    if(value.includes('.zip') ){
      return 'fa-regular fa-file-zip'
    }
    return 'fa-regular fa-file'
  }

}
