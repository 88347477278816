@if(firstPrice(); as price) {
  <div class="flex flex-row justify-between flex-wrap w-full">
    <div class="flex flex-col justify-between">
      <div class="flex flex-col">
        <div class="text-xl font-semibold">{{price.terms}}</div>
        <div class="text-sm">{{price.additionalTerms}}</div>
      </div>
      <div class="flex flex-col w-full justify-end">
        @for (bulkPrice of bulkPrices(); track bulkPrice) {
          <div class="flex flex-row w-full justify-between gap-2">
            <div class="text-xs text-light">
              ab {{bulkPrice.fromAmount}}
              @if(unit(); as unit) {
                {{unit.name}}
              }
            </div>
            <div class="text-xs text-light">
              {{bulkPrice.grossPrice | currencyFormat:currency()}}
            </div>
          </div>
        }
      </div>
    </div>
    <div class="flex flex-col items-end justify-between">
      <span [class.text-promotion]="promotion()">
        <span class="font-semibold text-xl">
          {{price.netPriceIncl | currencyFormat:currency()}}
        </span>
        @if(unit(); as unit) {
          <span class="font-light text-lg">
            / @if ((price.per ?? 1) > 1) { {{price.per}} } {{unit.name}}
          </span>
        }
      </span>
      <div class="flex flex-row justify-between w-full gap-2">
        <span class="font-semibold text-sm">Netto</span>
        <span class="font-semibold text-sm">{{price.netPrice | currencyFormat:currency()}}</span>
      </div>
      <div class="flex flex-row justify-between w-full gap-2">
        <span class="text-light text-sm">Brutto</span>
        <span class="text-light text-sm">{{price.netPriceIncl | currencyFormat:currency()}}</span>
      </div>
    </div>
  </div>
}
