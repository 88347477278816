import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Modal, UiCoreModule, Validators } from '@softline/ui-core';
import { Connection, Store } from '@softline/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'lib-connection-dialog',
    templateUrl: './connection-dialog.component.html',
    styleUrls: ['./connection-dialog.component.scss'],
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule]
})
export class ConnectionDialogComponent implements OnInit, Modal<Connection> {
  close!: (result: Connection) => void;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(undefined),
    host: new UntypedFormControl(undefined, [Validators.required()]),
    port: new UntypedFormControl(undefined, [
      Validators.min(1),
      Validators.max(65535),
    ]),
    basePath: new UntypedFormControl(undefined),
  });

  mode: 'new' | 'edit' = 'new';
  get connection(): Connection {
    return this.form.value;
  }
  set connection(connection: Connection) {
    this.form.reset();
    this.form.patchValue(connection);
  }

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: Connection) => void): void {
    this.close = handler;
  }
}
