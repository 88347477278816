import { SelectStrategy } from "./select.strategy";
import { equals } from '../../functions/equals.function';

export class MultiSelectStrategy<T> implements SelectStrategy<T[]> {

  constructor(protected options?: {allowDuplicates?: boolean}) {
  }

    select(value: T[], current?: T[]): T[] {
      if(!current)
        current = [];
      if(this.options?.allowDuplicates)
        return [...current, ...value]

      const returnValue = [...current];
      for(const item of value) {
        const index = current.findIndex(o => equals(o, item));
        if(index === -1)
          returnValue.push(item);
      }
      return returnValue;
    }
    unselect(value: T[], current?: T[]): T[] {
      if(!current)
        current = [];
      const returnValue = [...current];
      for (const item of value) {
        const index = returnValue.findIndex(o => equals(o, item));
        if (index !== -1) {
          returnValue.splice(index, 1);
        }
      }
      return returnValue;
    }
}
