import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@softline/ui-core';

@Component({
  selector: 'soft-query-bar-item',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './query-bar-item.component.html',
  styleUrl: './query-bar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBarItemComponent {

  readonly icon = input<string>();
  readonly title = input.required<string>();
  readonly value = input<string>();
  readonly readonly = input(false);

  readonly click = output<void>();
}
