import { Component, OnInit } from '@angular/core';
import { ImageInputDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { NgIf } from '@angular/common';
import { BooleanRulePipe } from '../../../../pipes/boolean-rule.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '../../../../pipes/form-control-name.pipe';
import {
  FieldValidationDirective,
  FileInputComponent as UiCoreFIC,
} from '@softline/ui-core';

@Component({
  selector: 'soft-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  imports: [
    NgIf,
    BooleanRulePipe,
    ReactiveFormsModule,
    FormControlPipe,
    UiCoreFIC,
    FieldValidationDirective,
  ],
})
export class FileInputComponent
  extends DynamicInputAtom<ImageInputDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
