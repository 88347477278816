import { Injectable, importProvidersFrom, inject, provideEnvironmentInitializer } from "@angular/core";
import { SOFTLINE_SERVICE_HTTP, SOFTLINE_STORE_DETAIL_SERVICE_FACTORY, SOFTLINE_STORE_ENTITY_SERVICE, SOFTLINE_STORE_REMOTE_OBJECT_SERVICE, SoftlineEntityDetailService, SoftlineEntityService, SoftlineObjectService, Store, StoreModule } from "@softline/core"
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application"
import { PmsPersonalStore } from "./store/personal.store"
import {
  SOFTLINE_CONFIG_PMS_STATUSABFRAGE_SEND_NOTIFICATION_COMPONENT,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
  SOFTLINE_PERMISSION_PMS_STATUSABFRAGE
} from "./statusabfrage.shared";
import { SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN, SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER, SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL } from "./statusabfrage.api";
import { PmsPersonalDetailStore } from "./store/personal-detail.store";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from './../i18n/de.json';
import { PmsAbteilungStore } from "./store/abteilung.store";
import {SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT} from '@softapps/allgemein/send-notification';
import {
  StatusabfrageEmpfaengerAuswahlComponent
} from './components/statusabfrage-empfaenger-auswahl/statusabfrage-empfaenger-auswahl.component';
import {AbteilungenService} from './services/abteilungen.service';

export const statusabfrageProviders = [
  provideEnvironmentInitializer(async () => {
      const store = inject(Store);

      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'pmsStatusabfrage',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Statusabfrage',
          route: '/statusabfrage',
          icon: 'fa-regular fa-user-clock',
          permission: SOFTLINE_PERMISSION_PMS_STATUSABFRAGE
        }
      );
    }),
  importProvidersFrom(
    StoreModule.forFeature({
      feature: PmsPersonalStore.feature,
      name: SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER
          )
        },
        {
          provide: SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
          useFactory: (service = inject(SOFTLINE_SERVICE_HTTP)) => () => new SoftlineEntityDetailService(
            service,
            SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
            'detail/{{idlstfix}}'
          )
        }
      ]
    }),
    StoreModule.forFeature({
      feature: PmsPersonalDetailStore.feature,
      name: SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: () => new SoftlineObjectService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL
          )
        }
      ]
    }),
    StoreModule.forFeature({
      feature: PmsAbteilungStore.feature,
      name: SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_PMS_STATUSABFRAGE_ABTEILUNGEN
          )
        },
      ]
    })
  ),
  AbteilungenService,
  {
    provide: SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT,
    multi: true,
    useValue: {
      name: SOFTLINE_CONFIG_PMS_STATUSABFRAGE_SEND_NOTIFICATION_COMPONENT,
      component: StatusabfrageEmpfaengerAuswahlComponent
    }
  }
];
