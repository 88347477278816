import {ChangeDetectionStrategy, Component, computed, input, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UmsatzstatistikQuery} from '../../types/umsatzstatistik.query';
import {BarChartModule} from '@swimlane/ngx-charts';
import { I18nModule, UiCoreModule, UiCorePipesModule } from '@softline/ui-core';
import {UmsatzartResult} from '../../types/umsatzstatistik';
import { Zeitraum } from '../../types/zeitraum';

@Component({
    selector: 'soft-compact-chart',
    imports: [
        CommonModule,
        BarChartModule,
        UiCorePipesModule,
        I18nModule,
        UiCoreModule,
    ],
    templateUrl: './compact-chart.component.html',
    styleUrl: './compact-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactChartComponent {
  readonly _query = signal<UmsatzstatistikQuery | null>(null);

  werte = input<UmsatzartResult | null>(null);
  zeitraumDatum = input<Zeitraum | null>(null);
  loading = input(false);
  title = input<string>();
  colorUmsatz = input<string>('var(--colorPrimary)');
  colorDb = input<string>('var(--colorAccent)');

  readonly vm = computed(() => {
    const werte = this.werte();

    if (!werte) return null;

    return {
      title: this.title(),
      umsatz: werte?.values?.netto ?? 0,
      db: werte?.values?.db ?? 0,
      dbproz: werte?.values?.dbproz ?? 0,
    };
  });
}
