import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesUnit } from '../../types/sales-unit';
import { UiCoreModule } from '@softline/ui-core';

@Component({
    selector: 'soft-sales-unit-card',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './sales-unit-card.component.html',
    styleUrl: './sales-unit-card.component.scss'
})
export class SalesUnitCardComponent {
  salesUnit = input.required<SalesUnit>();
}
