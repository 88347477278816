import { inject, Provider, provideEnvironmentInitializer, EnvironmentProviders } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_CONFIG_PREVIEW_HANDLER,
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { ArchivePdfPreviewHandler } from './services/archive-pdf-preview.handler';

export const pdfViewerProviders: Array<Provider | EnvironmentProviders> = [
  provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'pdf-viewer',
          language: 'de',
          translations: de,
        }
      );
    }),
  {
    provide: SOFTLINE_CONFIG_PREVIEW_HANDLER,
    useClass: ArchivePdfPreviewHandler,
    multi: true,
  },
];
