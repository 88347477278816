import { inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import { BackNavigationService } from "../program/back-navigation/back-navigation.service";
import { BackNavigable } from "@softline/application";
import { Router } from "@angular/router";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithBackNavigation = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BackNavigationMixin extends Base implements OnInit, OnDestroy, BackNavigable{

    #backNavigationService = inject(BackNavigationService);

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      this.#backNavigationService.set(this);
    }

    ngOnDestroy(): void {
      this.#backNavigationService.set(undefined);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    abstract navigateBack(): Promise<void>
  }
  return BackNavigationMixin;
}

export const WithRootBackNavigation = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BackNavigationRouteMixin extends WithBackNavigation(Base) implements BackNavigable{

    #router = inject(Router);

    constructor(...args: any[]) {
      super(...args);
    }

    override async navigateBack(): Promise<void> {
      await this.#router.navigate(['/']);
    }
  }
  return BackNavigationRouteMixin;
}

export const WithStaticBackNavigation = <T extends Constructor<{}>>(route: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BackNavigationRouteMixin extends WithBackNavigation(Base) implements BackNavigable{

    #router = inject(Router);

    constructor(...args: any[]) {
      super(...args);
    }

    override async navigateBack(): Promise<void> {
      await this.#router.navigate([route]);
    }
  }
  return BackNavigationRouteMixin;
}

export const WithDynamicBackNavigation = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BackNavigationRouteMixin extends WithBackNavigation(Base) implements BackNavigable{

    #router = inject(Router);

    constructor(...args: any[]) {
      super(...args);
    }

    override async navigateBack(): Promise<void> {
      const route = this.getBackNavigationRoute();
      await this.#router.navigate(route);
    }

    abstract getBackNavigationRoute(): any[];
  }
  return BackNavigationRouteMixin;
}
