import { computed, InjectionToken, isSignal, Signal, signal } from '@angular/core';
import { Patch } from '../../store/specialized/entity/types/patch';
import { SingleSelectService, SingleSelectServiceOptions } from '../selection/single-select.service';

export interface CollectionServiceOptions<T extends object> {
  idFunc?: (item: T) => string | number;
  defaultItems?: T[];
}

export class CollectionService<T extends object> {

  protected _all = signal<T[]>([]);

  all = this._all.asReadonly();
  ids = computed(() => this.all().map(o => this.getId(o)));
  count = computed(() => this.all().length);
  dict = computed(() => {
    const dict: Record<string | number, T> = {};
    for (const item of this._all())
      dict[this.getId(item)] = item;
    return dict;
  });

  item = (id: string | number | Signal<string | number>): Signal<T | undefined> => {
    return computed(() => {
      if(isSignal(id))
        id = id();
      return this._all().find(o => id === this.getId(o))
    })
  }

  items = (ids: (string | number | Signal<string | number>)[]): Signal<T[]> => {
    return computed(() => {
      const resolvedIds = ids.map(id => isSignal(id) ? id() : id);
      return this._all().filter(o => resolvedIds.includes(this.getId(o)))
    })
  }

  constructor(private options: CollectionServiceOptions<T> = {}) {
    if(options.defaultItems)
      this._all.set(options.defaultItems);
  }

  add(value: T): void {
    const id = this.getId(value);
    if (this.all().some(o => this.getId(o) === id))
      throw new Error(
        `[CollectionService] add: item with id ${id} already exists in the collection.`
      );
    this._all.set([...this._all(), value]);
  }

  addMany(values: T[]): void {
    const all = [...this.all()];
    const duplicateIds: (string | number)[] = [];
    for (const value of values) {
      const id = this.getId(value);
      if (all.some(o => this.getId(o) === id))
        duplicateIds.push(id);
      all.push(value);
    }
    if (duplicateIds.length > 0)
      throw new Error(
        `[CollectionService] addMany: items with ids ${duplicateIds.join(',')} already exists in the collection.`
      );
    this._all.set(all);
  }

  patch(patch: Patch<T>): void {
    const all = [...this.all()];
    const index = all.findIndex(o => this.getId(o) === patch.id);
    if(index === -1)
      throw new Error(
        `[CollectionStore] patch: item with id ${patch.id} does not exist in the collection.`
      );
    all.splice(index, 1, {...all[index], ...patch.changes} as T);
    this._all.set(all);
  }

  patchMany(patches: Patch<T>[]): void {
    const all = [...this.all()];
    const notFoundIds: (string | number)[] = [];

    for (const patch of patches) {
      const index = all.findIndex(o => this.getId(o) === patch.id);
      if (index === -1) {
        notFoundIds.push(patch.id);
        continue;
      }
      all.splice(index, 1, {...all[index], ...patch.changes} as T);
    }
    if (notFoundIds.length > 0)
      throw new Error(
        `[CollectionStore] patchMany: items with ids ${notFoundIds.join(',')} not found in the collection.`
      );
    this._all.set(all);
  }

  update(value: T): void {
    const all = [...this.all()];
    const id = this.getId(value);
    const index = all.findIndex(o => this.getId(o) === id);
    if (index === -1)
      throw new Error(
        `[CollectionService] update: item with id ${id} does not exist in the collection.`
      );
    all.splice(index, 1, value);
    this._all.set(all);
  }

  updateMany(values: T[]): void {
    const all = [...this.all()];
    const missingIds: (string | number)[] = [];
    for (const value of values) {
      const id = this.getId(value);
      const index = all.findIndex(o => this.getId(o) === id);
      if (index === -1)
        missingIds.push(id);
      else
        all.splice(index, 1, value);
    }
    if (missingIds.length > 0)
      throw new Error(
        `[CollectionService] updateMany: items with ids ${missingIds.join(',')} do not exist in the collection.`
      );
    this._all.set(all);
  }

  remove(valueOrId: string | number | T): void {
    if(typeof valueOrId === 'object')
      valueOrId = this.getId(valueOrId);
    const index = this.all().findIndex(o => this.getId(o) === valueOrId);
    if (index === -1)
      throw new Error(
        `[CollectionStore] remove: item with id ${valueOrId} does not exist in the collection.`
      );
    const all = [...this.all()];
    all.splice(index, 1);
    this._all.set(all);
  }

  removeMany(valuesOrIds: (string | number | T)[]): void {
    const all = [...this.all()];

    const notFoundIds: (string | number)[] = [];
    for (let valueOrId of valuesOrIds) {
      if(typeof valueOrId === 'object')
        valueOrId = this.getId(valueOrId);
      const index = all.findIndex(o => this.getId(o) === valueOrId);
      if (index === -1) {
        notFoundIds.push(valueOrId);
        continue;
      }
      all.splice(index, 1);
    }
    if (notFoundIds.length > 0)
      throw new Error(
        `[CollectionStore] removeMany: items with ids ${notFoundIds.join(',')} not found in the collection.`
      );

    this._all.set(all);
  }

  addOrUpdate(value: T): void {
    const all = [...this.all()];
    const id = this.getId(value);
    const index = all.findIndex(o => this.getId(o) === id);
    if(index === -1)
      all.push(value);
    else
      all.splice(index, 1, value);
    this._all.set(all);
  }

  addOrUpdateMany(values: T[]): void {
    const all = [...this.all()];
    for (const value of values) {
      const id = this.getId(value);
      const index = all.findIndex(o => this.getId(o) === id);
      if(index === -1)
        all.push(value);
      else
        all.splice(index, 1, value);
    }
    this._all.set(all);
  }

  clear(): void {
    this._all.set([]);
  }

  reset(): void {
    this._all.set(this.options.defaultItems || []);
  }

  protected getId = (item: T): string | number => {
    if(this.options.idFunc)
      return this.options.idFunc(item);
    return item['id'];
  }
}

export const createCollectionServiceToken = <T extends object>(name: string, options?: CollectionServiceOptions<T>) => {
  return new InjectionToken(`CollectionService<T>:${name}`, {factory: () => {
      return new CollectionService<T>(options);
    }});
}
