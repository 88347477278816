import { Component, OnDestroy, OnInit } from '@angular/core';
import { groupBy, QueryStore, RequestError, Store } from '@softline/core';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Timestamp } from '../../data/timestamp.model';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  showRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampQuery } from '../../data/timestamp.query';
import {
  SOFTLINE_FEATURE_TIMESTAMP,
  TimestampStore,
} from '../../stempelerfassung.shared';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { TimestampAppComponent } from '../timestamp-app.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'lib-query-result',
    templateUrl: './query-result.component.html',
    styleUrls: ['./query-result.component.scss'],
    imports: [CommonModule, UiCoreModule]
})
export class QueryResultComponent implements OnInit, OnDestroy, BackNavigable {
  queryParams$: Observable<any> = this.route.queryParams;

  dates$ = this.store
    .observe(SOFTLINE_FEATURE_TIMESTAMP, TimestampStore.getters.all)
    .pipe(
      map((timestamps) =>
        groupBy([...timestamps], (o) =>
          moment(o.timestamp).startOf('day').toISOString()
        )
      )
    );

  constructor(
    private store: Store,
    protected router: Router,
    protected route: ActivatedRoute,
    private backNavigationService: BackNavigationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      '#TIMESTAMP.TITLE_RESULT'
    );
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: QueryResultComponent,
        commands: this.createCommands(),
      }
    );

    const params = this.route.snapshot.queryParams;
    const query: TimestampQuery = {
      employeeid: +params['employeeid'],
      from: params['from'],
      to: params['to'],
    };
    this.store.commit(
      SOFTLINE_FEATURE_TIMESTAMP,
      TimestampStore.mutations.clear
    );
    this.store.commit(
      SOFTLINE_FEATURE_TIMESTAMP,
      TimestampStore.mutations.query.setQuery,
      query
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_TIMESTAMP,
      TimestampStore.actions.loadMany,
      { queryParams: query as any }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      QueryResultComponent
    );
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/stempelerfassung', 'query']);
  }

  createCommands(): Command[] {
    const query = this.store.get(
      SOFTLINE_FEATURE_TIMESTAMP,
      QueryStore.getters.query.query
    );
    const commands = [
      {
        icon: 'fa-regular fa-list',
        name: '#TIMESTAMP.QUERY_RESULT',
        class: 'menu main-menu main-menu-top',
        routerLink: ['timestamp', 'query', 'result'],
        routerLinkParams: { queryParams: query as object },
      },
    ];
    return commands;
  }

  sortDates(a: Timestamp, b: Timestamp): number {
    return moment(a.timestamp) < moment(b.timestamp) ? -1 : 1;
  }

  onDelete(entity: Timestamp): void {
    this.store
      .dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, {
        question: '#TIMESTAMP.ASK.CONFIRM_DELETE',
        dismiss: { escape: true, backdrop: true },
      })
      .then(async (result) => {
        if (result !== 'YES') {
          return;
        }
        try {
          this.store.commit(
            SOFTLINE_FEATURE_TIMESTAMP,
            TimestampStore.mutations.remove,
            entity
          );
          await this.store.dispatch(
            SOFTLINE_FEATURE_MESSAGE_BAR,
            MessageBarStore.actions.success,
            {
              title: '#TIMESTAMP.MESSAGES.REMOVE_SUCCESS.TITLE',
              message: '#TIMESTAMP.MESSAGES.REMOVE_SUCCESS.MESSAGE',
            }
          );
        } catch (e) {
          if (e instanceof RequestError) {
            await this.store.dispatch(
              SOFTLINE_FEATURE_MESSAGE_BAR,
              MessageBarStore.actions.error,
              {
                title: '#TIMESTAMP.MESSAGES.REMOVE_FAILURE.TITLE',
                message: '#TIMESTAMP.MESSAGES.REMOVE_FAILURE.MESSAGE',
              }
            );
          } else {
            showRequestErrors(this.store, e);
          }
        }
      });
  }
}
