import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent, IsDefinedNotEmptyPipe } from '@softline/ui-core';
import { Supplier } from '../../types/supplier';

@Component({
  selector: 'soft-supplier-card',
  imports: [CommonModule, IsDefinedNotEmptyPipe, ChipComponent],
  templateUrl: './supplier-card.component.html',
  styleUrl: './supplier-card.component.scss',
})
export class SupplierCardComponent {
  supplier = input<Supplier | null>(null);
}
