import { Dictionary } from '../../types/dictionary';

export interface Request<TLocation, T = undefined> {
  type: 'get' | 'create' | 'update' | 'patch' | 'delete';
  location: TLocation;
  payload?: T;
}

export interface Response<TLocation, TResponse, TRequest = undefined> {
  request: Request<TLocation, TRequest>;
  ok: boolean;
  body?: TResponse | null;
  meta?: Dictionary<unknown>;
  status?: number;
  message?: string;
}

export type RequestEvent<TResponse> =
  | SentRequestEvent
  | ResponseRequestEvent<TResponse>
  | ProgressRequestEvent;

export interface SentRequestEvent {
  type: 'sent';
}

export interface ResponseRequestEvent<TResponse> {
  type: 'response';
  response: TResponse | null;
}

export interface ProgressRequestEvent {
  type: 'progress';
  direction: 'upstream' | 'downstream';
  loaded: number;
  total: number | null;
}
