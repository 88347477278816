import { createHttpResourceLocation, HttpResourceLocation } from '../../data-access/types/http.location';
import { ResourceService } from '../../data/abstraction';
import { CRUDParameters, Repository } from '../abstraction';
import { Observable } from 'rxjs';
import { ReadonlyHttpRepository } from './readonly-http.repository';

export class HttpRepository<T, TResult> extends ReadonlyHttpRepository<TResult> implements Repository<T, TResult> {
  constructor(service: ResourceService<HttpResourceLocation>,
              url: string) {
    super(service, url);
  }

  create(value: T, params?: CRUDParameters | undefined): Observable<TResult> {
    const location = createHttpResourceLocation({ url: this.url, pathParams: params?.pathParams });
    return this.service.create(location, value);
  }
  update(value: T, params?: CRUDParameters | undefined): Observable<TResult> {
    const location = createHttpResourceLocation({ url: this.url, pathParams: params?.pathParams });
    return this.service.update(location, value);
  }
  patch(patch: Partial<T>, params?: CRUDParameters | undefined): Observable<TResult> {
    const location = createHttpResourceLocation({ url: this.url, pathParams: params?.pathParams });
    return this.service.patch(location, patch);
  }
  delete(params?: CRUDParameters | undefined): Observable<TResult> {
    const location = createHttpResourceLocation({ url: this.url, pathParams: params?.pathParams });
    return this.service.delete(location);
  }
}
