<div class="grid grid-rows-[auto_1fr_auto] md:grid-rows-[auto_auto_1fr_auto] min-h-full max-h-full">
  <div class="soft-page-header"
       [ngClass]="{'!hidden': !header()?.template() && menu.menuItems().length === 0}">
    <header class="flex flex-row items-center flex-nowrap gap-4">
      <div class="w-full">
        <ng-container
          *ngTemplateOutlet="header()?.template() ?? null"
        ></ng-container>
      </div>
      <soft-menu-outlet name="responsive" class="!hidden md:!flex flex-row !w-auto flex-nowrap shrink-0 justify-end items-center top-responsive-menu" #menu></soft-menu-outlet>
    </header>
  </div>

  <nav class="!hidden md:!flex !z-0 bg-surface overflow-x-auto">
    <soft-menu-outlet name="tab" class="top-tab-menu"></soft-menu-outlet>
  </nav>

  <main [ngClass]="blockScrollY() ? 'overflow-y-hidden' : 'overflow-y-auto'" class="px-4 overflow-x-hidden" softObserveElementSize (sizeChange)="contentSizeChanged($event)">
    @if (selectedTabTemplate(); as template) {
      <ng-container *ngTemplateOutlet="template"></ng-container>
    }
    <ng-content></ng-content>
  </main>

  <nav class="soft-page-bottom bg-transparent overflow-x-auto" style="align-self: end">
    <soft-menu-outlet name="responsive" class="md:!hidden bottom-responsive-menu"></soft-menu-outlet>
    <soft-menu-outlet name="tab" class="md:!hidden bottom-tab-menu bg-surface"></soft-menu-outlet>
    <soft-menu-outlet name="page-bottom" class="bottom-menu"></soft-menu-outlet>
  </nav>
</div>
