import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";
import {IsDefinedNotEmptyPipe} from '@softline/ui-core';

@Component({
    selector: 'soft-field-ok-data-view',
    imports: [CommonModule, IsDefinedNotEmptyPipe],
    templateUrl: './ktostamm-kunde-subnummer.list-view.html',
    styleUrls: ['./ktostamm-kunde-subnummer.list-view.scss']
})
export class KtostammKundeSubnummerListView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
