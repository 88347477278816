import { DocumentInformation } from '../types/document-information';


export function createCsvDocumentUserList (documentInformation: DocumentInformation): Blob {
  let csv = '';

  // Headings (first row)
  const col1 = 'username';
  const col2 = 'anwender';
  const col3 = 'favourite';
  const col4 = 'downloadedAt';
  const col5 = 'gelesen';
  const cols = [col1, col2, col3, col4, col5]

  cols.forEach((col, index) => {
    csv += col + ((index < cols.length - 1) ? ',' : '\r\n');
  });

  // Loop each property of the object
  for (let row = 0; row < documentInformation.userInformationResponseList.length; row++) {
    const item = documentInformation.userInformationResponseList[row];
    const col1 = item.anwender.anwender;
    const col2 = item.anwender.anwenderName;
    const col3 = item.webDocFavorit.favourite ? 'Ja' : 'Nein';
    const col4 = item.downloadHistory.downloadedAt ?? '';
    const col5 = item.downloadHistory.downloadedAt ? 'Ja' : 'Nein';

    const cols = [col1, col2, col3, col4, col5]

    cols.forEach((col, index) => {
      csv += col + ((index < cols.length - 1) ? ',' : '\r\n');
    });
  }

  const BOM = new Uint8Array([0xEF,0xBB,0xBF]);
  return new Blob([BOM, csv], { type: 'text/csv' });
}
