@let stockAmount = this.stockAmount();
<div class="flex flex-col justify-center items-end">
  <div class="flex flex-row flex-nowrap color-light items-center text-xl">
    <span>{{stockAmount.menge}}</span>
    <span class="ml-1">{{stockAmount.einheit.arteh}}</span>
  </div>
  @if (stockAmount.einheit.id !== stockAmount.einheit_lg.id) {
    <div class="flex flex-row flex-nowrap color-light items-center text-xl">
      <span class="whitespace-nowrap">= {{stockAmount.menge_lg}}</span>
      <span class="ml-1">{{stockAmount.einheit_lg.arteh}}</span>
    </div>
  }
</div>
