export function saveAs(data: Blob, filename: string) {
  const type = getMimeType(filename);
  console.log('mimetype', type);
  const blob = new Blob([data], { type });

  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;
  link.download = filename;
  link.click();
}

export function openBlob(data: Blob, filename: string, target = '_blank') {
  const type = getMimeType(filename);
  const blob = new Blob([data], { type });
  const downloadURL = window.URL.createObjectURL(blob);
  window.open(downloadURL, target)
}

export function getMimeType(filename: string): string | undefined {
  const extension = /\.([a-zA-Z0-9]+)$/.exec(filename);
  if (!extension) return undefined;
  switch (extension[1]) {
    case 'avi':
      return 'video/x-msvideo';
    case 'bmp':
      return 'image/bmp';
    case 'csv':
      return 'string/csv';
    case 'doc':
      return 'application/ms-word';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'gz':
      return 'application/gzip';
    case 'gif':
      return 'image/gif';
    case 'ics':
      return 'string/calendar';
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    case 'json':
      return 'application/json';
    case 'mp3':
      return 'audiop/mpeg';
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'rar':
      return 'application/vnd.rar';
    case 'tar':
      return 'application/x-tar';
    case 'txt':
      return 'string/plain';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'zip':
      return 'application/zip';
    case '7z':
      return 'application/x-7z-compressed';
    default:
      return undefined;
  }
}

export function getExtension(mimetype: string): string {
  switch (mimetype) {
    case 'video/x-msvideo':
      return 'avi';
    case 'image/bmp':
      return 'bmp';
    case 'string/csv':
      return 'csv';
    case 'application/ms-word':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/gzip':
      return 'gz';
    case 'image/gif':
      return 'gif';
    case 'string/calendar':
      return 'ics';
    case 'image/jpeg':
      return 'jpeg';
    case 'application/json':
      return 'json';
    case 'audio/mpeg':
      return 'mp3';
    case 'application/pdf':
      return 'pdf';
    case 'image/png':
      return 'png';
    case 'application/vnd.rar':
      return 'rar';
    case 'application/x-tar':
      return 'tar';
    case 'string/plain':
      return 'txt';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/zip':
      return 'zip';
    case 'application/x-7z-compressed':
      return '7z';
    default:
      return 'unknown';
  }
}
